<div class="container text-center">
    <div class="row">
        <div class="col align-self-center">
            <div class="page-title">
                <h2>{{ titleTranslateKey | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col align-self-center">
                <div class="mx-auto col-md-6 col-xs-12">
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                <input type="hidden" formControlName="userType"/>
                                <fieldset class="col2-set">
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.name' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="name" class="form-control"
                                                   [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors }"/>
                                        <div *ngIf="f.confirmPassword.touched && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">{{'user_profile.name_required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.country' | translate}}</label> <span class="required">*</span>
                                        <select formControlName="code" size="1" class="form-select"
                                                [attr.autocomplete]="'off'"
                                                [ngClass]="{ 'is-invalid': f.code.touched  &&f.code.errors } ">
                                            <option [value]="0">{{'common.select_country' | translate}}</option>
                                            <option *ngFor="let c of countries"
                                                    [ngValue]="c.id">{{ c.name }}</option>
                                        </select>
                                        <div *ngIf="f.code.touched && f.code.errors" class="invalid-feedback">
                                            {{'common.select_country_from_list' | translate}}
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'signup.phone_number' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="phone" class="form-control"
                                        [ngClass]="{ 'is-invalid': f.phone.touched && f.phone.errors }"
                                        [attr.autocomplete]="'off'"
                                        [placeholder]="'common.phone_with_code' | translate"/>
                                        <div *ngIf="f.phone.touched && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">{{'login.phone_number_required' | translate}}</div>
                                            <div *ngIf="f.phone.errors.phoneMustValid">Phone number is not valid</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.email' | translate}}</label> <span class="required">*</span>
                                        <input type="email" formControlName="email" class="form-control"
                                                   [attr.autocomplete]="'off'"
                                                   [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"/>
                                        <div *ngIf="( f.email.errors && f.email.touched )"
                                                 class="invalid-feedback">
                                            <div *ngIf="f.email != null && f.email.errors.required">
                                                        {{'signup.email_required' | translate}}
                                            </div>
                                            <div *ngIf="f.email != null && f.email.errors.email">
                                                        {{'signup.invalid_email' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.select_sector' | translate}}</label> <span class="required">*</span>
                                        <select formControlName="sectorId" class="form-select"
                                                        [ngClass]="{ 'is-invalid': f.sectorId.touched && f.sectorId.errors} ">
                                                    <option *ngFor="let sector of sectors"
                                                            [ngValue]="sector.id">{{ usingFrench? sector.nameFr : sector.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'login.password' | translate}}</label> <span class="required">*</span>
                                        <input type="password" formControlName="password" class="form-control"
                                                       [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }"/>
                                        <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                                            <div> {{ f.password.errors.pattern | json}} </div>
                                            <div *ngIf="f.password.errors.required">{{'login.password_required' | translate}}</div>
                                            <div *ngIf="f.password.errors.minlength != null">{{'signup.invalid_password' | translate}}</div>
                                            <div *ngIf="f.password.errors.pattern !== null">{{'signup.invalid_password_pattern' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'signup.confirm_password' | translate}}</label> <span class="required">*</span>
                                        <input type="password" formControlName="confirmPassword" class="form-control"
                                        [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }"/>
                                        <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPassword.errors.mustMatch">{{'signup.password_mismatch' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <button type="submit" [disabled]="registerForm.invalid || submitted" class="btn btn-success" title="register"
                                                name="send" id="send2">
                                            <span>{{ (submitted ? 'common.pending' : 'common.register') | translate}}</span>
                                        </button>
                                        &nbsp;
                                        &nbsp;
                                        <a [routerLink]="['/signin']" class="back_link">
                                            {{'common.back_to_login' | translate}}
                                        </a>
                                    </div>
                                </fieldset>
                                <p id="success-messages" style="margin-top: 10px; color: #0c812c;"></p>
                                <p id="error-messages" class="required" style="margin-top: 10px;"></p>
                            </form>
                            <div class="col-md-12 mb-3 mt-5" style="text-align: center;">
                                <p style="font-size: 14px;">
                                    {{'common.need_business_acc' | translate}} ?
                                    <a [routerLink]="['/register-business']" style="font-size: 14px;font-weight: bold;color: #0c812c;"> {{'common.click_here' | translate}} </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


