import {Injectable} from '@angular/core';


@Injectable()
export class ProgressBarService {

    color = '#9aae51';
    mode = 'determinate';
    value = 0;
    bufferValue = 100;

    hideProgressBar() {
        document.getElementById('app-bar').style.display = 'none';
    }

    start() {
        document.getElementById('app-bar').style.display = 'block';
        this.value = 0;
        this.incrementValue();
    }

    incrementValue() {
        const  context = this;
        setTimeout(function () {
            context.value += 30;
        }, 1000);
    }

    done () {
        this.value = 100;
        this.hideProgressBar();
    }

    stopAndHide() {
        this.value = 0;
        this.hideProgressBar();
    }
}
