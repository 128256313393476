<div class="recent-orders">
    <div class="title-buttons">
        <strong>{{'common.product_categories' | translate}}</strong>
    </div>
    <div class="table-responsive">
        <table class="table table-striped" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th colspan="2">{{'common.name' | translate}}</th>
                <th>{{'common.type' | translate}}</th>
                <th>{{'common.parent' | translate}}</th>
                <th>
                    <button (click)="add_product_category()"
                            class="button button-submit button-md"> {{'common.add' | translate}}
                    </button>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading">
            <tr>
                <td colspan="5" style="text-align: center">{{'common.loading' | translate}} ...</td>
            </tr>
            </tbody>
            <tbody *ngIf="!loading">
                <tr>
                    <td style="font-weight: bold;">Français</td>
                    <td style="font-weight: bold;">English</td>
                    <td style="font-weight: bold;" colspan="4"></td>
                </tr>
                <tr *ngFor="let category of categories | paginate: config ">
                    <td>{{ category.nameFr }}</td>
                    <td>{{ category.name }}</td>
                    <td>{{ category.type }}</td>
                    <td>{{ category.parent ? category.parent.name : '' }}</td>
                    <td>
                        <img *ngIf="category.picture" [src]="printImage(category)"
                             style="border: 1px solid #ccc;"/>
                        <ng-template #nopic>
                            <span>No picture</span>
                        </ng-template>
                    </td>
                    <td>
                        <span class="nobr">
                            <a (click)="edit_product_category(category)">{{'common.edit' | translate}}</a>
                            <span class="separator"> | </span>
                            <a (click)="delete_product_category(category)">{{'common.delete' | translate}}</a>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
        <div class="pager">
            <pagination-controls class="pages" maxSize="5" [previousLabel]="'common.previous' | translate" [nextLabel]="'common.next' | translate" autoHide="true"
                                 id="listing" (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </div>
</div>
