import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OrderService } from 'src/app/_services/order.service';
import { Order } from 'src/app/_models/order';
import { User } from 'src/app/_models/user';
import { IPagination } from 'src/app/_models/pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CartService } from 'src/app/_services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit, OnDestroy {
  title = 'My orders';

  titleKey = "sidebar.my_orders"

  private subscriptions: Subscription[] = [];

  loading = true;
  orders: Order[];
  config: any;
  user: User;
  page = '1';

  constructor(private titleService: Title,
    private orderService: OrderService,

    private cartService: CartService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService) {

    const subs0 = this.translateService.get(this.titleKey).subscribe(value => {
      this.title = value;
      this.titleService.setTitle(this.title);
    });

    this.subscriptions.push(subs0);

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.page = this.activatedRoute.snapshot.queryParamMap.get('page');
    this.page = this.page == null ? '1' : this.page;
  }

  ngOnInit() {
    this.loadOrders();
  }

  loadOrders() {
    this.loading = true;
    this.orderService
      .loadOrders(this.user.id, this.page)
      .subscribe((res: IPagination) => this.setConfig(res), () => this.loading = false, () => this.loading = false);
  }

  pageChange(page) {
    this.page = page;
    this.router.navigate(['/user/' + this.user.id + '/orders'], { queryParams: { page: page } });
    this.loadOrders();
  }

  deletedOrder(orderId: number) {
    const subs0 = this.translateService.get("orders_list.confirm_delete")
      .subscribe(value => {
        if (confirm(value)) {
          const subs1 = this.orderService.deleteOrder(this.user.id, orderId).subscribe(
            () => {
              const subs2 = this.translateService.get("orders_list.deleted")
                .subscribe(value => {
                  this.toastr.success(value);
                })
              this.loadOrders();
              this.subscriptions.push(subs2);
            }
          );
          this.subscriptions.push(subs1);
        }
      });
    this.subscriptions.push(subs0)
  }

  private setConfig(result: IPagination) {
    this.orders = result.data;
    this.config = {
      currentPage: result.page,
      itemsPerPage: result.itemPerPage,
      totalItems: result.totalOfItems,
      totalOfPage: result.totalOfPage,
      id: 'listing'
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
