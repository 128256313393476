<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="page-title">
                    <h2>{{ titleKey | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9" *ngIf="loading" style="text-align: center;">{{'common.loading' | translate}}  ...</div>
            <div class="col-md-9" *ngIf="!loading">
                <app-farmer-account *ngIf="!isAdmin"></app-farmer-account>
            </div>
            <div class="col-md-3">
                <app-sidebar [currentUser]="user"></app-sidebar>
            </div>
        </div>
    </div>
</div>




