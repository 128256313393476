import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URLS, SECURE_API_URLS} from '../config/api.urls';
import { Observable } from 'rxjs';
import { MeasurementUnit } from '../_models/measurement-unit';

@Injectable()

export class MeasurementService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<MeasurementUnit[]> {
      return this.http.get<MeasurementUnit[]>(API_URLS.LIST_OF_MEASUREMENT);
  }

  create(sector: MeasurementUnit) {
      return this.http.post(SECURE_API_URLS.MEASUREMENT, sector);
  }

  update(sector: MeasurementUnit) {
      return this.http.put(SECURE_API_URLS.MEASUREMENT + '/' + sector.id, sector );
  }

  delete(sector: MeasurementUnit) {
      return this.http.delete(SECURE_API_URLS.MEASUREMENT + '/' + sector.id);
  }
}
