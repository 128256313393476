import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { OfferService } from '../../_services/offer.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IPagination } from '../../_models/pagination';
import { environment } from '../../../environments/environment';
import { CartService } from '../../_services/cart.service';
import { getPrice, isOfferInPromotion, Offer } from '../../_models/offer';
import { User } from '../../_models/user';
import { WishlistService } from 'src/app/_services/wishlist.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/_services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TaxonomyService } from 'src/app/_services/taxonomy.service';

@Component({
    selector: 'app-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.css']
})
export class ProductsListComponent implements OnInit, OnDestroy {

    title = 'Products';
    titleTranslateKey = 'common.products';

    offers: Offer[];
    loading = false;
    pricing: string = null;
    page: any;
    config: any;
    q: string;
    categorySlug: string;
    isSearch = false;
    user: User = null;
    slug = null;
    taxonomy_id = null;
    subscriptions: Subscription[] = [];

    lang = 'fr';

    constructor(private offerService: OfferService,
        private activatedRoute: ActivatedRoute,
        private cartService: CartService,
        private storeService: StoreService,
        private wishlistService: WishlistService,
        private titleService: Title,
        private toastrService: ToastrService,
        private router: Router,
        private translateService: TranslateService,
        private _sanitizer: DomSanitizer,
        private taxonomyService: TaxonomyService
    ) {
        const titleSubscription = this.translateService.get(this.titleTranslateKey).subscribe(value => {
            this.title = value;
            this.titleService.setTitle(this.title);
        });

        this.subscriptions.push(titleSubscription);
        this.page = this.activatedRoute.snapshot.queryParamMap.get('page');
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.lang = translateService.currentLang;

        const queryParam = this.activatedRoute.snapshot.queryParamMap;
        const paramMap = this.activatedRoute.snapshot.paramMap;
        this.q = queryParam.get('q') || null;
        this.page = queryParam.get('page') || '1';
        this.pricing = paramMap.get('pricing') || null;
        this.slug = paramMap.get('slug') || null;
        this.taxonomy_id = paramMap.get('taxonomy_id') || null;
    }

    ngOnInit() {
        this.loading = true;
        if (this.q) {
            this.isSearch = true;
            this.searchForOffers();
        } else {
            this.isSearch = false;
            if(this.pricing) {
                this.getOffers();
            } else if(this.slug) {
                this.getOfferByCategory();
            } else if(this.taxonomy_id) {
                this.loadOffersByTaxonomy();
            } 
        }
    }

    searchForOffers() {
        this.offerService.search(this.q, this.page)
            .subscribe(
                (result: IPagination) => {
                    this.setConfig(result);
                    this.titleService.setTitle(
                        'Search for ' + this.q  + ' | eFarm.cm ' 
                        );
                },
                (error) => {},
                () => this.loading = false
            );
    }

    getOffers() {
        this.offerService.loadLastProductsByPricing(this.pricing, this.page)
            .subscribe(
                (result: IPagination) => {
                    this.setConfig(result);
                    this.titleService.setTitle(
                    (result.data.length > 0 ? 
                        result.data[0].pricing.name : this.pricing) + '| eFarm.cm ' 
                    );
                },
                (error) => {},
                () => this.loading = false
            );
    }

    getOfferByCategory() {
        this.offerService.loadOffersByCategory(this.slug, this.page)
            .subscribe(
                (result: IPagination) => {
                    this.setConfig(result);
                    this.titleService.setTitle(
                        (result.data.length > 0 ? 
                            result.data[0].product.category.name : this.categorySlug) + ' en vente | eFarm.cm ' 
                        );
                },
                (error) => {},
                () => this.loading = false
            );
    }

    getHTMLText(htmlText): SafeHtml {
        return this._sanitizer.sanitize(SecurityContext.HTML, htmlText).replace(/<[^>]*>/g, '');
    }

    loadOffersByTaxonomy() {
        this.taxonomyService.getTaxonomyOffers(this.taxonomy_id, this.page)
        .subscribe(
            (result: IPagination) => {
                this.setConfig(result);
                this.titleService.setTitle(
                    'Taxonomy | eFarm.cm '  
                    );
            },
            (error) => {},
            () => this.loading = false
        );
    }

    pageChange(page) {
        this.page = page;
        this.loading = true;
        if(this.pricing){
            this.router.navigate(['products', this.pricing], { queryParams: { page: page } });
            this.getOffers();
        } else if(this.slug) {
            this.router.navigate(['products','category', this.slug], { queryParams: { page: page } });
            this.getOfferByCategory();
        } else if(this.taxonomy_id) {
            this.router.navigate(['products','taxonomy', this.taxonomy_id], { queryParams: { page: page } });
            this.loadOffersByTaxonomy();
        }
    }

    isOfferInCart(offer) {
        return this.storeService.findItem(offer.id);
    }

    addToCart(offer) {
        this.cartService.addToLocalCart(offer)
            .subscribe(() => {
                const addCartMessage = this.translateService.get('common.message_for_added_to_cart')
                    .subscribe(message => {
                        this.toastrService.success(message);
                    });

                this.subscriptions.push(addCartMessage);
            });
    }

    // getImageSrc(imagePath: string) {
    //     return imagePath !== '' && imagePath !== null && imagePath.includes('media')
    //         ? environment.image_cdn_url + '/' + imagePath : '/assets/images/default.jpg';
    // }

    getImageSrc(offer: Offer) {
        return environment.image_cdn_url + '/' +
            (offer.defaultPicture.length > 0 ? offer.defaultPicture + '?func=crop&width=252&height=252' : offer.product.picture);
    }

    printImage(product) {
        return this.getImageSrc(product.picture);
    }

    isInPromotion(offer: Offer): boolean {
        return isOfferInPromotion(offer);
    }

    getOfferPrice(offer: Offer): number {
        return getPrice(offer);
    }

    addToWishlist(offerId: number) {
        this.wishlistService.addToWishList(this.user.id, offerId).subscribe();
    }

    private setConfig(result: any) {
        this.offers = result.data;
        this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            totalOfPage: result.totalOfPage,
            id: 'listing'
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
