<div class="recent-orders">
    <div class="title-buttons">
        <strong>{{'common.measurement_units' | translate}}</strong>
    </div>
    <div class="table-responsive">
        <table class="table table-striped" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th colspan="2">{{'common.name' | translate}}</th>
                <th>{{'common.status' | translate}}</th>
                <th>
                    <button (click)="add_measurementUnit()"
                            class="button button-submit button-md"> {{'common.add' | translate}}
                    </button>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading">
            <tr>
                <td colspan="4" style="text-align: center">{{'common.loading' | translate}} ...</td>
            </tr>
            </tbody>
            <tbody *ngIf="!loading">
                <tr>
                    <td style="font-weight: bold;">{{'measurement-units.french_name' | translate}}</td>
                    <td style="font-weight: bold;">{{'measurement-units.english_name' | translate}}</td>
                    <td colspan="2"></td>
                </tr>
            <tr *ngFor="let unit of measurementUnits">
                <td>{{ unit.descriptionFr }}</td>
                <td>{{ unit.description }}</td>
                <td>{{ unit.status }}</td>
                <td>
                       <span class="nobr">
                           <a (click)="edit_measurementUnit(unit)">{{'common.edit' | translate}}</a>
                           <span class="separator"> | </span>
                           <a (click)="delete_measurementUnit(unit)">{{'common.delete' | translate}}</a>
                       </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
    </div>
</div>
