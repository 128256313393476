import {Component, OnDestroy, OnInit} from '@angular/core';
import { PageService } from '../_services/page.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Page } from '../_models/page';
import {Subscription} from 'rxjs';
import {AppConstants} from '../_constants/constants';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {

  slug: string;
  loading = true;
  page: Page;
  pageSubscription: Subscription;
  loadingSubscription: Subscription;
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(private pageService: PageService, private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    this.pageSubscription = this.pageService.pageSubject.subscribe(
        (page: Page) => {
          this.page = page;
        },
        () => this.router.navigateByUrl('/'),
        () => this.loading = false
    );

    this.loadingSubscription = this.pageService.loadingSubject.subscribe(
        (v: boolean) => {
          this.loading = v;
        }
    );

  }

  ngOnInit() {
    this.pageService.getPage(this.slug);
  }


  ngOnDestroy(): void {
    this.pageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }

}
