import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContactService } from '../_services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy{

  title = 'Contact Us';
  titleTranslateKey = "footer.contact_us";
  subscriptions: Subscription[] = []
  form: UntypedFormGroup;
  submitting = false;
  loading = true;
  testimonials = []


  constructor(
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private contactService: ContactService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private translateService: TranslateService
    ) {

    console.log('First line');
    const translateTitleSubscription = this.translateService.get(this.titleTranslateKey)
    .subscribe(value => {
      this.title = value;
      this.titleService.setTitle(this.title);
    })

    this.subscriptions.push(translateTitleSubscription);

    this.form = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      subject: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required]),
      body: new UntypedFormControl('', [Validators.required]),
    });
    this.loading = false;
  }

  ngOnInit(): void {
    this.testimonials = [
    {
      url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/CPB9USD9jZ8'),
      content: '',
      author: 'Daral Addison',
      role: 'Founder/CEO Torpedopot.com',
      externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/CPB9USD9jZ8')
    },
    {
      url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/WvDgt_Wmu7w'),
      content: '',
      author: 'Rebecca MBIEH',
      role: 'Legal Counsel, Cameroon',
      externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/WvDgt_Wmu7w')
    },
    {
      url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/GaPY27MXMns'),
      content: '',
      author: 'Ayah W. Abine (Coach TIL)',
      role: 'Productivity & Business Leadership Coach/Consultant, Cameroon',
      externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/GaPY27MXMns')
    },
    {
      url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Bf3vRAP7RUs'),
      content: '',
      author: 'Ngwang Jonathan',
      role: 'Mondial Hotel, Cameroon',
      externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/Bf3vRAP7RUs')
    },{
      url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/E7OkDvjEoIE'),
      content: '',
      author: 'Killah Caleb',
      role: 'Poultry Farmer, Cameroon',
      externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/E7OkDvjEoIE')
    }
  ];
  }


  mailUs() {
    this.submitting = true;
    this.toastr.success('mail successfully sent');
    this.contactService.sendMail(this.form.value).subscribe(
      () => {
        this.submitting = false;
        this.form.reset();
        this.form.setErrors(null);
      }
    );
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  locations = [
    {
      name: 'eFarm.CM Littoral, CMR',
      tel: '+237679635621',
      email: 'littoral237@efarmcm.com',
      whatsapp: '+237 681 763 523 / +237 673 23 70 50'
    },
    {
      name: 'eFarm.CM Center, CMR',
      tel: '+237654156871',
      email: 'center237@efarmcm.com',
      whatsapp: '+237 654 156 871'
    },
    {
      name: 'eFarm.CM South West, CMR',
      tel: '+237672447978',
      email: 'sw237@efarmcm.com',
      whatsapp: '+237 654 777 419'
    },
    {
      name: 'eFarm.CM North West, CMR',
      tel: '+237671219698',
      email: 'nw237@efarmcm.com',
      whatsapp: '+237 671 219 698'
    }
  ]
}
