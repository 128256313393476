<div class="col-lg-12">
  <div class="panel panel-default">
    <div class="panel-heading">

    </div>
    <div class="panel-body">
      <p *ngIf="loading; else form">
        Loading ...
      </p>
      <ng-template #form>
       <div class="card">
        <div class="card-body">
          <form [formGroup]="userForm" novalidate *ngIf="!loading" (ngSubmit)="userForm.valid && submitUserForm()">
            <input type="hidden" formControlName="id"/>
            <div class="form-group mt-2" *ngIf="userToEdit == null; else userType ">
              <label>Account type : </label>
              <select formControlName="userType"  class="form-control"  [disabled]="userToEdit != null">
                <option *ngFor="let type of userTypes" [value]="type">{{ type }}</option>
              </select>
            </div>
            <ng-template #userType>
              <input type="hidden" [value]="userToEdit.role">
            </ng-template>
            <div class="form-group mt-2" *ngIf="isBusinessAccount(userToEdit) else businessType ">
              <label>Business Type : <span class="required">*</span></label>
              <select formControlName="businessType" class="form-control">
                <option *ngFor="let bt of businessTypes" value="{{bt.name}} " >{{ bt.name}}: {{bt.description}}</option>
              </select>
            </div>
            <div class="col-md-12 mt-2">
              <label>{{'common.select_sector' | translate}}</label> <span class="required">*</span>
              <select [disabled]="loading" formControlName="sectorId" class="form-select"
                              [ngClass]="{ 'is-invalid': f.sectorId.touched && f.sectorId.errors} ">
                          <option *ngFor="let sector of sectors"
                                  [ngValue]="sector.id">{{ usingFrench? sector.nameFr : sector.name }}</option>
              </select>
          </div>
            <ng-template #businessType>
              <input type="hidden" formControlName="businessType"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </ng-template>
            <div class="form-group mt-2">
              <label> {{ isBusinessAccount(userToEdit) ? 'Business Name' : 'Name' }} : <span class="required">*</span></label>
              <input type="text" formControlName="name"  class="form-control" [attr.autocomplete]="'off'">
              <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                Name is required
              </em>
            </div>

            <div class="form-group mt-2" *ngIf="isBusinessAccount(userToEdit) else contactPersonName">
              <label>Contact Person's name : </label>
              <input type="text" formControlName="contactName"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </div>
            <ng-template #contactPersonName>
              <input type="hidden" formControlName="contactName"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </ng-template>
            <div class="form-group mt-2" *ngIf="isBusinessAccount(userToEdit) else contactPhoneNumber">
              <label>Contact Phone number : </label>
              <input type="text" formControlName="contactPhoneNumber"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </div>
            <ng-template #contactPhoneNumber>
              <input type="hidden" formControlName="contactPhoneNumber"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </ng-template>

            <div class="form-group mt-2">
              <label>Country : <span class="required">*</span> </label>
              <select formControlName="code"  class="form-control">
                <option  *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
              </select>
              <em *ngIf="(f.code.touched && f.code.invalid)" class="text-danger">
                Country is required
              </em>
            </div>
            <div class="form-group mt-2" *ngIf="isBusinessAccount(userToEdit) else businessState ">
              <label>State <span class="required">*</span></label>
              <input type="text" formControlName="state"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </div>
            <ng-template #businessState>
              <input type="hidden" formControlName="state"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
            </ng-template>
            <div class="form-group mt-2">
              <label>City : <span class="required">*</span> </label>
              <input type="text" formControlName="town"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
              <em *ngIf="(f.town.touched && f.town.invalid)" class="text-danger">
                City is required
              </em>
            </div>

            <div class="form-group mt-2">
              <label>Account phone number: <span class="required">*</span></label>
              <input type="text" formControlName="phone"  class="form-control" [attr.autocomplete]="'off'">
              <em *ngIf="(f.phone.touched && f.phone.invalid)" class="text-danger">
                Phone is required
              </em>
            </div>
            <div class="form-group mt-2">
              <label>Email : </label>
              <input type="text" formControlName="email"  class="form-control" [attr.autocomplete]="'off'">
            </div>
            <div class="form-group mt-2">
              <label>Password : <span class="required" *ngIf="userToEdit == null || (userToEdit && !userToEdit.hasOwnProperty('id')) ">*</span> </label>
              <input type="password" formControlName="password"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
              <div  *ngIf="(f.password.touched && f.password.invalid)" class="text-danger">
                <em *ngIf="f.password.errors.required">Password is required</em>
                <em *ngIf="f.password.errors.minlength">Password must be at least 6 characters</em>
              </div>

            </div>
            <div class="form-group mt-2">
              <label>Confirm password : </label>
              <input type="password" formControlName="confirmPassword"  class="form-control" [attr.autocomplete]="'off'" autocomplete="off">
              <em *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid && f.confirmPassword.errors.mustMatch" class="text-danger">
                Confirm password don't match
              </em>
            </div>
            <div class="form-group mt-2">
              <button type="button" class="btn btn-default me-3" id="btn-back" (click)="back_to_previous()">Back</button>

              <button type="submit" class="btn btn-success" id="btn-submit" [disabled]="!userForm.dirty || userForm.invalid || (userForm.valid&& submitting)" >
                {{ submitting ? 'Saving ...' : 'Save' }}
              </button>
            </div>
          </form>
        </div>
       </div>
      </ng-template>
    </div>
  </div>
</div>
