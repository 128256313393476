import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Order } from 'src/app/_models/order';
import { OrderService } from 'src/app/_services/order.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit{

  orders: Order[] = [];
  loading = true;
  pageTitle = 'Orders List';
  page = 1;
  config: any;

  constructor(private orderService : OrderService, private router: Router, private titleService: Title){
    // this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.loadOrders();
  }

  loadOrders() {
    this.orderService.loadOrdersByAdmin(this.page).subscribe(
      (response) => {
        this.orders = response.data;
        this.setConfig(response);
      },
      (error) => {
        console.error(error);
      },
      () => this.loading = false
    )
  }

  private setConfig(response){
    this.config = {
      currentPage: response.page,
      itemsPerPage: response.itemPerPage,
      totalItems: response.totalOfItems,
      id: 'orders'
    };
  }

  nextPage(page) {
    this.page = page;
    this.router.navigate(['/admin', 'orders'], {
      queryParams: {
        page: page
      }
    });
    this.loading = true;
    this.loadOrders();
  }

}
