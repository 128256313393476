<div class="recent-orders">
  <div class="title-buttons">
      <strong>CRM Templates </strong>
  </div>
  <br/>
  <br/>
  
  <input [(ngModel)]="useFrench" id="show_en" name="preview-lang" type="checkbox"> &nbsp; <label for="show_en">French Version</label>
  
  <div class="table-responsive">  
      <table class="table table-striped table-users" id="my-orders-table">
          <thead>
          <tr class="first last">
              <th style="width: 20%">Subject <span *ngIf="useFrench">(FR)</span> <span *ngIf="!useFrench">(EN)</span></th>
              <th style="width: 10%">Send Mode</th>
              <th style="width: 15%">Created at</th>
              <th style="width: 5%;">
                  <button (click)="newTemplate()"
                          class="button button-submit button-md">Add</button>
              </th>
          </tr>
          </thead>
          <tbody *ngIf="loading">
              <tr>
                  <td colspan="6" style="text-align: center">Loading ...</td>
              </tr>
          </tbody>
          <tbody *ngIf="!loading">
              <tr *ngFor="let template of templates | paginate: config">
                  <td>{{ useFrench ? template.subjectFr :  template.subject }}</td>
                  <td>{{ template.sendMode }}</td>
                  <td>{{ template.createdAt | date: 'dd/MM/yyyy' }}</td>
                  <td>
                    <a (click)="edit(template)">Edit</a> | <a (click)="remove(template)">Delete</a>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
  <div class="toolbar" *ngIf="!loading">
      <div class="pager">
          <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
                               id="listing" (pageChange)="pageChange($event)"></pagination-controls>
      </div>
  </div>
</div>
