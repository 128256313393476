import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import {Observable} from 'rxjs';
import {IPagination} from '../_models/pagination';
import {RequestedProduct} from '../_models/requested-product';

@Injectable({
  providedIn: 'root'
})
export class RequestedProductService {

  constructor(private httpClient: HttpClient) {}

  findAll(page?: string ): Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.SECURE_REQUESTED_PRODUCTS_BASE_PATH + `?page=${page}`);
  }

  findById(id: number) {
    return this.httpClient.get<RequestedProduct>(API_URLS.SECURE_REQUESTED_PRODUCTS_BASE_PATH + `/${id}`);
  }

}
