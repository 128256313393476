import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryService } from '../../country/country.service';
import { ProductCategoryService } from '../../_services/product-category.service';
import { Country } from '../../country/Country';
import { ProductCategory } from '../../_models/product-category';
import { Title } from '@angular/platform-browser';
import { User } from '../../_models/user';
import { UserService } from '../../_services/user.service';
import { AlertService } from '../../_services/alert.service';
import { SectorService } from '../../_services/sector.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import { AppConstants } from 'src/app/_constants/constants';

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.css']
})
export class UserEditFormComponent implements OnInit, OnDestroy {

  titleKey = "rightnav.my_account";

  userEditForm: UntypedFormGroup;
  loading = true;
  submitting = false;
  countries: Country[];
  productCategories: ProductCategory[];
  currentUser: User;
  sectors: any[];

  usingFrench = true;

  private subscriptions: Subscription[] = [];

  constructor(public userService: UserService, private fb: UntypedFormBuilder,
    private cs: CountryService,
    private titleService: Title,
    private alertService: AlertService,
    private toastr: ToastrService,
    private location: Location,
    private sectorService: SectorService,
    private pcs: ProductCategoryService,
    private translateService: TranslateService) {
      this.currentUser = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER_KEY));
      const sub = this.translateService.get(this.titleKey).subscribe(value => {
        this.titleService.setTitle(value);
      });
      this.subscriptions.push(sub);
      this.usingFrench = this.translateService.currentLang === AppConstants.FRENCH;
    }

  ngOnInit() {
    console.log(this.currentUser);
    combineLatest([
      this.sectorService.getAll(),
      this.cs.loadAll(),
      this.pcs.get_product_categories()
    ]).subscribe(
      ([sectors, countries, categorires]) => {
        this.sectors = sectors;
        this.countries = countries;
        this.productCategories = categorires;

        this.userEditForm = this.fb.group({
          id: new UntypedFormControl(this.currentUser.id),
          name: new UntypedFormControl(this.currentUser.name, Validators.required),
          code: new UntypedFormControl(this.currentUser.code, Validators.min(1)),
          email: new UntypedFormControl(this.currentUser.email, Validators.email),
          phone: new UntypedFormControl(this.currentUser.phone, Validators.required),
          town: new UntypedFormControl(this.currentUser.town, Validators.required),
          overview: new UntypedFormControl(this.currentUser.overview),
          userType: new UntypedFormControl('user', Validators.required),
          sectorId: new UntypedFormControl(this.currentUser.sector.id, Validators.required),
          productCategoryId: new UntypedFormControl(this.currentUser.productCategoryId, Validators.min(1)),
        });

        this.loading = false;
      }
    );    
  }



  get f() {
    return this.userEditForm.controls;
  }

  updateUser() {
    if (this.userEditForm.invalid) {
      return;
    }
    this.submitting = true;
    this.userService.updateUser(this.userEditForm.value).subscribe(
      (user: User) => {
        this.submitting = false;
        const subs = this.translateService.get("user_profile.updated_message").subscribe(value => {
          this.toastr.success(value);
        });
        this.subscriptions.push(subs);
        localStorage.setItem('currentUser', JSON.stringify(user));
      },
      () => { this.submitting = false; }
    );
  }

  back_to_previous() {
    this.location.back();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
