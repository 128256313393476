<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <div class="page-title">
                <h2>{{'sidebar.edit_profile' | translate}}</h2>
            </div>
        </div>
        <!--col-xs-12-->
    </div>
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="panel-header">
                    <h2></h2>
                </div>
                <div class="card-body">
                    <p *ngIf="loading" style="padding-top: 20px;">
                        {{'common.loading' | translate}} ...
                    </p>
                    <form id="user-edit-form" [formGroup]="userEditForm"
                          (ngSubmit)="userEditForm.valid && updateUser()" novalidate *ngIf="!loading">
                        <input type="hidden" formControlName="id"/>
                        <input type="hidden" formControlName="userType"/>
                        <div class="form-group">
                            <label>{{'common.name' | translate}} : <span class="required">*</span></label>
                            <input type="text" formControlName="name" class="form-control"
                                   [attr.autocomplete]="'off'">
                            <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                                {{'user_profile.name_required' | translate}}
                            </em>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'common.country' | translate}} : <span class="required">*</span> </label>
                            <select formControlName="code" class="form-control">
                                <option *ngFor="let country of countries"
                                        [value]="country.id">{{ country.name }}</option>
                            </select>
                            <em *ngIf="(f.code.touched && f.code.invalid)" class="text-danger">
                                {{'common.country_required' | translate}}
                            </em>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'common.phone' | translate}} : <span class="required">*</span></label>
                            <input type="text" formControlName="phone" class="form-control"
                                   [attr.autocomplete]="'off'">
                            <em *ngIf="(f.phone.touched && f.phone.invalid)" class="text-danger">
                                {{'user_profile.phone_required' | translate}}
                            </em>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'common.email' | translate}} : </label>
                            <input type="text" formControlName="email" class="form-control"
                                   [attr.autocomplete]="'off'">
                            <em *ngIf="(f.email.touched && f.email.invalid)" class="text-danger">
                                {{'user_profile.invalid_email_address' | translate}}
                            </em>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'common.city' | translate}} : <span class="required">*</span> </label>
                            <input type="text" formControlName="town" class="form-control"
                                   [attr.autocomplete]="'off'">
                            <em *ngIf="(f.town.touched && f.town.invalid)" class="text-danger">
                                {{'common.city_required' | translate}}
                            </em>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'common.sector_of_activity' | translate}}</label>
                            <select formControlName="sectorId" class="form-control">
                                <option value="">{{'common.select_sector' | translate}}</option>
                                <option *ngFor="let sector of sectors"
                                        [value]="sector.id">{{ usingFrench ? sector.nameFr : sector.name }}</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="f.sectorId.touched && f.sectorId.errors.required ">
                                {{'common.must_select_sector' | translate}}
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label>{{'user_profile.overview' | translate}}</label>
                            <br/>
                            <textarea formControlName="overview" class="form-control"></textarea>
                        </div>
                        <div class="buttons-set mt-2">
                            <button type="button" class="btn btn-default me-3" id="btn-submit" (click)="back_to_previous()">{{'common.back' | translate}}</button>
                           
                            <button type="submit" class="btn btn-success" id="btn-submit" [disabled]="!userEditForm.dirty || userEditForm.invalid || (userEditForm.valid
    && submitting)">
                                {{ (submitting ? 'common.saving' : 'common.save' ) | translate}} <span *ngIf="submitting"> ...</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <app-sidebar [currentUser]="currentUser"></app-sidebar>
        </div>
    </div>
</div>
