import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS, SECURE_API_URLS } from '../config/api.urls';
import { Job } from '../_models/job';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private httpClient: HttpClient) { }

  addJob(body: any) {
    return this.httpClient.post(SECURE_API_URLS.JOBS_BASE_URL, body);
  }
  loadJob(id: any): Observable<Job> {
    return this.httpClient.get<Job>(SECURE_API_URLS.JOBS_BASE_URL + '/' + id);
  }
  updateJob(id: any, body: any) {
    return this.httpClient.put(SECURE_API_URLS.JOBS_BASE_URL + '/' + id, body);
  }

  deleteJob(id: any) {
    return this.httpClient.delete(SECURE_API_URLS.JOBS_BASE_URL + '/' + id);
  }

  loadJobsByadmin() : Observable<Job[]> {
    return this.httpClient.get<Job[]>(SECURE_API_URLS.JOBS_BASE_URL);
  }

  loadJobs(): Observable<Job[]> {
    return this.httpClient.get<Job[]>(API_URLS.JOBS_URL);
  }
}
