import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from 'src/app/_models/user';
import {CRMService} from 'src/app/_services/crm.service';
import {UserService} from 'src/app/_services/user.service';
import {OutboxEntry} from '../../_models/crm-template';
import {ToastrService} from 'ngx-toastr';
import {mergeMap} from 'rxjs/operators';

@Component({
    selector: 'app-crm',
    templateUrl: './crm.component.html',
    styleUrls: ['./crm.component.css']
})
export class CRMComponent implements OnInit {
    page = '1';
    currentUser: User;
    config: any;
    data: OutboxEntry[] = [];
    loading = false;
    title = 'crm.outbox.title';
    sending = false;

    constructor(
        private userService: UserService,
        private crmService: CRMService,
        private router: Router,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute
    ) {
        this.page = this.activatedRoute.snapshot.queryParamMap.get('page');
        this.currentUser = this.userService.getCurrntUser();
    }

    ngOnInit() {
      this.loadOutbox(this.page);
    }

    loadOutbox(page: any) {
        this.crmService.getOutbox(page == null ? 1 : page).subscribe(response => {
            this.data = response.data;
            this.config = {
                currentPage: response.page,
                itemsPerPage: response.itemPerPage,
                totalItems: response.totalOfItems,
                totalOfPage: response.totalOfPage,
                id: 'listing'
            };
        });
    }

    compose() {
        this.router.navigateByUrl('admin/outbox/compose');
    }

    send(id: number) {
        this.sending = true;
        this.crmService.send(id)
            .pipe(mergeMap( x => {
                return this.crmService.getOutbox(this.page == null ? 1 : this.page);
            })).subscribe(
            (response) => {
                this.toastrService.success('Messages are being sent');
                this.data = response.data;
                this.config = {
                    currentPage: response.page,
                    itemsPerPage: response.itemPerPage,
                    totalItems: response.totalOfItems,
                    totalOfPage: response.totalOfPage,
                    id: 'listing'
                };
            },
            () => {
                this.toastrService.error('Failed to process this action');
                this.sending = false;
            },
            () => {
                this.sending = false;
            }
        );
    }
}
