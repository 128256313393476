<div class="recent-orders">
    <div class="title-buttons">
        <strong>Requested Products</strong>
    </div>
    <br/>
    <br/>
    <div class="table-responsive">
        <table class="table table-striped products-table" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th>ID</th>
                <th>Product's name</th>
                <th>Category</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Requested by</th>
                <th>Created At</th>
            </tr>
            </thead>
            <tbody *ngIf="loading == true; then dataLoading else dataLoaded "></tbody>
            <ng-template #dataLoading>
                <tr>
                    <td colspan="7" style="text-align: center">Loading ...</td>
                </tr>
            </ng-template>
            <ng-template #dataLoaded>
                <tr *ngFor="let product of requestedProducts | paginate: config ">
                    <td>{{ product.id}}</td>
                    <td>{{ product.productName }}</td>
                    <td>{{ product.categoryName }}</td>
                    <td>{{ product.qty }}</td>
                    <td>{{ product.price }} {{ product.currency | uppercase }}</td>
                    <td>{{ product.username }}</td>
                    <td>{{ product.createdAt }}</td>
                </tr>

            </ng-template>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
        <div class="pager">
            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next"
                                 autoHide="true" id="listing" (pageChange)="pageChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>
