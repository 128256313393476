import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MediaService } from '../../_services/media.service';
import { CartService } from '../../_services/cart.service';
import { Observable } from 'rxjs';
import { ProductModel } from '../../_cart/product-model';
import { OfferService } from '../../_services/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getDiscountPrice, getPrice, isOfferInPromotion, Offer } from '../../_models/offer';
import { User } from '../../_models/user';
import { StoreService } from 'src/app/_services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
    selector: 'app-single-product',
    templateUrl: './single-product.component.html',
    styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {
    reviewStars = 0;
    finalTitle = '';
    reviewForm: FormGroup;

    slideConfig = {
        infinite: true,
        autoplay: true,
        dots: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.product-slider-single-nav',
        nextArrow: '<button class="btn nextBtn"><i class="fa fa-lg fa-angle-right"></i></button>',
        prevArrow: '<button class="btn prevBtn"><i class="fa fa-lg fa-angle-left"></i></button>',
    };

    navSlideConfig = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product-slider-single',
        nextArrow: '<button class="btn nextBtn"><i class="fa fa-lg fa-angle-right"></i></button>',
        prevArrow: '<button class="btn prevBtn"><i class="fa fa-lg fa-angle-left"></i></button>',
    }

    offer: Offer = null;
    offerId: any;
    user: User;
    mediaList = [];
    products: Observable<ProductModel[]>;
    productModel: ProductModel = null;
    relatedProducts: any[];
    reviews: any = null;
    displayReviews: any[] = [];
    relatedOffersLoading = true;
    showMoreButton = false;
    totalPage = 1;
    currentReviewPage = 1;
    usingFrench = true;
    loading = true;

    facebookShareUrl: string;
    twitterShareUrl: string;
    twitterShareText: any;
    linkedinShareUrl: string;
    instagramShareUrl: string;

    constructor(private mediaService: MediaService,private cartService: CartService,
        public userService: UserService,
        private toastService: ToastrService,
        private storeService: StoreService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private offerService: OfferService,
        private translateService: TranslateService,
        public fb: FormBuilder) {
        this.products = this.cartService.getProducts();
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.offerId = this.activatedRoute.snapshot.paramMap.get('id');
        this.productModel = this.storeService.findItem(this.offerId);
        window.scrollTo(0, 0);

        this.reviewForm = this.fb.group({
            review: [''],
            rating: []
        });

    }

    ngOnInit() {
        this.usingFrench = this.translateService.currentLang === 'fr';
        this.offerService.loadOffer(this.offerId)
            .pipe(
                switchMap((offer) => {
                    this.offer = offer;
                    this.mediaList = offer.mediaList;
                    this.finalTitle = (this.usingFrench ? 
                        offer.product.productNameFr + ' en vente à ' + offer.user.town : 
                        offer.product.name + ' on sale in ' + offer.user.town) + ' | eFarm.cm';
                    this.titleService.setTitle(this.finalTitle);
                    this.linkedinShareUrl = "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURI(window.location.href) + "&title=" + encodeURIComponent(this.offer.product.name) + "&summary=" + encodeURIComponent(this.offer.description) + "&source=efarm";
                    return this.offerService.getRelatedOffers(this.offer.product.category.id, this.offer.id);
                })
            ).subscribe(
                (relatedOffersResponse) => {
                    this.relatedProducts = relatedOffersResponse;
                    this.relatedOffersLoading = false;
                }
            );

        this.loadReviews(this.currentReviewPage).subscribe(
            (reviewResponse) => {
                if (reviewResponse.reviews.length > 0) {
                    this.displayReviews.push(...reviewResponse.reviews);
                    this.totalPage = reviewResponse.totalPage;
                }
                this.reviews = reviewResponse;
                this.loading = false;
            },
        );

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.facebookShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" 
        + window.location.href + "&amp;src=sdkpreparse";
        const twitterText = this.translateService.get('share.twitterText').subscribe(message => {
            this.twitterShareText = message;
            this.twitterShareUrl = "https://twitter.com/intent/tweet?url=" 
            + encodeURI(window.location.href) + "&text=" + encodeURIComponent(this.twitterShareText) + "&hashtags=efarm";
        });
        this.instagramShareUrl = "https://www.instagram.com/?url=" + encodeURI(window.location.href);
        window.scrollTo(0, 0);
    }

    submitReviewForm() {
        let formData = {
            "nbStars": this.reviewForm.value.rating,
            "remark": this.reviewForm.value.review
        }

        this.offerService.submitReview(this.offer.id, formData).subscribe((response: any) => {
            this.reviewForm.reset();
            window.location.reload();
        }, (error: any) => {
            if (error == 'Accès refusé') {
                this.toastService.error("Please log in to review this product")
                this.router.navigateByUrl('/signin');
            }
        })
    }

    showMore() {
        if (this.reviews.totalPage > this.currentReviewPage) {
            this.loadReviews(++this.currentReviewPage)
            if (this.reviews.totalPage === this.totalPage) {
                this.showMoreButton = false;
            }
        }
    }

    changeProductImage(current) {
        const product_zoom = document.getElementById('product-zoom');
        const current_to_zoom = document.getElementById(current);
        const imgPath = current_to_zoom.getAttribute('src');
        product_zoom.setAttribute('src', imgPath);
        product_zoom.setAttribute('data-zoom-image', imgPath);
    }

    getImageSrc(imagePath: string) {
        return imagePath !== '' && imagePath !== null && imagePath.includes('media')
            ? environment.image_cdn_url + '/' + imagePath + '?func=fit&trim=0&width=357&height=357' : '/assets/images/default.jpg';
    }

    printImage(product) {
        return this.getImageSrc(product.picture);
    }

    checkIfLazy(productImage: any) {
        const productImageElement: any = productImage && productImage.nativeElement ? productImage.nativeElement : productImage;
        return !productImageElement?.classList.contains('ng-lazyloaded');
    }

    loadReviews(page) {
        return this.offerService.getProductReviews(this.offerId, page);
    }

    setOwlCarousel() {
        $(document).ready(() => {
            $('#single-offer .slider-items').owlCarousel({
                autoplay: false,
                items: 4,
                itemsDesktop: [1024, 3],
                itemsDesktopSmall: [900, 4],
                itemsTablet: [600, 3],
                itemsMobile: [320, 2],
                navigation: true,
                navigationText: ['<a class="flex-prev"></a>", "<a class="flex-next"></a>'],
                slideSpeed: 500,
                pagination: false
            });
        });
    }

    addProductToCart(offer: any) {
        this.cartService.addToLocalCart(offer)
            .subscribe(
                () => {
                    this.productModel = this.storeService.findItem(this.offerId);
                }
            );
    }

    incrementQty(offer: Offer) {
        this.addProductToCart(offer);
    }

    decrementQty(offer: Offer) {
        if (this.productModel) {
            if (this.productModel.qty === 1) {
                this.productModel = null;
                this.storeService.removeItemFromStore(offer.id)
                    .subscribe();
            } else {
                return this.cartService.decrementQty(this.productModel.id)
                    .subscribe(() => {
                        this.productModel = this.storeService.findItem(this.offerId);
                    });
            }

        }
    }

    getCurrentQty(offer, products: ProductModel[]) {
        let qty = 0;
        const index = products.findIndex(p => p.id === offer.id);
        if (index >= 0) {
            qty = products[index].qty;
        }
        $('#qty').val(qty);
        return qty;
    }

    isInPromotion(offer: Offer): boolean {
        return isOfferInPromotion(offer);
    }

    getOfferPrice(offer: Offer): number {
        return getPrice(offer);
    }

    getDiscountPrice(offer: Offer): number {
        return getDiscountPrice(offer);
    }

    slickInit(e: any) {
    }
    breakpoint(e: any) {
    }
    afterChange(e: any) {
    }
    beforeChange(e: any) {}

}
