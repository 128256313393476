import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../_models/user';
import {ShippingAddress} from '../../../_models/shipping-address';
import {UserService} from '../../../_services/user.service';
import { combineLatest } from 'rxjs';
import { AppConstants } from 'src/app/_constants/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-farmer-account',
  templateUrl: './farmer-account.component.html',
  styleUrls: ['./farmer-account.component.css']
})
export class FarmerAccountComponent implements OnInit {

  user: any;
  defaultShippingAddress: ShippingAddress;
  loading = true;

  constructor(private userService: UserService, private toast: ToastrService) {
    this.user = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER_KEY));
  }

  ngOnInit() {
    this.userService.load().subscribe(
      (user: User) => {
        this.user = user;
        this.defaultShippingAddress = user.defaultShippingAddress;
      },
      (error) => {
        this.toast.error(error);
      },
      () => this.loading = false
    );


    // // console.log(this.user);
    // combineLatest([
    //   this.userService.loadShippingAddresses(this.user.id),
    //   this.userService.loadProfilePicture(this.user.id)
    // ]).subscribe(
    //   ([shippingAddresses, imageDTO]) => {
    //     this.user.shippingAddressList = shippingAddresses;
    //     this.defaultShippingAddress = this.user.shippingAddressList.find(function (e) {
    //       return e.status === 'primary';
    //     });

    //     this.user.picture = imageDTO.data;
    //     localStorage.setItem('currentUser', JSON.stringify(this.user));
    //     this.loading = false;
    //   },
    //   (error) => {}
    // );
  }

  loadProfilePicture() {
    // get user profile picture and update the current user in localStorage
    // this.userService.loadProfilePicture(this.user.id).subscribe(
    //     (imageDTO: any) => {
    //       this.user.picture = imageDTO.data;
    //       localStorage.setItem('currentUser', JSON.stringify(this.user));
    //     }
    // );
  }

}
