<!-- Top bar Start -->
<div class="top-bar">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-md-6 col-6 justify-content-start justify-content-md-start d-flex align-items-center contacts"
      >
        <a
          href="mailto:store@efarmcm.com"
          class="d-none d-lg-block text-decoration-none text-white"
          target="_blank"
        >
          <i class="fa fa-envelope"></i>
          store@efarmcm.com
        </a>
        <a
          href="tel:+237681763523"
          class="ps-md-4 ps-auto text-white text-decoration-none"
        >
          <i class="fa fa-phone-alt"></i>
          +237-681-763-523
        </a>
      </div>
      <div
        class="col-md-6 col-6 justify-content-end justify-content-md-end d-flex align-items-center justify-content-end"
      >
        <div class="dropdown translate">
          <button
            class="btn btn-default translate_btn text-white dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ "common.language" | translate }}
          </button>
          <ul class="dropdown-menu py-0 rounded-0">
            <li>
              <a class="dropdown-item" href="#" (click)="changeLanguage('fr')">
                Français
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#" (click)="changeLanguage('en')">
                English
              </a>
            </li>
          </ul>
        </div>
        <div class="ps-4 d-none d-lg-block">
          <span *ngIf="!userService.isLoggedIn(); else logged_in_dashboard">
            <a class="btn text-white login_btn link-underline-opacity-0 btn-link" [routerLink]="['/signin']">
              {{ "common.sell" | translate }}
              <i class="fa fa-store ms-1"></i>
            </a>
            <a
              [routerLink]="['/signin']"
              class="btn text-white login_btn link-underline-opacity-0 btn-link"
            >
              {{ "rightnav.log_in" | translate }}
              <i class="fa fa-sign-in ms-1 fa-xs"></i>
            </a>
            <a [routerLink]="['/signup']" class="btn text-white login_btn link-underline-opacity-0 btn-link">
              {{ 'common.register' | translate }}
              <i class="fa fa-user ms-1 fa-xs"></i>
            </a>
          </span>
          <ng-template #logged_in_dashboard>
            <a class="btn text-white login_btn link-underline-opacity-0 btn-link me-2" [routerLink]="['/user', user.id, 'products', 'add']">
              <i class="fa fa-store me-2"></i>
              {{ "common.sell" | translate }}
            </a>
            <a class="logged_links" [routerLink]="['/user/dashboard']">
              <i class="fa fa-user me-2"></i>
              {{ "rightnav.my_account" | translate }}
            </a>
            <a class="ms-4 logged_links" (click)="userService.logout()">
              <i class="fa fa-sign-out me-2"></i>
              {{ "rightnav.sign_out" | translate }}
            </a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Top bar End -->
