<!-- Ad Banner Start -->
<div class="container-fluid mb-4">
  <div class="ad_banners">
    <a class="banner_div" [routerLink]="[adLinkOne]">
      <img [src]="adImageOne" class="img-fluid" alt="" />
    </a>
    <a class="banner_div" [routerLink]="[adLinkTwo]" >
      <img [src]="adImageTwo" class="img-fluid" alt="" />
    </a>
  </div>
</div>
<!-- Ad Banner End -->
