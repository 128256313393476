<div class="recent-orders">
    <br />
    <br />
    <div class="table-responsive">
        <div class="form-page-block">
            <div class="card">
                <div class="card-header">
                  <span>New Job</span>
                </div>
                <div class="card-body">
                    <form class="form-page" [formGroup]="jobForm" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Title</label>
                                <input type="text" class="form-control" formControlName="title" />
                            </div>
                            <div class="col-md-6">
                                <label>Job Type</label>
                                <select class="form-control" formControlName="type">
                                    <option *ngFor="let type of types" [value]="type">{{type}}</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label>Location</label>
                                <input type="text" class="form-control" formControlName="location" />
                            </div>
                            <div class="col-md-12 form-check ms-2 mt-3">
                                <input id="status" type="checkbox" formControlName="status" class="form-check-input" />
                                <label for="status">Active</label>
                            </div>
                            <div class="col-md-12 mt-3">
                                <label>Description</label>
                                <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
                            </div>
                            <div class="button-set mt-2">
                                <button [disabled]="jobForm.invalid || submitting" class="btn btn-success" id="btn-submit" type="submit">
                                    Save
                                </button>
                                &nbsp;
                                <span *ngIf="submitting">saving ...</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  