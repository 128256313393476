export namespace CartAction {

    export class Remove {
        static readonly type = '[Product] Remove Product';
        constructor(public id: number) {
        }
    }

    export class IncrementQty {
        static readonly type = '[Product] increment';
        constructor(public id: number, public payload: any) {
        }
    }

    export class DecrementQty {
        static readonly type = '[Product] decrement';
        constructor(public id: number) {
        }
    }

    export class ClearTheCart {
        static readonly type = '[Product] clear all';
    }

    export class UpdateShippingOffer {
        static readonly type = '[Product] update shipping offer';
        constructor(public id: number, public shippingOfferId: number) {
        }
    }

    export class UpdateOrderId {
        static readonly type = '[Cart] update order id';
        constructor(public orderId: number) {}
    }

    export class SetId {
        static readonly type = '[Cart] set id';
        constructor(public id: string) {}
    }

}
