import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import { CartService } from '../_services/cart.service';
import { environment } from '../../environments/environment';
import { fadeIn, slide } from '../animations';
import { ShippingOffer } from '../_models/shipping-offer';
import { User } from '../_models/user';
import { StoreService } from '../_services/store.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyConversionService } from '../_services/currency-conversion.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.css'],
    animations: [
        slide, fadeIn
    ]
})
export class CartComponent implements OnDestroy{
    pageTitle = 'Shopping cart';
    pageTitleKey = 'cart.title'
    user: User;
    products: Observable<ProductModel[]>;
    shipiingOffers: ShippingOffer[] = [];

    subscriptions: Subscription[] = [];
    displayCurrency = environment.currency;

    /**
     * retrieve a shipping offer from a list from its id
     * @param id the shipping offer id to find
     * @param product The model that holds the list of shipping offers
     *
     * @return ShippingOffer
     */
    static findSelectedShippingOffer(id: number, product: ProductModel, amount?:number, currency?:string) {
        let shippingOffer: ShippingOffer = null;
        for (let i = 0; i < product.shippingOffers.length; i++) {
            if (id === product.shippingOffers[i].id) {
                shippingOffer = product.shippingOffers[i];
            }
        }
        return shippingOffer;
    }

    static structureShippingOffer(shippingOffer: ShippingOffer, amount?:number, currency?: string) {
        const shippingCost = amount || shippingOffer.shippingCost; //amount and currency are those converted, to efarm.cm's default currencies
        const shippingCostCurrency = currency || shippingOffer.scur.toUpperCase();        
        return `${shippingOffer.shippingMean.country.nicename}, ${shippingOffer.shippingMean.description} (${shippingCost} ${shippingCostCurrency})`
    }

    constructor(private cartService: CartService, private storeService: StoreService,
        private titleService: Title, private currencyConversionService: CurrencyConversionService) {

        this.titleService.setTitle(this.pageTitle + ' - ' + environment.appTitle);

        this.products = this.cartService.getProducts();
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        window.scrollTo(0, 0);
    }

    getImageSrc(imagePath) {
        return imagePath !== '' && imagePath !== null && imagePath.includes('media') ?
            environment.image_cdn_url + '/' + imagePath + '?func=crop&width=75&height=75' : '/assets/images/default.jpg';
    }

    isCartEmpty(products: any) {
        return this.storeService.productModels.length === 0;
    }

    removeProductIntoCat(product: ProductModel) {
        this.storeService.removeItemFromStore(product.id);
    }

    clearCart() {
        this.storeService.clearStore();
    }

    selectShippingOffer(event: any, product: ProductModel) {
        const shippingOfferId = parseInt(event.target.selectedOptions[0].value, 0);
        if (shippingOfferId !== 0) {
            CartComponent.findSelectedShippingOffer(shippingOfferId, product);
        }
        this.storeService.changeShippingOffer(product.id, shippingOfferId);
    }

    calculateThePrice(product: ProductModel) {
        if (product.shippingOfferId > 0) {
            const shippingOffer = CartComponent.findSelectedShippingOffer(product.shippingOfferId, product, 300, this.displayCurrency);
            return (product.qty * product.price) + (product.qty * shippingOffer.shippingCost);
        } else {
            return product.qty * product.price;
        }
    }


    getShippingOfferName(shippingOffer: ShippingOffer) {
        return this.currencyConversionService
        .convert(shippingOffer.shippingCost, this.displayCurrency)
        .pipe(map(amount => CartComponent.structureShippingOffer(shippingOffer, amount, this.displayCurrency)));
    } 

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
