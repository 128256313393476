<div class="card">
  <div class="card-header">
    {{ 'crm.compose' | translate}}
  </div>
  <div class="card-body" *ngIf="!loading">
    <form [formGroup]="composeForm">
      <div class="form-group">
        <label>Send Mode : </label>
        <select formControlName="sendMode" class="form-control" (change)="changeSendMode($event)">
          <option [value]="'EMAIL'" >EMAIL</option>
          <option [value]="'SMS'" >SMS</option>
        </select>
      </div>
      <div class="form-group">
        <label>Select the template message to send : </label>
        <select formControlName="templateId" class="form-control" [disabled]="loadTemplates">
          <option *ngFor="let template of templates" [value]="template.id" >{{ template.subject }}</option>
        </select>
        <em *ngIf="loading || loadTemplates">Loading ...</em>
      </div>
      <div class="form-group">
        <label>Users to send messages : </label>
        <div class="row">
          <div class="col-md-4">
            <input formControlName="searchTerm" autocomplete="off" type="text" placeholder="Name, phone or Email" class="form-control" id="searchTerm">
          </div>
          <div class="col-md-3">
            <select formControlName="sectorId"  class="form-control">
              <option [value]="0">- Sector -</option>
              <option *ngFor="let p of productCategories" [value]="p.id">{{ p.name }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <select formControlName="country" class="form-control">
              <option [value]="0">- Country -</option>
              <option *ngFor="let country of countries" [value]="country.id">{{ country.nicename }}</option>
            </select>
          </div>
          <div class="col-md-1">
            <button type="button" class="button button-submit button-filter" (click)="filterUsers()">Filter</button>
          </div>
          <div class="col-md-12">
            <table class="data-table table-striped table-bordered" id="table-users-filtered">
              <thead>
                <th class="input-checkbox">#</th>
                <th class="name">Name</th>
                <th class="phone">Phone</th>
                <th class="email">Email</th>
                <th class="location">Location</th>
              </thead>
              <tbody (scroll)="scrollDown($event)">
                <tr *ngIf="userIdsControls.controls.length == 0; else searchTableData">
                  <td colspan="5">Users not found </td>
                </tr>
                <ng-template #searchTableData>
                  <tr *ngFor="let control of userIdsControls.controls; let i = index">
                    <td class="input-checkbox">
                      <input type="checkbox" [formControl]="control" [value]="this.users[i].id"
                             [checked]="userIds.includes(control.value)" (change)="checkUsers($event)" />
                    </td>
                    <td class="name">{{ this.users[i].name }}</td>
                    <td class="phone">{{ this.users[i].phone }}</td>
                    <td class="email">{{ this.users[i].email }}</td>
                    <td class="location">{{ this.users[i].town }} {{ this.users[i].countryName }}</td>
                  </tr>
                </ng-template>
              </tbody>
              <tfoot>
                <tr style="font-weight: bold;">
                  <td colspan="5">
                    Total found : {{ totalFound }}, &nbsp;&nbsp;&nbsp;&nbsp;
                    Total loaded : {{ totalLoaded }},&nbsp;&nbsp;&nbsp;&nbsp;
                    Number of users selected : <span id="users-selected">0</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button  class="button button-submit" type="submit" [disabled]="composeForm.invalid || submitting" (click)="confirm('save')">
          Save
        </button>
        <button class="button button-submit button-save-send" type="submit"
                [disabled]="!(composeForm.valid && userIds.length != 0) || submitting" (click)="confirm('save_send')">
          Save & Send
        </button>
      </div>
    </form>
  </div>
</div>
