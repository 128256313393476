import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../_services/payment.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import { CartService } from '../_services/cart.service';
import {Title} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {User} from '../_models/user';

@Component({
  selector: 'app-payment-return',
  templateUrl: './payment-return.component.html',
  styleUrls: ['./payment-return.component.css']
})
export class PaymentReturnComponent implements OnInit {

  title: 'Payment process';
  loading = true;
  transaction_status: string;
  transaction_id: string;
  transaction_gateway: string;
  transaction_amount: string;
  message: string;
  queryParamMap: Observable<ParamMap>;
  paymentRef: string;
  user: User;

  constructor(private paymentService: PaymentService,
              private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private cartService: CartService) {
      this.titleService.setTitle(this.title);
      this.queryParamMap = this.activatedRoute.queryParamMap;
      this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
      this.extractParamValues();
  }

  extractParamValues(): void {
      this.queryParamMap.subscribe(
          (params) => {
              console.log(params);
              this.transaction_id = params.get('transaction_id');
              this.transaction_status = params.get('transaction_status');
              this.transaction_gateway = params.get('transaction_gateway');
              this.transaction_amount = params.get('transaction_amount');
              this.paymentRef = params.get('purchaseRef');


              if (this.transaction_status === 'SUCCESSFUL' || this.transaction_status === 'SUCCESS') {
                  this.cartService.clearCart();
                  this.message = 'Your payment has been successfully done';
              } else if ( this.transaction_status === 'FAILED') {
                  this.message = 'Your payment has failed';
              }

              this.loading = false;
          }
      );
  }


}
