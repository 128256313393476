import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LineOffer} from '../_models/LineOffer';
import {API_URLS, SECURE_API_URLS} from '../config/api.urls';
import {Observable} from 'rxjs';
import {Order} from '../_models/order';
import {ProductModel} from '../_cart/product-model';
import { Offer } from '../_models/offer';
import { IPagination } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) {}

  public loadOrders(userId: number, page): Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders?page=' + page);
  }

  public loadCurrentOrder(userId): Observable<Order> {
    return this.httpClient.get<Order>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/order');
  }

  public loadOrder(userId: number, orderId: number): Observable<Order> {
    return this.httpClient.get<Order>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + orderId);
  }

  public loadOrderByRef(userId: number, itemRef: string): Observable<Order> {
    return this.httpClient.get<Order>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + itemRef + '/get');
  }

  public saveOrder(lineOffers: LineOffer[], userId: number, user: number): Observable<Order> {
    return this.httpClient.post<Order>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders', {id: userId, lineOffers: lineOffers});
  }

  public deleteOrder(userId: number, orderId: number) {
    return this.httpClient.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + orderId);
  }

  public addOrUpdateLineOffer(userId: number, orderId: number, offerId: number , product: ProductModel) {
    return this.httpClient.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + orderId + '/line-offers', {
      offerId: offerId,
      qty: product != null ? product.qty : 0,
      price: product != null ? product.price : 0,
      shippingOfferId: product != null ? product.shippingOfferId : 0,
      shipTo: ''
    });
  }

  public deleteLineOffer(userId: number, orderId: number, offerId: number) {
    return this.httpClient.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + orderId + '/line-offers/' + offerId);
  }

  public clearAllLineOffers(userId: number, orderId: number) {
    return this.httpClient.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/orders/' + orderId + '/line-offers');
  }

  // ADMIN ORDERS

  public loadOrdersByAdmin(page: any): Observable<IPagination> {
    return this.httpClient.get<IPagination>(SECURE_API_URLS.ORDERS_BASE_URL + '?page=' + page);
  }

  public loadSingleOrderByAdmin(orderNumber: string): Observable<Order> {
    return this.httpClient.get<Order>(SECURE_API_URLS.ORDERS_BASE_URL + '/' + orderNumber);
  }

  public sendConfirmationMail(orderNumber: string): Observable<Order> {
    return this.httpClient.get<Order>(SECURE_API_URLS.ORDERS_BASE_URL + '/' + orderNumber + "/send_mail");
  }

}
