<div class="container text-center">
    <div class="row">
        <div class="col align-self-center">
            <div class="page-title">
                <h2>{{ titleTranslateKey | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col align-self-center">
                <div class="mx-auto col-md-6 col-xs-12">
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="login-form">
                                <input type="hidden" formControlName="userType"/>
                                <fieldset class="col2-set">
                                    <div class="col-md-12 mb-3">
                                        <input type="hidden" formControlName="userType">
                                        <label>{{'create_business_acc.business_name' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="name" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required"> {{'create_business_acc.business_name_required' | translate }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.country' | translate}}</label> <span class="required">*</span>
                                        <select [disabled]="loading" formControlName="code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.code.errors } ">
                                            <option *ngFor="let c of countries" value="{{ c.id }}" >{{ c.name }}</option>
                                        </select>
                                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                            <div *ngIf="f.code.errors.required">{{'common.country_required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'create_business_acc.business_type' | translate}}</label> <span class="required">*</span>
                                        <select [disabled]="loading" formControlName="businessType" size="1" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.businessType.errors } ">
                                            <option *ngFor="let bt of businessTypes" value="{{bt.name}} " >{{ bt.name}}: {{bt.description}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.businessType.errors" class="invalid-feedback">
                                            <div *ngIf="f.businessType.errors.required">{{'create_business_acc.business_type_required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.select_sector' | translate}}</label> <span class="required">*</span>
                                        <select [disabled]="loading" formControlName="sectorId" class="form-select"
                                                        [ngClass]="{ 'is-invalid': f.sectorId.touched && f.sectorId.errors} ">
                                                    <option *ngFor="let sector of sectors"
                                                            [ngValue]="sector.id">{{ usingFrench? sector.nameFr : sector.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.city' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="town" autocomplete="off" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.town.errors }" />
                                        <div *ngIf="submitted && f.town.errors" class="invalid-feedback">
                                            <div *ngIf="f.town.errors.required"> {{'common.city_required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.state' | translate}}</label>
                                        <input type="text" formControlName="state" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
                                        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                            <div *ngIf="f.state.errors.required"> {{'common.state_required' | translate}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label>{{'create_business_acc.phone_number' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="phone" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                                   autocomplete="off"
                                                   [placeholder]="'create_business_acc.phone_number_with_code' | translate"/>
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">{{'create_business_acc.business_phone_required' | translate}}</div>
                                            <div *ngIf="f.phone.errors.phoneMustValid">Phone number is not valid</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'create_business_acc.contact_person_name' | translate}}</label> <span class="required">*</span>
                                        <input type="text" formControlName="contactName" autocomplete="off" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.contactName.errors }" />
                                        <div *ngIf="submitted && f.contactName.errors" class="invalid-feedback">
                                            <div *ngIf="f.contactName.errors.required"> {{'create_business_acc.contact_person_name_required' | translate}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label>{{'create_business_acc.contact_phone' | translate}}</label> <span class="required">*</span>
                                        <input type="tel" formControlName="contactPhoneNumber" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.contactPhoneNumber.errors }"
                                                   autocomplete="off"
                                                   [placeholder]="'common.phone_with_code' | translate"/>
                                        <div *ngIf="submitted && f.contactPhoneNumber.errors" class="invalid-feedback">
                                            <div *ngIf="f.contactPhoneNumber.errors.required">{{'create_business_acc.contact_phone_required' | translate}}</div>
                                            <div *ngIf="f.contactPhoneNumber.errors.phoneMustValid">Phone number is not valid</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.website' | translate}}</label>
                                        <input type="text" formControlName="website" autocomplete="off" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.website.errors }" />
                                        <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                                            <div *ngIf="f.website.errors.required"> {{'common.website_required' | translate}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label>{{'common.business_email' | translate}}</label> <span class="required">*</span>
                                        <input type="mail" formControlName="email" class="form-control"
                                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">{{'common.email_required' | translate}}</div>
                                            <div *ngIf="f.email.errors.email">{{'signup.invalid_email' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'login.password' | translate}}</label> <span class="required">*</span>
                                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">{{'login.password_required' | translate}}</div>
                                            <div *ngIf="f.password.errors.minlength">{{'signup.invalid_password' | translate}}</div>
                                            <div *ngIf="f.password.errors.pattern !== null">{{'signup.invalid_password_pattern' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label>{{'signup.confirm_password' | translate}}</label> <span class="required">*</span>
                                        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPassword.errors.required">{{'signup.no_confirm_password' | translate}}</div>
                                            <div *ngIf="f.confirmPassword.errors.mustMatch">{{'signup.password_mismatch' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <button type="submit" class="btn btn-success" title="register" name="send" id="btn-submit">
                                            <span>{{'common.register' | translate}}</span>
                                        </button>
                                        &nbsp;
                                        &nbsp;
                                        <a [routerLink]="['/signin']" class="back_link">
                                            {{'common.back_to_login' | translate}}
                                        </a>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    






