import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppConstants } from 'src/app/_constants/constants';
import { CRMTemplate } from 'src/app/_models/crm-template';
import { CrmTemplateService } from 'src/app/_services/crm-template.service';
import { CRMService } from 'src/app/_services/crm.service';

@Component({
  selector: 'app-crm-add-template',
  templateUrl: './crm-add-template.component.html',
  styleUrls: ['./crm-add-template.component.css']
})
export class CrmAddTemplateComponent implements OnInit, OnDestroy {
  editorConfig: AngularEditorConfig = {
    editable: true,
    height: '20rem',
    maxHeight: '40rem',
    minHeight: '20rem',
    sanitize: true
  };

  title = 'crm.add_template.title';
  private editing = false;
  private id = -3;
  private readonly templateId;

  newTemplate: UntypedFormGroup;
  submitting = false;
  currentSendMode = 'EMAIL';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private crmService: CrmTemplateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.templateId = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.newTemplate = this.formBuilder.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      subjectFr: ['', [Validators.required]],
      bodyFr: ['', [Validators.required]],
      sendMode: [this.currentSendMode, [Validators.required]]
    });
  }

  ngOnInit() {
    const template: CRMTemplate = JSON.parse(localStorage.getItem(AppConstants.CURRENT_OUTBOX_TEMPLATE_KEY));
    if (template.id === parseInt(this.templateId, 0)) {
      this.editing = true;
      this.id = template.id;
      this.newTemplate.patchValue(template);
    }
  }

  save() {
    if (this.newTemplate.valid) {
      if (this.editing) {
        this.crmService
          .edit(this.id, this.newTemplate.value)
          .subscribe(response => {
            this.exit();
          });
      } else {
        this.crmService
          .addTemplate(this.newTemplate.value)
          .subscribe(() => {
            this.exit();
          });
      }
    }
  }

  exit() {
    if (this.editing) {
      this.router.navigateByUrl('/admin/crm-templates');
    } else {
      this.router.navigate(['..', ], {relativeTo: this.activatedRoute});
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem(AppConstants.CURRENT_OUTBOX_TEMPLATE_KEY);
  }

  changeSendMode($event: Event) {
    // @ts-ignore
    this. currentSendMode = $event.target.value;
  }
}
