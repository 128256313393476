export class ProductCategory {
    public id: number;
    public name: string;
    public nameFr: string;
    public parent: any;
    public slug: string;
    public type: string;
    public picture: string;
    constructor() {}
}
