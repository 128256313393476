import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { IPagination } from '../_models/pagination';
import { API_URLS } from '../config/api.urls';
import { Page } from '../_models/page';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  page?: Page;
  public pageSubject = new Subject<Page>();
  public loadingSubject = new Subject<boolean>();

  constructor(private httpClient: HttpClient) { }


  loadPage(slug: string): Observable<Page> {
    return this.httpClient.get<Page>(API_URLS.SINGLE_PAGE_BASE_PATH + '/' + slug);
  }

  getPage(slug: string) {
    this.httpClient.get<Page>(API_URLS.SINGLE_PAGE_BASE_PATH + '/' + slug)
        .subscribe(
            (page: Page) => {
              this.page = page;
              this.emitPageSubject();
              this.emitLoadingSubject(false);
            }
        );
  }

  loadPageById(id: string): Observable<Page> {
    return this.httpClient.get<Page>(API_URLS.SINGLE_PAGE_BASE_PATH + '/' + id + '/show');
  }

  loadPages(page: string): Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.ADMIN_PAGES_BASE_URL);
  }

  savePage(page: Page, id?: string) {
    if(id == null ) {
      return this.httpClient.post(API_URLS.ADMIN_PAGES_BASE_URL, page);
    } else {
      return this.httpClient.put(API_URLS.ADMIN_PAGES_BASE_URL + '/' + id, page);
    }
  }

  deletePage(id: number) {
    return this.httpClient.delete(API_URLS.ADMIN_PAGES_BASE_URL + '/' + id);
  }

  emitPageSubject() {
    this.pageSubject.next(this.page);
  }

  emitLoadingSubject(value: boolean) {
    this.loadingSubject.next(value);
  }

}
