import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMIN_API_URLS, API_URLS} from '../config/api.urls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) { }

  public loadPayments(page?: string) {
    if (page == null) {
      page = '1';
    }
    return this.httpClient.get(ADMIN_API_URLS.PAYMENTS_PATH + `?page=${page}`);
  }

  public changeStatus(transactionRef: string, status:string): Observable<any> {
    return this.httpClient.put(`${ADMIN_API_URLS.PAYMENTS_PATH}/${transactionRef}/change_status`, {}, {params:{status: status}})
  }

  searchPayment(searchTerm: string, paymentMethod: string, status: string, page: string): Observable<any> {
    return this.httpClient.get(`${ADMIN_API_URLS.PAYMENTS_PATH}/search`, {params: {searchTerm: searchTerm, paymentMethod: paymentMethod, status:status, page: page}});
  }
}
