import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import { Observable } from 'rxjs';
import { ProductCategory } from '../_models/product-category';
import { IProductCategory } from '../_models/iproduct-category';
import { IPagination } from '../_models/pagination';

@Injectable()
export class ProductCategoryService {

    constructor(private http: HttpClient) {
    }


    get_product_categories(): Observable<IProductCategory[]> {
        return this.http.get<IProductCategory[]>(API_URLS.LIST_OF_PRODUCT_CATEGORIES, { params: { itemPerPage: '-1', page: '1' } });
    }

    local_product_categories(itemPerPage, page): Observable<IPagination> {
        return this.http.get<IPagination>(API_URLS.PRODUCT_CATEGORIES_API, {
            params: {
                itemPerPage: itemPerPage,
                page: page
            }
        });
    }

    add_product_category(product_category: any, picture: Blob) {
        return this.http.post(API_URLS.PRODUCT_CATEGORIES_API, this.builFormData(product_category, picture));
    }

    update_product_category(product_category: any, picture: Blob) {
        return this.http.put(API_URLS.PRODUCT_CATEGORIES_API + '/' + product_category.id, this.builFormData(product_category, picture));
    }

    delete_product_category(product_category: ProductCategory) {
        return this.http.delete(API_URLS.PRODUCT_CATEGORIES_API + '/' + product_category.id);
    }

    get_category_list(): Observable<IProductCategory[]> {
        return this.http.get<IProductCategory[]>(API_URLS.LIST_OF_PRODUCT_CATEGORIES);
    }

    private builFormData(value: any, picture: any) {
        let formData = new FormData();
        formData.append('name', value.name);
        formData.append('name_fr', value.name_fr);
        formData.append('parent', value.parent);
        formData.append('type', value.type);
        formData.append('picture', picture);
        return formData;
    }

}
