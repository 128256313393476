import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-fp-requrest-sent',
  templateUrl: './fp-requrest-sent.component.html',
  styleUrls: ['./fp-requrest-sent.component.css']
})
export class FpRequrestSentComponent implements OnInit, OnDestroy {
  title = 'Account recovery';
  constructor(public apiService: ApiService, private router: Router) {
    this.apiService.show_header_footer = false;
    const val = localStorage.getItem('forget_password_done');
    if ( val === null ) {
      this.router.navigate(['forget-password']);
    }
    localStorage.removeItem('forget_password_done');
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.apiService.show_header_footer = true;
  }

}
