import { Injectable } from '@angular/core';
import { API_URLS, SECURE_API_URLS } from '../config/api.urls';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPagination } from '../_models/pagination';
import { OfferDTO } from '../_models/offer-dto';
import { OfferEditDTO } from '../_models/offer-edit-dto';
import { ADMIN_API_URLS } from '../config/admin-api.url';
import { ReviewResponse } from '../_models/review-response';

@Injectable({
    providedIn: 'root'
})
export class OfferService {


    constructor(private httpClient: HttpClient) {
    }

    getLatest(): Observable<any> {
        return this.httpClient.get(API_URLS.OFFERS_BASE_URL);
    }

    loadOffer(offerId): Observable<any> {
        return this.httpClient.get(API_URLS.OFFERS_BASE_URL + '/' + offerId);
    }

    loadLastProductsByPricing(pricing: string, page: any): Observable<IPagination> {
        return this.httpClient
            .get<IPagination>(API_URLS.OFFERS_BASE_URL + '/pricing/' + pricing, { params: { page: page == null ? 1 : page, itemPerPage: 15 } });
    }

    loadOffersByCategory(categorySlug: string, page: any) {
        return this.httpClient.get(API_URLS.OFFERS_BASE_URL + '/category/' + categorySlug, { params: { page: page == null ? 1 : page, itemPerPage: 15 } });
    }

    search(q: string, page: any) {
        return this.httpClient.get(API_URLS.SEARCH_URL, { params: { page: page == null ? 1 : page, term: q, itemPerPage: 15 } });
    }

    // protected api call
    getAll(userId: number, page: string): Observable<IPagination> {
        return this.httpClient.get<IPagination>(API_URLS.USERS_BASE_PATH + userId + '/offers', { params: { page: page } });
    }

    addOffer(userId: number, offerDTO: OfferDTO) {
        return this.httpClient.post(API_URLS.USERS_BASE_PATH + userId + '/offers', offerDTO);
    }

    getOffer(userId, offerId) {
        return this.httpClient.get(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId);
    }

    deleteOffer(userId, offerId) {
        return this.httpClient.delete(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId);
    }

    updateOffer(userId, offerId, offerDTO: OfferEditDTO) {
        return this.httpClient.put(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId, offerDTO);
    }

    update(offerId, offerDTO: OfferEditDTO) {
        return this.httpClient.put(SECURE_API_URLS.OFFERS_BASE_URL + '/' + offerId + '/update', offerDTO);
    }

    uploadPicture(userId, offerId, file) {
        const fb = new FormData();
        fb.append('picture', file);
        return this.httpClient.post(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId + '/upload_picture', fb);
    }

    savePaymentMethods(userId, offerId, paymentMethods) {
        return this.httpClient.post(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId + '/payment_methods', paymentMethods);
    }

    changeStatus(userId, offerId) {
        return this.httpClient.patch(API_URLS.USERS_BASE_PATH + userId + '/offers/' + offerId + '/changeStatus', {});
    }

    getSales(user_id: number, offer_id: number, page: number): Observable<IPagination> {
        return this.httpClient.get<IPagination>(API_URLS.USERS_BASE_PATH + user_id + '/offers/' + offer_id + '/sales');
    }

    getRelatedOffers(category_id: number, offer_id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(API_URLS.RELATED_OFFERS_BASE_URL, {
            params: {
                "categoryId": category_id,
                "offerId": offer_id
            }

        })
    }

    getProductReviews(offer_id: number, page: any): Observable<ReviewResponse> {
        return this.httpClient.get<ReviewResponse>(API_URLS.OFFERS_BASE_URL + "/" + offer_id + "/reviews", { params: { page: page == null ? 1 : page } })
    }

    submitReview(offer_id: number, reviewDTO: any) {
        return this.httpClient.post(API_URLS.OFFERS_BASE_URL + "/" + offer_id + "/reviews", reviewDTO)
    }


    /***************** ADMIN API CALL ****************************/

    loadOffersByAdmin(page, filterCategoryId, filterPrice, nameOrder, createdAt) {
        return this.httpClient.get(ADMIN_API_URLS.ADMIN_OFFERS, {
            params: {
                page: page, filterCategoryId: filterCategoryId, filterPrice: filterPrice, name: nameOrder, createdAt: createdAt
            }
        }
        );
    }

    approveOrDisapprove(offerId, status) {
        return this.httpClient.patch(ADMIN_API_URLS.ADMIN_OFFERS + '/' + offerId + '/approveOrDisapprove', status);
    }

}
