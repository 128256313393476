import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/_constants/constants';
import { Pricing } from 'src/app/_models/pricing';
import { PricingService } from 'src/app/_services/pricing.service';

@Component({
    selector: 'app-pricing-form',
    templateUrl: './pricing-form.component.html',
    styleUrls: ['./pricing-form.component.css']
})
export class PricingFormComponent implements OnInit {

    pricingForm: UntypedFormGroup;
    pricing: Pricing;
    title: string;
    submitted = false;

    constructor(private fb: UntypedFormBuilder, private location: Location,
        private pricingService: PricingService, private router: Router) {
        const item = window.localStorage.getItem(AppConstants.PRICING_KEY);
        this.pricing = item ? JSON.parse(item) : new Pricing();
        this.title = item ? 'pricings.edit' : 'pricings.add';
    }

    ngOnInit() {
        this.pricingForm = this.fb.group({
            id: new UntypedFormControl(0),
            name: new UntypedFormControl('', Validators.required),
            nameFr: new UntypedFormControl('', Validators.required),
            nicename: new UntypedFormControl('', Validators.required)
        });


        if (this.pricing.id) {
            this.pricingForm.patchValue({
                id: this.pricing.id,
                name: this.pricing.name,
                nameFr: this.pricing.nameFr,
                nicename: this.pricing.nicename
            });
        }
    }

    get f() {
        return this.pricingForm.controls;
    }

    submitForm() {
        if (!this.pricingForm.dirty || this.pricingForm.untouched || this.pricingForm.invalid) {
            return;
        }
        this.submitted = true;
        this.pricing.id ? this.update() : this.create();
    }

    create() {
        this.pricingService.add_pricing(this.pricingForm.value).subscribe(
            () => {
                this.router.navigate(['admin/pricing']);
            },
            (error) => this.submitted = false
        );
    }

    update() {
        this.pricingService.update_pricing(this.pricingForm.value).subscribe(
            () => {
                this.router.navigate(['admin/pricing']);
            },
            (error) => this.submitted = false
        );
    }

    back_to_previous() {
        this.location.back();
    }
}
