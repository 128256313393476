import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: './reset-password-complete.component.html',
  styleUrls: ['./reset-password-complete.component.css']
})
export class ResetPasswordCompleteComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService) { 
    this.apiService.show_header_footer = false; 
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.apiService.show_header_footer = true;
  }

}
