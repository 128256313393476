<div class="services mb-4">
  <div class="container-fluid">
    <div class="mb-4">
      <h3 class="text-center fw-bold" *ngIf="currentLanguage === 'en'">
        What <span class="text-primary">eFarm.cm</span> Marketplace offers?
      </h3>
      <h3 class="text-center fw-bold" *ngIf="currentLanguage === 'fr'">
        Ce que propose la Marketplace
        <span class="text-primary">eFarm.cm</span>?
      </h3>
    </div>
    <div class="row align-items-center">
      <div
        class="col-lg-3 col-md-6 feature-col"
        (click)="goTo('term-of-use')"
      >
        <div class="feature-content">
          <i class="fab fa-cc-mastercard"></i>
          <h2>{{ "services.secure_payment" | translate }}</h2>
          <p class="px-2">
            {{ "services.secure_payment_message" | translate }}
          </p>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 feature-col"
        (click)="goTo('delivery')"
      >
        <div class="feature-content">
          <i class="fa fa-truck"></i>
          <h2>{{ "services.worldwide_delivery" | translate }}</h2>
          <p class="px-2">
            {{ "services.worldwide_delivery_message" | translate }}
          </p>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 feature-col"
        (click)="goTo('return-and-refund-policy')"
      >
        <div class="feature-content">
          <i class="fa fa-sync-alt"></i>
          <h2>{{ "services.returns" | translate }}</h2>
          <p class="px-2">{{ "services.returns_message" | translate }}</p>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 feature-col"
        (click)="goTo('privacy-policy')"
      >
        <div class="feature-content">
          <i class="fa fa-comments"></i>
          <h2>{{ "services.support" | translate }}</h2>
          <p class="px-2">{{ "services.support_message" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
