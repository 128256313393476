<div class="recent-orders">
    <div class="title-buttons">
        <strong>{{'common.product_conditions' | translate}}</strong>
    </div>
    <div class="table-responsive">
        <table class="table table-striped" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th>{{'conditions.french_name' | translate}}</th>
                <th>{{'conditions.english_name' | translate}}</th>
                <th>{{'common.status' | translate}}</th>
                <th>
                    <button (click)="add_product_condition()"
                            class="button button-submit button-md"> {{'common.add' | translate}}
                    </button>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading">
            <tr>
                <td colspan="4" style="text-align: center">{{'common.loading' | translate}} ...</td>
            </tr>
            </tbody>
            <tbody *ngIf="!loading">
            <tr *ngFor="let condition of conditions ">
                <td>{{ condition.descriptionFr }}</td>
                <td>{{ condition.description }}</td>
                <td>{{ condition.status }}</td>
                <td>
                       <span class="nobr">
                           <a (click)="edit_product_condition(condition)">{{'common.edit' | translate}}</a>
                           <span class="separator"> | </span>
                           <a (click)="delete_product_condition(condition)">{{'common.delete' | translate}}</a>
                       </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
    </div>
</div>
