import { Component, OnInit } from '@angular/core';
import {UserService} from '../../_services/user.service';
import {CRMService} from '../../_services/crm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CRMTemplate} from '../../_models/crm-template';
import {ProductCategory} from '../../_models/product-category';
import {Country} from '../../country/Country';
import {ProductCategoryService} from '../../_services/product-category.service';
import {combineLatest} from 'rxjs';
import {CountryService} from '../../country/country.service';
import {User} from '../../_models/user';
import {ToastrService} from 'ngx-toastr';
import {NewOutBoxMessage} from '../../_models/crm-outbox-message-compose';

@Component({
  selector: 'app-crm-compose',
  templateUrl: './crm-compose.component.html',
  styleUrls: ['./crm-compose.component.css']
})
export class CrmComposeComponent implements OnInit {

  title = 'crm.compose';
  composeForm: UntypedFormGroup;
  submitting = false;
  userIds: number[] = [];
  currentSendMode = 'EMAIL';
  templates: CRMTemplate[] = [];
  loadTemplates = false;
  loading = true;
  productCategories: ProductCategory[] = [];
  countries: Country[] = [];
  users: User[] = [];
  currentPage = 1;
  maxPage = 1;
  loadUsers = false;
  filterUsersValues = { searchTerm: '', sectorId: 0, country: 0 };
  totalLoaded = 0;
  totalFound = 0;

  constructor( private userService: UserService,
               private crmService: CRMService,
               private router: Router,
               private toast: ToastrService,
               private formBuilder: UntypedFormBuilder,
               private activatedRoute: ActivatedRoute,
               private countryService: CountryService,
               private productCategoryService: ProductCategoryService) {
    this.composeForm = this.formBuilder.group({
        sendMode: [this.currentSendMode],
        templateId: [0, Validators.required],
        searchTerm: [''],
        country: [0],
        sectorId: [0],
        action: [],
        userIds: this.formBuilder.array([], Validators.required)
    });
  }

  get userIdsControls() {
      return this.composeForm.get('userIds') as UntypedFormArray;
  }


    ngOnInit() {
    combineLatest([
        this.countryService.loadAll(),
        this.productCategoryService.get_product_categories(),
        this.crmService.loadTemplateBySendMode(this.currentSendMode),
        this.userService.searchEFarms(this.filterUsersValues, this.currentPage)
    ]).subscribe(
        (values) => {
          this.countries = values[0];
          this.productCategories = values[1];
          this.templates = values[2];
          this.users = values[3].data;
          this.currentPage = values[3].page;
          this.maxPage = values[3].totalOfPage;
          this.totalFound = values[3].totalOfItems;
          this.totalLoaded = this.users.length;
          // init users
          const userIdsList = this.composeForm.get('userIds') as UntypedFormArray;
          this.users.forEach((user: User) => {
              userIdsList.push(new UntypedFormControl(user.id));
          });
        },
        () => this.loading = false,
        () => this.loading = false
    );
  }

  confirm(action: string) {
      if (this.composeForm.invalid) {
          this.toast.error('Some fields are required');
      }

      this.submitting = true;
      const outboxMessage = new NewOutBoxMessage();
      outboxMessage.templateId = this.composeForm.value.templateId;
      outboxMessage.userIds = this.userIds;
      outboxMessage.action = action;
      outboxMessage.sendBy = this.composeForm.value.sendMode;
      this.crmService.composeMessage(outboxMessage).subscribe(
          (response) => {
              console.log(response);
          },
          () => {this.toast.error('Failed to save the entry.'); this.submitting = false},
          () => {
              this.toast.success('Outbox successfully saved');
              this.router.navigateByUrl('admin/outbox');
          }
      );
  }

  changeSendMode($event: Event) {
    // @ts-ignore
    this.currentSendMode = $event.target.value;
    this.loadTemplates = true;
    this.templates = [];
    this.loadTemplateBySendMode();
  }

  loadTemplateBySendMode() {
    this.crmService.loadTemplateBySendMode(this.currentSendMode).subscribe(
        (data: CRMTemplate[]) =>  {
          this.templates = data;
        },
        () => this.loadTemplates = false,
        () => this.loadTemplates = false
    );
  }

  scrollDown($event: UIEvent) {
      // @ts-ignore
        if (parseInt($event.target.scrollTop, 0) + parseInt($event.target.offsetHeight, 0) === parseInt($event.target.scrollHeight, 0)) {
            if (this.currentPage < this.maxPage) {
                this.currentPage = this.currentPage + 1;
                this.searchUsers(false);
            }
        }
    }

    filterUsers() {
        const formValue = this.composeForm.value;
        this.filterUsersValues.country = parseInt(formValue.country, 0);
        this.filterUsersValues.searchTerm = formValue.searchTerm;
        this.filterUsersValues.sectorId = parseInt(formValue.sectorId, 0);
        this.currentPage = 1;
        this.searchUsers(true);
    }

    searchUsers(isSearch: boolean) {
        this.loadUsers = true;
        this.userService.searchEFarms(this.filterUsersValues, this.currentPage).subscribe(
            (pagination) => {
                // clear the table when we are searching for new users
                if (isSearch) {
                    this.users = [];
                    this.composeForm.removeControl('userIds');
                    this.composeForm.addControl('userIds', this.formBuilder.array([], Validators.required));
                }

                const userIdsList = this.composeForm.get('userIds') as UntypedFormArray;
                const data = pagination.data;
                if (isSearch) {
                    this.totalLoaded = pagination.data.length;
                    this.totalFound = pagination.totalOfItems;
                } else {
                    this.totalLoaded += data.length;
                }

                if (data.length) {
                    data.forEach((e: User) => {
                        const x = userIdsList.controls.find((item, index) => {
                            return e.id === item.value;
                        });

                        if (x === undefined) {
                            this.users.push(e);
                            userIdsList.push(new UntypedFormControl(e.id));
                        }
                    });
                }
            },
            () => this.loadUsers = false,
            () => this.loadUsers = false
        );
    }

    checkUsers(event: Event) {
        // @ts-ignore
        const value  = parseInt(event.target.value, 0);
        if (this.userIds.includes(value)) {
          const index = this.userIds.findIndex( item => value === item);
          this.userIds.splice(index, 1);
        } else {
            this.userIds.push(value);
        }
        document.getElementById('users-selected').innerHTML = `${this.userIds.length}`;
    }
}
