import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from './user/user.component';
import {AdminComponent} from './admin.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ProductCategoryComponent} from './product-category/product-category.component';
import {ProductCategoryFormComponent} from './product-category-form/product-category-form.component';
import {UserFormComponent} from './user-form/user-form.component';
import {RoleGuard} from '../_guards/role.guard';
import {ProductComponent} from './product/product.component';
import {ProductFormComponent} from './product-form/product-form.component';
import {ShippingMeansListComponent} from './shipping-means/shipping-means-list/shipping-means-list.component';
import {ShippingMeanFormComponent} from './shipping-means/shipping-mean-form/shipping-mean-form.component';
import {OfferComponent} from './offer/offer.component';
import {ViewOfferComponent} from './view-offer/view-offer.component';
import { PagesComponent } from './pages/pages.component';
import { AddPageComponent } from './add-page/add-page.component';
import { TeamListComponent } from './team/team-list/team-list.component';
import { AddMemberComponent } from './team/add-member/add-member.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import {RequestedProductComponent} from './requested-product/requested-product.component';
import {PaymentsComponent} from './payments/payments.component';
import { PricingComponent } from './pricing/pricing.component';
import { PricingFormComponent } from './pricing-form/pricing-form.component';
import { SectorFormComponent } from './sector-form/sector-form.component';
import { SectorsComponent } from './sectors/sectors.component';
import { MeasurementUnitsComponent } from './measurement-unit/measurement-units.component';
import { MeasurementUnitFormComponent } from './measurement-unit-form/measurement-unit-form.component';
import { ProductConditionsComponent } from './product-conditions/product-conditions.component';
import { ProductionConditionFormComponent } from './product-condition-form/product-condition-form.component';
import { CRMComponent } from './crm/crm.component';
import { CRMTemplatesComponent } from './crm-templates/crm-templates.component';
import { CrmAddTemplateComponent as CRMAddTemplateComponent } from './crm-add-template/crm-add-template.component';
import {EditOfferComponent} from './edit-offer/edit-offer.component';
import {CrmComposeComponent} from './crm-compose/crm-compose.component';
import { GroupsComponent } from './groups/groups.component';
import { BlogPostEditComponent } from './blog/blog-post-edit/blog-post-edit.component';
import { BlogPostListComponent } from './blog/blog-post-list/blog-post-list.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';
import { JobEditComponent } from './career/job-edit/job-edit.component';
import { JobComponent } from './career/job/job.component';
import { JobsComponent } from './career/jobs/jobs.component';

const routes: Routes = [{
    path: 'admin',
    component: AdminComponent,
    canActivate: [RoleGuard],
    children: [
        {path: 'dashboard', component: DashboardComponent},

        {path: 'team-member/:id/edit', component: AddMemberComponent},
        {path: 'team-member/add', component: AddMemberComponent},
        {path: 'team', component: TeamListComponent},

        {path: 'pages/:id/edit', component: AddPageComponent},
        {path: 'pages/add', component: AddPageComponent},
        {path: 'pages', component: PagesComponent},

        {path: 'users', component: UserComponent},
        {path: 'users/search', component: UserComponent},
        {path: 'users/add', component: UserFormComponent},
        {path: 'users/:id/edit', component: UserFormComponent},

        {path: 'product-categories', component: ProductCategoryComponent},
        {path: 'product-categories/add', component: ProductCategoryFormComponent},
        {path: 'product-categories/:id/edit', component: ProductCategoryFormComponent},

        {path: 'products', component: ProductComponent,  title: 'Products List'},
        {path: 'products/add', component: ProductFormComponent,  title: 'Add Product'},
        {path: 'products/:id/edit', component: ProductFormComponent,  title: 'Edit product'},
        {path: 'products/search', component: ProductComponent,  title: 'Search products results'},

        {path: 'payments', component: PaymentsComponent},
        {path: 'orders', component: OrdersListComponent,  title: 'Orders List'},
        {path: 'orders/:ref_num', component: OrderComponent,  title: 'Order Details'},

        {path: 'shipping-means/:id/edit', component: ShippingMeanFormComponent},
        {path: 'shipping-means/add', component: ShippingMeanFormComponent},
        {path: 'shipping-means', component: ShippingMeansListComponent},


        {path: 'offers', component: OfferComponent,  title: 'Offers List'},
        {path: 'offers/:offerId', component: ViewOfferComponent,  title: 'Offer details'},
        {path: 'offers/:offerId/edit', component: EditOfferComponent,  title: 'Edit offer'},

        {path: 'requested-products', component: RequestedProductComponent, title: 'Requested Products'},

        {path: 'pricing', component: PricingComponent},
        {path: 'pricing/:id/edit', component: PricingFormComponent},
        {path: 'pricing/add', component: PricingFormComponent},

        {path: 'sectors', component: SectorsComponent},
        {path: 'sectors/:id/edit', component: SectorFormComponent},
        {path: 'sectors/add', component: SectorFormComponent},

        {path: 'measurement-units', component: MeasurementUnitsComponent},
        {path: 'measurement-units/:id/edit', component: MeasurementUnitFormComponent},
        {path: 'measurement-units/add', component: MeasurementUnitFormComponent},

        {path: 'product-conditions', component: ProductConditionsComponent},
        {path: 'product-conditions/:id/edit', component: ProductionConditionFormComponent},
        {path: 'product-conditions/add', component: ProductionConditionFormComponent},
        {path: 'outbox', component: CRMComponent},
        {path: 'outbox/compose', component: CrmComposeComponent},
        {path: 'crm-templates', component: CRMTemplatesComponent},
        {path: 'crm-templates/add', component: CRMAddTemplateComponent},
        {path: 'taxonomies', component: GroupsComponent},

        {path: 'blog-posts', component: BlogPostListComponent},
        {path: 'blog-posts/:id/edit', component: BlogPostEditComponent},

        {path: 'jobs', component: JobsComponent},
        {path: 'jobs/add', component: JobComponent},
        {path: 'jobs/:id/edit', component: JobEditComponent},
    ]
},
{path: '404', component: PageNotFoundComponent},
{path: '**', redirectTo: '404'},

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {
}
