<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12 col-xs-12 wow bounceInUp animated animated" style="visibility: visible;">
          <p *ngIf="loading else data_loaded"
            style="height: 250px; text-align: center; display: flex; justify-content: center; justify-items: center;">
            {{'common.loading' | translate}} ...
          </p>
          <ng-template #data_loaded>
            <div class="col-md-12">
              <table style="width: 100%;" class="table table-condensed">
                <tbody>
                  <tr>
                    <td style="text-align: left; font-size: 16px;">{{'orders_list.ref' | translate}} : {{ order.orderNumber }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-size: 16px;">{{'orders_list.ordered_by' | translate}} : {{ order.orderBy }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-size: 16px;">
                      {{'common.payment_method' | translate}} : {{ order.payment ? get_payment_method_label(order.payment.paymentMethod) : ''  }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-size: 16px;">
                      {{'common.status' | translate}} : {{ order.status | translate }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-size: 16px;">{{'orders_list.ordered_at' | translate}} : {{ order.createdAt}}</td>
                  </tr>
                  <!-- <tr>
                    <td>
                        <button [disabled]="sending"  class="btn btn-success" id="btn-submit" (click)="sendConfirmationMail()">
                            {{ sending ? 'sending...' : 'Send Confimration Mail' }}
                        </button>
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <br />
              <table class="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>{{'common.products_name' | translate}}</th>
                    <th>{{'common.unit_price' | translate}}</th>
                    <th style="text-align: center;">{{ 'common.qty' | translate }}</th>
                    <th>Discount</th>
                    <th>Final Price</th>
                    <th>{{'orders_list.shipping_offer' | translate}}</th>
                    <th style="text-align: right;">{{'common.total' | translate}}</th>
                    <th style="text-align: right;">Seller</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lineOffer of lineOffers;">
                    <td>{{ lineOffer.offerDTO.product.name }}</td>
                    <td>
                      {{ lineOffer.offerDTO.price }} {{ lineOffer.offerDTO.currency | uppercase }}
                    </td>
                    <td style="text-align: center;">{{ lineOffer.qty }}</td>
                    <td>
                      {{ lineOffer.offerDTO.percentDiscount > 0 ? lineOffer.offerDTO.percentDiscount + '%' : '' }}
                    </td>
                    <td>
                      {{ getOfferPrice(lineOffer.offerDTO) }} {{ lineOffer.offerDTO.currency | uppercase }}
                    </td>
                    <td>
                      {{ (lineOffer.shippingOffer != null ? lineOffer.shippingOffer.shippingCost : 0 )}}  {{ lineOffer.offerDTO.currency | uppercase }}
                    </td>
                    <td  style="text-align: right;">
                      {{ calculateTotal(lineOffer) }} {{ lineOffer.offerDTO.currency | uppercase }}
                    </td>
                    <td style="text-align: right;">
                      <button type="button" class="btn btn-success" id="btn-success"  (click)="viewSellerDetails(lineOffer.offerDTO, seller_details_content)"
                      style="font-size: 14px; padding: 2px 5px; background-color: #1c8a45; color: #fff;">
                        Seller details
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr style="font-size: 20px">
                    <td style="text-transform: uppercase; text-align: left; font-weight: bold;" colspan="7">
                      {{'common.total' | translate}}
                    </td>
                    <td style="color: #ed6663; font-weight: bold; text-align: right; ">
                      {{ totalOrder }} XAF
                    </td>
                  </tr>
                  <tr style="font-size: 16px">
                    <td style="text-align: left;" colspan="7">
                      {{'common.order_commission' | translate}}
                    </td>
                    <td style="color: #009e18; font-weight: bold; text-align: right; ">
                      {{ totalOrder * (0.05) | number: '1.0-0'}} XAF
                    </td>
                  </tr>
                  <tr style="font-size: 16px">
                    <td style="text-align: left;" colspan="7">
                      {{'common.order_client_amount' | translate}}
                    </td>
                    <td style="color: #ed6663; font-weight: bold; text-align: right; ">
                      {{ totalOrder * (0.95) | number: '1.0-0'}} XAF
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </ng-template>

          <ng-template #seller_details_content>
            <div class="modal-header">
                <h4 class="modal-title">
                  Seller details Info
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                  (click)="closeModal()">
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-responsive table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{{ seller.name }}</td>
                    </tr>
                    <tr>
                      <th>Phone number</th>
                      <td>{{ seller.phone }}</td>
                    </tr>
                    <tr>
                      <th>Email address</th>
                      <td>
                        {{ seller.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>Location</th>
                      <td>
                          {{ seller.town }}, {{ seller.countryName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </ng-template>


        </div>
      </div>
</div>