import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URLS} from '../config/api.urls';

@Injectable()
export class CommonService {

    constructor(private http: HttpClient) {}

    loadAll(): Observable<any> {return this.http.get(API_URLS.LIST_OF_BUSINESS_TYPES);}

}
