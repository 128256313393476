import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TeamService } from '../_services/team.service';
import { TeamMember } from '../_models/team-member';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../_constants/constants';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  title = 'Meet our Team';
  loading = true;
  teamMembers: TeamMember[] = [];
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(private titleService: Title, private teamService: TeamService) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    this.teamService
      .loadTeam()
      .subscribe(
        (teamMembers: TeamMember[]) => {
          this.teamMembers = teamMembers;
          this.loading = false;
        }
      );
  }

  getImageSrc(imagePath) {
    return imagePath !== '' && imagePath !== null && imagePath.includes('media') ?
        environment.image_cdn_url + '/' + imagePath : '/assets/images/blog-img1_2.jpg';
  }

}
