import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Country } from '../../country/Country';
import { CountryService } from '../../country/country.service';
import { CommonService } from '../../_services/common.service';
import { MustMatch } from '../../_helpers/MustMatch';
import { Title } from '@angular/platform-browser';
import { UserService } from '../../_services/user.service';
import { AlertService } from '../../_services/alert.service';
import { PageTitleService } from '../../_services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import {AppConstants} from '../../_constants/constants';
import { PhoneMustValid } from 'src/app/_helpers/PhoneMustValid';
import { Sector } from 'src/app/_models/sector';
import { SectorService } from 'src/app/_services/sector.service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-register-business',
    templateUrl: './register-business.component.html',
    styleUrls: ['./register-business.component.css']
})
export class RegisterBusinessComponent implements OnInit, OnDestroy {
    title = 'Register a business account';
    titleTranslateKey = 'common.register_business_acc';
    registerForm: UntypedFormGroup;
    loading = true;
    submitted = false;
    countries: Country[];
    businessTypes: any[];
    subscriptions: Subscription[] = [];
    passwordValidator = [Validators.minLength(8), Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)];
    usingFrench = true;
    sectors: Sector[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private toastService: ToastrService,
        private userService: UserService,
        private alertService: AlertService,
        private cs: CountryService,
        private pageTitleService: PageTitleService,
        private sectorService: SectorService,
        private commonService: CommonService, private titleService: Title,
        private translateService: TranslateService) {

        this.translateService.get(this.title).subscribe(value => {
            this.title = value;
            this.pageTitleService.setPageTitle(this.title);
            this.titleService.setTitle(this.title);
        });
    }

    ngOnInit() {
        this.usingFrench = this.translateService.currentLang === AppConstants.FRENCH;
        this.registerForm = this.formBuilder.group({
            code: new UntypedFormControl(37, Validators.required),
            name: new UntypedFormControl('', Validators.required),
            businessType: new UntypedFormControl(null, Validators.required),
            sectorId: new UntypedFormControl(null, Validators.required),
            town: new UntypedFormControl('', Validators.required),
            state: new UntypedFormControl(''),
            phone: new UntypedFormControl('', [Validators.required, PhoneMustValid()]),
            contactName: new UntypedFormControl('', Validators.required),
            contactPhoneNumber: new UntypedFormControl('', [Validators.required, PhoneMustValid()]),
            website: new UntypedFormControl(''),
            userType: new UntypedFormControl('business', Validators.required),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            password: new UntypedFormControl('', this.passwordValidator),
            confirmPassword: new UntypedFormControl('', Validators.required),
        }, { validator: MustMatch('password', 'confirmPassword')});

        combineLatest([
            this.cs.loadAll(),
            this.commonService.loadAll(),
            this.sectorService.getAll(),
        ]).subscribe(
            ([countries, businessTypes, sectors]) => {
                this.countries = countries;
                this.businessTypes = businessTypes;
                this.sectors = sectors;
            },
            (error) => this.toastService.error(error),
            () => this.loading = false
        );

        window.scrollTo(0, 0);
    }


    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        // stop here if form is invalid
        console.log(this.submitted);
        this.submitted = true;
        if (!this.registerForm.valid) {
            console.log(this.submitted);
            return;
        }
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                () => {
                    const messageSubscription = this.translateService
                        .get('account_created_message.account_created_message').subscribe(value => {
                        this.alertService.success(value);
                    });
                    this.subscriptions.push(messageSubscription);
                },
                (error: any) => {
                    this.submitted = false;
                    window.scrollTo(0, 0);
                },
            () => {
                this.router.navigateByUrl('signup/confirmation');
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subs => subs.unsubscribe());
    }
}
