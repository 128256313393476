<div class="container-fluid">
  <div class="row">
    <div class="col-md-9 col-sm-12 mb-3" style="visibility: visible;">
      <div class="box-account">
        <div class="page-title">
          <h5>{{'sidebar.my_addresses' | translate}}</h5> 
          
          <button *ngIf="!loading && shippingAddresses.length < 2 " 
            class="btn btn-success mt-2 mb-2" id="btn-submit" (click)="addShippingAddress()">
            {{'common.add' | translate}}
          </button>
        </div>
        <p *ngIf="loading" style="padding-top: 20px;">
          {{'common.loading' | translate}} ...
        </p>
        <div class="row">
          <div class="col-md-6" *ngFor="let address of shippingAddresses; let i = index;">
            <div class="card" >
              <div class="card-header">
                <h5>{{ address.status.toUpperCase() }}</h5>
              </div>
              <div class="card-body">
                <p>
                  <label>{{'common.name' | translate}} :</label> {{ address.name }}
                </p>
                <p>
                  <label>{{'user_profile.region' | translate}} :</label> {{ address.region }}
                </p>
                <p>
                  <label>{{'common.city' | translate}} :</label> {{ address.town }}
                </p>
                <p>
                  <label>{{"user_profile.address_1" | translate}} :</label> {{ address.addressLine1 }}
                </p>
                <p>
                  <label>{{"user_profile.address_2" | translate}} :</label> {{ address.addressLine2 }}
                </p>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <a class="btn btn-success me-2" id="btn-submit" (click)="editShippingAddress(address)">{{'common.edit' | translate}}</a>
                    
                    <a class="btn btn-danger" (click)="confirmDeletion(address)">{{'common.delete' | translate}}</a>
                  </div>
                  <div class="delete-message" [attr.id]="'sphAddr' + address.id">
                    <p *ngIf="!deleting">{{'user_profile.confirm_delete_address' | translate}} ?
                      <a (click)="deleteShippingAddress(address)">{{'common.yes' | translate}}</a>
                      <span style="margin: 0 5px;">|</span>
                      <a (click)="cancelDeletion(address)">{{'common.no' | translate}}</a>
                    </p>
                    <p *ngIf="deleting">{{'common.waiting' | translate}} ...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showForm" class="mt-4 mb-3 {{ shippingAddresses.length == 0 ? 'col-md-8'  : 'col-md-8' }}">
            <div class="card">
              <div class="card-header">

              </div>
              <div class="card-body">
                <form class="form-shipping-address"  [formGroup]="shippingAddressForm" (ngSubmit)="saveShippingAddress()" novalidate>
                  <div class="row">
                    <div class="col-md-12">
                      <label>{{'common.name' | translate}} <em class="required">*</em></label>
                      <input type="hidden" formControlName="id">
                      <input type="hidden" formControlName="status">
                      <input type="text" formControlName="name" class="form-control required-entry"
                             required [attr.autocomplete]="'off'"
                             [disabled]="saving == true"
                      />
                      <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                        {{'user_profile.name_required' | translate}}
                      </em>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label>{{'user_profile.region' | translate}} <em class="required">*</em></label>
                      <input type="text" formControlName="region" class="form-control required-entry"
                             required [attr.autocomplete]="'off'"
                             [disabled]="saving == true" />
                      <em *ngIf="(f.region.touched && f.region.invalid)" class="text-danger">
                        {{'user_profile.region_required' | translate}}
                      </em>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label>{{'common.city' | translate}} <em class="required">*</em></label>
                      <input type="text" formControlName="town" class="form-control required-entry"
                             required [attr.autocomplete]="'off'"
                             [disabled]="saving == true" />
                      <em *ngIf="(f.town.touched && f.town.invalid)" class="text-danger">
                        {{'common.city_required' | translate}}
                      </em>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label>{{'user_profile.address_1' | translate}} <em class="required">*</em></label>
                      <input type="text" formControlName="addressLine1" class="form-control " required
                             [attr.autocomplete]="'off'"
                             [disabled]="saving == true" />
                      <em *ngIf="(f.addressLine1.touched && f.addressLine1.invalid)" class="text-danger">
                        {{'user_profile.address_1_required' | translate}}
                      </em>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label>{{'user_profile.address_2' | translate}}</label>
                          <input type="text" formControlName="addressLine2" class="form-control"
                                 [attr.autocomplete]="'off'"
                                 [disabled]="saving == true" />
                    </div>
                    <div class="col-md-12 mt-2">
                      <button type="button" class="btn btn-danger" (click)="closeForm()">
                        {{'common.close' | translate}}
                      </button>
                      <span class="ml-2"></span>
                      <button type="submit" class="btn btn-success" id="btn-submit" [disabled]="!shippingAddressForm.dirty || shippingAddressForm.invalid" >
                        {{ (saving == true ? 'common.saving' : 'common.save') | translate }} <span *ngIf="saving"> ...</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <app-sidebar [currentUser]="currentUser"></app-sidebar>
    </div>
</div>

