import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/_models/order';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OrderService } from 'src/app/_services/order.service';
import { User } from 'src/app/_models/user';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Offer, getPrice } from 'src/app/_models/offer';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  loading = true;
  title = 'View Order';
  titleKey = 'orders_list.view_order'
  order: Order;
  lineOffers: any[] = [];
  orderRef: string;
  user: User;
  totalOrder = 0;

  private subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router, private titleService: Title,
    private orderService: OrderService, private translateService: TranslateService ) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.orderRef = this.activatedRoute.snapshot.paramMap.get('orderRef');

      const subs0 = this.translateService.get(this.titleKey).subscribe(value => {
        this.title = value;
        this.titleService.setTitle(this.title);
      });

      this.subscriptions.push(subs0);

    if (this.orderRef == null) {
      this.router.navigate(['not-found']);
    }
  }

  ngOnInit() {
    const subs1 = this.orderService.loadOrderByRef(this.user.id, this.orderRef).subscribe(
      (order: Order) => {
        this.order = order;
        this.lineOffers = order.lineOffers;
        this.lineOffers.forEach((lineOffer: any) => this.totalOrder += this.calculateTotal(lineOffer));
      },
      () => this.router.navigate(['not-found']),
      () => this.loading = false
    );

    this.subscriptions.push(subs1);
  }

  calculateTotal(lineOffer: any ) {
    let total: number;
    if (lineOffer.shippingOffer == null) {
      total = lineOffer.qty * this.getOfferPrice(lineOffer.offerDTO);
    } else {
      total = lineOffer.qty * (this.getOfferPrice(lineOffer.offerDTO) + lineOffer.shippingOffer.shippingCost);
    }
    return total;
  }

  get_payment_method_label(key: string) {
    if(key == 'CashOnDelivery') {
      return 'Cash On Delivery';
    } else if(key == 'CompanyMobileMoney') {
      return 'eFarm.CM Mobile Money';
    } else {
      return 'Flutterwave';
    }
  }

  getOfferPrice(offer: Offer): number {
    if(offer.percentDiscount == 0){
      return offer.price;
    }

    return getPrice(offer);
  }

}
