import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ad-content',
  templateUrl: './ad-content.component.html',
  styleUrls: ['./ad-content.component.css']
})
export class AdContentComponent {
  @Input() adImageOne: string;
  @Input() adLinkOne: string;
  @Input() adImageTwo: string;
  @Input() adLinkTwo: string;
}
