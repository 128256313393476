<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="page-title">
                <h2>Product edit</h2>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h2></h2>
                </div>
                <div class="card-body">
                    <ng-template #form_template>
                        <form class="form-offer-edit" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="content">
                                <fieldset class="group-select">
                                    <div class="col-md-12">
                                        <label>Product </label>
                                        <input type="text" [disabled]="true" [value]="offer.product.name" 
                                        class="form-control input-disabled">
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <label>Quantity</label>
                                            <input type="number" min="1" formControlName="qty" class="form-control"/>
                                            <em *ngIf="f.qty.touched && f.qty.invalid" class="text-danger">
                                                Quantity is required
                                            </em>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Measurement Unit</label>
                                            <select class="form-control" formControlName="measurementunit" [compareWith]="compareValues">
                                                <option *ngFor="let measurement of measurementUnits"
                                                        [ngValue]="measurement">{{ measurement.description }}</option>
                                            </select>
                                            <em *ngIf="f.measurementunit.touched && f.measurementunit.invalid"
                                                            class="text-danger">
                                                            Select a measurement units in the list
                                                        </em>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <label>Price</label>
                                            <input type="number" min="00.00" formControlName="price"
                                                   class="form-control"
                                                   ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01"/>
                                            <em *ngIf="f.price.touched && f.price.invalid" class="text-danger">Price is
                                                required</em>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Currency</label>
                                            <select class="form-control" formControlName="currency">
                                                <option [value]="'xaf'">XAF</option>
                                                            <option [value]="'usd'">USD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <label>Product condition</label>
                                            <select class="form-control" formControlName="productCondition" [compareWith]="compareValues">
                                                <option *ngFor="let productCondition of productConditions"
                                                        [ngValue]="productCondition">{{ productCondition.description }}</option>
                                            </select>
                                            <em *ngIf="f.productCondition.touched && f.productCondition.invalid"
                                                class="text-danger">
                                                Select Product condition in the list
                                            </em>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Pricing</label>
                                            <br/>
                                            <select class="form-control" formControlName="pricing" [compareWith]="compareValues" (change)="onChange()">
                                                <option *ngFor="let p of pricing" [ngValue]="p">{{ p.name }}</option>
                                            </select>
                                            <em *ngIf="f.pricing.touched && f.pricing.invalid" class="text-danger">
                                                Select a pricing in the list
                                            </em>
                                        </div>
                                    </div>
                                    <div class="row mt-3" *ngIf="(selectedPricing.nicename == 'promotion' || selectedPricing.nicename == 'auction' )">
                                        <div class="col-md-6" *ngIf="selectedPricing != '' && selectedPricing.nicename == 'promotion'">
                                            <label>Percent Discount</label>
                                            <input type="number" min="0" max="99" formControlName="percentDiscount" class="form-control" />
                                        </div>
                                        <div class="col-md-6">
                                            <label>Expiration Date</label>
                                            <input type="date" [min]="currentDate" formControlName="expirationDate" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <label>Product Description</label>
                                        <angular-editor  formControlName="description" [config]="editorConfig" ></angular-editor>
                                        <em *ngIf="f.description.touched && f.description.invalid" class="text-danger">
                                            Product Description is required
                                        </em>
                                    </div>
                                    <div class="checkbox  mt-2">
                                        <label>
                                            <input type="checkbox" formControlName="returns"/>
                                             {{ 'common.returns_accepted' | translate }} ?
                                        </label>
                                    </div>
                                    <div class="buttons-set mt-3">
                                        <button type="submit" id="btn-submit" [disabled]="form.invalid || submitted" class="btn tbn-success btn-md">
                                            {{ submitted ? 'Saving ...' : 'Update product' }}
                                        </button>
                                    </div>
                                    
                                </fieldset>
                            </div>
                        </form>
                    </ng-template>
                    <span *ngIf="loading else form_template"> Loading ...</span>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <app-sidebar [currentUser]="user"></app-sidebar>
        </div>
    </div>
</div>


