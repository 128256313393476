import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgressBarService } from '../../_services/progress-bar.service';
import { ApiService } from '../../_services/api.service';
import { AlertService } from '../../_services/alert.service';
import { UserService } from '../../_services/user.service';
import { PageTitleService } from '../../_services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {

  title = 'Account recovery';
  titleTranslateKey = 'common.account_recovery';

  formGroup: UntypedFormGroup;
  submitted = false;
  loading = false;

  subscriptions: Subscription[] = [];

  constructor(private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private pageTitleService: PageTitleService,
    private progressBarService: ProgressBarService,
    private apiService: ApiService,
    private translateService: TranslateService) {

    this.apiService.show_header_footer = false;
    const titleTranslateSubscription = this.translateService.get(this.titleTranslateKey)
    .subscribe(value => {
      this.title = value;
      this.pageTitleService.setPageTitle(this.title);
    });

    this.subscriptions.push(titleTranslateSubscription);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      emailOrPhone: new UntypedFormControl('', Validators.required)
    });
  }

  get f() { return this.formGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (!this.formGroup.valid) {
      return;
    }

    this.loading = true;
    this.userService.reset_password(this.formGroup.value.emailOrPhone).subscribe(
      () => {
        localStorage.setItem('forget_password_done', '1');
        this.router.navigate(['forget-password/request-sent']);
      }
    );

    this.loading = false;
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.apiService.show_header_footer = true;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
