import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import { ProductsConditionsService } from 'src/app/_services/products-conditions.service';
import { ProductCondition } from 'src/app/_models/product-condition';
import { AppConstants } from 'src/app/_constants/constants';

@Component({
    selector: 'app-product-condition-form',
    templateUrl: './product-condition-form.component.html',
    styleUrls: ['./product-condition-form.component.css']
})
export class ProductionConditionFormComponent implements OnInit {

    productConditionForm: UntypedFormGroup;
    productCondition: ProductCondition;
    title: string;
    submitted = false;

    constructor(private fb: UntypedFormBuilder, private location: Location,
                private pcService: ProductsConditionsService, private router: Router) {
        const item = window.localStorage.getItem(AppConstants.PRODUCT_CONDITION_KEY);
        this.productCondition = item ? JSON.parse(item) : new ProductCondition();
        this.title = item ? 'conditions.edit' : 'conditions.add';
    }

    ngOnInit() {
        this.productConditionForm = this.fb.group({
            id: new UntypedFormControl(0),
            description: new UntypedFormControl('', Validators.required),
            descriptionFr: new UntypedFormControl('', Validators.required),
            status: new UntypedFormControl("", Validators.required)
        });
        

        if (this.productCondition.id) {
            this.productConditionForm.setValue({
                id: this.productCondition.id,
                description: this.productCondition.description,
                descriptionFr: this.productCondition.descriptionFr,
                status: this.productCondition.status
            });
        }
    }

    get f() {
        return this.productConditionForm.controls;
    }

    submitForm() {
        if (!this.productConditionForm.dirty || this.productConditionForm.untouched || this.productConditionForm.invalid) {
            return;
        }
        this.submitted = true;
        this.productCondition.id ? this.update() : this.create();
    }

    create() {
        this.pcService.add_product_condition(this.productConditionForm.value).subscribe(
            () => {
                this.router.navigate(['admin/product-conditions']);
            },
            (error) => this.submitted = false
        );
    }

    update() {
        this.pcService.update_product_condition(this.productConditionForm.value).subscribe(
            () => {
                this.router.navigate(['admin/product-conditions']);
            },
            (error) => this.submitted = false
        );
    }

    back_to_previous() {
        this.location.back();
    }
}
