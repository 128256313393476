export const environment = {
  production: true,
  apiUrl: 'https://efarm.cm:8443',
  liveApiUrl: 'https://efarm.cm:8443/',
  appTitle: 'eFarm Market Place',
  apiUsername: 'payunit_NzajDpri9',
  apiPassword: 'b142a723-bba3-4c11-bfdf-3567533cef26',
  apiKey: 'ae9c4d040eaa9c65b372a5ab888ab1fb53844f21',
  mode: 'live',
  return_url: 'https://efarm.cm/payment/return',
  notify_url: 'https://efarm.cm:8443/payments/return',
  purchaseRef: '',
  total_amount: '',
  name: 'Efarm Market Place',
  currency: 'XAF',
  image_cdn_url: 'https://awkamyaulq.cloudimg.io/v7',
  appName: 'EWeb',
  flutterwaveKey: 'FLWPUBK-841497264b77555804504fac7ef0a761-X',
  companyNumber: '681763523',
  fixerKey: 'fd63b536fa83182bfc5ea0ad979f9ad3',
  exchangeRateApiKey: 'ca039b9e0c82e80097c310f6'
};
