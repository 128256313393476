import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {SignupComponent} from './users/signup/signup.component';
import {SigninComponent} from './users/signin/signin.component';
import {RegisterBusinessComponent} from './users/register-business/';
import {MyAccountComponent} from './users/my-account/my-account.component';
import {ForgetPasswordComponent} from './users/forget-password/forget-password.component';
import {ResetPasswordComponent} from './users/reset-password/reset-password.component';
import {FpRequrestSentComponent} from './users/fp-requrest-sent/fp-requrest-sent.component';
import {ResetPasswordCompleteComponent} from './users/reset-password-complete/reset-password-complete.component';
import {ShippingAddressComponent} from './users/shipping-address/shipping-address.component';
import {UserEditFormComponent} from './users/user-edit-form/user-edit-form.component';
import {UserProfilePictureComponent} from './users/user-profile-picture/user-profile-picture.component';
import {FilterPageGuard} from './_guards/filter-page.guard';
import {AuthGuard} from './_guards/auth.guard';
import {OfferComponent} from './offer/offer.component';
import {OfferFormComponent} from './offer-form/offer-form.component';
import {OfferViewComponent} from './offer-view/offer-view.component';
import {OfferEditFormComponent} from './user-offer/offer-edit-form/offer-edit-form.component';
import {ProductsListComponent} from './products/products-list/products-list.component';
import {SingleProductComponent} from './products/single-product/single-product.component';
import {CartComponent} from './cart/cart.component';
import {UserChangePasswordComponent} from './users/user-change-password/user-change-password.component';
import {CheckoutComponent} from './checkout/checkout.component';
import { WishlistComponent } from './wishlists/wishlist/wishlist.component';
import { BlogComponent } from './blog/blog/blog.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { AddBlogPostComponent } from './blog/add-blog-post/add-blog-post.component';
import { PageComponent } from './page/page.component';
import { ContactComponent } from './contact/contact.component';
import { TeamComponent } from './team/team.component';
import { PaymentReturnComponent } from './payment-return/payment-return.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';
import {PlaceCommandComponent} from './users/place-command/place-command.component';
import {SalesComponent} from './sales/sales.component';
import {ActivationComponent} from './users/activation/activation.component';
import {SignupConfirmationComponent} from './users/signup-confirmation/signup-confirmation.component';
import { CareerComponent } from './career/career.component';


const routes: Routes = [
    {path: 'signup', component: SignupComponent},
    {path: 'signup/confirmation', component: SignupConfirmationComponent},
    {path: 'signin', component: SigninComponent},
    {path: 'forget-password', component: ForgetPasswordComponent},
    {path: 'forget-password/request-sent', component: FpRequrestSentComponent},
    {path: 'password-reset/:token', component: ResetPasswordComponent},
    {path: 'activate/:token', component: ActivationComponent},
    {path: 'password-reset-complete', component: ResetPasswordCompleteComponent},
    {path: 'register-business', component: RegisterBusinessComponent},
    {
        path: 'user',
        canActivate: [AuthGuard, FilterPageGuard],
        children: [
            {
                path: 'dashboard',
                component: MyAccountComponent,
                title: 'My eFarm'
            },
            {path: 'place-your-command', component: PlaceCommandComponent,  title: 'Place command'},
            {
                path: ':id/wishlist',
                component: WishlistComponent,
                title: 'WishList'
            },
            {
                path: ':id/change-password',
                component: UserChangePasswordComponent,
                title: 'Change password'
            },
            {
                path: ':id/edit',
                component: UserEditFormComponent,
                title: 'Edit User'
            },
            {
                path: ':id/upload-profile-picture',
                component: UserProfilePictureComponent,
                title: 'Update Profile Picture'
            },
            {
                path: 'shipping-addresses',
                component: ShippingAddressComponent,
                title: 'Shipping addresses'
            },
            {
                path: ':id/products',
                component: OfferComponent,
                title: 'Products'
            },
            {
                path: ':id/products/add',
                component: OfferFormComponent
            },
            {
                path: ':id/products/:productId',
                component: OfferViewComponent,
                title: 'Add Offer'
            },
            {
                path: ':id/products/:productId/sales',
                component: SalesComponent,
                title: 'My Sales'
            },
            {
                path: ':id/products/:productId/edit',
                component: OfferEditFormComponent,
                title: 'Edit Offer'
            },
            {path: ':id/orders', component: OrdersListComponent, title: 'My Orders'},
            {path: ':id/orders/:orderRef', component: OrderComponent, title: 'Order details'},
        ]
    },
    {path: 'shopping-cart', component: CartComponent, title: 'Cart details'},
    {path: 'products/category/:slug', component: ProductsListComponent, title: 'Category - Products'},
    {path: 'products/taxonomy/:taxonomy_id', component: ProductsListComponent, title: 'Taxonomy - Products'},
    {path: 'products/:slug/:id', component: SingleProductComponent, title: 'Product - details'},
    {path: 'products/:pricing', component: ProductsListComponent, runGuardsAndResolvers: 'always', title: 'Pricing - Products'},
    {path: 'checkout', component: CheckoutComponent, title: 'Checkout'},
    {path: 'products', component: ProductsListComponent, title: 'Products'},
    {path: 'blog/:slug/:id', component: BlogPostComponent, title: 'Blog post'},
    {path: 'pages/:slug', component: PageComponent, title: 'Page'},
    {path: 'blog/add', component: AddBlogPostComponent, canActivate: [AuthGuard], title: 'Add blog post'},
    {path: 'blog', component: BlogComponent, title: 'Blog page'},
    {path: 'payment/return', component: PaymentReturnComponent},
    {path: 'payment/success/:itemRef', component: PaymentSuccessComponent, canActivate: [AuthGuard]},
    {path: 'contact-us', component: ContactComponent, title: 'Contact us'},
    {path: 'career', component: CareerComponent, title: 'Opening jobs positions'},
    {path: 'meet-our-team', component: TeamComponent, title: 'Meet our team'},
    {path: 'search', component: ProductsListComponent, title: 'Products - searcch results'},
    {path: 'not-found', component: PageNotFoundComponent},
    {path: '', component: HomeComponent, title: 'Home'},
    {path: '', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
