import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getCountryCallingCode, isValidPhoneNumber, getCountries, MetadataJson } from 'libphonenumber-js';
import { Country } from '../country/Country';


// custom validator to check that two fields match
export function IsPhoneNumberValid(countryCodeFieldName: string, phoneNumberFieldName: string) {

    return (formGroup: UntypedFormGroup) => {
        const countryCodecontrol = formGroup.controls[countryCodeFieldName];
        const phoneNumberControl = formGroup.controls[phoneNumberFieldName];
        const countries: Country[] = JSON.parse(localStorage.getItem('countries'));
        const country: Country =  countries.find((country) => { return country.id == countryCodecontrol.value});

        const phoneNumber = phoneNumberControl.value;
        const cc =  getCountries().find((c) => { return c == country.iso})

        if(country != null && isValidPhoneNumber(phoneNumberControl.value, cc)) {
            phoneNumberControl.setErrors(null);
        } else {
            console.log('Phone number is not valid');
            phoneNumberControl.setErrors({phoneMustValid:true});
        }
        // set error on matchingControl if validation fails

    };
}
