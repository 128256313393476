import { Component, OnInit } from '@angular/core';
import { OrderService } from '../_services/order.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from '../_models/user';
import { Order } from '../_models/order';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

  title = 'Confirmation';
  itemRef: string;
  user: User;
  order: Order;

  constructor(private orderService: OrderService, private activateRoute: ActivatedRoute, private toastr: ToastrService) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.itemRef = this.activateRoute.snapshot.paramMap.get('itemRef');
    if (this.itemRef === null) {
      this.toastr.error('Invalid order reference');
      window.location.href = '/#/not-found';
    }
  }

  ngOnInit() {
    this.loadOrder();
  }

  loadOrder() {
    this.orderService.loadOrderByRef(this.user.id, this.itemRef).subscribe(
      (order: Order) => this.order = order,
      () => {
        this.toastr.error('Order not found');
        window.location.href = '/#/not-found';
      }
    );
  }

}
