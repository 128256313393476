import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Router } from '@angular/router';
import { User } from '../../_models/user';
import { AlertService } from '../../_services/alert.service';
import { UserService } from '../../_services/user.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['./user-profile-picture.component.css']
})
export class UserProfilePictureComponent implements OnInit, OnDestroy {

  currentUser: User;
  selectedFile = null;
  imageChangedEvent: any = '';
  imageCroppedEvent: ImageCroppedEvent;
  croppedImage: any = '';
  croppedImageBase64: any = '';
  cropped = false;
  submitting = false;
  profileImage = '/assets/images/default.jpg';
  loading = true;

  private subscriptions: Subscription[] = [];

  constructor(private alertService: AlertService, public userService: UserService,
    private translateService: TranslateService, private router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.userService.load().subscribe(
      (user: User) => {
        this.profileImage = `${environment.image_cdn_url}/${user.picture}`;
      },
      () => this.loading = false,
      () => this.loading = false
    );
  }

  changePicture() {
    document.getElementById('file-input').click();
  }

  onSelectedPicture(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
  }

  imageLoaded() {
    this.alertService.hide();
  }

  loadImageFailed() {
    this.selectedFile = null;
    const subs = this.translateService.get("user_profile.invalid_image_type")
    .subscribe(response => {
      this.alertService.error(response);
    });

    this.subscriptions.push(subs);
  }

  onCancelImageChangedEvent() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.croppedImageBase64 = '';
    this.cropped = false;
    this.selectedFile = null;
  }

  onCrop() {
    this.cropped = true;
  }

  imageCropped(imageCroppedEvent: ImageCroppedEvent) {
    this.imageCroppedEvent = imageCroppedEvent;
    this.croppedImage = imageCroppedEvent.file;
    this.croppedImageBase64 = imageCroppedEvent.base64;
  }

  uploadPicture() {
    this.submitting = true;
    this.userService.uploadPicture(this.currentUser, this.croppedImage).subscribe(
      () => {
        this.userService.load().subscribe(
          (user: User) => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.router.navigate(['/user/dashboard']);
          }
        );
      },
      () => { this.submitting = false; }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
