import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PricingService } from 'src/app/_services/pricing.service';
import { Pricing } from 'src/app/_models/pricing';
import { AppConstants } from 'src/app/_constants/constants';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit, OnDestroy {

    pricings: Pricing[];
    page = '1';
    item_per_page = '10';
    config: any;
    loading: boolean;

    constructor(private pricingService: PricingService,
        private router: Router, private route: ActivatedRoute, private titleService: Title) {
        this.route.queryParamMap.subscribe(
            (paramAsMap: any) => {
                if (paramAsMap.params.page) {
                    this.page = paramAsMap.params.page;
                }
            }
        );
    }

    ngOnInit() {
        this.load_pricings(this.page);
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('');
    }

    load_pricings(page: string) {
        this.loading = true;
        this.pricingService.local_pricings(this.item_per_page, page)
            .subscribe(
                (result) => {
                    this.pricings = result.data;
                    this.config = {
                        currentPage: result.page,
                        itemsPerPage: result.itemPerPage,
                        totalItems: result.totalOfItems,
                        id: 'listing'
                    };
                    this.loading = false;
                }
            );
    }

    add_pricing() {
        window.localStorage.removeItem(AppConstants.PRICING_KEY);
        this.router.navigate(['admin/pricing/add']);
    }

    edit_pricing(pricing: Pricing) {
        window.localStorage.removeItem(AppConstants.PRICING_KEY);
        window.localStorage.setItem(AppConstants.PRICING_KEY, JSON.stringify(pricing));
        this.router.navigate(['admin/pricing', pricing.id, 'edit']);
    }

    delete_pricing(pricing: Pricing) {
        this.pricingService.delete_pricing(pricing)
        .subscribe(() => {
            this.pricings = this.pricings.filter(pc => pc.id !== pricing.id);
                this.page = '1';
                this.router.navigate(['/admin/pricing'], { queryParams: { page: this.page } });
                this.load_pricings(this.page);
        });
    }

    pageChange(page: string) {
        this.page = page;
        this.router.navigate(['/admin/pricing'], { queryParams: { page: page } });
        this.load_pricings(page);
    }
}
