import { Component } from '@angular/core';
import { ProductCategoryService } from '../_services/product-category.service';
import { IProductCategory } from '../_models/iproduct-category';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.css']
})
export class CategorySliderComponent {
  categorySlides = [
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
    { img: "assets/images/new/product-1.png" },
  ];
  loading = true;
  categories: IProductCategory[] = [];
  lang = 'en';

  slideConfig = {
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 2,
    centerMode: false,
    prevArrow: "<button class='slide-arrow prev-arrow'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    nextArrow: "<button class='slide-arrow next-arrow'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }

  constructor(private categoryService: ProductCategoryService, 
    private translateService: TranslateService) { 
      this.lang = this.translateService.currentLang;
    }

  ngOnInit(): void {
    this.categoryService.get_category_list().subscribe(
      (categories) => {
        this.categories =categories;
        this.loading = false;
      },
      (error) => {console.error(error)}
    )
  }

  printImage(category) {
    return category.picture !== '' && category.picture !== null  ?
        environment.image_cdn_url + '/' + category.picture + '?func=crop&width=130&height=120' : '/assets/images/default.jpg';
  }
}
