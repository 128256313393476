<section class="main-container col2-left-layout bounceInUp animated">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-heading">
          <div class="page-title">
            <h2>{{ titleTranslateKey | translate }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 product-list">
        <ng-template #listing_offers>
          <div class="pro-coloumn">
            <ng-template #products_not_found>
              <h3>
                {{
                  (isSearch
                    ? "products.no_search_results"
                    : "products.no_products"
                  ) | translate
                }}
              </h3>
            </ng-template>
            <article *ngIf="offers.length > 0; else products_not_found">
              <div class="toolbar" *ngIf="config.totalOfPage > 1">
                <div class="pager">
                  <pagination-controls
                    class="pages"
                    maxSize="5"
                    [previousLabel]="'common.previous' | translate"
                    [nextLabel]="'common.next' | translate"
                    autoHide="true"
                    id="listing"
                    (pageChange)="pageChange($event)"
                  >
                  </pagination-controls>
                </div>
              </div>
              <div class="bbb_viewed_slider_container">
                <div class="row product_row" id="products-list">
                  <div
                    class="col-lg-3 col-6 px-0 product_col"
                    *ngFor="let offer of offers | paginate : config"
                  >
                    <!-- product image -->
                    <div
                      class="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center"
                    >
                      <a
                        [routerLink]="[
                          '/products',
                          offer.product.slug == null
                            ? offer.product.name
                            : offer.product.slug,
                          offer.id
                        ]"
                        title="{{ offer.product.name }}"
                        class="bbb_viewed_image"
                      >
                        <img
                          class="small-image"
                          [defaultImage]="offer.defaultPicture"
                          [lazyLoad]="getImageSrc(offer)"
                          alt="{{ offer.product.name }}"
                        />
                      </a>
                      <!-- product name -->
                      <div class="product-shop">
                        <div class="text-start">
                          <a
                            [routerLink]="[
                              '/products',
                              offer.product.slug == null
                                ? offer.product.name
                                : offer.product.slug,
                              offer.id
                            ]"
                            title="{{
                              lang === 'fr'
                                ? offer.product.productNameFr
                                : offer.product.productName
                            }}"
                            class="bbb_viewed_name mt-2"
                          >
                            {{
                              lang === "fr"
                                ? offer.product.productNameFr
                                : offer.product.productName
                            }}
                          </a>
                        </div>
                        <div
                          class="desc std text-start"
                          [innerHTML]="
                            getHTMLText(offer.description) | slice : 0 : 150
                          "
                        ></div>
                        <div class="price-box text-start">
                          <!-- <p class="old-price" *ngIf="isInPromotion(offer)">
                            <span class="price">
                              {{ offer.price }}
                              {{ offer.currency | uppercase }} /
                              {{
                                lang === "fr"
                                  ? offer.measurementunit.descriptionFr
                                  : offer.measurementunit.description
                              }}
                            </span>
                          </p> -->
                          <span class="regular-price">
                            <span class="price">
                              {{ getOfferPrice(offer) }}
                              {{ offer.currency | uppercase }} /
                              {{
                                lang === "fr"
                                  ? offer.measurementunit.descriptionFr
                                  : offer.measurementunit.description
                              }}</span
                            >
                          </span>
                        </div>
                        <div class="actions mt-2">
                          <div class="row">
                            <div
                              class="col-12 col-md-6 pe-auto pe-md-1 pb-2 pb-md-0"
                            >
                              <button
                                *ngIf="!isOfferInCart(offer)"
                                class="btn btn-default btn-cart ajx-cart"
                                [title]="'common.add_to_cart' | translate"
                                type="button"
                                (click)="addToCart(offer)"
                              >
                                <span>
                                  <i class="fa fa-shopping-cart me-2"></i>
                                  {{
                                    "offers.add_to_cart" | translate
                                  }}
                                </span>
                              </button>
                            </div>
                            <div class="col-12 col-md-6 ps-auto ps-md-1">
                              <button
                                title="add to WishList"
                                [title]="'common.add_to_wishlist' | translate"
                                class="btn btn-default btn-wishlist"
                                (click)="addToWishlist(offer.id)"
                              >
                                <span>
                                  <i class="far fa-heart me-2"></i>
                                  {{
                                    "offers.add_to_wishlist" | translate
                                  }}
                              </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="toolbar" *ngIf="config.totalOfPage > 1">
                <div class="pager mt-3">
                  <pagination-controls
                    class="pages"
                    maxSize="5"
                    [previousLabel]="'common.previous' | translate"
                    [nextLabel]="'common.next' | translate"
                    autoHide="true"
                    id="listing"
                    (pageChange)="pageChange($event)"
                  >
                  </pagination-controls>
                </div>
              </div>
            </article>
          </div>
        </ng-template>
        <div class="pro-coloumn" *ngIf="loading; else listing_offers">
          <p style="text-align: center">
            {{ "common.loading" | translate }} ...
          </p>
        </div>
      </div>

      <!-- <aside
        class="col-left sidebar col-sm-3 col-xs-12 col-sm-pull-9 wow bounceInUp animated animated"
        style="visibility: visible"
      > -->
      <!-- BEGIN SIDE-NAV-CATEGORY -->
      <!-- <app-side-nav-categories></app-side-nav-categories> -->

      <!-- <app-side-nav-cart></app-side-nav-cart> -->
      <!-- </aside> -->
      <!--col-right sidebar-->
    </div>
    <!--row-->
  </div>
  <!--container-->
</section>
