<div class="col-md-12">
    <div class="mb-3 mt-2">
        <h5>List of Taxonomies</h5>
    </div>
    <div class="mb-3 mt-3">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#groupForm">
            Add Taxonomy
        </button>
    </div>
    <div class="table-responsive">
        <form>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>
                            Taxonomy
                        </th>
                        <th>
                            Number of offers
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="loading; else tableContent">
                        <td colspan="4">Loading</td>
                    </tr>
                    <ng-template #tableContent>
                        <tr *ngFor="let group of taxonomies;">
                            <td>{{ group.title }}</td>
                            <td>{{ group.totalOfOffers }}</td>
                            <td>{{ group.status ? 'Active' : 'Disabled' }}</td>
                            <td>
                                <button type="button" class="btn btn-primary btn-sm" (click)="open(group, assign_offers)" >Assign products</button>
                                <!-- |
                                <a [routerLink]="['/admin', 'taxonomies', group.id, 'edit']">Edit</a>
                                |
                                <a [routerLink]="['/admin', 'taxonomies', group.id, 'delet']">Delete</a> -->
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </form>
    </div>

    <div class="modal fade" id="groupForm" data-bs-backdrop="static" 
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Group</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" (ngSubmit)="submit()">
                    <div class="col-md-12">
                        <label>Title of group</label>
                        <input type="text" formControlName="title" class="form-control" required>
                    </div>
                    <div class="col-md-12 mt-3">
                        <button class="btn btn-primary btn-xs" [disabled]="addForm.invalid">
                            Save
                        </button>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      
</div>

<ng-template #assign_offers>
    <div class="modal-header">
        <h4 class="modal-title">Assign produts to {{ modalTitle }}</h4>
        <button type="button" class="btn-close" aria-label="Close" 
        (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="assignForm">
            <div class="form-check mb-2" *ngFor="let offer of offers">
                <input type="checkbox"  class="form-check-input" (change)="onChange(offer.id, $event)" [id]="offer.product.name + offer.id" [value]="offer.id">
                <div class="fomr-check-label" >
                    <label [for]="offer.product.name + offer.id">
                        {{ offer.product.name }} ({{ offer.price }} {{ offer.currency | uppercase }}) 
                        <span *ngIf="offerIds.includes(offer.id)" style="color: red;">*</span>
                    </label>
                </div>
            </div>
            <div class="mt-3">
                <button type="button" class="btn btn-outline-dark me-3"
                (click)="close()">Close
            </button>
                <button type="submit" [disabled]="submitting" class="btn btn-primary btn-xs" (click)="assignOffers()">
                    Assign
                </button>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <p>{{ offerIds.length }} offers selected</p>
    </div>
   
</ng-template>
