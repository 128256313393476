<div class="row">
  <div class="col-md-12" *ngIf="loading; else userInfo">
    <h4>{{ "common.waiting" | translate }}</h4>
  </div>
  <ng-template #userInfo>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h5>{{'dashboard.contact_infos' | translate}}</h5>
        </div>
        <div class="card-body">
          <p>
            <label>{{'dashboard.full_name' |translate}} : </label> {{user.name }}
          </p>
          <p>
            <label>{{'dashboard.email_address' |translate}} :</label> {{user.email }}
          </p>
          <p>
            <label>{{'login.phone_number' | translate}} :</label> {{user.phone }}
          </p>
          <p>
            <label>{{'common.country' | translate}}: </label> {{user.country.name}}
          </p>
          <p>
            <label>{{'common.city' | translate}} :</label> {{user.town }}
          </p>
          <p>
            <a class="btn btn-primary" [routerLink]="['/user/' + user.id + '/edit']">{{'common.edit' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header" id="shipping-box">
          <h5>{{'dashboard.default_shipping_address' | translate}}</h5>
        </div>
        <div class="card-body">
          <div *ngIf="defaultShippingAddress">
            <p>
              <label>{{'common.name' | translate}} :</label> {{ defaultShippingAddress.name }}
            </p>
            <p>
              <label>{{'user_profile.region' | translate}} :</label> {{ defaultShippingAddress.region }}
            </p>
            <p>
              <label>{{'common.city' | translate}} :</label> {{ defaultShippingAddress.town }}
            </p>
            <p>
              <label>{{'user_profile.address_1' | translate}} :</label> {{ defaultShippingAddress.addressLine1 }}
            </p>
            <p>
              <label>{{'user_profile.address_2' | translate}} :</label> {{ defaultShippingAddress.addressLine2 }}
            </p>
          </div>
          <p>
            <a class="btn btn-primary" [routerLink]="['/user/shipping-addresses']">{{'sidebar.my_addresses' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
