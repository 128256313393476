import {Injectable} from '@angular/core';
@Injectable()
export class ApiService {

    show_header_footer = true;
    admin_layout = false;

    loadAppContent ( ) {
        return window.location.href.endsWith('#/');
    }

}
