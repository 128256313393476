<div class="container text-center">
    <div class="row">
        <div class="col align-self-center">
            <div class="page-title">
                <h2>{{ titleTranslateKey | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="mx-auto col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12 align-self-center" style="margin: auto;">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="login-form" class="row g-3">
                                <div [attr.id]="'content-messages'">
                                    <app-alert></app-alert>
                                </div>
                                <div class="col-md-12">
                                    <label>{{'login.phone_number'| translate}}</label>
                                    <div class="input-box">
                                        <input type="text" formControlName="phone" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                            [placeholder]="'login.phone_number_placeholder' | translate"/>
                                    </div>
            
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">{{'login.phone_number_required' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                     <label>{{'login.password' | translate}}</label>
                                    <div class="input-box">
                                        <input type="password" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                                    </div>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">{{'login.password_required' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <input type="checkbox" name="remember_password" value="1">
                                    <label style="margin-left: 5px;">{{'login.remember_me' | translate}}</label>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6" style="text-align: right;">
                                    <a [routerLink]="['/forget-password']" class="forgot-word forget_link"> {{'login.forgot_password' | translate}}?</a>
                                </div>
                                <div class="col-md-12">
                                    <div class="remember-me-popup">
                                        <div class="remember-me-popup-head" style="display:none">
                                            <h3 id="text2">What's this?</h3>
                                            <a href="#" class="remember-me-popup-close" onClick="showDiv()"
                                            title="Close">Close</a>
                                        </div>
                                        <div class="remember-me-popup-body" style="display:none">
                                            <p id="text1">Checking "Remember Me" will let you access your shopping cart on this
                                                computer when you
                                                are logged out</p>
            
                                            <div class="remember-me-popup-close-button a-right">
                                                <a href="#" class="remember-me-popup-close button" [title]="'common.close' | translate"
                                                onClick="showDiv()"><span>{{'common.close' | translate}}</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="buttons-set" style="text-align: center;">
                                        <button [disabled]="loading" type="submit" class="btn btn-success" [title]="'rightnav.log_in'| translate" name="send" id="send2">
                                            <span>{{'rightnav.log_in' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <br/>
                            <div class="buttons-set2" style="text-align: center;">
                                <p>{{'login.want_to_buy_sell' | translate}} ? <a [routerLink]="['/signup']" class="signup_link">{{'rightnav.sign_up' | translate}}</a></p>
                                <p style="height: 50px;"><span *ngIf="loading" class="loader fe-loader"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--account-login-->
</div>



