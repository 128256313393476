<div class="row">
    <div class="col-md-12 mb-3">
        <h5>
            Blog posts
        </h5>
    </div>
    <div class="col-md-12">
        <table class="table table-bordered table-striped table-sm">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Posted by</th>
                    <th>Posted At</th>
                    <th>Actions </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="loading; else tableResult">
                    <td class="text-center" colspan="4">Loading ...</td>
                </tr>
                <ng-template #tableResult>
                    <tr *ngFor="let post of posts | paginate: config">
                        <td>{{ post.title.length > 80 ? (post.title | slice:0:80) + '...' : post.title }}</td>
                        <td>{{ post.name}}</td>
                        <td>{{ post.postedAt}}</td>
                        <td>
                            <a [routerLink]="['/admin/','blog-posts', post.id, 'edit']" class="btn btn-success btn-sm" id="btn-edit">
                                Edit
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>

        <div class="toolbar" *ngIf="!loading">
            <div class="pager mt-3">
              <pagination-controls
                class="pages"
                maxSize="5"
                [previousLabel]="'common.previous' | translate"
                [nextLabel]="'common.next' | translate"
                autoHide="true"
                id="blogpost"
                (pageChange)="pageChange($event)"
              >
              </pagination-controls>
            </div>
          </div>
    </div>
</div>