import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validator, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ShippingMeanService} from '../../../_services/shipping-mean.service';
import {ToastrService} from 'ngx-toastr';
import {CountryService} from '../../../country/country.service';
import {ShippingType} from '../../../_models/shipping-type';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-shipping-mean-form',
    templateUrl: './shipping-mean-form.component.html',
    styleUrls: ['./shipping-mean-form.component.css']
})
export class ShippingMeanFormComponent implements OnInit, OnDestroy {

    title: string;
    shippingMean: any;
    form: UntypedFormGroup;
    countries: any[];
    shippingTypes: ShippingType[]= [];
    submitted = false;
    loading = true;

    constructor(private fb: UntypedFormBuilder,
                private router: Router,
                private countryService: CountryService,
                private shippingMeanService: ShippingMeanService,
                private toastrService: ToastrService) {
        this.shippingMean = JSON.parse(localStorage.getItem('shipping-mean'));
        this.title = this.shippingMean.id ? 'Edit Shipping Mean' : 'Add Shipping mean';
    }

    ngOnInit() {
        combineLatest([
                this.shippingMeanService.getShippingTypes(),
                this.countryService.loadAll()
            ])
            .subscribe(
                ([shippingTypes, countries]) => {
                    this.shippingTypes = shippingTypes;
                    this.countries = countries;
                    
                },
                () => {},
                () => {
                    this.buildForm();
                    this.loading = false;
                }
            );
    }

    buildForm() {
        this.form = this.fb.group({
            id: new UntypedFormControl(this.shippingMean.id ? this.shippingMean.id : '' ),
            description: new UntypedFormControl(this.shippingMean.id ? this.shippingMean.description : null, Validators.required),
            status: new UntypedFormControl(this.shippingMean.id ? this.shippingMean.status : ''),
            countryId: new UntypedFormControl( this.shippingMean.id ? this.shippingMean.country.id : '', Validators.required),
            shippingTypeId: new UntypedFormControl(this.shippingMean.id ? this.shippingMean.shippingType.id : '', Validators.required)
        });
    }

    get f() {
        return this.form.controls;
    }

    ngOnDestroy(): void {
        localStorage.removeItem('shipping-mean');
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.submitted = true;
        this.form.value.status =  this.form.value.status ? 1 : 0;
        const obs = this.shippingMean.id ?
            this.shippingMeanService.updateShippingMean(this.shippingMean.id, this.form.value)
            :
            this.shippingMeanService.addShippingMean(this.form.value);
        obs.subscribe(() => {
            this.toastrService.success('Shipping mean successfully saved');
            this.router.navigate(['admin', 'shipping-means']);
        },
            () => { this.submitted = false; });
    }

    backToPrevious() {
        this.router.navigate(['admin', 'shipping-means']);
    }

}
