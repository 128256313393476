<div class="recent-orders">
  <div class="title-buttons">
    <strong>New Page </strong>
  </div>
  <br />
  <br />
  <div class="table-responsive">
    <div class="form-page-block">
      <div class="card">
        <div class="card-header">
          <span>&nbsp;</span>
        </div>
        <div class="card-body">
          <form class="form-page" [formGroup]="form" (ngSubmit)="save()">
            <div class="row">
              <div class="col-md-6">
                <label>Title (En) </label>
                <input type="text" class="form-control" formControlName="title" />
              </div>
              <div class="col-md-6">
                <label>Title (Fr)</label>
                <input type="text" class="form-control" formControlName="titleFr" />
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <label>Body (En)</label>
              <angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
            </div>
            <div class="col-md-12 mt-3">
              <label>Body (Fr)</label>
              <angular-editor formControlName="contentFr" [config]="editorConfig"></angular-editor>
            </div>
            <div class="button-set mt-2">
              <button [disabled]="form.invalid || submitting" class="btn btn-success" id="btn-submit" type="submit">
                Save
              </button>
              &nbsp;
              <span *ngIf="submitting">saving ...</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
