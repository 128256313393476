import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URLS} from '../config/api.urls';

@Injectable({
  providedIn: 'root'
})
export class ShippingOfferService {

  constructor(private httpClient: HttpClient) {}

  addShippingOffer(offerId, shippingOffer) {
    return this.httpClient.post(API_URLS.OFFERS_BASE_URL + '/' + offerId + '/shipping_offers', shippingOffer);
  }

  updateShippingOffer(offerId, shippingOffer) {
    return this.httpClient.put(API_URLS.OFFERS_BASE_URL + '/' + offerId + '/shipping_offers/' + shippingOffer.id , shippingOffer);
  }

  deleteShippingOffer(offerId, shippingOfferId) {
    return this.httpClient.delete(API_URLS.OFFERS_BASE_URL + '/' + offerId + '/shipping_offers/' + shippingOfferId);
  }
}
