<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="page-title text-center">
        <h3>{{ titleTranslateKey | translate }}</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 mt-4 mx-auto">
      <app-alert></app-alert>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" id="login-form">
        <div class="row mt-2">
          <h5 class="text-center">
            {{'forgot_password.find_account' | translate}}
          </h5>
          <div class="col-md-12 mt-2">
            <!-- <label>{{'common.email_or_phone' | translate}}</label> -->
            <input type="text" formControlName="emailOrPhone" class="form-control" id="emailOrPhone"
            [placeholder]="'common.enter_email_or_phone' | translate"/>
            <div *ngIf="submitted && f.emailOrPhone.errors" class="invalid-feedback">
              <div *ngIf="f.emailOrPhone.errors.required">{{'common.required_field' | translate}}</div>
            </div>
          </div>
          <div class="buttons-set mt-2">
            <button [disabled]="loading" type="submit" class="btn btn-success" title="Login" name="send" id="btn-submit">
              <span *ngIf="!loading; else waiting">{{'common.submit' | translate}}</span>
              <ng-template #waiting>
                <span >{{'common.waiting' | translate}} ...</span>
              </ng-template>
            </button>
            &nbsp;
            <a [routerLink]="['/signin']" class="forgot-word">{{'common.back_to_login' | translate}}</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
