import {Component, OnInit} from '@angular/core';
import {OfferService} from 'src/app/_services/offer.service';
import {IPagination} from 'src/app/_models/pagination';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ProductCategory} from 'src/app/_models/product-category';
import {combineLatest} from 'rxjs';
import {ProductCategoryService} from 'src/app/_services/product-category.service';
import {OfferStatus} from '../../commons/offer-status';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

    page = '1';
    offers: any[] = [];
    config: any;
    loading = true;
    filterCategoryId = '0';
    filterPrice = '0';
    nameSort = 'desc';
    createdAtSort = 'desc';
    filterForm: UntypedFormGroup;
    categories: ProductCategory[];

    constructor(private offerService: OfferService,
                private router: Router,
                private route: ActivatedRoute,
                private fb: UntypedFormBuilder,
                private productCatService: ProductCategoryService,
                private browserTitle: Title) {
        // set browser title
        this.browserTitle.setTitle('Offers - ' + environment.appTitle);
        this.filterForm = this.fb.group({
            filterCategoryId: [0],
            filterPrice: [''],
        });
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(
            (paramAsMap: any) => {
                if (paramAsMap.params.page) {
                    this.page = paramAsMap.params.page;
                    this.nameSort = paramAsMap.params.name;
                    this.createdAtSort = paramAsMap.params.createdAt;
                }
            }
        );

        combineLatest(
            [
                this.productCatService.get_product_categories(),
                this.loadOfferByAdmin()
            ]
        ).subscribe(
            ([categories, values]) => {
                this.categories = categories;
                this.extractResult(values);
            },
            (error) => {},
            () => this.loading = false
        );

    }

    getOffers() {
        this.loading = true;
        this.loadOfferByAdmin().subscribe(
            (result: IPagination) => this.extractResult(result),
            null,
            () => this.loading = false
        );
    }

    getStatus(status) {
        return OfferStatus.getStatus(status);
    }

    extractResult(result: any) {
        this.offers = result.data;
        this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            id: 'pagination'
        };
    }

    loadOfferByAdmin() {
        return this.offerService.loadOffersByAdmin(this.page, this.filterCategoryId, this.filterPrice, this.nameSort, this.createdAtSort);
    }

    pageChange(page) {
        this.page = page;
        this.router.navigate(['/admin/offers'], {queryParams: {page: page, name: this.nameSort, createdAt: this.createdAtSort}});
        this.getOffers();
    }

    sortResult(nameSort: string, createdAtSort: string) {
        this.router.navigate(['/admin/offers'], {queryParams: {page: this.page, name: nameSort, createdAt: createdAtSort}});
        this.getOffers();
        this.nameSort = nameSort === 'desc' ? 'asc' : 'desc';
        this.createdAtSort = createdAtSort === 'desc' ? 'asc' : 'desc';
    }

    filterOffers() {
        if (this.filterForm.value.filterCategoryId === '' && this.filterForm.value.filterPrice === '') {
            return;
        }
        this.filterCategoryId = this.filterForm.value.filterCategoryId;
        this.filterPrice = this.filterForm.value.filterPrice;
        if (this.filterForm.value.filterPrice === '' || this.filterForm.value.filterPrice === null) {
            this.filterPrice = '0';
        }

        this.getOffers();
    }

}
