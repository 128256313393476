import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { OfferService } from '../_services/offer.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Utilities } from '../commons/utilities';
import { MediaService } from '../_services/media.service';
declare var $: any;
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { PaymentMethod } from '../_models/PaymentMethod';
import { PaymentMethodService } from '../_services/payment-method.service';

import 'rxjs/operator/combineLatest';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { ShippingMeanService } from '../_services/shipping-mean.service';
import { ShippingType } from '../_models/shipping-type';
import { ShippingOfferService } from '../_services/shipping-offer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-offer-view',
    templateUrl: './offer-view.component.html',
    styleUrls: ['./offer-view.component.css']
})
export class OfferViewComponent implements OnInit, OnDestroy {
    offer: any;
    user: User;
    offerId: string;
    hasPictures = false;
    mediaList = [];
    paymentMethods: PaymentMethod[];
    myPaymentMethods = [];
    selectedPaymentMethods = [];
    picture: any;
    imageChangedEvent: any = '';
    imageCroppedEvent: ImageCroppedEvent;
    croppedImage: any;
    totalOfImages = 0;
    loading = true;
    formShippingOpened = false;
    shippingTypes: ShippingType[];
    shippingOffers = [];
    shippingOffer: any;
    shippingMeans = [];

    currentLanguage = 'fr';

    private subscriptions: Subscription[] = [];

    constructor(private router: Router,
        private state: ActivatedRoute,
        private toastrService: ToastrService,
        private offerService: OfferService,
        private shippingOfferService: ShippingOfferService,
        public userService: UserService,
        private shippingMeanService: ShippingMeanService,
        private paymentMethodService: PaymentMethodService,
        private mediaService: MediaService,
        private translateService: TranslateService
    ) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.shippingTypes = [];
        this.currentLanguage = translateService.currentLang;
    }

    ngOnInit() {
        this.offerId = this.state.snapshot.paramMap.get('productId');

        // get all payment methods and load a single offer
        combineLatest(
            this.getOffer(),
            this.getAllShippingMeans()
        )
            .subscribe(
                (values: any) => {
                    this.offer = values[0];
                    this.shippingMeans = values[1];
                    this.groupShippingMeansByType(values[1]);
                    this.shippingOffers = this.offer.shippingOffers;
                    this.myPaymentMethods = this.offer.paymentMethods;
                    this.mediaList = this.offer.mediaList;
                    this.hasPictures = (this.mediaList.length > 0);
                    this.totalOfImages = this.mediaList.length;
                },
                (error: any) => {
                    this.toastrService.error(error);
                },
                () => {
                    this.loading = false;
                    this.setSelectedPayments();
                }
            );
    }

    getOffer() {
        return this.offerService.getOffer(this.user.id, this.offerId);
    }

    getAllShippingMeans() {
        return this.shippingMeanService.getAllShippingMeans();
    }

    chooseFiles(eltId: string) {
        document.getElementById(eltId).click();
    }

    onChange(event) {
        this.picture = event.target.files;
        this.hasPictures = true;
        this.imageChangedEvent = event;
    }

    imageCropped(imageCroppedEvent: ImageCroppedEvent) {
        this.imageChangedEvent = null;
        this.imageCroppedEvent = imageCroppedEvent;
        this.croppedImage = imageCroppedEvent.file;
        this.uploadPicture();
    }

    uploadPicture() {
        const uuid = Utilities.getUUID();
        $('.screenshots #pictures').append('<div class="product-img" id="' + uuid + '"><div class="loader"></div></div>');
        return this.offerService.uploadPicture(this.user.id, this.offerId, this.croppedImage)
            .subscribe(
                (media: any) => {
                    $('#' + uuid).remove();
                    this.hasPictures = true;
                    this.totalOfImages++;
                    this.mediaList.push(media);
                }
            );
    }

    deleteOfferPicture(media) {
        this.totalOfImages--;
        if (this.totalOfImages === 0) {
            this.hasPictures = false;
        }
        const index = this.mediaList.indexOf(media);
        this.mediaList.splice(index, 1);
        this.mediaService.deleteMedia(media.id).subscribe();
    }

    isSelected(paymentMethod) {
        let found = false;
        for (let i = 0; i < this.myPaymentMethods.length; i++) {
            if (this.myPaymentMethods[i].id === paymentMethod.id) {
                found = true;
                break;
            }
        }
        return found;
    }

    checkOrUncheckPaymentMethod(paymentMethod) {
        const index = this.selectedPaymentMethods.indexOf(paymentMethod);
        (this.selectedPaymentMethods.length === 0 || (index < 0)) ?
            this.selectedPaymentMethods.push(paymentMethod)
            :
            this.selectedPaymentMethods.splice(index, 1);
    }

    saveSelectedPaymentMethods() {
        this.myPaymentMethods = this.selectedPaymentMethods.slice(0, this.selectedPaymentMethods.length);

        const items = [];
        for (let j = 0; j < this.selectedPaymentMethods.length; j++) {
            const paymentMethod = new PaymentMethod();
            paymentMethod.id = this.selectedPaymentMethods[j].id;
            items.push(paymentMethod);
        }

        const subs = this.offerService.savePaymentMethods(this.user.id, this.offerId, items).subscribe(
            () => {
                const subs = this.translateService.get('offers.payment_methods_saved').subscribe(value => {
                    this.toastrService.success(value);
                });
                this.subscriptions.push(subs);
            },
            error => {
                this.toastrService.error(error);
            }
        );

        this.subscriptions.push(subs);
    }

    getImageSrc(imagePath) {
        return imagePath !== '' && imagePath !== null ? environment.apiUrl + '/' + imagePath : '/assets/images/default.jpg';
    }

    openShippingOfferForm() {
        this.formShippingOpened = !this.formShippingOpened;
        this.shippingOffer = null;
    }

    editShippingOffer(shippingOffer) {
        this.shippingOffer = shippingOffer;
        this.formShippingOpened = !this.formShippingOpened;
    }

    deleteShippingOffer(shippingOffer) {
        const subs = this.translateService.get('offers.confirm_delete').subscribe(value => {
            if (confirm(value)) {
                this.removeCurrentShippingOfferInTheList(shippingOffer);
                this.shippingOfferService.deleteShippingOffer(this.offerId, shippingOffer.id).subscribe(
                    (result: any) => this.toastrService.success(result.message)
                );
            }
        });

        this.subscriptions.push(subs);
    }

    saveShippingOffer(shippingOffer) {
        // clear shipping type
        shippingOffer.shippingMean.shippingType.shippingMeanList = [];
        shippingOffer.id !== '' ? this.updateShippingOffer(shippingOffer) : this.addShippingOffer(shippingOffer);
        this.groupShippingMeansByType(this.shippingMeans);
    }

    closeShippingOfferForm(status) {
        this.formShippingOpened = status;
    }

    private setSelectedPayments() {
        for (let i = 0; i < this.paymentMethods.length; i++) {
            if (this.isSelected(this.paymentMethods[i])) {
                this.selectedPaymentMethods.push(this.paymentMethods[i]);
            }
        }
    }

    private groupShippingMeansByType(shippingMeans) {
        this.shippingTypes = [];
        let shippingType = new ShippingType();
        for (let i = 0; i < shippingMeans.length; i++) {
            if (i === 0 && shippingType.id !== shippingMeans[i].shippingType.id) {
                if (shippingType.id !== undefined && shippingType.id !== shippingMeans[i].shippingType.id) {
                    this.shippingTypes.push(shippingType);
                }
                shippingType = shippingMeans[i].shippingType;
                shippingType.shippingMeanList = [];
            }

            shippingType.shippingMeanList.push(shippingMeans[i]);
        }

        if (shippingType.id !== undefined) {
            this.shippingTypes.push(shippingType);
        }
    }

    private addShippingOffer(shippingOffer) {
        const subs = this.shippingOfferService.addShippingOffer(this.offerId, shippingOffer).subscribe(
            (shippingOfferSaved: any) => {
                this.shippingOffers.push(shippingOfferSaved);
                this.formShippingOpened = false;

                this.translateService.get('offers.order_added').subscribe(value => {
                    this.toastrService.success(value);
                });
            },
            () => {
                this.translateService.get('offers.failed_add_order').subscribe(value => {
                    this.toastrService.error(value);
                });
            }
        );

        this.subscriptions.push(subs);
    }

    private updateShippingOffer(shippingOffer) {
        this.removeCurrentShippingOfferInTheList(shippingOffer);
        const subs = this.shippingOfferService.updateShippingOffer(this.offerId, shippingOffer).subscribe(
            (shippingOfferSaved: any) => {
                this.shippingOffers.push(shippingOfferSaved);
                this.formShippingOpened = false;

                this.translateService.get('offers.shipping_updated').subscribe(value => {
                this.toastrService.success(value);
                });
            },
            () => {
                this.translateService.get('offers.failed_update_shipping').subscribe(value => {
                    this.toastrService.error(value);
                });
            }
        );

        this.subscriptions.push(subs);
    }

    private removeCurrentShippingOfferInTheList(shippingOffer) {
        const index = this.shippingOffers.indexOf(shippingOffer);
        this.shippingOffers.splice(index, 1);
    }


    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
