import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function PhoneMustValid(): ValidatorFn {

    return (control:AbstractControl) : ValidationErrors | null => {

        const phoneNumber = control.value;

        if (!phoneNumber) {
            return;
        }
        const regex = new RegExp(/^\+(?:[0-9]){6,14}[0-9]$/);
        return regex.test(phoneNumber) === true ? null :  {phoneMustValid:true};
    }
}
