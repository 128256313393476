<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            {{ title }}
        </div>
        <div class="card-body">
            <div class="text-center" *ngIf="loading; else addProductForm">
                Loading ...
            </div>
            <ng-template #addProductForm>
                <form [formGroup]="productForm" (ngSubmit)="onSubmit($event)" novalidate>
                    <input type="hidden" formControlName="id">
                    <div class="form-group">
                        <label>Name of Product (En) <span class="require-star">*</span></label>
                        <input type="text" formControlName="name" class="form-control">
                        <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                            Name (En) is required
                        </em>
                    </div>
                    <div class="form-group mt-2">
                        <label>Name of Product (Fr) <span class="require-star">*</span></label>
                        <input type="text" formControlName="productNameFr" class="form-control">
                        <em *ngIf="(f.productNameFr.touched && f.productNameFr.invalid)" class="text-danger">
                            Name (Fr)  is required
                        </em>
                    </div>
                    <div class="form-group  mt-2">
                        <label>Category <span class="require-star">*</span></label>
                        <select formControlName="categoryId" class="form-control">
                            <option></option>
                            <option *ngFor="let p of productCategories" [value]="p.id">{{  p.name }}</option>
                        </select>
                        <em *ngIf="(f.categoryId.touched && f.categoryId.invalid)" class="text-danger">
                            Please a product category
                        </em>
                    </div>
                    <div class="checkbox  mt-2">
                        <label>
                            <input type="checkbox" formControlName="status"/>
                            Active
                        </label>
                    </div>
                    <div *ngIf="selectedFile; then fileSelected else fileNotSelected"></div>
                    <ng-template #fileNotSelected>
                        <div class="form-group">
                            <label>Image of product <span *ngIf="!this.product.id" class="require-star">*</span></label>
                            <br >
                            <input type="file" formControlName="picture" (change)="onSelectedPicture($event)">
                        </div>
                    </ng-template>
                    <ng-template #fileSelected>
                        <div class="form-group" *ngIf="!cropped">
                            <image-cropper format="png, jpeg, jpg" [imageChangedEvent]="imageChangedEvent"
                                           (imageCropped)="imageCropped($event)"
                                           (loadImageFailed)="loadImageFailed()"
                                           (imageLoaded)="imageLoaded()"
                                           [resizeToWidth]="900" style="width: 385px; display: inline-block;"
                                           *ngIf="!cropped"></image-cropper>
                            <div style="display: inline-block; width:31%; padding:10% 4%;">
                                <button class="button" (click)="onCrop()" *ngIf="!cropped"> Crop</button>
                                <span class="ml-2"></span>
                                <button class="button button-cancel" (click)="onCancelImageChangedEvent()"> Cancel</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="form-group" *ngIf="cropped">
                            <div style="max-width: 383px; width: 100%; display: inline-block;">
                                <img [src]="croppedImageBase64" style="width: 250px;"/>
                            </div>
                            <div style="display: inline-block; width:31%; padding:10% 4%;">
                                <button class="button button-cancel button-md" (click)="onCancelImageChangedEvent()"> delete
                                    image
                                </button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </ng-template>
                    <div class="buttons-set mt-3">
                        <button class="btn btn-success me-2" type="submit" id="btn-submit"
                                [disabled]="
                      ( !product.id && ( !productForm.dirty || productForm.invalid || !cropped) )
                      || ( product.id && selectedFile != null && !cropped )
                      || ( selectedFile == null && !productForm.dirty ) "
                        >
                            <span>{{ submitted ? 'Wait ...' : 'submit' }}</span>
                        </button>
                        
                        <a class="btn btn-danger" (click)="backToPrevious()">
                            <span>Back</span>
                        </a>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</div>
