<div class="col-lg-12">
    <div class="card mt-3">
        <div class="card-header">
            {{ title | translate}}
        </div>
        <div class="card-body">
            <form [formGroup]="measurementUnitForm" (ngSubmit)="submitForm()" novalidate>
                <input type="hidden" formControlName="id">
                <div class="form-group">
                    <label>{{'measurement-units.french_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="descriptionFr" class="form-control">
                    <em *ngIf="(f.descriptionFr.touched && f.descriptionFr.invalid)" class="text-danger">
                        {{'common.description_required' | translate}}
                    </em>
                </div>
                <div class="form-group mt-2">
                    <label>{{'measurement-units.english_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="description" class="form-control">
                    <em *ngIf="(f.description.touched && f.description.invalid)" class="text-danger">
                        {{'common.description_required' | translate}}
                    </em>
                </div>
                <div class="form-group mt-2">
                    <label>{{'common.status' | translate}} <span class="require-star">*</span></label>
                    <select type="text" formControlName="status" class="form-control">
                        <option *ngFor="let s of statusses" [value]="s">{{ s | uppercase }}</option>
                    </select>
                    <em *ngIf="(f.status.touched && f.status.invalid)" class="text-danger">
                        {{'common.status_required' | translate }}
                    </em>
                </div>
                <div class="buttons-set mt-2">
                    <button class="btn btn-success btn-sm" type="submit"
                            [disabled]="!measurementUnitForm.dirty || measurementUnitForm.invalid ">
                        <span>{{ (submitted ? 'common.wait' : 'common.submit') | translate }}</span> <span *ngIf="submitted"> ...</span>
                    </button>
                    <span class="ms-3"></span>
                    <a class="btn btn-primary btn-sm" (click)="back_to_previous()">
                        <span>{{'common.back' | translate}}</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>
