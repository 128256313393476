import { Component, OnInit } from '@angular/core';
import {ShippingMeanService} from '../../../_services/shipping-mean.service';
import {Router} from '@angular/router';
import {ShippingMean} from '../../../_models/shipping-mean';
import {User} from '../../../_models/user';
import {IPagination} from '../../../_models/pagination';

@Component({
  selector: 'app-shipping-means-list',
  templateUrl: './shipping-means-list.component.html',
  styleUrls: ['./shipping-means-list.component.css']
})
export class ShippingMeansListComponent implements OnInit {

  shippingMeans: ShippingMean[];
  user: User;
  page = 1;
  title = 'Shipping means';
  loading = false;

  constructor(private shippingMeanService: ShippingMeanService, private router: Router) {
  }

  ngOnInit() {
    this.getAllShippingMeans();
  }

  getAllShippingMeans() {
    this.loading = true;
    this.shippingMeanService.getAll(this.page).subscribe(
        (response: IPagination) => {
          this.shippingMeans = response.data;
        },
        null,
        () => this.loading = false
    );
  }

  addShippingMean() {
    localStorage.setItem('shipping-mean', JSON.stringify(new ShippingMean()));
    this.router.navigate(['admin', 'shipping-means', 'add']);
  }

  editShippingMean(shppingMean: ShippingMean) {
    localStorage.setItem('shipping-mean', JSON.stringify(shppingMean));
    this.router.navigate(['admin', 'shipping-means', shppingMean.id , 'edit']);
  }

  deleteShippingMean(shppingMean: ShippingMean) {
  }
}
