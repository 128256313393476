import {Component, OnDestroy, OnInit} from '@angular/core';
import {OfferService} from '../_services/offer.service';
import {User} from '../_models/user';
import {IPagination} from '../_models/pagination';
import {Router} from '@angular/router';
import {UserService} from '../_services/user.service';
import {ToastrService} from 'ngx-toastr';
import {OfferStatus} from '../commons/offer-status';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit, OnDestroy {

    data = [];
    page = '1';
    user: User;
    config: any;
    private subscriptions: Subscription[] = [];
    loading = true;
    currentLanguage = 'fr';

    constructor(private offerService: OfferService,
                public userService: UserService,
                private toastr: ToastrService,
                private router: Router,
                private translateService: TranslateService) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        this.currentLanguage = translateService.currentLang;
    }

    ngOnInit() {
        this.loadAllOffers();
    }

    public loadAllOffers() {
        this.offerService.getAll(this.user.id, this.page).subscribe(
            (res: IPagination) => {
                this.data = res.data;
                this.config = {
                    currentPage: res.page,
                    itemsPerPage: res.itemPerPage,
                    totalItems: res.totalOfItems,
                    id: 'products'
                };
            },
            (error) => {
                this.loading = false;
             },
            () => {
                this.loading = false;
            }
        );
    }

    pageChange(page) {
        this.page = page;
        this.router.navigate(['/user/' + this.user.id + '/products'], {queryParams: {page: page}});
        this.loadAllOffers();
    }

    public newOffer() {
        this.router.navigate(['user', this.user.id, 'products', 'add']);
    }

    public viewOffer(offer: any) {
        localStorage.setItem('offer', JSON.stringify(offer));
        this.router.navigate(['user', this.user.id, 'products', offer.id]);
    }


    public changeStatus(offer: any, event) {
        const subs = this.offerService.changeStatus(this.user.id, offer.id).subscribe(
            (res: any) => {
                this.toastr.success(res.message);
            },
            null,
            () => {
                if(offer.activated) {
                    this.translateService.get("common.enable").subscribe(value => {
                        event.target.innerText = value;
                    });
                } else {
                    this.translateService.get("common.disable").subscribe(value => {
                        event.target.innerText= value;
                    })
                }

                const actionKey = offer.activated ? 'common.enable' : 'common.disable';
                this.translateService.get(actionKey)
                .subscribe(value => {
                    event.target.innerText = value 
                });
            }
        );

        this.subscriptions.push(subs);
    }

    public deleteOffer(offer: any) {
        const sub = this.translateService.get("offers.deleted").subscribe(message => {
            this.toastr.success(message);
        });

        this.subscriptions.push(sub);

        document.getElementById('offer-' + offer.id).remove();
        const sub1 = this.offerService.deleteOffer(this.user.id, offer.id).subscribe();
        this.subscriptions.push(sub1);
        this.loadAllOffers();
    }

    getStatus(status) {
        return OfferStatus.getStatus(status);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
