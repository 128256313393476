<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="page-title">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <!--col-xs-12-->
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="table-responsive">
        <div *ngIf="loading">
          <p>Loading</p>
        </div>
        <table *ngIf="!loading" class="clean-table linearize-table table table-stripped" id=wishlist-table>
          <thead class="first last">
            <tr>
              <th class="customer-wishlist-item-image"></th>
              <th class="customer-wishlist-item-info">Product name</th>
              <th class="customer-wishlist-item-price">Price</th>
              <th class="customer-wishlist-item-cart"></th>
              <th class="customer-wishlist-item-remove"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let w of wishlist">
              <td class="customer-wishlist-item-info">
                <a class="product-image" [title]="w.offerDTO.product.name"
                  [routerLink]="['/products', (w.offerDTO.product.name.replace(' ', '') | lowercase), w.offerDTO.product.id]">
                  <img [alt]="w.offerDTO.product.name" [src]="getImageSrc(w.offerDTO.product.picture)" style="width: 100px;">
                </a>
              </td>
              <td class="customer-wishlist-item-info">{{ w.offerDTO.product.name }}</td>
              <td class="customer-wishlist-item-price">{{ w.offerDTO.price }}
                {{ w.offerDTO.currency | uppercase }}</td>
              <td class="customer-wishlist-item-cart">
                <div class="cart-cell">
                  <button type="button" title="Add to cart" class="button btn-cart" (click)="addToCart(w.offerDTO)" >
                    <span>
                      <span>Add To cart</span>
                    </span>
                  </button>
                </div>
              </td>
              <td class="customer-wishlist-item-remove last">
                <button class="remove-item" title="remoe item" (click)="removeFromWishlist(w.id)" >
                  <span>
                    <span></span>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-3">
      <app-sidebar [currentUser]="user"></app-sidebar>
    </div>
  </div>
</div>