import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { UserService } from '../../_services/user.service';
import { PageTitleService } from '../../_services/page-title.service';
import { User } from 'src/app/_models/user';
import { Title } from '@angular/platform-browser';
import { CartService } from 'src/app/_services/cart.service';
import { CartItem } from 'src/app/_models/cart-item';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/_constants/constants';

@Component({
    selector: 'app-authentification',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {
    title = 'Login';
    titleTranslateKey = 'rightnav.log_in';
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    user: User = null;

    subscriptions: Subscription[] = [];

    constructor(
        private titleService: PageTitleService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private cartService: CartService,
        private authenticationService: AuthenticationService,
        public userService: UserService,
        private titleSer: Title,
        private translateService: TranslateService
    ) {
        const titleTranslateSubscription = this.translateService
        .get(this.titleTranslateKey).subscribe(value => {
            this.title = value;
            this.titleSer.setTitle(this.title);
        });

        this.subscriptions.push(titleTranslateSubscription);
    }

    ngOnInit() {
        if (this.userService.isLoggedIn()) {
            console.log("user is logged in")
            this.router.navigate(['/']);
        }

        this.titleService.setPageTitle(this.title);

        this.loginForm = this.formBuilder.group({
            phone: ['', Validators.required],
            password: ['', Validators.required]
        });

        window.scrollTo(0, 0);
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (!this.loginForm.valid) {
            return;
        }

        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        /* set disabled the submit button */
        this.loading = true;
        const formData = new FormData();
        formData.append('username', this.loginForm.controls.phone.value);
        formData.append('password', this.loginForm.controls.password.value);
        this.authenticationService.login(formData)
            .mergeMap((response: any) => {
                localStorage.setItem('tokenDetails', JSON.stringify(response));
                const today = new Date();
                localStorage.setItem('tokenExpiredAt', '' + today.getTime() + response.expired_time * 1000);
                return this.userService.load();
            })
            .mergeMap((user: User) => {
                this.user = user;
                localStorage.setItem(AppConstants.CURRENT_USER_KEY, JSON.stringify(user));
                if (this.userService.isAdmin()) {
                    this.router.navigateByUrl('admin/dashboard');
                    return null;
                } else {
                    return this.cartService.loadItems(user.id);
                }
            })
            .subscribe(
                (cartItems: CartItem[]) => {
                    if (cartItems !== null) {
                        // load cart items if exists
                        this.updateCartStore(cartItems);
                    }
                },
                () => this.loading = false,
                () => {
                    if (this.userService.isAdmin()) {
                        this.router.navigateByUrl('admin/dashboard');
                    } else {
                        this.router.navigateByUrl(returnUrl || 'user/dashboard');
                    }
                }
            );
    }

    private updateCartStore(cartItems: CartItem[]) {
        cartItems.forEach(cartItem => {
            this.cartService.addItemToStore(cartItem).subscribe();
        });
    }

}

