<div class="payments">
  <div class="title-buttons">
    <h3>List of payments</h3>
  </div>
  <br />
  <br />
  <div class="table-responsive">
    <!-- <form [formGroup]="searchForm" (ngSubmit)="search()" class="mb-3">
      <div class="row">
        <div class="col-md-7">
          <input type="text" placeholder="Search for payments"
              formControlName="searchTerm" class="form-control ms-2" />
        </div>
        <div class="col-md-2">
          <button class="btn btn-success btn-md" id="btn-submit">Search</button>
        </div>
      </div>
    </form> -->
    <br>
    <table class="table table-striped products-table table-sm" id="payments-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Order No</th>
          <th>Order By</th>
          <th>Payment Method</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading else table_payments">
          <td colspan="7" style="text-align: center">
            Loading ...
          </td>
        </tr>
        <ng-template #table_payments>
          <tr *ngFor="let payment of payments | paginate : config; let i = index ">
            <td> {{ i + 1 }}</td>
            <td> {{ payment.order.orderNumber }}</td>
            <td> {{ payment.user.name }}</td>
            <td> {{ payment.paymentMethod }}</td>
            <td> {{ payment.amount }} {{ payment.currency | uppercase}}</td>
            <td> {{ payment.transactionStatus }}</td>
            <td> {{ payment.updatedAt | date: 'medium'}}</td>
            <td>
              <div *ngIf="payment.transactionStatus === 'Unconfirmed' && (payment.paymentMethod==='CashOnDelivery' || payment.paymentMethod==='CompanyMobileMoney')"
                style="display: flex; flex-direction: column;">
                <button (click)="approve(payment.transactionRef)" class="button button-submit">Approve</button>
                <button (click)="reject(payment.transactionRef)" style="margin-top: .5rem;"
                  class="button button-cancel">Reject</button>

                <p [attr.class]="'hidden'" class="confirm-action" [id]="'payment' + payment.transactionRef">
                  <span>
                    <b>Do you want to {{action | lowercase}} this payment? </b>
                    <a (click)="changeStatus(payment.transactionRef, action)">Yes</a> | <a
                      (click)="undoAction(payment.transactionRef)">No</a>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <div class="toolbar mt-3" *ngIf="!loading">
    <div class="pager">
      <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
        id="payments" (pageChange)="pageChange($event)"></pagination-controls>
    </div>
  </div>