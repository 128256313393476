import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { BlogPost } from 'src/app/_models/blog-post';
import { ProductCategory } from 'src/app/_models/product-category';
import { BlogService } from 'src/app/_services/blog.service';
import { ProductCategoryService } from 'src/app/_services/product-category.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-blog-post-edit',
  templateUrl: './blog-post-edit.component.html',
  styleUrls: ['./blog-post-edit.component.css']
})
export class BlogPostEditComponent implements OnInit {
  post: BlogPost;
  post_id: any;
  loading = true;
  blogPostForm: UntypedFormGroup;
  categories: ProductCategory[] = [];
  submitting = false;
  picture: Blob;

  Editor: ClassicEditor;

  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };



  constructor(private blogService: BlogService, private router: Router, private route: ActivatedRoute,
    private fb: UntypedFormBuilder, private categoryService: ProductCategoryService ){
    this.post_id = this.route.snapshot.paramMap.get('id');
    this.blogPostForm = this.fb.group({
      id: new  UntypedFormControl(''),
      title: new  UntypedFormControl('', Validators.required),
      category_id: new  UntypedFormControl('', Validators.required),
      weight: new  UntypedFormControl('1'),
      body: new  UntypedFormControl('', Validators.required),
      picture: new UntypedFormControl(),
      slug: new UntypedFormControl('', [Validators.required, Validators.pattern("^([a-zA-Z0-9]|-){10,}$")])
    })
  }

  ngOnInit(): void {
    combineLatest([
      this.categoryService.get_category_list(),
      this.blogService.loadBlogPost(this.post_id)
    ]).subscribe(
      ([categories, post]) => {
        this.categories = categories;
        this.post = post;
        this.loading = false;
        this.blogPostForm.patchValue({
          id: this.post.id,
          body: this.post.body,
          title: this.post.title,
          category_id: this.post.categoryId,
          weight: this.post.weight,
          slug: this.post.slug
        });
        this.loading = false;
      }
    )
  }

  get f() {
    return this.blogPostForm.controls;
  }

  onSelectedPicture(event) {
    this.picture = event.target.files[0];
  }

  submit() {
    this.submitting = true;
    this.blogService.adminUpdateBlogPost(this.blogPostForm.value, this.picture).subscribe(
      (response) => {
        this.router.navigate(['/admin', 'blog-posts']);
      },
      (error) => {
        this.submitting = false;
      },
    )
  }

}
