import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JobService } from 'src/app/_services/job.service';

@Component({
  selector: 'app-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.css']
})
export class JobEditComponent implements OnInit{

  loading = true;
  submitting = false;
  jobForm: UntypedFormGroup;
  types = ['Full Time', 'Part time', 'Internship','Consultant','Freelance', 'Volunteer'];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  jobId: any;

  constructor(private jobService: JobService, private toastService: ToastrService,
    private router: Router, private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute){
      this.jobId = this.activatedRoute.snapshot.paramMap.get('id') || 0;
      this.jobForm = this.fb.group({
        'title': new  UntypedFormControl('', Validators.required),
        'description': new  UntypedFormControl('', Validators.required),
        'location': new  UntypedFormControl('', Validators.required),
        'type': new  UntypedFormControl('', Validators.required),
        'status': new  UntypedFormControl(true, Validators.required),
      });
  }

  ngOnInit(): void {
    this.jobService.loadJob(this.jobId).subscribe(
      (job) => {
        this.jobForm.patchValue({
          title: job.title,
          description: job.description,
          type: job.type,
          location: job.location,
          status: job.status,
        });
        this.loading = false;
      },
      (error) => {
        this.toastService.error(error);
        this.router.navigateByUrl('/admin/jobs');
      }
    )
  }

  submit() {
    this.submitting = true;
    this.jobService.updateJob(this.jobId, this.jobForm.value).subscribe(
      (response: any) => {
        this.toastService.success(response.message);
        this.router.navigateByUrl('/admin/jobs');
      },
      (error) => {
        this.toastService.error(error);
        this.submitting = false;
      }, 
    ) 
  }
  


}

