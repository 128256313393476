<div class="container-fluid mb-4 blogs">
  <div class="row mb-2">
    <div class="col-12">
      <div
        class="section_title d-flex justify-content-between align-items-center"
      >
        <h2 class="bbb_viewed_title">
          {{ 'common.latest_blogs' | translate }}
        </h2>
        <div class="d-none d-lg-block">
          <a class="btn btn-primary text-white" [routerLink]="['/blog']">
            {{ 'common.view_all' | translate }}
            <i class="ms-2 fa fa-angle-right"></i>
          </a>
        </div>
        <div class="d-lg-none d-block">
          <a [routerLink]="['/blog']" class="btn btn-link text-primary btn-sm"
            >{{ 'common.more' | translate }}
            <i class="fa fa-angle-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    <div *ngFor="let post of blogPosts" class="col">
      <div class="card">
        <img
          [lazyLoad]="getImageSrc(post.imagePath)"
          class="card-img-top"
          [alt]="post.title"
          [defaultImage]="defaultImage"
        />
        <div class="card-body">
          <div class="tagging mb-2">
            <!-- <span class="text-primary mr-3">
              <i class="fa fa-pen-nib text-dark"></i> Farming</span
            > -->
            <span class="text-primary">
              <i class="far fa-calendar text-dark"></i>
              {{ post.postedAt }}</span
            >
          </div>
          <h5 class="card-title mt-3">{{ post.title }}</h5>
          <div
            class="card-text truncate-overflow mt-2"
            [innerHTML]="post.body | slice : 0 : 300"></div>

          <div>
            <a [routerLink]="['/blog', post.slug, post.id]"
            class="btn btn-link text-primary px-0 py-1 read_more">
            {{ 'common.read_more' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Partners & clients -->

<div class="container-fluid mb-4 blogs">
  <div class="row mb-2">
    <div class="col-12">
      <div
        class="section_title d-flex justify-content-between align-items-center"
      >
        <h2 class="bbb_viewed_title">
          {{ 'common.partners_and_customers' | translate }}
        </h2>
      </div>
    </div>
  </div>
  <div class="row" style="display: flex; align-items: center;">
    <div *ngFor="let partner of partners" class="col-lg-2 col-md-3 col-sm-2 ms-auto me-auto mb-3" style="text-align: center;">
      <img [src]="partner.image_path" [alt]="partner.name" style="max-height: 100px; max-width: 195px;" />
    </div>
  </div>
</div>


<!-- Testimonials -->

<div class="container-fluid mb-4 blogs">
  <div class="row mb-2">
    <div class="col-12">
      <div
        class="section_title d-flex justify-content-between align-items-center"
      >
        <h2 class="bbb_viewed_title">
          {{ 'common.testimonials' | translate }}
        </h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let e of testimonials" class="col-lg-4 col-md-6 col-sm-12 ms-auto me-auto mb-4">
      <div class="card">
        <div class="card-body">
          <a target="_blank" [href]="e.externalUrl" class="overlay">{{e.author}}</a>
          <div>
            <iframe width="100%" height="280" [src]="e.url" title={{e.author}} [class.thumbnail]="thumbnail"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"></iframe>
          </div>
          <h4 class="author"  style="font-size: 0.8rem;">
            {{e.author}}
          </h4>
          <span style="font-size: 0.8rem;" class="role">
            {{ (e.role.length>30) ? (e.role | slice: 0 : 30) + '...' : e.role }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
