import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ProductCondition } from '../_models/product-condition';
import {API_URLS, SECURE_API_URLS} from '../config/api.urls';
import { Observable } from 'rxjs';

@Injectable()
export class ProductsConditionsService {

  constructor(private http: HttpClient) {
  }

  get_product_conditions(): Observable<ProductCondition[]> {
      return this.http.get<ProductCondition[]>(API_URLS.LIST_OF_PRODUCTS_CONDITIONS, { params: {itemPerPage: '-1', page: '1' } });
  }

  add_product_condition(product_condition: ProductCondition) {
      return this.http.post(SECURE_API_URLS.LIST_OF_PRODUCTS_CONDITIONS, product_condition);
  }

  update_product_condition(product_condition: ProductCondition) {
      return this.http.put(SECURE_API_URLS.LIST_OF_PRODUCTS_CONDITIONS + '/' + product_condition.id, product_condition );
  }

  delete_product_condition(product_condition: ProductCondition) {
      return this.http.delete(SECURE_API_URLS.LIST_OF_PRODUCTS_CONDITIONS + '/' + product_condition.id);
  }
}
