import { Component, Input } from '@angular/core';
import { CartService } from '../_services/cart.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../_constants/constants';
import { Offer, getPrice } from '../_models/offer';
import { environment } from 'src/environments/environment';
import { TaxonomyService } from '../_services/taxonomy.service';
import { TaxonomyOffers } from '../_models/taxonomy-offers';

@Component({
  selector: 'app-taxonomy-offers',
  templateUrl: './taxonomy-offers.component.html',
  styleUrls: ['./taxonomy-offers.component.css']
})
export class TaxonomyOffersComponent {
  @Input() taxonomyId: number;
  @Input() title: string;
  taxonomyOffers: TaxonomyOffers;
  offers: Offer[];
  loadingState: boolean;
  totalOfItems = 0;

  defaultImage = '/assets/images/default.jpg';
  imageSrc: string;
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(
    private cartService: CartService,
    private toastService: ToastrService,
    private translateService: TranslateService, private taxonomyService: TaxonomyService) {
      this.loadingState = true;
  }


  addProductToCart(offer: any) {
    this.cartService.addToLocalCart(offer).subscribe(
      () => {
        const addCartMessage = this.translateService.get('common.message_for_added_to_cart')
          .subscribe(message => {
            this.toastService.success(message);
          });
      }
    );
  }

  getImageSrc(offer: Offer) {
    return environment.image_cdn_url + '/' +
      (offer.defaultPicture.length > 0 ? offer.defaultPicture + '?func=crop&width=280&height=280' : offer.product.picture);
  }

  getOfferPrice(offer: Offer): number {
    return getPrice(offer);
  }

  ngOnInit() {
    this.taxonomyService.getTaxonomyOffers(this.taxonomyId)
    .subscribe(
      (response: TaxonomyOffers) => {
        this.taxonomyOffers = response;
        this.offers = response.data;
        this.loadingState = false;
      }
    )
  }

}
