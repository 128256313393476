import { Component, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { Offer, getPrice } from '../_models/offer';
import { AppConstants } from '../_constants/constants';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.css']
})
export class RelatedProductsComponent {
  @Input() relatedProducts: any[];
  @Input() loadingState: boolean;

  defaultImage = '/assets/images/default.jpg';
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  slideConfig = {
    autoplay: false,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    centerMode: false,
    nextArrow: '<button class="btn nextBtn"><i class="fa fa-lg fa-angle-right"></i></button>',
    prevArrow: '<button class="btn prevBtn"><i class="fa fa-lg fa-angle-left"></i></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  getOfferPrice(offer: Offer): number {
    return getPrice(offer);
  }

  getImageSrc(offer: Offer) {
    return environment.image_cdn_url + '/' +
      (offer.defaultPicture.length > 0 ? offer.defaultPicture + '?func=crop&width=280&height=280' : offer.product.picture);
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }

  constructor() { }
  ngOnInit(): void { }
}
