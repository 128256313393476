import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminHeaderComponent } from '../_layouts/admin-header/admin-header.component';
import { AdminFooterComponent } from '../_layouts/admin-footer/admin-footer.component';
import { AdminLayoutComponent } from '../_layouts/admin-layout/admin-layout.component';
import { SidebarComponent } from '../_layouts/sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductCategoryFormComponent } from './product-category-form/product-category-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserFormComponent } from './user-form/user-form.component';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PaymentMethodFormComponent } from './payment-method-form/payment-method-form.component';
import { ShippingMeansListComponent } from './shipping-means/shipping-means-list/shipping-means-list.component';
import { ShippingMeanFormComponent } from './shipping-means/shipping-mean-form/shipping-mean-form.component';
import { OfferComponent } from './offer/offer.component';
import { ViewOfferComponent } from './view-offer/view-offer.component';
import { PagesComponent } from './pages/pages.component';
import { AddPageComponent } from './add-page/add-page.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { TeamListComponent } from './team/team-list/team-list.component';
import { AddMemberComponent } from './team/add-member/add-member.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { RequestedProductComponent } from './requested-product/requested-product.component';
import { PaymentsComponent } from './payments/payments.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { eFarmTranslateLoader } from '../app.module';
import { PricingComponent } from './pricing/pricing.component';
import { PricingFormComponent } from './pricing-form/pricing-form.component';
import { SectorFormComponent } from './sector-form/sector-form.component';
import { SectorsComponent } from './sectors/sectors.component';
import { MeasurementUnitsComponent } from './measurement-unit/measurement-units.component';
import { MeasurementUnitFormComponent } from './measurement-unit-form/measurement-unit-form.component';
import { ProductConditionsComponent } from './product-conditions/product-conditions.component';
import { ProductionConditionFormComponent } from './product-condition-form/product-condition-form.component';
import { CRMComponent } from './crm/crm.component';
import { ComposeMessageComponent } from './compose-message/compose-message.component';
import { ViewMessageComponent } from './view-message/view-message.component';
import { CRMTemplatesComponent } from './crm-templates/crm-templates.component';
import { CrmAddTemplateComponent } from './crm-add-template/crm-add-template.component';
import { EditOfferComponent } from './edit-offer/edit-offer.component';
import { CrmComposeComponent } from './crm-compose/crm-compose.component';
import { GroupsComponent } from './groups/groups.component';
import { AssignOffersComponent } from './groups/assign-offers.component';
import { BlogPostListComponent } from './blog/blog-post-list/blog-post-list.component';
import { BlogPostEditComponent } from './blog/blog-post-edit/blog-post-edit.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';
import { JobsComponent } from './career/jobs/jobs.component';
import { JobComponent } from './career/job/job.component';
import { JobEditComponent } from './career/job-edit/job-edit.component';
@NgModule({
  declarations: [
      UserComponent,
      AdminComponent,
      AdminHeaderComponent,
      AdminFooterComponent,
      AdminLayoutComponent,
      SidebarComponent,
      DashboardComponent,
      ProductCategoryComponent,
      ProductCategoryFormComponent,
      UserFormComponent,
      ProductComponent,
      ProductFormComponent,
      PaymentMethodComponent,
      PaymentMethodFormComponent,
      ShippingMeansListComponent,
      ShippingMeanFormComponent,
      OfferComponent,
      ViewOfferComponent,
      PagesComponent,
      AddPageComponent,
      TeamListComponent,
      AddMemberComponent,
      RequestedProductComponent,
      PaymentsComponent,
      PricingComponent,
      PricingFormComponent,
      SectorFormComponent,
      SectorsComponent,
      MeasurementUnitsComponent,
      MeasurementUnitFormComponent,
      ProductConditionsComponent,
      ProductionConditionFormComponent,
      ComposeMessageComponent,
      ViewMessageComponent,
      CRMComponent,
      CRMTemplatesComponent,
      CrmAddTemplateComponent,
      EditOfferComponent,
      CrmComposeComponent,
      GroupsComponent,
      BlogPostListComponent,
      BlogPostEditComponent,
      OrdersListComponent,
      OrderComponent,
      JobsComponent,
      JobComponent,
      JobEditComponent
  ],
    imports: [
        CommonModule,
        AdminRoutingModule,
        NgbModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        ImageCropperModule,
        AngularEditorModule,
        CKEditorModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (eFarmTranslateLoader),
                deps: [HttpClient],
            }
        }),
        FormsModule
    ]
})
export class AdminModule { }
