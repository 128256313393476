import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {ProductsConditionsService} from '../../_services/products-conditions.service';
import {MeasurementService} from '../../_services/measurement.service';
import {PricingService} from '../../_services/pricing.service';
import {OfferService} from '../../_services/offer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../_services/user.service';
import {DatePipe} from '@angular/common';
import {User} from '../../_models/user';

@Component({
    selector: 'app-offer-edit-form',
    templateUrl: './offer-edit-form.component.html',
    styleUrls: ['./offer-edit-form.component.css'],
    providers: [DatePipe]
})
export class OfferEditFormComponent implements OnInit {

    offer: any;
    form: UntypedFormGroup;
    pricing: any;
    productConditions: any;
    measurementUnits: any;
    userId: string;
    offerId: string;
    loading: boolean;
    selectedPricing: any = '';
    currentDate = '';
    submitted = false;
    user: User;
    editorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        // uploadUrl: 'v1/image',
        // upload: (file: File) => { ... }
        // uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };

    constructor(private fb: UntypedFormBuilder,
                private offerService: OfferService,
                private productConditionService: ProductsConditionsService,
                private measurementService: MeasurementService,
                private state: ActivatedRoute,
                public userService: UserService,
                private datePipe: DatePipe,
                private router: Router,
                private pricingService: PricingService
    ) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.userId = this.state.snapshot.paramMap.get('id');
        this.offerId = this.state.snapshot.paramMap.get('productId');
        this.loading = true;
        const date = new Date();
        this.currentDate = this.convertDate(date.setDate(date.getDate() + 1));
    }

    ngOnInit() {
        combineLatest(
            [
                this.offerService.getOffer(this.userId, this.offerId),
                this.pricingService.get_pricings(),
                this.productConditionService.get_product_conditions(),
                this.measurementService.getAll()
            ]
        ).subscribe(
            ([offer, response, productConditions, measurementUnits]) => {
                this.offer =  offer;
                this.pricing = response.data;
                this.productConditions = productConditions;
                this.measurementUnits = measurementUnits;
                this.selectedPricing = this.offer.pricing;
            },
            () => {},
            () => {
                this.loading = false;
                this.createFormEditForm();
            }
        );
    }

    private createFormEditForm() {
        this.form = this.fb.group({
            currency: [this.offer.currency, Validators.required],
            description: new UntypedFormControl(this.offer.description, Validators.required),
            measurementunit: new UntypedFormControl(this.offer.measurementunit, Validators.required),
            price: new UntypedFormControl(this.offer.price, Validators.required),
            pricing: new UntypedFormControl(this.offer.pricing, Validators.required),
            productCondition: new UntypedFormControl(this.offer.productCondition, Validators.required),
            qty: new UntypedFormControl(this.offer.qty, Validators.required),
            expirationDate: new UntypedFormControl(this.offer.expirationDate ? this.convertDate(this.offer.expirationDate ) : this.currentDate ),
            percentDiscount: new UntypedFormControl(this.offer.percentDiscount),
            returns: new UntypedFormControl(this.offer.returns),
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onChange() {
        this.selectedPricing = this.form.value.pricing;
    }

    compareValues(currentVal, optionVal) {
        return currentVal.id === optionVal.id;
    }

    onSubmit() {
        if (this.form.untouched) {
            this.backToOfferViewPage();
        }
        this.submitted = true;
        this.offerService.updateOffer(this.userId, this.offerId, this.form.value).subscribe(
            () => {
                this.backToOfferViewPage();
            },
            () => { this.submitted = false; }
        );
    }

    private backToOfferViewPage() {
        this.router.navigateByUrl('user/' + this.userId + '/products/' + this.offerId );
    }

    private convertDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
}
