import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from './users/signup/signup.component';
import { AppRoutingModule } from './app-routing.module';
import { SigninComponent } from './users/signin/signin.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentComponent } from './content/content.component';
import { RegisterBusinessComponent } from './users/register-business';

// used to create fake backend
import { HomeComponent } from './home/home.component';


import { CountryComponent } from './country/country.component';
import { CountryService } from './country/country.service';
import { CommonService } from './_services/common.service';


import { ShoppingCartComponent } from './users/shopping-cart/shopping-cart.component';
import { MyAccountComponent } from './users/my-account/my-account.component';
import { ApiService } from './_services/api.service';
import { ProgressBarComponent } from './header/progress-bar/progress-bar.component';
import { ProgressBarService } from './_services/progress-bar.service';
import { ForgetPasswordComponent } from './users/forget-password/forget-password.component';
import { ResetPasswordComponent } from './users/reset-password/reset-password.component';
import { FpRequrestSentComponent } from './users/fp-requrest-sent/fp-requrest-sent.component';
import { ResetPasswordCompleteComponent } from './users/reset-password-complete/reset-password-complete.component';
import { HeaderWithoutMenuComponent } from './header-without-menu/header-without-menu.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ProductCategoryService } from './_services/product-category.service';
import { ShippingAddressComponent } from './users/shipping-address/shipping-address.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserEditFormComponent } from './users/user-edit-form/user-edit-form.component';
import { UserProfilePictureComponent } from './users/user-profile-picture/user-profile-picture.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserService } from './_services/user.service';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { RoleGuard } from './_guards/role.guard';
import { FilterPageGuard } from './_guards/filter-page.guard';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_directives/alert.component';
import { PageTitleComponent } from './_directives/page-title.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AdminModule } from './admin/admin.module';
import { Location } from '@angular/common';
import { PageTitleService } from './_services/page-title.service';
import { SliderComponent } from './slider/slider.component';
import { SectorComponent } from './sector/sector.component';
import { SectorService } from './_services/sector.service';
import { BasicDataService } from './_services/basic-data.service';
import { OfferComponent } from './offer/offer.component';
import { AppErrorHandler } from './commons/app-error-handler';
import { OfferFormComponent } from './offer-form/offer-form.component';
import { PricingService } from './_services/pricing.service';
import { ProductsConditionsService } from './_services/products-conditions.service';
import { MeasurementService } from './_services/measurement.service';
import { ProductsConditionsComponent } from './products-conditions/products-conditions.component';
import { MeasurementComponent } from './measurement/measurement.component';
import { OfferViewComponent } from './offer-view/offer-view.component';
import { OfferEditFormComponent } from './user-offer/offer-edit-form/offer-edit-form.component';
import { OwlModule } from 'ngx-owl-carousel';
import { ProductsListComponent } from './products/products-list/products-list.component';
import { SingleProductComponent } from './products/single-product/single-product.component';
import { ShippingOfferFormComponent } from './shipping-offer-form/shipping-offer-form.component';
import { NgxsModule } from '@ngxs/store';
import { CartState } from './_cart/cart.state';
import { CartComponent } from './cart/cart.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxPaginationModule } from 'ngx-pagination';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SearchFormComponent } from './products/search-form/search-form.component';
import { SideNavCategoriesComponent } from './products/side-nav-categories/side-nav-categories.component';
import { SideNavCartComponent } from './products/side-nav-cart/side-nav-cart.component';
import { UserChangePasswordComponent } from './users/user-change-password/user-change-password.component';
import { FeaturesBoxComponent } from './features-box/features-box.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { PaymentService } from './_services/payment.service';
import { OrderService } from './_services/order.service';
import { WishlistComponent } from './wishlists/wishlist/wishlist.component';
import { WishlistFormComponent } from './wishlists/wishlist-form/wishlist-form.component';
import { WishlistService } from './_services/wishlist.service';
import { BlogComponent } from './blog/blog/blog.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { BlogService } from './_services/blog.service';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { BlogSidebarComponent } from './blog/blog-sidebar/blog-sidebar.component';
import { AddBlogPostComponent } from './blog/add-blog-post/add-blog-post.component';
import { PageService } from './_services/page.service';
import { PageComponent } from './page/page.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ContactComponent } from './contact/contact.component';
import { TeamComponent } from './team/team.component';
import { ContactService } from './_services/contact.service';
import { PaymentReturnComponent } from './payment-return/payment-return.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';
import { HomeSliderItemComponent } from './home/home-slider-item/home-slider-item.component';
import { FarmerAccountComponent } from './users/my-account/farmer-account/farmer-account.component';
import { AdminAccountComponent } from './users/my-account/admin-account/admin-account.component';
import { PlaceCommandComponent } from './users/place-command/place-command.component';
import { SalesComponent } from './sales/sales.component';
import { PageSidebarComponent } from './page-sidebar/page-sidebar.component';
import { ActivationComponent } from './users/activation/activation.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { FlutterwaveModule } from 'flutterwave-angular-v3';
import { SignupConfirmationComponent } from './users/signup-confirmation/signup-confirmation.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TocurrencyPipe } from './_pipes/tocurrency.pipe';
import { CurrencyState } from './_currency/currency.state';
import { TopbarComponent } from './topbar/topbar.component';
import $ from 'jquery';
import { HeaderNewComponent } from './header-new/header-new.component';
import { NewSliderComponent } from './new-slider/new-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CategorySliderComponent } from './category-slider/category-slider.component';
import { BestSellingComponent } from './best-selling/best-selling.component';
import { AdContentComponent } from './ad-content/ad-content.component';
import { FeaturedProductsComponent } from './featured-products/featured-products.component';
import { RecommendedProductsComponent } from './recommended-products/recommended-products.component';
import { LatestBlogsComponent } from './latest-blogs/latest-blogs.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { ServicesComponent } from './services/services.component';
import { RelatedProductsComponent } from './related-products/related-products.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TaxonomyService } from './_services/taxonomy.service';
import { TaxonomyOffersComponent } from './taxonomy-offers/taxonomy-offers.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { CareerComponent } from './career/career.component';

export function migrationState(state) {
    return {
        products: state.products,
        orderId: state.orderId
    };
}

export function eFarmTranslateLoader(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ProgressBarComponent,
        FooterComponent,
        SignupComponent,
        SigninComponent,
        ContentComponent,
        RegisterBusinessComponent,
        HomeComponent,
        ShoppingCartComponent,
        MyAccountComponent,
        CountryComponent,
        AlertComponent,
        PageTitleComponent,
        ForgetPasswordComponent,
        ResetPasswordComponent,
        FpRequrestSentComponent,
        ResetPasswordCompleteComponent,
        HeaderWithoutMenuComponent,
        ShippingAddressComponent,
        SidebarComponent,
        UserEditFormComponent,
        UserProfilePictureComponent,
        SliderComponent,
        SectorComponent,
        OfferComponent,
        OfferFormComponent,
        ProductsConditionsComponent,
        MeasurementComponent,
        OfferViewComponent,
        OfferEditFormComponent,
        ProductsListComponent,
        SingleProductComponent,
        ShippingOfferFormComponent,
        CartComponent,
        SearchFormComponent,
        SideNavCategoriesComponent,
        SideNavCartComponent,
        UserChangePasswordComponent,
        FeaturesBoxComponent,
        NewsletterComponent,
        CheckoutComponent,
        WishlistComponent,
        WishlistFormComponent,
        BlogComponent,
        BlogPostComponent,
        SafeHtmlPipe,
        BlogSidebarComponent,
        AddBlogPostComponent,
        PageComponent,
        ContactComponent,
        TeamComponent,
        PaymentReturnComponent,
        PaymentSuccessComponent,
        PageNotFoundComponent,
        OrdersListComponent,
        OrderComponent,
        HomeSliderItemComponent,
        FarmerAccountComponent,
        AdminAccountComponent,
        PlaceCommandComponent,
        SalesComponent,
        PageSidebarComponent,
        ActivationComponent,
        MobileMenuComponent,
        SignupConfirmationComponent,
        TocurrencyPipe,
        TopbarComponent,
        HeaderNewComponent,
        NewSliderComponent,
        CategorySliderComponent,
        BestSellingComponent,
        AdContentComponent,
        FeaturedProductsComponent,
        RecommendedProductsComponent,
        LatestBlogsComponent,
        DownloadAppComponent,
        ServicesComponent,
        RelatedProductsComponent,
        TaxonomyOffersComponent,
        BackToTopComponent,
        CareerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-left',
            preventDuplicates: true,
        }),
        HttpClientModule,
        MatProgressBarModule,
        MatCardModule,
        BrowserAnimationsModule,
        ImageCropperModule,
        NgxPaginationModule,
        OwlModule,
        AdminModule,
        AngularEditorModule,
        LazyLoadImageModule,
        SlickCarouselModule,
        NgxSkeletonLoaderModule,
        NgbModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (eFarmTranslateLoader),
                deps: [HttpClient],
            }
        }),
        NgxsModule.forRoot([CartState, CurrencyState], { developmentMode: false }),
        NgxsStoragePluginModule.forRoot(
            {
                migrations: [
                    {
                        key: 'cart',
                        version: 1,
                        'migrate': migrationState
                    }
                ]
            }
        ),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger'
        }),
        FlutterwaveModule,
        ServiceWorkerModule.register('ngsw-worker.js',
            { enabled: environment.production })
    ],
    exports: [
        AlertComponent
    ],
    providers: [
        AuthenticationService,
        AlertService,
        UserService,
        AuthGuard,
        RoleGuard,
        FilterPageGuard,
        CountryService,
        CommonService,
        Location,
        ProductCategoryService,
        Title,
        ApiService,
        PageTitleService,
        ProgressBarService,
        SectorService,
        BasicDataService,
        PricingService,
        ProductsConditionsService,
        MeasurementService,
        PaymentService,
        OrderService,
        WishlistService,
        BlogService,
        PageService,
        ContactService,
        TaxonomyService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: ErrorHandler, useClass: AppErrorHandler }
    ],
    bootstrap: [AppComponent, MobileMenuComponent]
})
export class AppModule {
}
// {provide: LocationStrategy, useClass: HashLocationStrategy},





