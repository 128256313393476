import { Component, OnInit } from '@angular/core';
import {UserService} from '../../_services/user.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../_helpers/MustMatch';
import {User} from '../../_models/user';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {

  form: UntypedFormGroup;
  currentUser: User;
  submitted = false;

  constructor (public userService: UserService, private fb: UntypedFormBuilder, private toastr: ToastrService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.form = this.fb.group({
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', Validators.required),
    }, { validator: MustMatch('newPassword', 'confirmPassword') });
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

  get f () {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.form.value);
    this.userService.changeUserPassword(this.currentUser, this.form.value).subscribe(
        (result: any) => {
          this.toastr.success(result.message);
          this.form.reset();
        },
        () => { this.submitted = false; },
        () => {
          this.submitted = false;
        }
    );
  }

}
