<h3 class="page-sidebar-heading-title">Contents</h3>
<ul class="page-sidebar">
  <li>
    <button style="background: transparent; border: medium none;" (click)="goTo('what-is-efarm-cm')" title="Terms of use">
     {{ language == 'en'  ? 'What is eFarm.CM' : 'À propos de eFarm.CM' }}
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('how-efarm-cm-works')" title="Privacy Policy">
      {{ language == 'en'  ? 'How eFarm.CM Works' : 'Comment ca marche' }}
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('academy')" title="eFarm.CM Academy">
      eFarm.CM Academy
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('FAQ')" title="FAQ">
      FAQ
    </button>
  </li>
<!--  <li>-->
<!--    <a href="/#/meet-our-team">Our Team</a>-->
<!--  </li>-->
<li>
  <a routerLink="/meet-our-team" style="color: #191919;">
    {{ "footer.meet_team" | translate }}
  </a>
</li>
<li>
  <a routerLink="/career" style="color: #191919;">
    {{ 'footer.career' | translate }}
  </a>
</li>
</ul>
<br/>
<h3 class="page-sidebar-heading-title">Policies</h3>
<ul class="page-sidebar" >
  <li>
    <button style="background: transparent; border: medium none;" (click)="goTo('term-of-use')" title="Terms of use">
      {{ language == 'en'  ? 'Terms of Use' : 'Conditions d\'utilisation' }}
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('privacy-policy')" title="Privacy Policy">
      {{ language == 'en'  ? 'Privacy Policy' : 'Confidentialité' }}
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('delivery')" title="Delivery">
      {{ language == 'en'  ? 'Delivery' : 'Livraison' }}
    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('return-and-refund-policy')" title="Refund Policy">
      {{ language == 'en'  ? 'Refund Policy' : 'Remboursement' }}

    </button>
  </li>
  <li>
    <button style="background: transparent; border: medium none;"  (click)="goTo('declaimer')" title="Disclaimer">
      {{ language == 'en'  ? 'Disclaimer' : 'Avertissement' }}
    </button>
  </li>
</ul>
<br/>
