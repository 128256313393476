import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { User } from 'src/app/_models/user';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/_services/blog.service';
import { ProductCategoryService } from 'src/app/_services/product-category.service';
import { ProductCategory } from 'src/app/_models/product-category';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-blog-post',
  templateUrl: './add-blog-post.component.html',
  styleUrls: ['./add-blog-post.component.css']
})
export class AddBlogPostComponent implements OnInit {
  title = 'Add new post';
  form: UntypedFormGroup;
  user: User = null;
  categories: ProductCategory[] = [];
  loading = false;
  submitting = false;
  selectedFile = null;
  Editor: ClassicEditor;

  editorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    maxHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };



  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private blogService: BlogService,
     private categoryService: ProductCategoryService) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.Editor = ClassicEditor;
  }

  ngOnInit() {
    this.categoryService.get_product_categories().subscribe(
      (categories: ProductCategory[]) => this.categories = categories,
      null,
      () => this.loading = false
    );
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      userId: new UntypedFormControl(this.user.id),
      name: new UntypedFormControl(this.user.name),
      body: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      categoryId: new UntypedFormControl('', [Validators.required]),
      picture: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSelectedPicture(event) {
    this.selectedFile = event.target.files[0];
  }

  publishPostBlog() {
    this.submitting = true;
    this.blogService.addBlogPost(this.form.value, this.selectedFile).subscribe(
      () => this.form.reset(),
      () => { this.submitting = false; },
      () => this.router.navigateByUrl('/blog')
    );
  }

}
