import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URLS} from '../config/api.urls';
import {Country} from './Country';

@Injectable()
export class CountryService {

    constructor(private http: HttpClient) {}

    loadAll(): Observable<Country[]> {
        return this.http.get<Country[]>(API_URLS.LIST_OF_COUNTRIES);
    }

    load(id: string):  Observable<Country>{
        return this.http.get<Country>(API_URLS.LIST_OF_COUNTRIES + '/' + id);
    }

}
