<div class="recent-orders">
    <div class="title-buttons">
        <strong>List of Offers</strong>
    </div>
    <br/>
    <br/>
    <div class="table-responsive">
        <form [formGroup]="filterForm" class="filter-form form-inline mb-3" (ngSubmit)="filterOffers()">
            <div class="row">
                <div class="col-md-5">
                    <select formControlName="filterCategoryId" class="form-control">
                        <option value="0">--- Filter by Category ---</option>
                        <option *ngFor="let category of categories" [value]="category.id">
                            {{ category.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <input type="number" min="1" placeholder="Filter by price" formControlName="filterPrice"
                    class="form-control"/>
                </div>
                <div class="col-md-2">
                    <button [disabled]="loading" class="btn btn-md btn-success" id="btn-submit">Filter</button>
                </div>
            </div>
        </form>
        <table class="table table-striped products-table" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th>Product
                    <a (click)="sortResult(nameSort, createdAtSort)">
                        <i class="fa {{ nameSort == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down' }}"></i>
                    </a>
                </th>
                <th>Qty</th>
                <th>Price</th>
                <th>Status</th>
                <th>Created at</th>
                <th>
                    Created by
                    <a (click)="sortResult(nameSort, createdAtSort)">
                        <i class="fa {{ createdAtSort == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down' }}"></i>
                    </a>
                </th>
                <th>Phone</th>
                <th>
                    <a href="#">Action</a>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading; else dataLoaded ">
            <tr>
                <td colspan="8" style="text-align: center">Loading ...</td>
            </tr>
            </tbody>
            <ng-template #dataLoaded>
                <tbody>
                <tr *ngFor="let offer of offers | paginate: config">
                    <td>{{ offer.product.name }}</td>
                    <td>{{ offer.qty }}</td>
                    <td>{{ offer.price }} {{ offer.currency | uppercase }}</td>
                    <td>{{ getStatus(offer.status) | translate}}</td>
                    <td> {{ offer.creationDate }}</td>
                    <td>{{ offer.createdBy }}</td>
                    <td>{{ offer.phone }}</td>
                    <td>
                        <a [routerLink]="[offer.id]"> view </a>
                    </td>
                </tr>
                </tbody>

            </ng-template>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
        <div class="pager">
            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
                                 id="pagination" (pageChange)="pageChange($event)">

            </pagination-controls>
        </div>
    </div>
</div>
