import { PaymentMethod } from './PaymentMethod';
import { Product } from './product';
import { User } from './user';
import { Pricing } from './pricing';
import { PricingEnum } from './pricing-enum';

export interface Offer {
    id: number;
    description: string;
    expirationDate: string;
    currency: string;
    expired: number;
    imagePath: string;
    price: number;
    qty: number;
    percentDiscount: number;
    returns: number;
    status: number;
    user: User;
    measurementunit: any;
    mediaList: any[];
    paymentMethods: PaymentMethod[];
    product: Product;
    shippingOffers: any[];
    productCondition: any;
    createdAt: string;
    pricing: Pricing;
    localPickup: number;
    defaultPicture: string;
}


export function isOfferInPromotion(offer: Offer): boolean {
    return offer.pricing.nicename === PricingEnum.PROMOTION;
}

export function getPrice(offer: Offer) {
    if (isOfferInPromotion(offer)) {
        return offer.price - (offer.price * 0.01 * offer.percentDiscount);
    } else {
        return offer.price;
    }
}

export function getDiscountPrice(offer: Offer) {
    if (isOfferInPromotion(offer)) {
        return offer.price;
    } else {
        return 0;
    }
}
