<div class="container-fluid">
  <div class="col-lg-6 col-lg-offset-3 col-md-6 mx-auto mt-4">
    <p *ngIf="loading else std">
      Please wait ...
    </p>
  </div>
  <ng-template #std>
    <div class="std">
      <div class="page-not-found">
        <div>
          <h3>{{message}}</h3>
        </div>
        <div>
          <a href="/#/signin" class="btn-home" *ngIf="validated">Sign In Here</a>
          <p *ngIf="!validated">Redirection ...</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
