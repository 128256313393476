import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import { TaxonomyOffers } from '../_models/taxonomy-offers';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TaxonomyService {

    constructor(private httpClient: HttpClient) { }

    getTaxonomies() {
        return this.httpClient.get(API_URLS.TAXONOMY_URL);
    }

    getTaxonomyOffers(taxonomy_id: number, page?: number, size?: number): Observable<TaxonomyOffers> {
        return this.httpClient.get<TaxonomyOffers>(API_URLS.TAXONOMY_URL + "/" + taxonomy_id + "/offers", { params: { page: page == null ? 1 : page, size: size || 15 } });
    }

}
