import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { User } from '../_models/user';
import { FormBuilder, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentMethodService } from '../_services/payment-method.service';
import { from, merge, Observable, of, Subscription } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import { CartService } from '../_services/cart.service';
import { environment } from '../../environments/environment';
import { ShippingOffer } from '../_models/shipping-offer';
import { PageTitleService } from '../_services/page-title.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { PaymentService } from '../_services/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse } from 'flutterwave-angular-v3';
import { InitTransactionRequestOffer } from '../_models/init-transaction-req-offer';
import { InitTransactionRequest } from '../_models/init-transaction-req';
import { CartStateModel } from '../_cart/cart.state';
import { mergeMap, switchMap } from 'rxjs/operators';
import { PaymentMethods } from '../_constants/payment-methods';
import { AppConstants } from '../_constants/constants';
import { CurrencyConversionService } from '../_services/currency-conversion.service';
import { CartComponent } from '../cart/cart.component';


@Component({
    selector: 'app-checkout',
    templateUrl: './order-overview.component.html',
    styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy {
    title = 'Check Out';
    titleKey = 'cart.checkout';
    user: User;
    form: UntypedFormGroup;
    products: Observable<ProductModel[]>;
    orderId = 0;
    total = 0;
    currency = environment.currency;
    submitting = false;
    isSuccess = false;
    action = '';
    openModal = false;
    processing = false;
    processingId: any;
    loading = true;
    initiating = false;
    usingCompanyMomo = false;
    browserCartId = null;
    closeFlutterWaveModal = false;

    methods = [
        {
            name: 'common.company_money',
            value: 0,
            id: PaymentMethods.CompanyMobileMoney
        },
        {
            name: 'common.mobile_wallet',
            value: 2.5,
            id: PaymentMethods.FlutterWave
        },
        {
            name: 'common.card',
            value: 3.8,
            id: PaymentMethods.FlutterWave
        },
        {
            name: 'common.cash_on_delivery',
            value: 0,
            id: PaymentMethods.CashOnDelivery
        }
    ];

    chosen_method = '';
    current_fee = 0;
    totalPlusCharge = 0;
    paymentMethodForm: UntypedFormGroup;

    cart: CartStateModel;

    subscriptions: Subscription[] = [];
    companyNumber = environment.companyNumber;

    companyPaymentDetailsForm: UntypedFormGroup;
    prcocessCompanyMomo = false;

    transactionId: string;
    paymentData: InlinePaymentOptions = {
        public_key: environment.flutterwaveKey,
        tx_ref: null,
        amount: 0,
        currency: this.currency,
        payment_options: 'mobilemoneyfranco, ussd',
        redirect_url: '',
        customer: null,
        customizations: {
            title: 'efarm.CM',
            description: 'eFarm.CM Checkout',
            logo: 'https://efarm.cm/assets/images/logo.png'
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
        callbackContext: this
    };

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        private cartService: CartService,
        private pageTitleService: PageTitleService,
        private titleService: Title,
        private router: Router,
        private orderService: OrderService,
        private toastr: ToastrService,
        private paymentService: PaymentService,
        private paymentMethodService: PaymentMethodService,
        private translateService: TranslateService,
        private flutterwave: Flutterwave,
        private activeRoute: ActivatedRoute,
        private currencyConverter: CurrencyConversionService
    ) {
        this.paymentMethodForm = new UntypedFormGroup({
            fee: new UntypedFormControl(PaymentMethods.FlutterWave, Validators.required)
        });

        this.paymentMethodForm.get('fee')
            .valueChanges.subscribe(value => {
                this.chosen_method = value;
                this.current_fee = this.getFee();
                this.totalPlusCharge = this.total + this.current_fee;
            });

        this.products = this.cartService.getProducts();
        const subs = this.products.subscribe(values => {
            if (values.length === 0 && !this.isSuccess) {
                this.router.navigateByUrl('shopping-cart');

                this.translateService.get('cart.is_empty_message')
                    .subscribe(response => {
                        this.toastr.warning(response);
                    });
            }
        });
        this.cartService.getOrderId().subscribe(value => {
            if (value !== 0) {
                this.orderId = value;
            }
        });


        this.cartService.getClientCartId().subscribe(value => {
            if (value) {
                this.browserCartId = value;
            }
        });

        this.pageTitleService.setPageTitle(this.title);
        this.titleService.setTitle(this.title);
        this.setTotalAndCurrency();
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.form = this.fb.group({
            paymentMethodId: new UntypedFormControl(null, Validators.required),
            phone: new UntypedFormControl(null, [
                Validators.required,
                Validators.minLength(9)
            ])
        });


        this.companyPaymentDetailsForm = new UntypedFormGroup({
            moMoTxRef: new UntypedFormControl('', [Validators.required]),
            phoneNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(15)])
        });
        window.scrollTo(0, 0);
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this.chosen_method = this.methods[0].name; // only set the currrent payment method once the total has been calculated
        this.paymentMethodForm.get('fee').setValue(this.chosen_method);
    }
    getImageSrc(imagePath) {
        return imagePath !== '' && imagePath !== null && imagePath.includes('media')
            ? environment.image_cdn_url + '/' + imagePath + '?func=crop&width=75&height=75'
            : '/assets/images/default.jpg';
    }

    getShippingOffer(
        shippingOfferId,
        shippingOffers: ShippingOffer[]
    ): ShippingOffer {
        let s: ShippingOffer = null;
        shippingOffers.forEach(e => {
            if (e.id === shippingOfferId) {
                s = e;
            }
        });
        return s;
    }

    getShippingOfferCost(shippingOfferId, shippingOffers, qty: number) {
        const shippingOffer = this.getShippingOffer(shippingOfferId, shippingOffers);
        return this.currencyConverter.convert(shippingOffer.shippingCost * qty, shippingOffer.scur);
    }

    closeModal() {
        this.openModal = false;
        if (this.isSuccess) {
            this.router.navigateByUrl(`user/${this.user.id}/orders`);
        }
    }

    getFee() {
        return this.methods.find(m => m.name === this.chosen_method).value * this.total / 100;
    }

    calculateThePrice(product: ProductModel) {
        if (product.shippingOfferId > 0) {
            const shippingOffer = CartComponent.findSelectedShippingOffer(product.shippingOfferId, product, 300, product.currency);
            return (product.qty * product.price) + (product.qty * shippingOffer.shippingCost);
        } else {
            return product.qty * product.price;
        }
    }

    selectPaymentMethod() {
        this.openModal = true;
    }

    place_your_order() {
        console.log("Processing paying order");
        if (this.isSuccess) {
            return;
        } else if (this.userService.getCurrntUser() === null) {
            console.log("Ananymous user. redirect user to log in page");
            this.translateService.get('common.message_for_signin')
                .subscribe(response => {
                    this.toastr.error(response);
                    this.router.navigate(['/', 'signin'], { relativeTo: this.activeRoute, queryParams: { 'returnUrl': '/checkout' } });
                });
        } else {
            this.user = this.userService.getCurrntUser();
            console.log("current user : " + this.user);
            const cart = this.cartService.getCart()
                .pipe(
                    switchMap((data) => {
                        const paymentMethodObj = this.methods.find(m => m.name === this.chosen_method);
                        this.cart = data;
                        console.log("cart details : " + data);
                        return this.paymentMethodService.getTransactionRef({
                            amount: this.total,
                            clientApp: environment.appName,
                            code: data.cartId,
                            items: data.products.map(m => this.getItem(m)),
                            paymentMethod: paymentMethodObj.id,
                            transactionFee: this.getFee()
                        }, this.user.name);
                    }),
                    switchMap((transaction) => {
                        this.transactionId = transaction.txRef;
                        const paymentMethodObj = this.methods.find(m => m.name === this.chosen_method);

                        if (paymentMethodObj.id === PaymentMethods.CashOnDelivery) { // clear cart and redirect to orders page
                            this.isSuccess = true;
                            this.showOrders();
                        } else if (paymentMethodObj.id === PaymentMethods.FlutterWave) {
                            const customerDetails = { name: this.user.name, email: this.user.email, phone_number: this.user.phone };

                            this.paymentData.tx_ref = transaction.txRef;
                            this.paymentData.customer = customerDetails;
                            this.paymentData.amount = this.totalPlusCharge;
                            this.paymentData.payment_options = this.chosen_method.includes('mobile') ? 'mobilemoneyfranco, ussd' : 'card';
                            // initiate the payment on flutterwave
                            from(this.flutterwave.asyncInlinePay(this.paymentData))
                                .pipe(
                                    switchMap((successResponse: PaymentSuccessResponse) => {
                                        if (typeof (successResponse) === 'string') {
                                            this.closeFlutterWaveModal = true;
                                            return this.paymentMethodService.verify(transaction.txRef, { status: successResponse });
                                        } else {
                                            return this.paymentMethodService.verify(transaction.txRef, successResponse);
                                        }
                                    })
                                )
                                .subscribe((backendVerificationResponse: any) => { // payment verified, so show user their orders
                                    if (backendVerificationResponse.code === 200) {
                                        this.toastr.success(backendVerificationResponse.message);
                                        this.flutterwave.closePaymentModal();
                                        this.showOrders();
                                        localStorage.removeItem(AppConstants.USER_AGENT_TOKEN); // clear the code after a successful payment
                                    } else if (this.closeFlutterWaveModal) {
                                        console.log('Flutter Wave modal manually closed by the user');
                                    } else {
                                        this.toastr.error(backendVerificationResponse.message);
                                    }
                                });

                            this.initiating = false;
                            this.openModal = false;
                        } else if (paymentMethodObj.id === PaymentMethods.CompanyMobileMoney) { // handle company mobile money
                            this.usingCompanyMomo = true;
                            this.openModal = false;
                        } else {
                            console.log("an error occurred during the process of paying order");
                            return null;
                        }
                        
                    })
                ).subscribe(
                    (e) => {
                        console.log(e);
                    },
                    (error) => {
                        if(error = 'Reset your cart. Some products are no longer exist in our system'){
                            // this.cartService.clearCart();
                        }
                    },
                    () => {},
                );
            this.subscriptions.push(cart);
        }
    }

    showOrders() {
        this.isSuccess = true;
        this.cartService.removeAgentCartId();
        this.cartService.clearCart()
            .subscribe(
                () => this.router.navigateByUrl(`/user/${this.user.id}/orders`)
            );
    }

    getExtraProps(currentUsr: User, data: CartStateModel): any {
        const x = {
            'additionalProp1': { 'userId': currentUsr.id },
            'additionalProp2': { 'userCode': currentUsr.code },
            'additionalProp3': { 'clientCode': data.cartId }
        };
        return x;
    }

    getItem(m: ProductModel): InitTransactionRequestOffer {
        const shippingOffer = this.getShippingOffer(m.shippingOfferId, m.shippingOffers);

        const shippingCost = shippingOffer === null ? 0 : shippingOffer.shippingCost;
        const shippingOfferId = shippingOffer === null ? 0 : shippingOffer.id;
        return {
            amount: m.price,
            offerId: m.id,
            orderItemId: m.lineId,
            qty: m.qty,
            shippingOfferId: shippingOfferId,
            shippingPrice: shippingCost
        };
    }

    private setTotalAndCurrency() {
        this.products
            .pipe(
                switchMap(products => from(products)), // stream each product in cart
                mergeMap(product => {
                    const productCostObservable = this.currencyConverter.convert(product.qty * product.price, product.currency);
                    // conver the product's cost to default
                    if (product.shippingOfferId !== 0) {
                        return merge( // if the product has a shipping offer selected, which is different from local pickup, then it's cost
                            // should include shipping costs
                            this.getShippingOfferCost(product.shippingOfferId, product.shippingOffers, product.qty), // convert shipping cost to default
                            // currency
                            productCostObservable,
                        );
                    } else { // if the selected shipping offer is local pickup, then the product's cost is based on
                        // quantity and unit price only
                        return productCostObservable;
                    }
                }, 1)
            )
            .subscribe(amount => { // sum the total cost per product as the responses come
                this.total += amount;
            });
    }

    makePaymentCallback(response: PaymentSuccessResponse): void {
    }

    closedPaymentModal(): void { }

    processCompanyMobileMoney() {
        this.prcocessCompanyMomo = true;

        if (this.companyPaymentDetailsForm.valid) {
            this.usingCompanyMomo = false;

            this.paymentMethodService
                .registerMoMoTransaction(this.companyPaymentDetailsForm.value, this.transactionId, this.user.name)
                .subscribe(response => {
                    if (response.code === 200) {
                        this.toastr.success(response.message);
                        this.showOrders();
                    } else {
                        this.toastr.error(response.message);
                    }
                });

        }
    }

    closeCompanyMobileMoney(): void {
        this.usingCompanyMomo = false;
    }

    ngOnDestroy() {
        clearInterval(this.processingId);
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
