<div class="container-fluid">
    <div class="row">
        <div class="page-title col-md-12">
            <h2> {{'product.product' | translate}} : {{  currentLanguage == 'fr'? offer.product.productNameFr : offer.product.productName }}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10">
            <ng-template #section>
                <div class="recent-orders">
                    <br/>
                    <section id="offer-products-details" class="product-section">
                        <h4>{{'product.details' | translate}} &nbsp;&nbsp;
                            <a [routerLink]="'/user/' + user.id + '/products/' + offerId + '/edit'">
                                <i class="fa fa-edit"></i>
                            </a>
                        </h4>
                        <table class="table table-bordered">
                            <tbody>
                            <tr>
                                <td>
                                    <label>{{'common.quantity' | translate}}</label> : {{ offer.qty}}
                                </td>
                                <td>
                                    <label>{{'product.measurement_unit' | translate}}</label> : {{ currentLanguage == 'fr'? offer.measurementunit.descriptionFr : offer.measurementunit.description}}
                                </td>
                                <td>
                                    <label>{{'product.price' | translate}}</label> : {{ offer.price }} {{ offer.currency | uppercase}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>{{'product.pricing' | translate}}</label> : {{ currentLanguage === 'fr'? offer.pricing.nameFr : offer.pricing.name }}
                                </td>
                                <td colspan="2">
                                    <label>{{'product.condition' | translate}} </label> : {{  currentLanguage === 'fr'? offer.productCondition.descriptionFr : offer.productCondition.description }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <label>{{'product.description' | translate}} </label> :
                                    <br/>
                                    <div [innerHTML]="offer.description ">
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section id="offer-product-images" class="product-section">
                        <div>
                            <h4>{{'product.images' | translate}}</h4>
                            <div class="with-border">
                                <ng-template #imageBlock>
                                    <div class="screenshots">
                                        <div id="pictures">
                                            <div class="product-img" *ngFor="let media of mediaList"
                                                 id="img-{{ media.id }}">
                                                <img [src]="getImageSrc(media.path)" alt=""/>
                                                <span (click)="deleteOfferPicture(media)"><i
                                                        class="fa fa-remove"></i></span>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="status" style="text-align: right" *ngIf="totalOfImages < 6 ">
                                        <button class="btn" (click)="chooseFiles('image-field-1')">{{'common.browse_files' | translate}}
                                        </button>
                                        <input type="file" (change)="onChange($event)" name="picture"
                                               style="display: none;" id="image-field-1">
                                    </div>
                                </ng-template>
                                <p *ngIf="!hasPictures else imageBlock " id="browseBtnBlock">
                                    <span style="font-size: 12px; margin-bottom: 10px;">{{'product.message_add_image' | translate}}</span>
                                    <button class="btn" (click)="chooseFiles('image-field-2')">{{'common.browse_files' | translate}}</button>
                                    <input type="file" (change)="onChange($event)" name="picture"
                                           style="display: none;" id="image-field-2">
                                </p>
                                <image-cropper
                                        [imageChangedEvent]="imageChangedEvent"
                                        (imageCropped)="imageCropped($event)"
                                        [autoCrop]="true"
                                        [resizeToWidth]="320"
                                >
                                </image-cropper>
                            </div>
                        </div>
                    </section>
                    <section id="offer-shipping-offers" class="product-section">
                        <div>
                            <h4>{{'orders_list.shipping_offers' | translate}}</h4>
                            <div class="with-border">
                                <div *ngIf="!formShippingOpened else shippingOfferForm;">
                                    <ol id="shipping-offers-list">
                                        <li *ngFor="let s of shippingOffers">
                                            <p>
                                                <span class="float-li">
                                                    {{ s.shippingMean.shippingType.description }}
                                                        ({{ s.shippingMean.country.name | titlecase }})  :
                                                        {{ s.shippingMean.description }}
                                                        , {{ s.shippingCost }} {{ s.scur | uppercase }}
                                                        , {{ s.eta > 1 ? s.eta + ' days' : '1 day' }}
                                                </span>
                                                <span>
                                                    <a class="btn-edit-2" (click)="editShippingOffer(s)"></a>
                                                </span>
                                                <span>
                                                    <a class="btn-remove" (click)="deleteShippingOffer(s)"></a>
                                                </span>
                                                <span class="clear-fix"></span>
                                            </p>
                                        </li>
                                    </ol>
                                    <ul style="list-style: none; padding-left: 25px;">
                                        <li>
                                            <p>
                                                <button class="btn btn-success" (click)="openShippingOfferForm()">
                                                    {{'orders_list.add_shipping_offer' | translate}}
                                                </button>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <ng-template #shippingOfferForm>
                                    <app-shipping-offer-form [formShippingOpened]="formShippingOpened"
                                                             [shippingTypes]="shippingTypes"
                                                             [offer]="offer"
                                                             [currentShippingOffer]="shippingOffer"
                                                             (shippingOffer)="saveShippingOffer($event)"
                                                             (changeStatus)="closeShippingOfferForm($event)"></app-shipping-offer-form>
                                </ng-template>
                            </div>
                        </div>
                    </section>

                </div>
            </ng-template>
            <p *ngIf="loading else section" style="text-align: center; padding: 10px 0;">
                <label>{{'common.loading' | translate}} ...</label>
            </p>
        </div>
        <div class="col-md-2">
            <app-sidebar [currentUser]="user"></app-sidebar>
        </div>
    </div>
</div>