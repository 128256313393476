<div class="page-heading">
  <div class="page-title">
    <h2>{{ title }}</h2>
  </div>
</div>
<section class="content-wrapper">
  <div class="container">
    <div class="std">
      <div class="confirmation-page">
        <div>
          <h3 style="color: #0c812c; text-align: center;">Payment successfully done.</h3>
          <h4> Our team will contact you soon for the next steps.</h4>
        </div>
      </div>
    </div>
  </div>
</section>
