<section class="h-100 h-custom" style="background-color: #f6f6f6;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12">
          <div class="card card-registration card-registration-2" style="border-radius: 15px;">
            <div class="card-body p-0">
              <div class="row g-0">
                <div class="col-lg-8">
                  <div class="p-5">
                    <div *ngFor="let product of products | async"  class="row mb-4 d-flex justify-content-between align-items-center">
                      <div class="col-md-1 col-lg-1 col-xl-1">
                          <img class="img-fluid rounded-3" [alt]="product.name" [src]="getImageSrc(product.imagePath)"
                                                width="75">
                      </div>
                      <div class="col-md-5 col-lg-5 col-xl-5">
                        <h6 class="text-muted">{{ product.name }}</h6>
                        <h6 class="text-black mb-0">
                            <span class="me-1">{{'common.unit_price' | translate}} </span> : 
                            {{(product.price | tocurrency : product.currency | async).toLocaleString() }}
                                {{ currency | uppercase }} / {{ product.measurementUnit }}
                        </h6>
                        <h6 *ngIf="product.shippingOfferId != 0" class="mt-2">
                            <span>
                                <span class="me-1">{{'common.shipping_fee' | translate}} </span> :  
                                {{
                                    (getShippingOfferCost(product.shippingOfferId, product.shippingOffers, product.qty) | async)
                                }}
                                {{
                                    getShippingOffer(product.shippingOfferId, product.shippingOffers).scur | uppercase
                                }}
                            </span> 
                        </h6>
                      </div>
                      <div class="col-md-2 col-lg-2 col-xl-1 d-flex">
                        {{ product.qty }} 
                      </div>
                      <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                        <h6 class="mb-0">
                            <!-- {{ ((product.price * product.qty) | tocurrency: product.currency | async).toLocaleString() }} {{ currency | uppercase }} -->
                            {{ (calculateThePrice(product) | tocurrency :product.currency | async).toLocaleString() }} {{ currency | uppercase }}
                        </h6>
                      </div>
                    </div>
  
                    <div class="pt-5">
                      <h6 class="mb-0"><a [routerLink]="['/shopping-cart']" class="text-body"><i
                            class="fas fa-long-arrow-alt-left me-2"></i>Back to cart</a></h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 bg-grey">
                  <div class="p-5">
                    <h3 class="fw-bold mb-5 mt-2 pt-1">Summary</h3>
                    <form [formGroup]="paymentMethodForm">
                        <hr class="my-4">
                        <h5 class="text-uppercase mb-3">
                            {{'common.payment_method' | translate}}
                        </h5>
                        <div class="mb-4 pb-2">
                            <select class="form-select" formControlName="fee">
                                <option *ngFor="let m of methods" [value]="m.name">
                                    {{m.name | translate}} ({{'common.fee' | translate }}: {{m.value }}%)
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-between mb-1">
                            <h5>
                                {{'common.transaction_fee' | translate}}
                            </h5>
                            <h5>{{current_fee.toLocaleString()}} {{currency}}</h5>
                        </div>
                        <div class="d-flex justify-content-between mb-1">
                            <h5>
                                {{'common.order_amount' | translate}}:
                            </h5>
                            <h5>{{ total.toLocaleString() }} {{ currency | uppercase}}</h5>
                        </div>

                        <div class="d-flex justify-content-between mb-2">
                            <h5>
                                {{'common.total_amount' | translate}}:
                            </h5>
                            <h5> 
                                {{totalPlusCharge.toLocaleString()}} {{currency}}
                            </h5>
                         </div>

                        <button type="submit" [disabled]="paymentMethodForm.invalid"
                            class="btn btn-danger btn-md" 
                            style=" cursor: pointer;  background-color: #1a8b44;border-color: #1a8b44;color: #fff;" 
                            (click)="place_your_order()" >
                            {{ (chosen_method === 'common.cash_on_delivery' ? 'common.confirm' : 'common.confirm') | translate}}
                        </button>  
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<div *ngIf="total > 0" class="modal fadei" id="myModal" [ngClass]="usingCompanyMomo ? 'show' : ''">
    <div class="modal-dialog modal-dialog-centered  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title" style="font-weight: bold;">{{"payment.company_mobile_money_payment" |
                    translate}}</h5>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <br>
                <div class="payment-message">
                    <div class="message" style="font-size: 16px;">
                        {{'common.to_continue' | translate}},
                        <ol>
                            <li>{{'payment.please_transfer' | translate}}
                                <span class="text-bold">{{currency}} {{totalPlusCharge.toLocaleString()}} </span> <br>
                                <ul>
                                    <li>
                                       Via  MTN Mobile Money :  <br> {{'payment.to_number' | translate }}
                                        <span class="text-bold">{{companyNumber}}</span> ({{'common.name' | translate | lowercase}} = <span class="text-bold">EFARM CM</span> ) 
                                        <br>
                                        <span>{{ 'payment.dial' | translate }} <b>*126#</b></span>
                                    </li>
                                    <li style="color: red; font-weight: bold;">---------------- {{'payment.or' | translate}} ----------------</li>
                                    <li>
                                        Via Orange Money :  <br> {{ 'payment.dial' | translate }}
                                        <b style="font-weight: bold; font-size: 18px;">#150*47*764120*{{'payment.amount_to_pay' | translate}}#</b>  ({{'common.name' | translate | lowercase}} = <span class="text-bold">EFARM CM</span> ) 
                                    </li>
                                </ul>
                                <br/>
                            </li>
                            <li>{{'payment.fill_transaction_id' | translate}}</li>
                        </ol>
                    </div>
                </div>

                <form (ngSubmit)="processCompanyMobileMoney()" [formGroup]="companyPaymentDetailsForm">
                    <div style="padding: 0 4vw;">
                        <div class="formGroup input-box">
                            <label for="accountNumber" style="display: block;">{{'payment.account_number' | translate}}</label>
                            <input formControlName="phoneNumber" type="text" class="input-text">
                            <div *ngIf="prcocessCompanyMomo && companyPaymentDetailsForm.controls.accountNumber.errors" class="invalid-feedback">
                                {{'payment.invalid_account_number' | translate}}
                            </div>
                        </div>
    
                        <div class="formGroup input-box">
                            <label for="transactionId" style="display: block;">{{'payment.transaction_id' | translate}}</label>
                            <input formControlName="moMoTxRef" type="text" class="input-text">
                            <div *ngIf="prcocessCompanyMomo && companyPaymentDetailsForm.controls.transactionId.errors" class="invalid-feedback">
                                {{'payment.invalid_transaction_id' | translate}}
                            </div>
                        </div>
    
                        <!-- Modal footer -->
                        <br>
                        <div class="modal-footer" style="text-align:center">
                            <button type="button" (click)="closeCompanyMobileMoney()" class="btn btn-danger me-2" id="btn-cancel">
                                {{'common.close' | translate}}</button>
                            <button type="submit" class="btn btn-md btn-success" id="btn-submit">
                                {{'common.submit' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
