<div class="col-lg-12">
  <div class="card">
    <div class="card-header">
        {{ title }}
    </div>
    <div class="card-body">
      <div class="col-md-12" *ngIf="loading; else shippingForm">
        <p class="text-center">
          {{ "common.waiting" | transalate }}
        </p>
      </div>
      <ng-template #shippingForm>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
          <input type="hidden" formControlName="id">
          <div class="form-group mt-3">
            <label>Description: <span class="require-star">*</span></label>
            <input type="text" formControlName="description" class="form-control">
            <em *ngIf="(f.description.touched && f.description.invalid)" class="text-danger">
              Please enter a description
            </em>
          </div>
          <div class="form-group mt-3">
            <label>Shipping Type <span class="require-star">*</span></label>
            <select formControlName="shippingTypeId" class="form-control">
              <option></option>
              <option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">{{  shippingType.description }}</option>
            </select>
            <em *ngIf="(f.shippingTypeId.touched && f.shippingTypeId.invalid)" class="text-danger">
              Please select a type
            </em>
          </div>
          <div class="form-group  mt-3">
            <label>Country <span class="require-star">*</span></label>
            <select formControlName="countryId" class="form-control">
              <option></option>
              <option *ngFor="let country of countries" [value]="country.id">{{  country.name }}</option>
            </select>
            <em *ngIf="(f.countryId.touched && f.countryId.invalid)" class="text-danger">
              Please select a country
            </em>
          </div>
          <div class="checkbox  mt-3">
            <label>
              <input type="checkbox" formControlName="status"  />
              Active
            </label>
          </div>
          <div class="buttons-set mt-3">
            <button class="button button-submit" class="btn btn-success" id="btn-submit" type="submit" [disabled]="submitted || form.invalid">
              <span>{{ submitted ? 'Wait ...' : 'submit' }}</span>
            </button>
            <span class="ml-2"></span>
            <a class="button button-back" (click)="backToPrevious()">
              <span>Back</span>
            </a>
          </div>
          <div class="col-md-12 mt-3">
            {{ form.value | json }}
          </div>
        </form>
      </ng-template>
    </div>
  </div>
</div>
