<div class="recent-orders">
    <div class="title-buttons">
      <strong>Jobs</strong>
    </div>
    <br />
    <br />
    <div class="table-responsive">
      <table class="table table-striped table-users" id="my-orders-table">
        <thead>
          <tr class="first last">
            <th>Title</th>
            <th>Type</th>
            <th>Location</th>
            <th>Active</th>
            <th>Created at </th>
            <th>
              <button (click)="addJob()" class="button button-submit button-md"> Add Job </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading else tbody">
            <td colspan="6" style="text-align: center;">Loading ...</td>
          </tr>
          <ng-template #tbody>
            <tr *ngFor="let job of jobs">
              <td>
                {{ job.title }}
              </td>
              <td>
                {{ job.type }}
              </td>
              <td>
                {{ job.location }}
              </td>
              <td>
                {{ job.status ? 'Yes' : 'No' }}
              </td>
              <td>
                {{ job.created_at | date: 'dd/MM/yyyy' }}
              </td>
              <td>
                <a [routerLink]="['/admin', 'jobs', job.id, 'edit']">Edit</a>
                &nbsp;
                <button type="submit" class="btn-delete" (click)="deleteJob(job.id)">Delete</button>
              </td>
            </tr>
          </ng-template>
          
        </tbody>
      </table>
    </div>
  </div>