<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="page-title text-center">
        <h5>{{ title }}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 main-blog mb-5">
        <div id="main" class="blog-wrapper">
          <div id="primary" class="site-content">
            <div id="content" role="main">
              <p *ngIf="loading else AddBlogPost" style="text-align: center; padding: 10px 0;">
                <label>{{'common.loading' | translate}} ...</label>
              </p>
              <ng-template #AddBlogPost>
                <form class="form-blog-post comment-form" [formGroup]="form" novalidate (ngSubmit)="publishPostBlog()">
                  <div class="row">
                    <div class="col-md-12">
                      <label>{{'blog.title' | translate}}</label>
                      <input type="text" formControlName="title" class="form-control" />
                    </div>
                    <div class="col-md-12 mt-4">
                      <label>{{'common.category' | translate}}</label>
                      <select formControlName="categoryId" class="form-control">
                        <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label>{{'blog.body' | translate}}</label>
                      <!-- <textarea type="text" formControlName="body" class="form-control" cols="5" rows="5"></textarea> -->
                      <angular-editor formControlName="body" [config]="editorConfig"></angular-editor>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label>{{'blog.feature_image' | translate}}</label><br/>
                      <input type="file" formControlName="picture" (change)="onSelectedPicture($event)" />
                    </div>
                    <div class="col-md-12 mt-4">
                      <input type="hidden" formControlName="userId" />
                      <input type="hidden" formControlName="name" />
                      <button [disabled]="form.invalid || submitting"
                        style="width: 30%; display: inline-block;" type="submit"
                        class="btn btn-success btn-md">{{'blog.publish' | translate}}
                      </button>
                      &nbsp;
                      <span *ngIf="submitting">{{'common.submiting' | translate}} ...</span>
                    </div>
                  </div>
                </form>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>