<div class="container-fluid">
  <div class="row">
    <div class="col-md-9">
      <div class="my-account">
        <div class="dashboard">
          <div class="box-account">
            <div class="page-title">
              <h5>{{'sidebar.change_pfp' | translate}}</h5>
            </div>
          </div>
        </div>
        <br>
        <div class="col2-set">
          <p style="height: 30px; text-align: center" *ngIf="loading; else formProfilePicture">
            {{'common.loading' | translate}} ...
          </p>
          <ng-template #formProfilePicture>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h2> </h2>
              </div>
              <div class="panel-body">
                <form novalidate>
                  <div *ngIf="!selectedFile">
                    <a (click)="changePicture()" id="edit-picture-link">
                      <span>{{'user_profile.click_to_change_pfp' | translate}} </span>
                      <img [src]="profileImage" class="img-responsive"  style="max-width: 120px;"/>
                    </a>
                    <input type="file" id="file-input" (change)="onSelectedPicture($event)">
                  </div>
                  <div *ngIf="selectedFile">
                    <div class="col-lg-6" *ngIf="!cropped">
                      <image-cropper format="png, jpeg, jpg" [imageChangedEvent]="imageChangedEvent"
                                     (imageCropped)="imageCropped($event)"
                                     (loadImageFailed)="loadImageFailed()"
                                     (imageLoaded)="imageLoaded()"
                                     [resizeToWidth]="120" style="width: 260px;" *ngIf="!cropped"></image-cropper>
                    </div>
                    <div class="{{ cropped ? 'col-lg-12' : 'col-lg-6' }}">
                      <img [src]="croppedImageBase64" style="width: 120px;" *ngIf="cropped" />
                      <button class="button" (click)="onCrop()" *ngIf="!cropped"> {{'user_profile.crop' | translate}} </button>
                      <span class="ml-2"></span>
                      <button class="button button-submit" (click)="uploadPicture()" *ngIf="cropped" [disabled]="submitting"> {{ (submitting ? 'user_profile.uploading' : 'user_profile.upload') | translate }} <span *ngIf="submitting"> ...</span> </button>
                      <span class="ml-2"></span>
                      <button class="button button-cancel" [disabled]="submitting" (click)="onCancelImageChangedEvent()"> {{'common.cancel' | translate}} </button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <app-sidebar [currentUser]="currentUser"></app-sidebar>
    </div>
  </div>
</div>

