import {Action, State, StateContext} from '@ngxs/store';
import {ProductModel} from './product-model';
import {CartAction} from './cart.actions';
import Remove = CartAction.Remove;
import IncrementQty = CartAction.IncrementQty;
import DecrementQty = CartAction.DecrementQty;
import ClearTheCart = CartAction.ClearTheCart;
import UpdateShippingOffer = CartAction.UpdateShippingOffer;
import UpdateOrderId = CartAction.UpdateOrderId;
import SetCartId = CartAction.SetId;

export interface CartStateModel {
    products: ProductModel[];
    orderId: number;
    cartId: string;
}

@State<CartStateModel>({
    name: 'cart',
    defaults: {
        products: [],
        orderId: 1,
        cartId: null
    }
})
export class CartState {

    private static updateProduct(products: ProductModel[], action: any) {
        const index = products.findIndex(p => p.id === action.id );

        if (index < 0) {
            // if product does not exists in the store and the action is instance of IncrementQty, the we add it in the store
            if (action instanceof IncrementQty) {
                products.push(action.payload);
            }
            return products;
        }

        const product = products[index];
        // if the product Qty is equal to 1, we remove it in the store
        if ((action instanceof DecrementQty) && product.qty < 2) {
            return products.filter(p => p.id !== action.id);
        }


        // here we update an existing item based on the action type
        products[index] = {
            lineId: product.lineId,
            id: product.id,
            name: product.name,
            qty: (action instanceof UpdateShippingOffer) ?
            product.qty : (action instanceof IncrementQty) ? (product.qty + 1) : product.qty - 1 ,
            price: product.price,
            measurementUnit: product.measurementUnit,
            slug: product.slug,
            currency: product.currency,
            imagePath: product.imagePath,
            shippingOffers: product.shippingOffers,
            shippingOfferId: (action instanceof UpdateShippingOffer) ? action.shippingOfferId : product.shippingOfferId
        };
        return products;
    }

    @Action(Remove)
    removeProduct(ctx: StateContext<CartStateModel>, action: Remove) {
        const state =  ctx.getState();
        ctx.patchState({
            products: [...state.products.filter(product => product.id !== action.id)],
            orderId: state.orderId
        });
    }

    @Action(IncrementQty)
    incrementQty(ctx: StateContext<CartStateModel>, action: IncrementQty) {
        const products = [...ctx.getState().products];
        ctx.patchState({
            products: CartState.updateProduct(products, action),
            orderId: ctx.getState().orderId
        });
    }

    @Action(DecrementQty)
    decrementQty(ctx: StateContext<CartStateModel>, action: IncrementQty) {

        const products = [...ctx.getState().products];
        ctx.patchState({
            products: CartState.updateProduct(products, action),
            orderId: ctx.getState().orderId
        });
    }

    @Action(ClearTheCart)
    clearAll(ctx: StateContext<CartStateModel>) {
        ctx.patchState({
            products: [],
            orderId: 0
        });
    }

    @Action(UpdateShippingOffer)
    updateShippingOffer(ctx: StateContext<CartStateModel>, action: UpdateShippingOffer) {
        const products = [...ctx.getState().products];
        ctx.patchState({
            products: CartState.updateProduct(products, action),
            orderId: ctx.getState().orderId
        });
    }

    @Action(UpdateOrderId)
    updateOrderId(ctx: StateContext<CartStateModel>, action: UpdateOrderId) {
        ctx.patchState({
            products: ctx.getState().products,
            orderId: action.orderId
        });
    }

    @Action(SetCartId)
    retrieveCcart(ctx: StateContext<CartStateModel>, action: SetCartId) {
        ctx.patchState({
            cartId: action.id
        })
    }

}







