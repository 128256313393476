
import {CurrencyActions} from './currency.actions';
import {Action, State, StateContext} from '@ngxs/store';
import Clear = CurrencyActions.Clear;
import Add = CurrencyActions.AddRate;
import GetRate = CurrencyActions.GetRate;
import { CurrencyRate } from './currency-model';

export interface CurrencyRatesModel {
    rates: CurrencyRate[];
}

@State<CurrencyRatesModel>({
    name: 'currencyRates',
    defaults: {
        rates: []
    }
})
export class CurrencyState {
    @Action(Clear)
    clear(ctx: StateContext<CurrencyRatesModel>) {
        ctx.patchState({
            rates: []
        });
    }

    @Action(Add)
    add(ctx: StateContext<CurrencyRatesModel>, action: any) {
        const rates = ctx.getState().rates;
        const element = this.getRate(rates, action.rate.from, action.to);
        if(element) {//replace the rate already existing
            rates[rates.indexOf(element)] = action.rate;
        } else { //add new rate if it does not exist already
            rates.push(action.rate)
        }        

        ctx.patchState({
            rates: rates
        });
    }

    @Action(GetRate)
    retrieveRate(ctx: StateContext<CurrencyRatesModel>, from: string, to: string) {
        const rates = ctx.getState().rates;
        return this.getRate(rates, from, to);
    }

    private getRate(rates: CurrencyRate[], from: string, to:string) : CurrencyRate {  
        return rates.find(r => r.from === from && r.to=== to);
    }
}







