import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConstants } from '../_constants/constants';
import { PageService } from '../_services/page.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, OnDestroy {
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  pageSubscription: Subscription;

  constructor(private router: Router, private pageService: PageService) { }

  ngOnInit() {
    this.pageSubscription = this.pageService.pageSubject.subscribe();
  }

  goTo(slug: string) {
    if (window.location.href.trim().search('pages') <= 0) {
      this.router.navigateByUrl(`pages/${slug}`);
    } else {
      this.pageService.emitLoadingSubject(true);
      this.pageService.getPage(slug);
    }
  }

  ngOnDestroy(): void {
    this.pageSubscription.unsubscribe();
  }
}
