import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URLS, SECURE_API_URLS} from '../config/api.urls';

import { AuthenticationService } from './authentication.service';
import { Router} from '@angular/router';
import { ResetPassword } from '../_models/reset-password';
import { Observable } from 'rxjs';
import { ShippingAddress } from '../_models/shipping-address';
import {User} from '../_models/user';
import { AppConstants } from '../_constants/constants';
import {IPagination} from '../_models/pagination';

@Injectable()
export class UserService {

    constructor(private http: HttpClient, private authService: AuthenticationService, private router: Router) {}

    register(user: User) {
        return this.http.post(API_URLS.REGISTER, user);
    }

    load(): Observable<User> {
        return this.http.get<User>(API_URLS.LOAD_CURRENT_USER_DETAILS);
    }

    loadByAdmin(userId) {
        return this.http.get(API_URLS.LIST_OF_USERS + userId + '/load');
    }

    loadProfilePicture(userId): Observable<any> {
        return this.http.get(API_URLS.LOAD_CURRENT_USER_DETAILS +  '/' + userId + '/load-picture');
    }

    reset_password( email_or_phone: string ) {
        return this.http.post(API_URLS.FORGET_PASSWORD, {emailOrPhone: email_or_phone});
    }

    isAdmin() {
        const  currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return (currentUser && currentUser.role && currentUser.role.toLowerCase() === 'admin');
    }

    isLoggedIn () {
        const  currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return (currentUser && currentUser.role && (currentUser.role.toLowerCase() === 'admin' || currentUser.role.toLowerCase() === 'user'
            || currentUser.role.toLowerCase() === 'business'));
    }

    verify_sign_up_token(token: string) {
        return this.http.get(API_URLS.VERIFY_SIGN_UP_TOKEN + '/' + token);
    }

    verify_password_reset_token(token: string) {
        return this.http.post(API_URLS.PASSWORD_VERIFY_TOKEN, { token: token });
    }

    resetPassword(rs: ResetPassword) {
        return this.http.post(API_URLS.UPDATE_PASSWORD, rs);
    }

    logout () {
        this.authService.logout();
        this.router.navigateByUrl('/signin');
    }

    loadUsers(page) {
        return this.http.get(API_URLS.LIST_OF_USERS, { params: { page: page }});
    }

    searchUsers(values: any, page) {
        return this.http.get(API_URLS.LIST_OF_USERS + 'search', { params:
                {
                    searchTerm: (values.searchTerm == null ? '' : values.searchTerm),
                    role: (values.role == null ? '' : values.role),
                    page: page
                }
        });
    }

    loadShippingAddresses(user_id): Observable<ShippingAddress[]> {
        return this.http.get<ShippingAddress[]>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user_id + '/shipping-addresses');
    }

    saveShippingAddress(user_id , shippingAddress: ShippingAddress) {
        if ( shippingAddress.id > 0 ) {
            return this.http.put(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user_id + '/shipping-addresses', shippingAddress);
        } else {
            return this.http.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user_id + '/shipping-addresses', shippingAddress);
        }
    }

    deleteShippingAddress(shippingAddress: ShippingAddress) {
        return this.http.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/shipping-addresses/' + shippingAddress.id);
    }

    addUser(user: User) {
        return this.http.post(API_URLS.LIST_OF_USERS + 'add/', user);
    }

    updateUser(user: User) {
        return this.http.put(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user.id , user);
    }

    changeUserPassword(user: User, formValues: any) {
        const data = new FormData();
        data.append('oldPassword', formValues.oldPassword);
        data.append('newPassword', formValues.newPassword);
        return this.http.put(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user.id + '/update-password', data);
    }

    changeStatus(user, status) {
        return this.http.get(API_URLS.LIST_OF_USERS + user.id + '/change_status',  { params: {status: status}});
    }

    uploadPicture(user: User , file) {
        const  fb = new FormData();
        fb.append('picture', file);
        return this.http.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + user.id + '/upload', fb );
    }

    deleteUser(user) {
        return this.http.delete(API_URLS.LIST_OF_USERS  + user.id );
    }

    getCurrntUser(): User {
        return JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER_KEY));
    }

    searchEFarms(values: any, page: any): Observable<IPagination> {
        return this.http.get<IPagination>(SECURE_API_URLS.USERS_SEARCH_BASE_URL, { params:
                {
                    searchTerm: values.searchTerm,
                    sectorId: values.sectorId,
                    countryId: values.country,
                    page: page
                }
        });
    }
}
