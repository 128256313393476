<div class="container-fluid">
  <div class="row">
    <div class="page-heading">
      <div class="page-title">
        <h2>{{ titleKey | translate }}</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9 col-xs-12 wow bounceInUp animated animated" style="visibility: visible;">
      <p *ngIf="loading else data_loaded"
        style="height: 250px; text-align: center; display: flex; justify-content: center; justify-items: center;">
        {{'common.loading' | translate}} ...
      </p>
      <ng-template #data_loaded>
        <div class="col-md-12">
          <table style="width: 100%;" class="table table-condensed">
            <tbody>
              <tr>
                <td style="text-align: left; font-size: 16px; text-transform: uppercase; ">{{'orders_list.ref' | translate}} : {{ order.orderNumber }}</td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 16px; text-transform: uppercase;">
                  {{'common.payment_method' | translate}} : {{ order.payment ? get_payment_method_label(order.payment.paymentMethod) : ''  }}
                </td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 16px;  text-transform: uppercase;">
                  {{'common.status' | translate}} : {{ order.status | translate }}
                </td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 16px; text-transform: uppercase;">{{'orders_list.ordered_at' | translate}} : {{ order.createdAt}}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th>{{'common.products_name' | translate}}</th>
                <th>{{'common.unit_price' | translate}}</th>
                <th style="text-align: center;">{{ 'common.qty' | translate }}</th>
                <th>Discount</th>
                <th>Final Price</th>
                <th>{{'orders_list.shipping_offer' | translate}}</th>
                <th style="text-align: right;">{{'common.total' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lineOffer of lineOffers;">
                <td>{{ lineOffer.offerDTO.product.name }}</td>
                <td>
                  {{ lineOffer.offerDTO.price }} {{ lineOffer.offerDTO.currency | uppercase }}
                </td>
                <td style="text-align: center;">{{ lineOffer.qty }}</td>
                <td>
                  {{ lineOffer.offerDTO.percentDiscount > 0 ? lineOffer.offerDTO.percentDiscount + '%' : '' }}
                </td>
                <td>
                  {{ getOfferPrice(lineOffer.offerDTO) }} {{ lineOffer.offerDTO.currency | uppercase }}
                </td>
                <td>
                  {{ (lineOffer.shippingOffer != null ? lineOffer.shippingOffer.shippingCost : 0 )}}  {{ lineOffer.offerDTO.currency | uppercase }}
                </td>
                <td  style="text-align: right;">
                  {{ calculateTotal(lineOffer) }} {{ lineOffer.offerDTO.currency | uppercase }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr style="font-size: 20px">
                <td style="text-transform: uppercase; text-align: left; font-weight: bold;" colspan="6">
                  {{'common.total' | translate}}
                </td>
                <td style="color: #020202; font-weight: bold; text-align: right; ">
                  {{ totalOrder }} XAF
                </td>
              </tr>
              <tr style="font-size: 16px">
                <td style="text-align: left;" colspan="6">
                  {{'common.order_commission' | translate}}
                </td>
                <td style="color: #009e18; font-weight: bold; text-align: right; ">
                  {{ totalOrder * (0.05) | number: '1.0-0'}} XAF
                </td>
              </tr>
              <tr style="font-size: 16px">
                <td style="text-align: left;" colspan="6">
                  {{'common.order_client_amount' | translate}}
                </td>
                <td style="color: #ed6663; font-weight: bold; text-align: right; ">
                  {{ totalOrder * (0.95) | number: '1.0-0'}} XAF
                </td>
              </tr>
            </tfoot>
          </table>
          <br />
          <!-- <p *ngIf="order.status == 'PENDING'">
            <a [routerLink]="['/checkout']" class="button button-submit">{{'common.checkout' | translate}}</a>
            &nbsp;
            <button class="button button-cancel">{{'common.delete' | translate}}</button>
          </p> -->
        </div>
      </ng-template>
    </div>
    <div class="col-md-3">
      <app-sidebar [currentUser]="user"></app-sidebar>
    </div>
  </div>
</div>

