import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OfferService } from '../_services/offer.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CartService } from '../_services/cart.service';
import { Observable } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import { User } from '../_models/user';
import { Offer, getPrice } from '../_models/offer';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '../_services/store.service';
import { AppConstants } from '../_constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { TaxonomyService } from '../_services/taxonomy.service';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    offers: any[];
    bestSellingOffers: any[];
    featuredOffers: any[];
    recommendedOffers: any[];
    products: Observable<ProductModel[]>;
    user: User = null;
    loading = true;
    bestSellingLoading = true;
    featuredLoading = true;
    recommendedLoading = true;

    IsProductInCart = false;
    defaultImage = '/assets/images/default.jpg';
    imageSrc: string;
    currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

    constructor(private titleService: Title,
        private offerService: OfferService,
        private router: Router,
        private storeService: StoreService,
        private toastService: ToastrService,
        private cartService: CartService,
        private translateService: TranslateService,
        private taxonomyService: TaxonomyService) {
        this.titleService.setTitle('Home - ' + environment.appTitle);
        this.user = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.products = this.cartService.getProducts();
        this.getLatestOffers();
        this.getBestSellingOffers();
        this.getFeaturedOffers();
        this.getRecommendedOffers();
    }

    getLatestOffers() {
        this.offerService.getLatest().subscribe(
            (offers: any) => {
                this.offers = offers;
                this.loading = false;
            },
            null,
            () => {
                this.addOwlCarousel();
            }
        );
    }

    getBestSellingOffers() {
        this.taxonomyService.getTaxonomyOffers(1).subscribe((bestSelling: any) => {
            this.bestSellingOffers = bestSelling;
            this.bestSellingLoading = false;
        }, null)
    }

    getFeaturedOffers() {
        this.taxonomyService.getTaxonomyOffers(2).subscribe((featured: any) => {
            this.featuredOffers = featured;
            this.featuredLoading = false;
        }, null)
    }

    getRecommendedOffers() {
        this.taxonomyService.getTaxonomyOffers(2).subscribe((recommended: any) => {
            this.recommendedOffers = recommended;
            this.recommendedLoading = false;
        })
    }

    isOfferInCart(offer) {
        return this.storeService.findItem(offer.id);
    }

    getImageSrc(offer: Offer) {
        return environment.image_cdn_url + '/' +
            (offer.defaultPicture.length > 0 ? offer.defaultPicture + '?func=crop&width=280&height=280' : offer.product.picture);
    }

    addOwlCarousel() { }

    addProductToCart(offer: any) {
        this.cartService.addToLocalCart(offer).subscribe(
            () => {
                const addCartMessage = this.translateService.get('common.message_for_added_to_cart')
                    .subscribe(message => {
                        this.toastService.success(message);
                    });
            }
        );
    }

    getOfferPrice(offer: Offer): number {
        return getPrice(offer);
    }

}
