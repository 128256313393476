import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductCategory} from '../../_models/product-category';
import {Location} from '@angular/common';
import {ProductCategoryService} from '../../_services/product-category.service';
import {Router} from '@angular/router';
import {IProductCategory} from '../../_models/iproduct-category';

@Component({
    selector: 'app-product-category-form',
    templateUrl: './product-category-form.component.html',
    styleUrls: ['./product-category-form.component.css']
})
export class ProductCategoryFormComponent implements OnInit {

    productCategoryForm: UntypedFormGroup;
    productCategory: ProductCategory;
    title: string;
    product_categories: IProductCategory[] = [];
    submitted = false;
    picture: Blob = null;

    constructor(private fb: UntypedFormBuilder, private location: Location,
                private pcService: ProductCategoryService, private router: Router) {
        const item = window.localStorage.getItem('product-category');
        this.productCategory = item ? JSON.parse(item) : new ProductCategory();
        this.title = item ? 'categories.edit' : 'categories.add';
        this.load_product_categories();
    }

    ngOnInit() {
        this.productCategoryForm = this.fb.group({
            id: new UntypedFormControl(0),
            name: new UntypedFormControl('', Validators.required),
            name_fr: new UntypedFormControl('', Validators.required),
            parent: new UntypedFormControl(0),
            type: new UntypedFormControl(''),
            picture: new UntypedFormControl('', this.productCategory.id == undefined ? Validators.required :  null),
        });
        

        if (this.productCategory.id) {
            this.productCategoryForm.patchValue({
                id: this.productCategory.id,
                name: this.productCategory.name,
                name_fr: this.productCategory.nameFr,
                parent: this.productCategory.parent != null ? this.productCategory.parent.id : 0,
                type: this.productCategory.type,
                picture: null
            });
        }
    }

    load_product_categories() {
        const context = this;
        this.pcService.get_product_categories().subscribe(
            (result) => {
                result.forEach(function (product_category, index) {
                    if (!product_category.parent) {
                        context.product_categories.push(product_category);
                    }
                });
            }
        );
    }

    get f() {
        return this.productCategoryForm.controls;
    }

    onChangeFile(event) {
        this.picture = event.target.files[0];
        console.log(this.picture);
    }

    submitForm() {
        if (!this.productCategoryForm.dirty || this.productCategoryForm.untouched || this.productCategoryForm.invalid) {
            return;
        }
        this.submitted = true;
        this.productCategory.id ? this.update() : this.create();
    }

    create() {
        this.pcService.add_product_category(this.productCategoryForm.value, this.picture).subscribe(
            () => {
                this.router.navigate(['admin/product-categories']);
            },
            (error) => this.submitted = false
        );
    }

    update() {
        this.pcService.update_product_category(this.productCategoryForm.value, this.picture).subscribe(
            () => {
                this.router.navigate(['admin/product-categories']);
            },
            (error) => this.submitted = false
        );
    }

    back_to_previous() {
        this.location.back();
    }
}
