import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';


@Injectable()
export class PageTitleService {

    private subject = new Subject<string>();

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.subject.next();
            }
        });
    }

    setPageTitle(pageTitle: string) {
        this.subject.next(pageTitle);
    }

    getPageTitle(): Observable<string> {
        return this.subject.asObservable();
    }
}
