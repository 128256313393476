<div class="container-fluid mb-5 related">
  <div class="row">
    <div class="col">
      <div class="bbb_main_container">
        <div
          class="bbb_viewed_title_container d-flex align-items-center justify-content-between"
        >
          <h3 class="bbb_viewed_title">
            {{ 'common.related_products' | translate }}
          </h3>
          <div class="d-none d-lg-block">
            <!-- <button class="btn btn-primary">
              View All <i class="ms-2 fa fa-angle-right"></i>
            </button> -->
          </div>
          <div class="d-sm-block d-md-block d-lg-none d-none">
            <a class="btn btn-link text-primary btn-sm"
              >More <i class="fa fa-angle-right"></i
            ></a>
          </div>
          <div class="d-lg-none d-sm-none d-block">
            <button class="btn btn-default p-2 text-primary btn-sm">
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div
          class="bbb_viewed_slider_container"
          *ngIf="!loadingState; else loadingDiv"
        >
          <ngx-slick-carousel
            class="carousel bbb_viewed_slider"
            #slickModal="slick-carousel"
            [config]="slideConfig"
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)"
          >
            <div
              ngxSlickItem
              *ngFor="let offer of relatedProducts"
              class="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center"
            >
              <div class="bbb_viewed_image">
                <img
                  [routerLink]="[
                    '/',
                    'products',
                    offer.product.name.replace(' ', '-'),
                    offer.id
                  ]"
                  [attr.title]="offer.product.name"
                  [lazyLoad]="getImageSrc(offer)"
                  [defaultImage]="offer.defaultPicture"
                  [alt]="offer.product.name"
                />
              </div>
              <div class="bbb_viewed_content">
                <div class="text-start">
                  <a
                    [routerLink]="[
                      '/',
                      'products',
                      offer.product.name.replace(' ', '-'),
                      offer.id
                    ]"
                    [attr.title]="offer.product.name"
                    class="bbb_viewed_name prdt_name fs-6 fw-medium"
                  >
                    {{
                      currentLanguage == "fr" &&
                      offer.product.productNameFr != null &&
                      offer.product.productNameFr != ""
                        ? offer.product.productNameFr
                        : offer.product.name
                    }}
                  </a>
                </div>

                <div class="d-flex justify-content-between align-items-end">
                  <div class="row">
                    <div class="text-start col-md-12">
                      <div class="bbb_viewed_price text-primary">
                        {{ getOfferPrice(offer) }} {{ offer.currency | uppercase }} / {{
                          currentLanguage == "fr" &&
                          offer.measurementunit.descriptionFr != null &&
                          offer.measurementunit.descriptionFr != ""
                            ? offer.measurementunit.descriptionFr
                            : offer.measurementunit.description
                        }}
                      </div>
                    </div>
                    <!-- <div class="col-md-12" *ngIf="offer.percentDiscount > 0">
                      <span class="text-primary discount">
                        -{{ offer.percentDiscount }}%
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>

              <ul class="product-links">
                <li>
                  <a href="#" data-tip="Add to Wishlist"
                    ><i class="fas fa-heart"></i
                  ></a>
                </li>
                <!-- <li>
                  <a href="#" data-tip="Quick View"
                    ><i class="fa fa-search"></i
                  ></a>
                </li> -->
                <li>
                  <a href="" data-tip="Add to Cart"
                    ><i class="fa fa-shopping-cart"></i
                  ></a>
                </li>
              </ul>
            </div>
          </ngx-slick-carousel>
        </div>

        <ng-template #loadingDiv>
          <div class="loader-box">
            <div class="lds-dual-ring"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
