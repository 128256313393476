<footer class="footer">
  <div class="container">
    <div class="row">
      <!-- Quick Links -->
      <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
        <h2 class="footer-heading text-uppercase">
          {{ "footer.quick_links" | translate }}
        </h2>
        <ul class="list-unstyled">
          <li>
            <a [routerLink]="['/']" class="py-1 d-block">{{
              "common.home" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['products', 'all']" class="py-1 d-block">{{
              "common.all_products" | translate
            }}</a>
          </li>
          <li>
            <a routerLink="user/place-your-command" class="py-1 d-block">{{
              "menu.place_command" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/blog']" title="Blog" class="py-1 d-block"
              >Blog</a
            >
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=4RlFmaLPb08&t=402s"
              class="py-1 d-block"
              >Help Center</a
            >
          </li>
        </ul>
      </div>
      <!-- Categories -->
      <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
        <h2 class="footer-heading text-uppercase">
          {{ "common.categories" | translate }}
        </h2>
        <ul class="list-unstyled" *ngIf="(category_list | json) != ({} | json)">
          <li *ngFor="let category of category_list.slice(0, 5)">
            <a
              [routerLink]="['products', 'category', category.slug]"
              class="py-1 d-block"
              >{{
                currentLanguage == "fr" &&
                category.nameFr != null &&
                category.nameFr != ""
                  ? category.nameFr
                  : category.name
              }}</a
            >
          </li>
        </ul>
        <!-- <a href="#" class="more"
          >{{ "common.more" | translate }} <i class="fa fa-arrow-right"></i
        ></a> -->
      </div>
      <!-- About Efarm -->
      <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
        <h2 class="footer-heading text-uppercase">
          {{ "footer.about" | translate }}
          <span
            class="text-primary"
            style="text-transform: none !important; font-size: 16px"
            >eFarm.cm</span
          >
        </h2>
        <ul class="list-unstyled">
          <li>
            <a
              (click)="goTo('what-is-efarm-cm')"
              [title]="'footer.what_is_efarm' | translate"
              class="py-1 d-block"
              >{{ "footer.what_is_efarm" | translate }}</a
            >
          </li>
          <li>
            <a
              (click)="goTo('how-efarm-cm-works')"
              [title]="'footer.how_efarm_workds' | translate"
              class="py-1 d-block"
              >{{ "footer.how_efarm_workds" | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="meet-our-team"
              [title]="'footer.meet_team' | translate"
              class="py-1 d-block"
              >{{ "footer.meet_team" | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="career"
              [title]="'footer.career' | translate"
              class="py-1 d-block"
              >{{ "footer.career" | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="contact-us"
              [title]="'footer.contact_us' | translate"
              class="py-1 d-block"
              >{{ "footer.contact_us" | translate }}</a
            >
          </li>
          <li>
            <a
              (click)="goTo('academy')"
              [title]="'footer.efarm_cm_academy' | translate"
              class="py-1 d-block"
              >{{ "footer.efarm_cm_academy" | translate }}</a
            >
          </li>
          <li>
            <a
              (click)="goTo('faq')"
              [title]="'faq'"
              class="py-1 d-block"
              >FAQ</a
            >
          </li>
        </ul>
      </div>
      <!-- Subscribe & Follow Us -->
      <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
        <h2 class="footer-heading text-uppercase">
          {{ "common.subscribe" | translate }}
        </h2>

        <p class="mb-2" style="font-size: 13px">
          {{ "footer.subscribe_to_newsletter" | translate }}
        </p>
        <form action="#" class="subscribe-form">
          <div class="form-group d-flex">
            <input
              type="email"
              [formControl]="newsletterForm"
              class="form-control rounded-0"
              (keydown)="userTyped = true"
              placeholder="{{ 'footer.enter_email' | translate }}"
            />

            <button
              (click)="subscribe()"
              class="form-control submit rounded-0"
              style="cursor: pointer"
              type="submit"
            >
              <i class="fa fa-paper-plane"></i>
            </button>
          </div>

          <div
            class="invalid-feedback"
            [ngClass]="{ 'd-block': userTyped }"
            *ngIf="
              newsletterForm.hasError('email') &&
              !newsletterForm.hasError('required')
            "
          >
            {{ "subscribeForm.enter_valid_email" | translate }}
          </div>
          <div
            class="invalid-feedback"
            [ngClass]="{ 'd-block': userTyped }"
            *ngIf="newsletterForm.hasError('required')"
          >
            {{ "subscribeForm.email_required" | translate }}
          </div>
          <div class="text-success">
            {{ message }}
          </div>
        </form>
        <h2 class="footer-heading text-uppercase mt-4">
          {{ "footer.follow_us" | translate }}
        </h2>
        <ul class="ftco-footer-social p-0 list-unstyled">
          <li class="ftco-animate">
            <a
              href="https://www.facebook.com/efarm.cm"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              title="Facebook"
              ><i class="fa-brands fa-facebook-f"></i
            ></a>
          </li>
          <li class="ftco-animate">
            <a
              href="https://twitter.com/efarm_cm"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              title="Twitter"
              ><i class="fa-brands fa-twitter"></i
            ></a>
          </li>
          <li class="ftco-animate">
            <a
              href="https://www.linkedin.com/company/efarmcameroon"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              title="LinkedIn"
              ><i class="fa-brands fa-linkedin-in"></i
            ></a>
          </li>
          <li class="ftco-animate">
            <a
              href="https://www.instagram.com/efarm.cm/"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              title="Instagram"
              ><i class="fa-brands fa-instagram"></i
            ></a>
          </li>
          <li class="ftco-animate">
            <a
              href="https://www.youtube.com/@efarm237"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              title="YouTube"
              ><i class="fa-brands fa-youtube"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr class="my-2 text-white" />

  <!-- Payment options -->
  <div class="container mt-4">
    <div class="row text-lg-center justify-content-lg-center mb-0">
      <div class="col-12">
        <p>{{ "footer.payment_systems" | translate }}</p>
        <div class="payment-methods">
          <img src="assets/icons/PaymentIcons.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 mt-3 border-top py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-8">
          <p class="copyright">
            Copyright &copy;
            {{ year }}
            {{ "footer.rights_reserved" | translate }} |
            {{ "footer.powered_by" | translate }}
            <a href="https://efarm.cm" target="_blank">eFarm.cm</a> LTD.
          </p>
        </div>
        <div class="col-md-6 col-lg-4 text-md-right">
          <p class="mb-0 list-unstyled">
            <a
              class="me-md-3"
              (click)="goTo('term-of-use')"
              [title]="'footer.terms_of_use' | translate"
              >{{ "footer.terms" | translate }}</a
            >
            <a
              class="me-md-3"
              (click)="goTo('privacy-policy')"
              [title]="'footer.privacy_policy' | translate"
              >{{ "footer.privacy" | translate }}</a
            >
            <a
              class="me-md-3"
              (click)="goTo('return-and-refund-policy')"
              [title]="'footer.refund_policy' | translate"
              >{{ "footer.refund" | translate }}</a
            >
            <a
              class="me-md-3"
              (click)="goTo('declaimer')"
              [title]="'footer.disclaimer' | translate"
              >{{ "footer.disclaimer" | translate }}</a
            >
            <a
              class="me-md-3"
              (click)="goTo('delivery')"
              [title]="'footer.delivery' | translate"
              >{{ "footer.delivery" | translate }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
