import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URLS} from '../config/api.urls';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private httpClient: HttpClient) {}

    public loadMedia(mediaGroup: string, id: number) {
        return this.httpClient.get(API_URLS.MEDIA_BASE_URL + '/' + mediaGroup + '/' + id + '/load');
    }

    public deleteMedia(id) {
        return this.httpClient.delete(API_URLS.MEDIA_BASE_URL + '/' + id);
    }
}
