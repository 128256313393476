import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Country } from '../../country/Country';
import { CountryService } from '../../country/country.service';

import { MustMatch } from '../../_helpers/MustMatch';
import { ProductCategory } from '../../_models/product-category';
import { ProductCategoryService } from '../../_services/product-category.service';
import { UserService } from '../../_services/user.service';
import { PageTitleService } from '../../_services/page-title.service';
import { SectorService } from '../../_services/sector.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/_constants/constants';
import { PhoneMustValid } from 'src/app/_helpers/PhoneMustValid';
import { Sector } from 'src/app/_models/sector';
import { IsPhoneNumberValid } from 'src/app/_helpers/IsPhoneNumberValid';




@Component({ templateUrl: './signup.component.html' })
export class SignupComponent implements OnInit, OnDestroy {
    title = 'Create an account';

    titleTranslateKey = 'common.create_account';

    registerForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    countries: Country[];
    productCategories: ProductCategory[];
    sectors: Sector[];
    usingFrench = true;

    subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private titleService: PageTitleService,
        private router: Router,
        private toastr: ToastrService,
        private cs: CountryService,
        private pcs: ProductCategoryService,
        private sectorService: SectorService,
        private titleS: Title,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.usingFrench = this.translateService.currentLang === AppConstants.FRENCH;
        const pageTitleSubscripton = this.translateService.get(this.titleTranslateKey).subscribe(value => {
            this.title = value;
            this.titleService.setPageTitle(this.title);
        });

        this.subscriptions.push(pageTitleSubscripton);

        const titleSubscription = this.translateService.get('rightnav.sign_up').subscribe(value => {
            this.titleS.setTitle(value);
        });

        this.subscriptions.push(titleSubscription);

        const passwordValidator = [Validators.minLength(8), Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)];

        this.getSectors();
        this.setCountries();
        this.setProductCategoryIds();
        this.registerForm = this.formBuilder.group({
            name: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', passwordValidator),
            confirmPassword: new UntypedFormControl(''),
            code: new UntypedFormControl(37, Validators.min(1)),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            phone: new UntypedFormControl('', [Validators.required]),
            userType: new UntypedFormControl('user', Validators.required),
            sectorId: new UntypedFormControl(null, Validators.required)
        },
        {
            validator: [
                MustMatch('password', 'confirmPassword'),
                IsPhoneNumberValid('code', 'phone')
            ]
        });
    }

    setCountries() {
        this.cs.loadAll().subscribe(
            data => {
                this.countries = data;
                localStorage.setItem('countries', JSON.stringify(this.countries));
            }
        );
    }

    setProductCategoryIds() {
        this.pcs.get_product_categories().subscribe(
            data => { this.productCategories = data; }
        );
    }

    getSectors() {
        this.sectorService.getAll().subscribe(
            (sectors: any) => {
                this.sectors = sectors;
            }
        );
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {

        this.submitted = true;

        // stop here if form is invalid
        if (!this.registerForm.valid) {
            this.submitted = false;
            return;
        }

        this.userService.register(this.registerForm.value)
            .subscribe(
                () => {
                    this.setSuccessMessage();
                    this.router.navigateByUrl('signup/confirmation');
                },
                (error: any) => {
                    this.loading = false;
                    this.submitted = false;
                    this.setErrorMessage(error);
                },
                () => {
                }
            );
    }


    setSuccessMessage() {
        const e = document.getElementById('success-messages');

        const successMessageSubscription = this.translateService.get('common.successfully_done')
        .subscribe(value => {
            e.innerHTML = value;
        });

        const subs0 = this.translateService.get('login.success_message')
        .subscribe(value => {
            this.toastr.success(value);
        });

        this.subscriptions.push(successMessageSubscription);
    }

    setErrorMessage(error) {
        const e = document.getElementById('error-messages');
        e.innerHTML = error;
        this.toastr.error(error);
        setTimeout(() => {
            e.innerHTML = '';
        }, 5000);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
