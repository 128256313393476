import { Injectable } from '@angular/core';
import {API_URLS} from '../config/api.urls';
import {HttpClient} from '@angular/common/http';
import {PaymentMethod} from '../_models/PaymentMethod';
import {Observable} from 'rxjs';
import {InitPaymentTransactionResp} from '../_models/init-payment-transaction-resp';
import {PayUnitPaymentResp} from '../_models/pay-unit-payment-resp';
import { InitTransactionRequest } from '../_models/init-transaction-req';
import { MoMoPaymentTransaction } from '../_models/momo-payment-transaction-details';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private httpClient: HttpClient) { }

  public loadPaymentsMethods() {
    return this.httpClient.get(API_URLS.LIST_OF_PAYMENT_METHODS);
  }

  public search(searchTerm: string, page: string) {
    return this.httpClient.get(API_URLS.PAYMENT_METHODS_BASE_URL + '/search', { params: {searchTerm: searchTerm, page: page} });
  }

  public addPaymentMethod(paymentMethod) {
    const formData = new FormData();
    formData.append('name', paymentMethod.name);
    formData.append('gateway', paymentMethod.gateway);
    formData.append('picture', paymentMethod.picture);
    return this.httpClient.post(API_URLS.PAYMENT_METHODS_BASE_URL, formData);
  }

  public updatePaymentMethod(paymentMethod) {
    const formData = new FormData();
    formData.append('name', paymentMethod.name);
    formData.append('gateway', paymentMethod.gateway);
    formData.append('status', paymentMethod.status === false ? '0' : '1');
    formData.append('picture', paymentMethod.picture);
    return this.httpClient.put(API_URLS.PAYMENT_METHODS_BASE_URL + '/' + paymentMethod.id, formData);
  }

  public deletePaymentMethod(paymentMethod: PaymentMethod) {
    return this.httpClient.delete(API_URLS.PAYMENT_METHODS_BASE_URL + '/' + paymentMethod.id);
  }

  // load PSP
  public init_payment_transaction(amount: string, orderId: number): Observable<InitPaymentTransactionResp> {
    return this.httpClient.post<InitPaymentTransactionResp>(API_URLS.PAYMENTS_BASE_URL + `/init-transaction`, {
      amount: amount, orderId: orderId, clientApp: 'EWeb'
    });
  }

  public makePaymentWithPayUnit(body: any): Observable<PayUnitPaymentResp> {
    return this.httpClient.post<PayUnitPaymentResp>(API_URLS.PAYMENTS_BASE_URL + '/place-order', body);
  }

  public checkPaymentStatus(data: any) {
    return this.httpClient.post(API_URLS.PAYMENTS_BASE_URL + '/check-status', data);
  }

  public getTransactionRef(data: InitTransactionRequest, name: string): Observable<any> {
    return this.httpClient.post<string>(API_URLS.PAYMENTS_BASE_URL + '/init-transaction?name=' + name, data);
  }

  public verify(transactionRef: string, value: any): Observable<any> {
    return this.httpClient.post(`${API_URLS.PAYMENTS_BASE_URL}/confirmation/${transactionRef}`, value);
  }

  public registerMoMoTransaction(details: MoMoPaymentTransaction, transaction: string, name: string): Observable<any> {
    return this.httpClient.post(`${API_URLS.PAYMENTS_BASE_URL}/register_payment_details/${transaction}?name=${name}`,  details)
  }
}
