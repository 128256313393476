import { Component, OnInit } from '@angular/core';
import {UserService} from '../../_services/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  token: string;
  loading = true;
  message: string;
  validated = false;

  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    const context = this;
    this.userService.verify_sign_up_token(this.token).subscribe(
        () => { this.message = 'Your account has been successfully activated'; this.validated = true; },
        () =>  {
            this.message = 'The activation link is not longer valid';
            this.loading = false;
            setTimeout(() => {
              context.router.navigateByUrl('/');
            }, 5000);
          },
        () => this.loading = false
    );
  }

}
