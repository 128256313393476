import {animate, state, style, transition, trigger} from '@angular/animations';


export let slide = trigger('slide', [
    transition(':leave', [
        style({ opacity: 0, transform: 'translateX(-100%)'}),
        animate(500)
    ])
]);

export let fadeIn = trigger('fadeIn', [
    transition(':enter', [
        style({transform: 'translateX(-50px)', opacity: 0}),
        animate(300)
    ])
]);

export let slideUp = trigger('slideUp', [
    transition(':leave', [
        style({height: 0, opacity: 0}),
        animate(300)
    ])
]);

export let slideDown = trigger('slideDown', [
    transition(':enter', [
        style({height: 'auto', opacity: 1}),
        animate(300)
    ])
]);
