import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Observable} from 'rxjs';
import {AppError} from '../commons/app-error';
import {NotFoundError} from '../commons/not-found-error';

@Injectable()
export class BasicDataService {

    public static handleError(error: Response) {
        if ( error.status === 400 ) {
            return Observable.throwError(new AppError(error.json()));
        } else if ( error.status === 404 ) {
            return Observable.throwError(new NotFoundError(error.json()));
        } else { throw error; }
    }

    constructor(private httpClient: HttpClient) {}

    getAll(url: string): Observable<any> | any{
        return this.httpClient.get(url).catch(BasicDataService.handleError);
    }

    create(url: string, resource): Observable<any> | any{
        return this.httpClient.post(url, JSON.stringify(resource)).catch(BasicDataService.handleError);
    }

    update(url: string, resource): Observable<any> | any {
        return this.httpClient.put(url, JSON.stringify(resource)).catch(BasicDataService.handleError);
    }

    delete(url: string): Observable<any> | any {
        return this.httpClient.delete(url).catch(BasicDataService.handleError);
    }

}
