<!-- BEGIN Main Container col2-right -->
<div class="page-heading">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="page-title">
          <h2>{{'sidebar.change_password' | translate}}</h2>
        </div>
      </div>
      <!--col-xs-12-->
    </div>
    <!--row-->
  </div>
  <!--container-->
</div>
<section class="main-container col2-right-layout">
  <div class="main container">
    <div class="row">
      <section class="col-main col-sm-9 col-xs-12 wow bounceInUp animated animated" style="visibility: visible;">
        <div class="my-account">
          <div class="col2-set">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h2>{{'user_profile.change_password_form' | translate}}</h2>
              </div>
              <div class="panel-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
                  <div class="content">
                    <fieldset class="col2-set">
                      <ul class="form-list">
                        <li>
                          <label>{{'user_profile.old_password' | translate}}</label>
                          <div class="input-box">
                            <input type="password" formControlName="oldPassword" class="input-text" />
                          </div>
                          <em *ngIf="f.oldPassword.touched && f.oldPassword.invalid" class="text-danger">{{'user_profile.old_password_required' | translate}}</em>
                        </li>
                        <li>
                          <label>{{'user_profile.new_password' | translate}}</label>
                          <div class="input-box">
                            <input type="password" formControlName="newPassword" class="input-text" />
                          </div>
                          <div *ngIf="f.newPassword.touched && f.newPassword.invalid">
                            <em *ngIf="f.newPassword.errors.required" class="text-danger">{{'user_profile.new_password_required' | translate}}</em>
                            <em *ngIf="f.newPassword.errors.minlength" class="text-danger">{{'user_profile.new_password_must_be_valid' | translate}}</em>
                          </div>
                        </li>
                        <li>
                          <label>{{'signup.confirm_password' | translate}}</label>
                          <div class="input-box">
                            <input type="password" formControlName="confirmPassword" class="input-text" />
                          </div>
                          <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid">
                            <em *ngIf="f.confirmPassword.errors.required" class="text-danger">{{'signup.no_confirm_password' | translate}}</em>
                            <em *ngIf="f.confirmPassword.errors.mustMatch" class="text-danger">{{'signup.password_mismatch' | translate}}</em>
                          </div>
                        </li>
                      </ul>
                      <div class="buttons-set">
                        <button type="submit" [disabled]="submitted ||  form.untouched || form.invalid" class="button login" [title]="'common.change' | translate" name="send" id="send2">
                          <span>{{'common.change' | translate}}</span>
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--col-main col-sm-9 wow bounceInUp animated-->
      <app-sidebar [currentUser]="currentUser"></app-sidebar>
      <!--col-right sidebar col-sm-3 wow bounceInUp animated-->
    </div>
    <!--row-->
  </div>
  <!--main container-->
</section>
<!--main-container col2-left-layout-->
