<div class="col-md-12">
    <div class="mb-3 mt-2">
        <h5>Orders List</h5>
    </div>
    <div class="table-responsive">
        <form>
            <table class="table table-bordered table-sm" id="orders-list-table">
                <thead>
                    <tr>
                        <th>
                            Order N<sup>o</sup>
                        </th>
                        <th>
                            {{'common.order_client_amount' | translate}} XAF
                        </th>
                        <th>
                            Ordered By
                        </th>
                        <th>
                            {{'common.order_commission' | translate}} XAF
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Created At
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="loading; else tableContent">
                        <td colspan="4">Loading</td>
                    </tr>
                    <ng-template #tableContent>
                        <tr *ngFor="let order of orders | paginate: config">
                            <td>{{ (order.orderNumber.length>15) ? (order.orderNumber | slice: 0 : 15) + '...' : order.orderNumber }}</td>
                            <td>{{ order.totalAmount * (0.95) | number: '1.0-0'}}</td>
                            <td>{{ order.orderBy }}</td>
                            <td>{{ order.totalAmount * (0.05) | number: '1.0-0'}}</td>
                            <td>{{ order.status }}</td>
                            <td>{{ order.createdAt }}</td>
                            <td>
                                <a class="btn btn-primary btn-sm" id="btn-edit" [routerLink]="['/admin', 'orders', order.orderNumber]">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </form>
    </div>