import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/_constants/constants';
import { Sector } from 'src/app/_models/sector';
import { SectorService } from 'src/app/_services/sector.service';

@Component({
    selector: 'app-sector-form',
    templateUrl: './sector-form.component.html',
    styleUrls: ['./sector-form.component.css']
})
export class SectorFormComponent implements OnInit {

    sectorForm: UntypedFormGroup;
    sector: Sector;
    title: string;
    submitted = false;

    constructor(private fb: UntypedFormBuilder, private location: Location,
        private sectorService: SectorService, private router: Router) {
        const item = window.localStorage.getItem(AppConstants.SECTORS_KEY);
        this.sector = item ? JSON.parse(item) : new Sector();
        this.title = item ? 'sectors.edit' : 'sectors.add';
    }

    ngOnInit() {
        this.sectorForm = this.fb.group({
            id: new UntypedFormControl(0),
            name: new UntypedFormControl('', Validators.required),
            nameFr: new UntypedFormControl('', Validators.required)
        });


        if (this.sector.id) {
            this.sectorForm.patchValue({
                id: this.sector.id,
                name: this.sector.name,
                nameFr: this.sector.nameFr
            });
        }
    }

    get f() {
        return this.sectorForm.controls;
    }

    submitForm() {
        if (!this.sectorForm.dirty || this.sectorForm.untouched || this.sectorForm.invalid) {
            return;
        }
        this.submitted = true;
        this.sector.id ? this.update() : this.create();
    }

    create() {
        this.sectorService.create(this.sectorForm.value).subscribe(
            () => {
                this.router.navigate(['admin/sectors']);
            },
            (error) => this.submitted = false
        );
    }

    update() {
        this.sectorService.update(this.sectorForm.value).subscribe(
            () => {
                this.router.navigate(['admin/sectors']);
            },
            (error) => this.submitted = false
        );
    }

    back_to_previous() {
        this.location.back();
    }
}
