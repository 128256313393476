<div class="recent-orders">
  <div class="title-buttons">
    <strong>Pages </strong>
  </div>
  <br />
  <br />
  <div class="table-responsive">
    <table class="table table-striped table-users" id="my-orders-table">
      <thead>
        <tr class="first last">
          <th>Title (En)</th>
          <th>Title (Fr)</th>
          <th>Created at </th>
          <th>
            <button (click)="addPage()" class="button button-submit button-md"> New Page </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading else tbody">
          <td colspan="3" style="text-align: center;">Loading ...</td>
        </tr>
        <ng-template #tbody>
          <tr *ngFor="let page of pages | paginate: config">
            <td>
              <a target="_blank" [routerLink]="['/pages', page.slug]">{{ page.title }}</a>
            </td>
            <td>
              <a target="_blank" [routerLink]="['/pages', page.slug]">{{ page.titleFr }}</a>
            </td>
            <td>{{ page.createdAt }}</td>
            <td>
              <a [routerLink]="['/admin', 'pages', page.id, 'edit']">Edit</a>
              &nbsp;
              <button type="submit" class="btn-delete" (click)="deletePage(page.id)">Delete</button>
            </td>
          </tr>
        </ng-template>
        
      </tbody>
    </table>
  </div>
  <div class="toolbar" *ngIf="!loading">
    <div class="pager">
      <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
        id="listing" (pageChange)="pageChange($event)"></pagination-controls>
    </div>
  </div>
</div>
