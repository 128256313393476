<div class="col-lg-12 mt-2">
    <div class="card">
        <div class="card-header">
            {{ title | translate}}
        </div>
        <div class="card-body">
            <form [formGroup]="pricingForm" (ngSubmit)="submitForm()" novalidate>
                <input type="hidden" formControlName="id">
                <div class="form-group">
                    <label>{{'pricings.french_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="nameFr" class="form-control">
                    <em *ngIf="(f.nameFr.touched && f.nameFr.invalid)" class="text-danger">
                        {{'user_profile.name_required' | translate}}
                    </em>
                </div>
                <div class="form-group">
                    <label>{{'pricings.english_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="name" class="form-control">
                    <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                        {{'user_profile.name_required' | translate}}
                    </em>
                </div>
                <div class="form-group">
                    <label>{{'pricings.nicename' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="nicename" class="form-control">
                    <em *ngIf="(f.nicename.touched && f.nicename.invalid)" class="text-danger">
                        {{'pricings.nicename_required' | translate}}
                    </em>
                </div>
                <div class="buttons-set">
                    <button class="button button-submit" type="submit"
                            [disabled]="!pricingForm.dirty || pricingForm.invalid ">
                        <span>{{ (submitted ? 'common.wait' : 'common.submit') | translate }}</span> <span *ngIf="submitted"> ...</span>
                    </button>
                    <span class="ml-2"></span>
                    <a class="button button-back" (click)="back_to_previous()">
                        <span>{{'common.back' | translate}}</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>
