<div class="mb-4">
  <div class="app_download d-none d-sm-block">
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6 text-center text-white">
        <h1 class="fw-">DOWNLOAD OUR APP</h1>
        <p class="sub_download">It's natural, it's green.</p>
        <p class="download_desc">
          Just search from various products and categories we have available
        </p>

        <div class="available">AVAILABLE ON</div>

        <a
          href="https://play.google.com/store/apps/details?id=com.bee_studio.efarm"
          target="_blank"
          class="btn btn-md btn-dark text-white shadow"
        >
          <i class="fab fa-google-play text-white"></i> Google Play
        </a>
      </div>
    </div>
  </div>
  <div class="text-center mobile_app_download d-sm-none">
    <h4>DOWNLOAD OUR APP</h4>
    <p>Get Access to Exclusive offers!</p>
    <a
      href="https://play.google.com/store/apps/details?id=com.bee_studio.efarm"
      target="_blank"
      class="btn btn-md btn-dark text-white shadow"
    >
      <i class="fab fa-google-play text-white"></i> Google Play
    </a>
  </div>
</div>
