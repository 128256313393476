<div class="container-fluid">
  <div class="page-title text-center mt-5 mb-5">
    <h2>
      {{ "footer.meet_team" | translate }}
    </h2>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3">
      <app-page-sidebar [language]="currentLanguage"></app-page-sidebar>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9 product-list">
      <p style="text-align: center;" *ngIf="loading else pageBlock">Loading ...</p>
      <ng-template #pageBlock>
        <div *ngFor="let member of teamMembers; let i = index" style="margin-bottom: 20px; display: block; position: relative;">
          <div class="row team-member">
            <div class="col-sm-12 col-md-3 col-lg-2">
              <img [src]="getImageSrc(member.imagePath)" [alt]="member.fullName" class="img-responsive" />
            </div>
            <div class="col-md-9 col-lg-10 col-sm-12">
              <p class="fullName">{{ member.fullName }}</p>
              <p class="jobPosition">{{ member.jobPosition }}</p>
              <p class="description" [innerHTML]="member.description"></p>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </ng-template>
    </div>
  </div>
  
</div>