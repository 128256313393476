import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrencyConversionService } from '../_services/currency-conversion.service';

@Pipe({
  name: 'tocurrency'
})
export class TocurrencyPipe implements PipeTransform {
  private readonly usedCurrency = environment.currency;

  constructor(private conversionService: CurrencyConversionService) { }

  transform(value: number, arg: string): Observable<number> {
    return this.conversionService.convert(value, arg);
  }
}
