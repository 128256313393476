import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';

import { Group } from 'src/app/_models/group';
import { GroupService } from 'src/app/_services/group.service';
import { UserService } from 'src/app/_services/user.service';
import { OfferService } from 'src/app/_services/offer.service';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  taxonomies: Group[];
  addForm: UntypedFormGroup;
  assignForm: UntypedFormGroup;
  assignModal: NgbModalRef;
  offers: any[];
  modalTitle = '';
  taxonomy: Group;
  submitting = false;
  offerIds: number[] = [];
  ids: number[] = [];
  loading = true;

  constructor(
    private groupService: GroupService, 
    private toastService: ToastrService,
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private modalService: NgbModal,
    private offerService: OfferService
    ){
      this.assignForm = this.fb.group({
        offerIds: new UntypedFormArray([], [Validators.required])
      });
    }

  ngOnInit(): void {
      this.addForm = this.fb.group({
        title: new UntypedFormControl('', [Validators.required, Validators.minLength(5)])
      });

      combineLatest([this.offerService.getLatest(), this.groupService.getAll()])
      .subscribe(
        ([offers, taxonomies]) => {
          this.offers = offers;
          this.taxonomies = taxonomies;
          this.loading = false;
        }
      )
  }

  onChange(id: number, event: any){
    if(!event.target.checked && this.offerIds.includes(id)){
      this.offerIds.splice(this.offerIds.indexOf(id), 1);
    } else if(!this.offerIds.includes(id)) {
      this.offerIds.push(id);
    }
  }

  open(group: Group, content) {
    this.taxonomy = group;
    this.modalTitle = group.title;
    // group.offers.forEach((offer) => this.ids.push(offer.id));
    this.offerIds = this.taxonomy.selectedOfferIds;
    this.assignModal = this.modalService.open(content, { scrollable: true });
  }

  close() {
    this.assignModal.close();
  }

  assignOffers(){
    if(this.offerIds.length == 0){
      this.toastService.warning("Please select at least one offers");
      return;
    }
    this.submitting = true;
    this.groupService.saveOffers(this.offerIds,this.taxonomy.id)
    .subscribe(
      () => {
        this.toastService.success('offers successfully assigned');
        this.assignModal.close();
        window.location.reload();
      },
      () => this.submitting = false
    );
  }

  submit() {
    this.groupService.addGroup(this.addForm.value.title)
    .pipe(
      switchMap(() =>this.groupService.getAll())
    ).subscribe(
      (groups) => {this.taxonomies = groups; this.toastService.success("Group successfully added")},
      (error) => {
        this.toastService.error("failed to create a new group")
      },
      () => {
        window.location.reload();
      }
    );
  }
}
