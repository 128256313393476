import { Component, OnDestroy, OnInit} from '@angular/core';
import { ProductCategory} from '../../_models/product-category';
import { ProductCategoryService} from '../../_services/product-category.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-product-category',
    templateUrl: './product-category.component.html',
    styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit, OnDestroy {

    categories: ProductCategory[];
    page = '1';
    item_per_page = '10';
    config: any;
    loading: boolean;

    constructor(private pcService: ProductCategoryService,
                private router: Router, private route: ActivatedRoute, private titleService: Title) {
        this.route.queryParamMap.subscribe(
            (paramAsMap: any) => {
                if (paramAsMap.params.page) {
                    this.page = paramAsMap.params.page;
                }
            }
        );
    }

    ngOnInit() {
        this.load_categories(this.page);
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('');
    }

    load_categories(page: string) {
        this.loading = true;
        this.pcService.local_product_categories(this.item_per_page, page).subscribe(
            (result) => {
                this.categories = result.data;
                this.config = {
                    currentPage: result.page,
                    itemsPerPage: result.itemPerPage,
                    totalItems: result.totalOfItems,
                    id: 'listing'
                };
                this.loading = false;
            }
        );
    }

    add_product_category() {
        window.localStorage.removeItem('product-category');
        this.router.navigate(['admin/product-categories/add']);
    }

    edit_product_category(productCategory: ProductCategory) {
        window.localStorage.removeItem('product-category');
        window.localStorage.setItem('product-category', JSON.stringify(productCategory));
        this.router.navigate(['admin/product-categories', productCategory.id, 'edit']);
    }

    delete_product_category(productCategory: ProductCategory) {
        this.pcService.delete_product_category(productCategory).subscribe(
            () => {
                this.categories = this.categories.filter(pc => pc !== productCategory);
                this.page = '1';
                this.router.navigate(['/admin/product-categories'], {queryParams: {page: this.page}});
                this.load_categories(this.page);
            }
        );
    }

    pageChange(page: string) {
        this.page = page;
        this.router.navigate(['/admin/product-categories'], {queryParams: {page: page}});
        this.load_categories(page);
    }

    
    printImage(category) {
        return category.picture !== '' && category.picture !== null  ?
            environment.image_cdn_url + '/' + category.picture + '?func=crop&width=45&height=45' : '/assets/images/default.jpg';
    }
}
