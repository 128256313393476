import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PageService} from '../_services/page.service';
import {Page} from '../_models/page';

@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.css']
})
export class PageSidebarComponent implements OnDestroy {

  pageSubscription: Subscription;
  slug: string;
  page: Page;
  loading = false;
  @Input() language: string;
  constructor(private router: Router, private pageService: PageService, private activatedRoute: ActivatedRoute) {
    this.pageSubscription = this.pageService.pageSubject.subscribe(
        (page: Page) => {
          this.page = page;
        },
        () => this.router.navigateByUrl('/'),
        () => this.loading = false
    );
  }

  goTo(slug: string) {
    if (window.location.href.trim().search(slug) <= 0) {
      this.router.navigateByUrl(`pages/${slug}`);
      this.pageService.emitLoadingSubject(true);
      this.pageService.getPage(slug);
    }
  }

  ngOnDestroy(): void {
    this.pageSubscription.unsubscribe();
  }

}
