import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Product} from '../_models/product';
import {Observable} from 'rxjs';
import {API_URLS} from '../config/api.urls';
import {IPagination} from '../_models/pagination';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {}

  public loadProducts(page: string):  Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.LIST_OF_PRODUCTS, { params: { page: page  } });
  }

  public addProduct(product: Product) {
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('nameFr', product.productNameFr);
    formData.append('categoryId', product.categoryId.toString());
    formData.append('picture', product.picture);
    return this.httpClient.post(API_URLS.LIST_OF_PRODUCTS, formData);
  }

  public updateProduct(product) {
    const formData = new FormData();
    formData.append('status', product.status === false ? '0' : '1');
    formData.append('name', product.name);
    formData.append('nameFr', product.productNameFr);
    formData.append('categoryId', product.categoryId.toString());
    formData.append('picture', product.picture);
    return this.httpClient.put(API_URLS.LIST_OF_PRODUCTS + '/' + product.id, formData);
  }

  public deleteProduct(product: Product) {
    return this.httpClient.delete(API_URLS.LIST_OF_PRODUCTS + '/' + product.id);
  }

  public search(searchTerm: string, page: string) {
    return this.httpClient.get(API_URLS.LIST_OF_PRODUCTS + '/search', { params: {searchTerm: searchTerm, page: page} });
  }

  public sendRequest(values: any) {
    const formData = new FormData();
    formData.append('qty', values.qty);
    formData.append('description', values.description);
    formData.append('price', values.price);
    formData.append('currency', values.currency);
    formData.append('productId', values.productId);
    formData.append('picture', values.picture);
    return this.httpClient.post(API_URLS.USERS_BASE_PATH  + values.userId + '/place_command', formData);
  }
}
