import { Component, OnInit } from '@angular/core';
import {ShippingAddress} from '../../_models/shipping-address';
import {Title} from '@angular/platform-browser';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../_models/user';
import {UserService} from '../../_services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.css']
})
export class ShippingAddressComponent implements OnInit {

  shippingAddresses: ShippingAddress[] = [];
  loading = true;
  showForm = false;
  currentUser: User;
  shippingAddressForm: UntypedFormGroup;
  saving = false;
  deleting = false;

  constructor(public userService: UserService, public titleService: Title, 
    private fb: UntypedFormBuilder, private translateService: TranslateService) {
    
      this.translateService.get("rightnav.my_account").subscribe(value => {
        this.titleService.setTitle(value);
      })

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.loadingShippingAddress();
  }

  loadingShippingAddress() {
    this.userService.loadShippingAddresses(this.currentUser.id).subscribe(
        (shippingAddresses) => {
          this.shippingAddresses = shippingAddresses;
          this.currentUser.shippingAddressList  = shippingAddresses;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          this.loading = false;
        }
    );
  }

  addShippingAddress() {
    this.generateForm(null);
    this.showForm = true;
  }

  generateForm(shippingAddress: ShippingAddress) {
    this.shippingAddressForm = this.fb.group({
      id: new UntypedFormControl(shippingAddress ? shippingAddress.id : 0),
      name: new UntypedFormControl(shippingAddress ? shippingAddress.name : '', Validators.required),
      region: new UntypedFormControl(shippingAddress ? shippingAddress.region : '', Validators.required),
      status: new UntypedFormControl(shippingAddress ? shippingAddress.status : ''),
      town: new UntypedFormControl(shippingAddress ? shippingAddress.town : '', Validators.required),
      addressLine1: new UntypedFormControl(shippingAddress ? shippingAddress.addressLine1 : '', Validators.required),
      addressLine2: new UntypedFormControl(shippingAddress ? shippingAddress.addressLine2 : ''),
    });
  }

  editShippingAddress(shippingAddress: ShippingAddress) {
    this.generateForm(shippingAddress);
    this.showForm = true;
  }

  saveShippingAddress() {
    if (!this.shippingAddressForm.dirty ||  this.shippingAddressForm.untouched || this.shippingAddressForm.invalid) {
      return;
    }
    this.saving = true;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if ( this.shippingAddressForm.value.id === 0 ) {
      this.shippingAddressForm.removeControl('status');
    }
    this.userService.saveShippingAddress(currentUser.id, this.shippingAddressForm.value).subscribe(
        () => {
          this.showForm = false;
          this.loading = true;
          this.shippingAddressForm.reset();
          this.loadingShippingAddress();
        },
        () => { }
    );
    this.saving = false;
  }

  confirmDeletion(address: ShippingAddress) {
    this.showForm = false;
    document.getElementById('sphAddr' + address.id).classList.remove('delete-message');
  }

  cancelDeletion(address: ShippingAddress) {
    document.getElementById('sphAddr' + address.id).classList.add('delete-message');
  }

  deleteShippingAddress(shippingAddress: ShippingAddress) {
    this.deleting = true;
    this.userService.deleteShippingAddress(shippingAddress).subscribe(
        () => {
          this.loading = true;
          this.loadingShippingAddress();
        },
        () => { this.deleting = false; }
    );
  }

  get f() { return this.shippingAddressForm.controls; }

  closeForm() {
    this.showForm = false;
  }
}
