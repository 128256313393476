import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../_constants/constants';
import { IPagination } from '../_models/pagination';
import { PricingService } from '../_services/pricing.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit {
  pricing: any[];

  usingEnglish = false;


  constructor(
    private pricingService: PricingService,
    private translateService: TranslateService

  ) { 
    this.usingEnglish = this.translateService.currentLang === AppConstants.ENGLISH;
  }

  ngOnInit() {
    this.getPricing();
  }

  getPricing() {
    return this.pricingService.get_pricings().subscribe(
        (result: IPagination) => {
            this.pricing = result.data;
        }
    );
}
}
