import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.css']
})
export class SignupConfirmationComponent implements OnInit {

  titleTranslateKey =  'common.create_account_confirmation';


  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {}

}
