import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/_constants/constants';
import { MeasurementService } from 'src/app/_services/measurement.service';
import { MeasurementUnit } from 'src/app/_models/measurement-unit';

@Component({
    selector: 'app-measurement-unit-form',
    templateUrl: './measurement-unit-form.component.html',
    styleUrls: ['./measurement-unit-form.component.css']
})
export class MeasurementUnitFormComponent implements OnInit {

    measurementUnitForm: UntypedFormGroup;
    measurementUnit: MeasurementUnit;
    title: string;
    submitted = false;
    statusses = ['active', 'inactive'];

    constructor(private fb: UntypedFormBuilder, private location: Location,
        private measurementUnitService: MeasurementService, private router: Router) {
        const item = window.localStorage.getItem(AppConstants.MEASUREMENT_UNITS_KEY);
        this.measurementUnit = item ? JSON.parse(item) : new MeasurementUnit();
        this.title = item ? 'measurement-units.edit' : 'measurement-units.add';
    }

    ngOnInit() {
        this.measurementUnitForm = this.fb.group({
            id: new UntypedFormControl(0),
            description: new UntypedFormControl('', Validators.required),
            descriptionFr: new UntypedFormControl('', Validators.required),
            status: new UntypedFormControl('', Validators.required)
        });


        if (this.measurementUnit.id) {
            this.measurementUnitForm.patchValue({
                id: this.measurementUnit.id,
                description: this.measurementUnit.description,
                descriptionFr: this.measurementUnit.descriptionFr,
                status: this.measurementUnit.status,
            });
        }
    }

    get f() {
        return this.measurementUnitForm.controls;
    }

    submitForm() {
        if (!this.measurementUnitForm.dirty || this.measurementUnitForm.untouched || this.measurementUnitForm.invalid) {
            return;
        }
        this.submitted = true;
        this.measurementUnit.id ? this.update() : this.create();
    }

    create() {
        this.measurementUnitService.create(this.measurementUnitForm.value).subscribe(
            () => {
                this.router.navigate(['admin/measurement-units']);
            },
            (error) => this.submitted = false
        );
    }

    update() {
        this.measurementUnitService.update(this.measurementUnitForm.value).subscribe(
            () => {
                this.router.navigate(['admin/measurement-units']);
            },
            (error) => this.submitted = false
        );
    }

    back_to_previous() {
        this.location.back();
    }
}
