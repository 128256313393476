import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {EXT_URLS} from '../config/api.urls';
import {CurrencyRate} from '../_currency/currency-model';
import {CurrencyStoreService} from './currency-store.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyConversionService {
  usedCurrency = environment.currency;

  constructor(private currencyRateStore: CurrencyStoreService, private httpClient: HttpClient) { }

  convert(value: number, arg: string): Observable<number> {
    const sourceCurrency = arg.toUpperCase();
    const destination = this.usedCurrency.toUpperCase();

    const e = this.currencyRateStore
      .retrieve(sourceCurrency, destination);

    if (e) {
      return of(this.compute(value, e.rate));
    } else {
      return this.getRateFromExchangeRateApi(sourceCurrency, destination)
          .pipe(map(rate => {
            // cache the rate
            const newRate: CurrencyRate = {from: sourceCurrency, to: destination, rate: rate}
            this.currencyRateStore.add(newRate);
            return this.compute(value, rate);
          }));
    }
  }

  getRateFromFixer(from: string, to: string) {
    this.httpClient
      .get(EXT_URLS.FIXER_CURRENCY_EXCHANGE, { params: { base: from, symbols: to } })
      .subscribe(response => {

      });
    return null;
  }

  getRateFromExchangeRateApi(from: string, to: string) {
    return this.httpClient
      .get<ExchangeRateResponse>(`${EXT_URLS.EXCHANGE_RATE_API_URL}/${from}/${to}`)
      .pipe(map(res => res.conversion_rate));
  }

  private compute(rate: number, amount: number) {
    return Math.ceil(amount * rate);
  }
}

class ExchangeRateResponse {
  base_code: string;
  conversion_rate: number;
  documentation: string;
  result: 'success' | 'error';
  target_code: string;
  terms_of_use: string;
  time_last_update_unix: number;
  time_last_update_utc: string;
  time_next_update_unix: string;
  time_next_update_utc: string;
}
