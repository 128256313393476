import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../_services/page.service';
import { Subscription } from 'rxjs';
import { AppConstants } from '../_constants/constants';
import { ProductCategoryService } from '../_services/product-category.service';
import { FormControl, Validators } from '@angular/forms';
import { NewsletterService } from '../_services/newsletter.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  pageSubscription: Subscription;
  isFirst = true;
  year = new Date().getFullYear()
  category_list: any[];

  newsletterForm: FormControl;
  userTyped = false;
  submitting = false;
  message = '';

  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(private productCatService: ProductCategoryService, private router: Router,
    private pageService: PageService, private toastService: ToastrService,
    private newsletterService: NewsletterService) { }



  ngOnInit() {
    this.pageSubscription = this.pageService.pageSubject.subscribe();

    this.productCatService.get_category_list()
      .subscribe((data) => {
        this.category_list = data;
      });

    this.newsletterForm = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  goTo(slug: string) {
    if (window.location.href.trim().search(slug) <= 0) {
      this.router.navigateByUrl(`pages/${slug}`);
      this.pageService.emitLoadingSubject(true);
      this.pageService.getPage(slug);
    }
  }

  subscribe() {
    if (this.newsletterForm.valid) {
      this.message = 'submitting ...'
      const contest = this;
      this.newsletterService.newsLetterSubscribe(this.newsletterForm.value).subscribe(response => {
        this.newsletterForm.reset()
        this.userTyped = false
        this.message = response.message;
        setTimeout(() => {
          contest.message = '';
        }, 4000);
      }),
      (error) => {}
    } else {

      this.toastService.show("Enter your email address to subscribe");
      console.log("Enter your email address to subscribe");
    }
  }

  ngOnDestroy(): void {
    this.pageSubscription.unsubscribe();
  }

}
