<div class="my-account">
  <div class="dashboard">
    <div class="box-account">
      <br>
      <div class="col2-set">
        <div class="card">
          <div class="card-header">
            <h2></h2>
          </div>
          <div class="card-body">
            <ng-template #form_template>
              <form class="form-offer-edit" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="content">
                  <fieldset class="group-select">
                    <ul>
                      <li>
                        <label>Product </label>
                        <br/>
                        <input type="text" [disabled]="true" [value]="offer.product.name" class="input-text input-disabled">
                      </li>
                      <li>
                        <div>
                          <div class="input-box">
                            <label>Quantity</label>
                            <br/>
                            <input type="number" min="1" formControlName="qty" class="input-text"/>
                            <em *ngIf="f.qty.touched && f.qty.invalid" class="text-danger">Quantity is required</em>
                          </div>

                          <div class="input-box">
                            <label>Measurement Unit</label>
                            <br/>
                            <select class="input-select" formControlName="measurementunit" [compareWith]="compareValues">
                              <option *ngFor="let measurement of measurementUnits" [ngValue]="measurement">{{ measurement.description }}</option>
                            </select>
                            <em *ngIf="f.measurementunit.touched && f.measurementunit.invalid" class="text-danger">
                              Select a measurement units in the list
                            </em>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div class="input-box">
                            <label>Price</label>
                            <br/>
                            <input type="number" min="00.00" formControlName="price"
                                   class="input-text" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01"/>
                            <em *ngIf="f.price.touched && f.price.invalid" class="text-danger">Price is required</em>
                          </div>

                          <div class="input-box">
                            <label>Currency</label>
                            <br/>
                            <select class="input-select" formControlName="currency">
                              <option [value]="'xaf'">XAF</option>
                              <option [value]="'usd'">USD</option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div class="input-box">
                            <label>Product condition</label>
                            <br/>
                            <select class="input-select" formControlName="productCondition" [compareWith]="compareValues">
                              <option *ngFor="let productCondition of productConditions"
                                      [ngValue]="productCondition">{{ productCondition.description }}</option>
                            </select>
                            <em *ngIf="f.productCondition.touched && f.productCondition.invalid" class="text-danger">
                              Select Product condition in the list
                            </em>
                          </div>
                          <div class="input-box">
                            <label>Pricing</label>
                            <br/>
                            <select class="input-select" formControlName="pricing" [compareWith]="compareValues" (change)="onChange()">
                              <option *ngFor="let p of pricing" [ngValue]="p">{{ p.name }}</option>
                            </select>
                            <em *ngIf="f.pricing.touched && f.pricing.invalid" class="text-danger">
                              Select a pricing in the list
                            </em>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="(selectedPricing.nicename == 'promotion' || selectedPricing.nicename == 'auction' )">
                        <div>
                          <div class="input-box" *ngIf="selectedPricing != '' && selectedPricing.nicename == 'promotion'">
                            <label>Percent Discount</label>
                            <br/>
                            <input type="number" min="0" max="99" formControlName="percentDiscount" class="input-text" />
                          </div>
                          <div class="input-box">
                            <label>Expiration Date</label>
                            <br/>
                            <input type="date" [min]="currentDate" formControlName="expirationDate" class="input-text" />
                          </div>
                        </div>
                      </li>
                      <li>
                        <label>Product Description</label>
                        <br/>
                        <angular-editor  formControlName="description" ></angular-editor>
                        <em *ngIf="f.description.touched && f.description.invalid" class="text-danger">
                          Product Description is required
                        </em>
                      </li>
                      <li>
                        <label>
                          <input type="checkbox" formControlName="returns"/>
                          {{ 'common.returns_accepted' | translate }} ?
                        </label>
                      </li>
                    </ul>
                    <div class="buttons-set">
                      <button type="submit" [disabled]="form.invalid || submitted" class="button btn-md">
                        {{ submitted ? 'Saving ...' : 'Update product' }}
                      </button>
                    </div>
                  </fieldset>
                </div>
              </form>
            </ng-template>
            <span *ngIf="loading else form_template"> Loading ...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
