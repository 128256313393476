import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProgressBarService } from '../_services/progress-bar.service';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private progressBarService: ProgressBarService,
    private toastr: ToastrService,
    private authService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        
        // if (!request.url.includes('/secure')) {
        //   this.progressBarService.stopAndHide();
        // }
        // console.error(err.error);
        const error = err.error.message || err.statusText;
        
        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api;

          
          if (err.error.error === 'invalid_token') {
            // clear access token
            this.authService.logout();
            this.toastr.error('Session expired');
            window.location.reload();
          }
          this.toastr.error('Wrong username and password');
        } else if (err.status === 500 || err.status === 504) {
          this.toastr.error(error);
        } else if (err.status === 400 || err.status === 404) {
          if (err.error.error && err.error.error === 'invalid_grant') {
            this.toastr.error('Wrong username and password');
          } else {
            this.toastr.error(error);
          }
        }
        return throwError(error);
      })
    );
  }
}
