import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CartAction } from '../_cart/cart.actions';
import Remove = CartAction.Remove;
import IncrementQty = CartAction.IncrementQty;
import DecrementQty = CartAction.DecrementQty;
import ClearTheCart = CartAction.ClearTheCart;
import SetCartId = CartAction.SetId;
import { getPrice, Offer } from '../_models/offer';
import UpdateShippingOffer = CartAction.UpdateShippingOffer;
import { Observable, of } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import UpdateOrderId = CartAction.UpdateOrderId;
import { CartStateModel } from '../_cart/cart.state';
import { User } from '../_models/user';
import { OrderService } from './order.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CartItem } from '../_models/cart-item';
import { AppConstants } from '../_constants/constants';
import { map } from 'rxjs/operators';
import { StoreService } from './store.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    user: User;
    cartSize = 0;

    constructor(private store: Store, private orderService: OrderService,
        private httpClient: HttpClient, private storeService: StoreService) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.storeService.findAll().subscribe(response => {
            this.cartSize = response.length
        });
    }

    loadItems(userId): Observable<CartItem[]> {
        return of ([])
        // return this.httpClient.get<CartItem[]>(environment.apiUrl + '/user/' + userId + '/cart');
    }

    addOrUpdateItemIntoCart(userId, offerId, price, shipTo, shippingOfferId, qty) {
    }


    private buildCartItem(offer: Offer, qty: number): CartItem {
        return {
            itemId: ++this.cartSize,
            shippingOfferPrice: 0,
            shippingOfferId: 0,
            price: getPrice(offer),
            qty: qty,
            currency: offer.currency,
            picture: this.getImagePath(offer.product),
            measurementUnit: offer.measurementunit.description,
            productName: offer.product.name,
            offerId: offer.id,
            shipTo: null,
            shippingOffers: offer.shippingOffers
        }
    }

    addToLocalCart(offer: Offer, qty = 1): Observable<any> {
        this.storeService.retrieveCart()
            .subscribe(cart => {
                if (!cart.cartId) { // if the current cart does not have an id, request for one and register it
                    this.getAgentCartId()
                        .subscribe(id => {
                            this.store.dispatch(new SetCartId(id));
                        });
                }
            })

        const cartItem = this.buildCartItem(offer, qty);
        return this.addItemToStore(cartItem);
    }

    addItemToCart(userId, offerId, price, shipTo, shippingOfferId, qty): Observable<CartItem> {
        return this.httpClient.post<CartItem>(environment.apiUrl + '/user/' + userId + '/cart', {
            offerId: offerId,
            price: price,
            qty: qty,
            shipTo: shipTo,
            shippingOfferId: shippingOfferId
        });
    }

    updateItemToCart(userId, offerId, price, shipTo, shippingOfferId, qty, itemId) {
        return this.httpClient.put(environment.apiUrl + '/user/' + userId + '/cart/' + itemId, {
            offerId: offerId,
            price: price,
            qty: qty,
            shipTo: shipTo,
            itemId: itemId,
            shippingOfferId: shippingOfferId
        });
    }

    removeItemFromCart(userId, itemId) {
        return this.httpClient.delete(environment.apiUrl + '/user/' + userId + '/cart/' + itemId);
    }

    removeAllItemsFromCart(userId) {
        return this.httpClient.delete(environment.apiUrl + '/user/' + userId + '/cart');
    }

    removeAgentCartId() {
        localStorage.removeItem(AppConstants.USER_AGENT_TOKEN);
    }

    getAgentCartId(): Observable<string> {
        const agentToken = localStorage.getItem(AppConstants.USER_AGENT_TOKEN);
        if (agentToken) {
            return of(agentToken);
        } else { // if there is no user token, then request for one and return it
            return this.httpClient.get<any>(environment.apiUrl + '/common/get_cart_identifier')
                .pipe(map(c => {
                    localStorage.setItem(AppConstants.USER_AGENT_TOKEN, c.code);
                    return c.code;
                }));
        }
    }



    getProducts(): Observable<ProductModel[]> {
        return this.store.select((state => state.cart.products));
    }

    getOrderId(): Observable<number> {
        return this.store.select((state => state.cart.orderId));
    }

    getCart(): Observable<CartStateModel> {
        return this.store.select((state => state.cart));
    }

    getClientCartId(): Observable<string> {
        return this.store.select((state => state.cart.cartId));
    }

    updateOrderId(orderId: number) {
        return this.store.dispatch(new UpdateOrderId(orderId));
    }

    addProduct(offer: Offer) {
        return this.store.dispatch(new IncrementQty(
            offer.id,
            {
                lineId: 0,
                id: offer.id,
                name: offer.product.name,
                qty: 1,
                price: getPrice(offer),
                slug: offer.product.slug,
                measurementUnit: offer.measurementunit.description,
                currency: offer.currency,
                imagePath: this.getImagePath(offer.product),
                shippingOffers: offer.shippingOffers,
                shippingOfferId: 0
            })
        );
    }


    public addItemToStore(cartItem: CartItem) {
        return this.store.dispatch(new IncrementQty(
            cartItem.offerId, {
            lineId: cartItem.itemId,
            id: cartItem.offerId,
            name: cartItem.productName,
            qty: cartItem.qty,
            price: cartItem.price,
            measurementUnit: cartItem.measurementUnit,
            slug: cartItem.productName,
            currency: cartItem.currency,
            imagePath: cartItem.picture,
            shippingOffers: cartItem.shippingOffers,
            shippingOfferId: cartItem.shippingOfferId
        }
        ));
    }

    incrementQty(offer: Offer) {
        const item = this.buildCartItem(offer, 1);
        return this.store.dispatch(new IncrementQty(offer.id, item));
    }

    decrementQty(id) {
        return this.store.dispatch(new DecrementQty(id));
    }

    removeProduct(id) {
        return this.store.dispatch(new Remove(id)).subscribe();
    }

    clearCart() {
        return this.store.dispatch(new ClearTheCart());
    }

    changeShippingOffer(id: number, shippingOfferId: number) {
        return this.store.dispatch(new UpdateShippingOffer(id, shippingOfferId));
    }

    getProduct(cart: CartStateModel, offerId: number) {
        let productFound = null;
        cart.products.forEach((product) => {
            if (offerId === product.id) {
                productFound = product;
            }
        });
        return productFound;
    }

    updateOrder(observable: Observable<any>, user: User, offer: Offer) {
        return observable.mergeMap((res: any) => {
            if (user !== null && res.cart.orderId !== undefined && res.cart.orderId !== 0) {
                return this.orderService.addOrUpdateLineOffer(user.id,
                    res.cart.orderId, offer.id, this.getProduct(res.cart, offer.id));
            } else {
                return null;
            }
        });
    }

    private getImagePath(product) {
        return product.picture !== '' && product.picture !== null && product.picture.includes('media') ?
            product.picture : '/assets/images/default.jpg';
    }

}
