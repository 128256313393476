<div class="col-md-12" *ngIf="currentUser">
  <div class="block-title"> {{'rightnav.my_account' | translate}} </div>
  <div class="block-content">
    <ul>
      <li>
        <a [routerLink]="['/user/dashboard']">
          <span>{{'sidebar.dashboard' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id + '/orders']">
          <span>{{'sidebar.my_orders' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id + '/edit']">
          <span>{{'sidebar.edit_profile' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id  + '/upload-profile-picture']">
          <span>{{'sidebar.change_pfp' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id + '/change-password']">
          <span>{{'sidebar.change_password' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/shipping-addresses']">
          <span>{{'sidebar.my_addresses' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id + '/wishlist']">
          <span>{{'sidebar.my_wishlist' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/user/' + currentUser.id + '/products']">
          <span>{{'sidebar.products_list' | translate}}</span>
        </a>
      </li>
      <li>
        <a title="log out" (click)="logout()">
          <span>{{'rightnav.sign_out' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
