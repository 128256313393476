import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {User} from '../../_models/user';
import {UserService} from '../../_services/user.service';
import {PageTitleService} from '../../_services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit, OnDestroy {
    title = 'Dashboard';
    titleKey = "sidebar.dashboard"
    loading = true;
    isAdmin = false;
    isLoggedIn = false;
    user: User;

    private subscriptions: Subscription[] = [];

    constructor(public titleService: Title,
                private pageTitleService: PageTitleService,
                private userService: UserService, private router: Router,
                private translateService: TranslateService) {
        this.isLoggedIn = this.userService.isLoggedIn();
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (this.user) {
            this.isAdmin = this.userService.isAdmin();
            if (!this.isAdmin) {
                this.user = JSON.parse(localStorage.getItem('currentUser'));
                this.isAdmin = this.userService.isAdmin();
                this.pageTitleService.setPageTitle(this.title);
                titleService.setTitle(this.title);

                const subs = this.translateService.get(this.titleKey).subscribe(value => {
                    this.titleService.setTitle(value);
                });

                this.subscriptions.push(subs);
            }
        } else {
            this.userService.logout();
        }
    }
    ngOnInit() {
        if (this.isAdmin) {
            this.router.navigateByUrl('admin/dashboard');
        }
        this.loading = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
