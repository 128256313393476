<div class="recent-orders">
    <div class="title-buttons">
        <strong>Products</strong>
    </div>
    <br/>
    <br/>
    <div class="table-responsive">
        <form [formGroup]="searchForm" (ngSubmit)="searchForm.valid && search()">
            <input type="text" placeholder="Search for products" formControlName="searchTerm" class="input-text"/>
            <span class="ml-2"></span>
            <button class="button button-md" id="btn-search">Search</button>
        </form>
        <table class="table table-striped products-table" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th>Picture</th>
                <th>Name (En)</th>
                <th>Name (Fr)</th>
                <th>Category</th>
                <th>
                    <button (click)="addProduct()"
                            class="button button-submit button-md"> Add
                    </button>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading == true; then dataLoading else dataLoaded ">
            </tbody>
            <ng-template #dataLoading>
                <tr>
                    <td colspan="4" style="text-align: center">Loading ...</td>
                </tr>
            </ng-template>
            <ng-template #dataLoaded>
                <tr *ngFor="let product of products | paginate: config ">
                    <td>
                        <img [src]="printImage(product)" [attr.id]="'img-'+product.id"
                             style="width: 45px; height: 45px; border: 1px solid #ccc;"/>
                    </td>
                    <td>{{ product.name }}</td>
                    <td>{{ product.productNameFr }}</td>
                    <td>{{ product.category.name }}</td>
                    <td>
                         <span class="nobr">
                             <a (click)="editProduct(product)">Edit</a>
                             <span class="separator"> | </span>
                             <a (click)="deleteProduct(product)">Delete</a>
                         </span>
                    </td>
                </tr>

            </ng-template>
        </table>
    </div>
    <div class="toolbar" *ngIf="!loading">
        <div class="pager">
            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next"
                                 autoHide="true" id="listing" (pageChange)="pageChange($event)">

            </pagination-controls>
        </div>
    </div>
</div>
