import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {OfferService} from '../../_services/offer.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {Offer} from '../../_models/offer';

@Component({
    selector: 'app-view-offer',
    templateUrl: './view-offer.component.html',
    styleUrls: ['./view-offer.component.css']
})
export class ViewOfferComponent implements OnInit {

    offer: any;
    loading = false;
    offerId: string;
    status = 0;
    submitting = false;

    constructor(private router: Router,
                private browserTitle: Title,
                private offerService: OfferService,
                private toastr: ToastrService,
                private route: ActivatedRoute) {
        this.offerId = this.route.snapshot.paramMap.get('offerId');
    }

    ngOnInit() {
        this.getOffer(this.offerId);
    }

    getOffer(offerId) {
        this.loading = true;
        this.offerService.loadOffer(offerId).subscribe(
            (offer: any) => {
                this.offer = offer;
                this.browserTitle.setTitle(offer.product.name);
                this.loading = false;
                this.status = offer.status;
            }
        );
    }


    changeStatus(offer: any, status) {
        this.submitting = true;
        this.offerService.approveOrDisapprove(offer.id, status).subscribe(
            (res: any) => {
                this.toastr.success(res.message);
                this.status = status;
                this.submitting = false;
            },
        (err: any) => {
            this.toastr.success(err.message);
                this.submitting = false;
            }
        );
    }

    getImageSrc(imagePath) {
        return imagePath !== '' && imagePath !== null ? environment.apiUrl + '/' + imagePath : '/assets/images/default.jpg';
    }

    editOffer(offer: Offer) {
        this.router.navigateByUrl(`admin/offers/${offer.id}/edit`);
    }
}
