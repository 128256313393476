import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../_services/user.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            if (this.userService.isAdmin() ) {
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        this.userService.isLoggedIn() ? this.router.navigate(['/user/dashboard']) : this.backToLogin(state);
        return false;
    }

    private  backToLogin (state) {
        this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url } });
    }

}
