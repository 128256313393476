<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            {{ title | translate}}
        </div>
        <div class="card-body">
            <form [formGroup]="productCategoryForm" (ngSubmit)="submitForm()" novalidate>
                <input type="hidden" formControlName="id">
                <div class="form-group">
                    <label>{{'categories.french_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="name_fr" class="form-control">
                    <em *ngIf="(f.name_fr.touched && f.name_fr.invalid)" class="text-danger">
                        {{'user_profile.name_required' | translate}}
                    </em>
                </div>
                <div class="form-group mt-3">
                    <label>{{'categories.english_name' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="name" class="form-control">
                    <em *ngIf="(f.name.touched && f.name.invalid)" class="text-danger">
                        {{'user_profile.name_required' | translate}}
                    </em>
                </div>
                <div class="form-group mt-3">
                    <label>{{'categories.parent' | translate}}</label>
                    <select formControlName="parent" class="form-control">
                        <option value="0"></option>
                        <option *ngFor="let p of product_categories" [value]="p.id">{{  p.name }}</option>
                    </select>
                </div>
                <div class="form-group mt-3">
                    <label>{{'categories.type' | translate}} <span class="require-star">*</span></label>
                    <input type="text" formControlName="type" class="form-control">
                    <em *ngIf="(f.type.touched && f.type.invalid)" class="text-danger">
                        {{'categories.type_required' | translate}}
                    </em>
                </div>
                <div class="form-group mt-3">
                    <label>Picture <span *ngIf="this.productCategory.id == undefined "  class="required">*</span> </label><br>
                    <input type="file" formControlName="picture" (change)="onChangeFile($event)" />
                </div>
                <div class="buttons-set mt-3">
                    <button class="btn btn-success me-2" type="submit" id="btn-submit"
                            [disabled]="!productCategoryForm.dirty || productCategoryForm.invalid ">
                        <span>{{ (submitted ? 'common.wait' : 'common.submit') | translate }}</span> <span *ngIf="submitted"> ...</span>
                    </button>
                    
                    <a class="button button-back" (click)="back_to_previous()">
                        <span>{{'common.back' | translate}}</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>
