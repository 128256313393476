import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogPost } from 'src/app/_models/blog-post';
import { BlogService } from 'src/app/_services/blog.service';

@Component({
  selector: 'app-blog-post-list',
  templateUrl: './blog-post-list.component.html',
  styleUrls: ['./blog-post-list.component.css']
})
export class BlogPostListComponent implements OnInit{
  posts: BlogPost[] = [];
  loading = true;
  page: any = 1;
  config: any;

  constructor(private blogService: BlogService, private router: Router, private route: ActivatedRoute){
    this.page = this.route.snapshot.queryParamMap.get('page') || 1;
  }

  ngOnInit(): void {
    this.loadPosts();
  }

  loadPosts() {
    this.blogService.loadBlog(this.page)
      .subscribe(
        (response) => {
          this.page = response.page;
          this.posts = response.data;
          this.config = {
            currentPage: response.page,
            itemsPerPage: response.itemPerPage,
            totalItems: response.totalOfItems,
            totalOfPage: response.totalOfPage,
            id: 'blogpost'
          };
          this.loading = false;
        }
      );
  }

  pageChange(page) {
    this.page = page;
    this.router.navigate(['/admin', 'blog-posts'], { queryParams: { page: page } });
    this.loading = true;
    this.config.currentPage = this.page;
    this.loadPosts();
  }
}
