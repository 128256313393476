import { Component, OnInit } from '@angular/core';
import {OfferService} from '../_services/offer.service';
import {ActivatedRoute} from '@angular/router';
import {User} from '../_models/user';
import {IPagination} from '../_models/pagination';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

  sales: any[];
  page = 1;
  config: any;
  user: User;
  offer_id: number;
  loading = true;

  constructor(private offerService: OfferService, private activatedRoute: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.offer_id = parseInt(this.activatedRoute.snapshot.paramMap.get('productId'), 0);
  }

  ngOnInit() {
    this.getSales();
  }

  getSales() {
    this.offerService.getSales(this.user.id, this.offer_id, this.page).subscribe(
        (res: IPagination) => {
          this.sales = res.data;
        },
        null,
        () => this.loading = false
    );
  }


}
