export enum AppConstants {
  CURRENT_LANG_STORE_KEY = 'CURRENT_LANG',
  PRICING_KEY = 'PRICING',
  SECTORS_KEY = 'SECTORS',
  MEASUREMENT_UNITS_KEY = 'MEASUREMENT_UNITS',
  PRODUCT_CONDITION_KEY = 'PRODUCT_CONDITION',
  USER_AGENT_TOKEN = 'USER_AGENT_ID',
  FRENCH = 'fr',
  ENGLISH = 'en',
  CURRENT_USER_KEY = 'currentUser',
  CURRENT_OUTBOX_TEMPLATE_KEY = 'CURRENT_OUTBOX_TEMPLATE',
  CLIENT_APP = 'EWeb'
}
