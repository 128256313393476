import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { PricingService } from '../_services/pricing.service';
import { API_URLS } from '../config/api.urls';
import { IPagination } from '../_models/pagination';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import { CartService } from '../_services/cart.service';
import { environment } from '../../environments/environment';
import { StoreService } from '../_services/store.service';
import { User } from '../_models/user';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../_constants/constants';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css']
})
export class HeaderNewComponent implements OnInit {
  pricing: any[];

  products: Observable<ProductModel[]>;

  total = 0;

  user: User = null;

  nbrOfTypeOfProductsInCart = 0;

  appCurrentLang: string;

  availableLangs: string[] = [AppConstants.FRENCH, AppConstants.ENGLISH];
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  searchForm: UntypedFormGroup;

  constructor(public userService: UserService,
    private router: Router,
    private cartService: CartService,
    private storeService: StoreService,
    private pricingService: PricingService,
    private translationService: TranslateService, private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute,
    private translateService: TranslateService) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.nbrOfTypeOfProductsInCart = this.storeService.productModels.length;
    this.appCurrentLang = this.translationService.currentLang;
    this.searchForm = this.fb.group({
      q: new UntypedFormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.products = this.cartService.getProducts();
    this.getPricing();
  }

  getPricing() {
    return this.pricingService.get_pricings().subscribe(
      (result: IPagination) => {
        this.pricing = result.data;
      }
    );
  }

  getImageSrc(imagePath: string) {
    return imagePath !== '' && imagePath !== null && imagePath.includes('media') ?
      environment.image_cdn_url + '/' + imagePath + '?func=crop&width=45&height=45' : '/assets/images/default.jpg';
  }

  nbOfProducts(products: any) {
    this.total = 0;
    for (let i = 0; i < products.length; i++) {
      this.total += products[i].qty;
    }
    return this.total;
  }

  removeProductIntoCat(product: ProductModel) {
    this.cartService.removeItemFromCart(this.user.id, product.lineId).subscribe();
    this.storeService.removeItemFromStore(product.id);
  }

  changeLanguage(newLanguage: string) {
    // use the new languages
    if (this.availableLangs.includes(newLanguage)) {
      this.translationService.use(newLanguage)
        .subscribe(response => {
          this.appCurrentLang = newLanguage;

          // persist the new language
          localStorage.setItem(AppConstants.CURRENT_LANG_STORE_KEY, this.appCurrentLang);
          // refresh the page for complete translation
          location.reload();
        });
    } else { throw new Error('Unknown Language'); }
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }
    this.goToSearchUrl(this.searchForm.value.get('q'));
  }

  getInput(event: any) {
    if (event.keyCode === 13) {
      this.goToSearchUrl(event.target.value);
    }
  }

  search(){
    this.goToSearchUrl(this.searchForm.value.q);
  }

  goToSearchUrl(q) {
    if (q != null && q !== '') {
      this.router.navigate(['search'], { queryParams: { q: q } });
    }
  }

  showMobileSearch() {
    var x = document.getElementById('mobileSearch');
    if (x.style.display == 'none') {
      // x.animate({ transform: 'translateY(0)' }, { duration: 500, fill: 'forwards' })

      x.style.display = 'block';
      window.setTimeout(() => {
        x.style.opacity = "1";
        x.style.transform = 'translateY(0)';
        // x.style.duration = "500";
        x.style.fill = 'forwards';
      }, 0)
    } else {
      x.style.display = 'none';
      x.style.opacity = "0";
      x.style.transform = 'translateY(-20px)';
    }
  }


}
