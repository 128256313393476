import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppConstants } from 'src/app/_constants/constants';
import { Sector } from 'src/app/_models/sector';
import { SectorService } from 'src/app/_services/sector.service';

@Component({
    selector: 'app-sectors',
    templateUrl: './sectors.component.html',
    styleUrls: ['./sectors.component.css']
})
export class SectorsComponent implements OnInit, OnDestroy {

    sectors: Sector[];
    loading: boolean;

    constructor(private sectorService: SectorService,
        private router: Router, private route: ActivatedRoute, private titleService: Title) {
    }

    ngOnInit() {
        this.load_sectors();
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('');
    }

    load_sectors() {
        this.loading = true;
        this.sectorService.getAll()
            .subscribe(
                (result) => {
                    this.sectors = result;
                    this.loading = false;
                }
            );
    }

    add_sector() {
        window.localStorage.removeItem(AppConstants.SECTORS_KEY);
        this.router.navigate(['admin/sectors/add']);
    }

    edit_sector(sector: Sector) {
        window.localStorage.removeItem(AppConstants.SECTORS_KEY);
        window.localStorage.setItem(AppConstants.SECTORS_KEY, JSON.stringify(sector));
        this.router.navigate(['admin/sectors', sector.id, 'edit']);
    }

    delete_sector(sector: Sector) {
        this.sectorService.delete(sector)
        .subscribe(() => {
            this.sectors = this.sectors.filter(sc => sc.id !== sector.id);
                this.router.navigate(['/admin/sectors']);
                this.load_sectors();
        });
    }
}
