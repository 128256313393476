<div class="container-fluid  text-center mt-5" style="height: 400px;">
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="page-title">
        <h3>{{ titleTranslateKey | translate }}</h3>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-lg-6  col-md-6 mx-auto">
      <p>
        {{ 'common.account_successfully_created' | translate}}
      </p>
    </div>
  </div>
</div>

