<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="page-title">
                    <h2>{{'sidebar.products_list' | translate}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9" *ngIf="loading" style="text-align: center;">{{'common.loading' | translate}}  ...</div>
            <div class="col-md-9" *ngIf="!loading">
                <div class="recent-orders">
                    <br/>
                    <br/>
                    <div class="table-responsive">
                        <table class="table table-striped" id="my-offers">
                            <thead>
                            <tr class="first last">
                                <th>{{'product.product' | translate}}</th>
                                <th>{{'common.quantity' | translate}}</th>
                                <th>{{'common.unit_price' | translate}}</th>
                                <th>{{'common.status' | translate}}</th>
                                <th>{{'common.created_at' | translate}}</th>
                                <th>
                                    <button (click)="newOffer()" class="btn btn-success btn-xs">{{'common.sell_a_product' | translate}}</button>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let offer of data | paginate: config" id="offer-{{ offer.id }}">
                                <td>{{  currentLanguage == 'fr'? offer.product.productNameFr : offer.product.productName }}</td>
                                <td>{{ offer.qty }}</td>
                                <td>{{ offer.price }} {{ offer.currency | uppercase }}</td>
                                <td> {{ getStatus(offer.status) | translate}}</td>
                                <td> {{ offer.creationDate }}</td>
                                <td>
                                    <a class="view" (click)="viewOffer(offer)">{{'common.view' | translate}}</a>
                                    |
                                    <a class="view" [routerLink]="[this.offer.id , 'sales']">{{'common.sales' | translate}}</a>
                                    |
                                    <a mwlConfirmationPopover [popoverTitle]="'Confirmation'"
                                        [popoverMessage]="'common.confirm_delete' | translate"
                                        placement="top" 
                                        (confirm)="deleteOffer(offer)"
                                        >
                                        {{'common.delete' | translate}}
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="toolbar" *ngIf="!loading">
                        <div class="pager">
                            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next"
                                                 autoHide="true" id="products" (pageChange)="pageChange($event)">

                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <app-sidebar [currentUser]="user"></app-sidebar>
            </div>
        </div>
    </div>
</div>

