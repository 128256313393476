import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-shipping-offer-form',
  templateUrl: './shipping-offer-form.component.html',
  styleUrls: ['./shipping-offer-form.component.css']
})
export class ShippingOfferFormComponent implements OnInit {

  @Input() shippingTypes;

  @Input() offer;

  @Input() currentShippingOffer;

  @Input() formShippingOpened;

  @Output() changeStatus = new EventEmitter<boolean>();

  @Output() shippingOffer = new EventEmitter<any>();

  shippingOfferForm: UntypedFormGroup;

  submitted = false;

  days = [];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.setDays();
    this.shippingOfferForm = this.fb.group({
      id: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.id : ''),
      shippingMean: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.shippingMean : '', Validators.required),
      shippingCost: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.shippingCost : '', Validators.required),
      scur: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.scur : '', Validators.required),
      eta: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.eta : '', Validators.required),
      packagingDetails: new UntypedFormControl(this.currentShippingOffer ? this.currentShippingOffer.packagingDetails : '', Validators.required),
    });
  }

  closeShippingOfferForm() {
    this.changeStatus.emit(!this.formShippingOpened);
  }

  onSubmit() {
    if (this.shippingOfferForm.invalid) {
      return;
    }
    this.submitted = true;
    this.shippingOffer.emit(this.shippingOfferForm.value);
  }

  compareValues(currentVal, optionVal) {
    return currentVal.id === optionVal.id;
  }

  setDays() {
    for (let i = 1; i <= 30; i++) {
      this.days.push(i);
    }
  }

  get f() {
    return this.shippingOfferForm.controls;
  }

}
