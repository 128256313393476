import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Offer, getPrice } from 'src/app/_models/offer';
import { Order } from 'src/app/_models/order';
import { OrderService } from 'src/app/_services/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  order: Order;
  orderNumber: string;
  loading = true;
  totalOrder: number = 0;
  lineOffers: any[] = [];
  sending = false;
  senderModalRef: NgbModalRef;
  seller: any;
  

  constructor(private orderService : OrderService, private router: Router, private modalService: NgbModal,
    private activatedRoute: ActivatedRoute, private toast: ToastrService) {
      this.orderNumber = this.activatedRoute.snapshot.paramMap.get('ref_num') || null;
  }

  ngOnInit(): void {
      if(this.orderNumber == null) {
        this.toast.error("Order number can not ben null");
        this.router.navigateByUrl('admin/orders');
        return;
      }

      this.orderService.loadSingleOrderByAdmin(this.orderNumber).subscribe(
        (order) => {
          this.order = order;
          this.lineOffers = order.lineOffers;
          this.lineOffers.forEach((lineOffer: any) => this.totalOrder += this.calculateTotal(lineOffer));
        },
        (error) => this.toast.error(error),
        () => this.loading = false
      );
  }

  calculateTotal(lineOffer: any ) {
    let total: number;
    if (lineOffer.shippingOffer == null) {
      total = lineOffer.qty * this.getOfferPrice(lineOffer.offerDTO);
    } else {
      total = lineOffer.qty * (this.getOfferPrice(lineOffer.offerDTO) + lineOffer.shippingOffer.shippingCost);
    }
    return total;
  }

  getOfferPrice(offer: Offer): number {
    if(offer.percentDiscount == 0){
      return offer.price;
    }

    return getPrice(offer);
  }

  get_payment_method_label(key: string) {
    if(key == 'CashOnDelivery') {
      return 'Cash On Delivery';
    } else if(key == 'CompanyMobileMoney') {
      return 'eFarm.CM Mobile Money';
    } else {
      return 'Flutterwave';
    }
  }

  sendConfirmationMail(){
    this.sending = true;
    this.orderService.sendConfirmationMail(this.orderNumber).subscribe(
      (response: any) => {
        this.toast.success(response.message);
      },
      (error) => {
        this.toast.error(error);
      },
      () => this.sending = false
    )
  }

  viewSellerDetails(offerDto: any, content){
    this.seller = offerDto.seller;
    this.senderModalRef = this.modalService.open(content, { scrollable: true });
  }

  closeModal(){
    this.senderModalRef.close()
  }

}
