import { Injectable } from '@angular/core';
import { StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductModel } from '../_cart/product-model';
import { CartItem } from '../_models/cart-item';
import {CartAction} from '../_cart/cart.actions';
import Remove = CartAction.Remove;
import IncrementQty = CartAction.IncrementQty;
import DecrementQty = CartAction.DecrementQty;
import ClearTheCart = CartAction.ClearTheCart;
import UpdateShippingOffer = CartAction.UpdateShippingOffer;
import { CartStateModel } from '../_cart/cart.state';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    public  productModels: ProductModel[] = [];

    public constructor(private store: Store) {
        this.findAll().subscribe(
            (productModels: ProductModel[]) => this.productModels = productModels
        );
    }

    public findAll(): Observable<ProductModel[]> {
        return this.store.select((state => state.cart.products));
    }

    public retrieveCart(): Observable<CartStateModel> {
        return this.store.select((state)=> state.cart);
    }

    public addItemToStore(cartItem: CartItem) {
        return this.incremetItemQty(cartItem);
    }

    public findItem(offerId): ProductModel {
        return this.productModels.find(p => p.id === parseInt(offerId, 0));
    }

    public UpdateItemToStore(cartItem: CartItem, action: string) {
        if (action.match('increment')) {
            return this.addItemToStore(cartItem);
        } else {
            return this.decrementItemQty(cartItem.offerId);
        }
    }

    public removeItemFromStore(offerId) {
        return this.store.dispatch(new Remove(offerId));
    }

    public clearStore() {
        return this.store.dispatch(new ClearTheCart());
    }

    changeShippingOffer(offerId: number, shippingOfferId: number) {
        return this.store.dispatch(new UpdateShippingOffer(offerId, shippingOfferId));
    }

    private incremetItemQty(cartItem: CartItem) {
        return this.store.dispatch(new IncrementQty (
            cartItem.offerId, {
                lineId: cartItem.itemId,
                id: cartItem.offerId,
                name: cartItem.productName,
                qty: cartItem.qty,
                price: cartItem.price,
                measurementUnit: cartItem.measurementUnit,
                slug: cartItem.productName,
                currency: cartItem.currency,
                imagePath: cartItem.picture,
                shippingOffers: [],
                shippingOfferId: cartItem.shippingOfferId
            }
        ));
    }

    private decrementItemQty(offerId) {
        return this.store.dispatch(new DecrementQty(offerId));
    }
}
