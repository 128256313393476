<div class="recent-orders">
    <br/>
    <div class="table-responsive">
        <div class="col-md-3">
            <img [src]="getImageSrc(offer.product.picture)" [title]="offer.product.name" class="img-responsive" />
        </div>
        <div class="col-md-9">
            <table class="data-table table-striped products-table">
                <tbody>
                <tr>
                    <th>Product name :</th>
                    <td> {{ offer.product.name }}</td>
                </tr>
                <tr>
                    <th>Posted by :</th>
                    <td> {{ offer.user.name }}</td>
                </tr>
                <tr>
                    <th>Posted at :</th>
                    <td> {{ offer.creationDate }}</td>
                </tr>
                <tr>
                    <th>Quantity :</th>
                    <td> {{ offer.qty }} </td>
                </tr>
                <tr>
                    <th>Price :</th>
                    <td> {{ offer.price }} {{ offer.currency | uppercase }}
                        / {{ offer.measurementunit.description }}</td>
                </tr>
                <tr>
                    <th>Product condition :</th>
                    <td>{{ offer.productCondition.description }}</td>
                </tr>

                <tr>
                    <th>Gallery :</th>
                    <td>{{ offer.mediaList.length > 0 ? '' : 'No media found' }}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button *ngIf="status != 2" (click)="changeStatus(offer, 2)" [disabled]="submitting"  class="button button-cancel" id="disapproved-link">Disapprove</button>
                        &nbsp;
                        <button *ngIf="status != 1" (click)="changeStatus(offer, 1)" [disabled]="submitting"  class="button link" id="approved-link">Approve</button>
                        &nbsp;
                        <button (click)="editOffer(offer)" class="button link" >Edit Offer</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
