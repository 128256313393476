<div class="container-fluid">
  <div class="row">
    <!-- <div class="sidebar col-md-3 blog-side">
      &nbsp;
    </div> -->
    <div class="col-md-12 main-blog">
      <div id="main" class="blog-wrapper">
        <div id="primary" class="site-content">
          <div id="content" role="main">
            <p *ngIf="loading else blogPost" style="text-align: center; padding: 10px 0;">
              <label>loading ...</label>
            </p>
            <ng-template #blogPost>
              <article class="post clearfix" itemscope itemtype="https://schema.org/BlogPosting" itemid="{{ 'https://efarm.cm/blog/' + blog.slug + '/' + blog.id  }}">
                <meta itemprop="wordCount" content="{{ blog.body.length }}" />
                <header class="clearfix">
                  <h1 class="post-title mt-3" itemprop="name">
                    {{ blog.title }}
                  </h1>
                  <div class="post-meta">
                    <span>
                      {{ blog.postedAt }}
                    </span>
                    <span>
                      <i class="fa fa-comments"></i> <span style="margin-left: 8px;" itemprop="commentCount">{{ config.totalItems }}</span> comments
                    </span>
                    <span>
                      <span style="visibility: hidden;" itemprop="sdDatePublished"> {{ convertPostedDateToISODate(blog.postedAt) }} </span>
                      <span style="visibility: hidden;" itemprop="sdPublisher"> {{ blog.name }} </span>
                    </span>
                  </div>
                  <div class="post-thumbnail">
                    <img width="1200"  class="me-3 mb-3" *ngIf="hasImagePath(blog)" [src]="getImageSrc(blog.imagePath)  + '?func=cropfit&width=1200&dpr=1.75&bg_colour=auto&trim=0&quality=100'" [alt]="blog.title" />
                  </div>
                </header>
                <div class="post-body" itemprop="articleBody" [innerHTML]="blog.body"></div>
                <p *ngIf="loadingComments else blockComment" style="text-align: center; padding: 10px 0;">
                  <label>loading ...</label>
                </p>
                <ng-template #blockComment>
                  <div class="comment-content wow bounceInUp animated mb-5" style="visibility: visible;">
                    <div *ngIf="config.totalItems > 0" class="comments-wrapper">
                      <h3>Comments</h3>
                      <ol class="commentlist">
                        <li *ngFor="let comment of comments | paginate: config" class="comment">
                          <div>
                            <img src="/assets/images/member1.png" class="avatar avatar-60 photo" />
                            <div class="comment-text">
                              <p class="meta">
                                <strong>{{ comment.name }}</strong>
                                <span> - </span>
                                <time>{{ comment.commentedAt }}</time>
                              </p>
                              <div class="description">
                                <p [innerHTML]="comment.body"></p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                    <div class="comments-form-wrapper clearfix">
                      <h3>Leave a reply</h3>
                      <form class="comment-form" [formGroup]="form" (ngSubmit)="saveComment()">
                        <input type="hidden" formControlName="id" />
                        <input type="hidden" formControlName="userId" />
                        <input type="hidden" formControlName="name" />
                        <div class="row">
                          <div class="col-md-12">
                            <label for="email">
                              Email <em class="required">*</em>
                            </label>
                            <input type="email" formControlName="email" class="form-control validate-email" id="email" autocomplete="off" />
                          </div>
                          <div class="col-md-12 mt-2 aw-blog-comment=aera">
                            <label for="commnt">
                              Comment <em class="required">*</em>
                            </label>
                            <textarea formControlName="body" cols="5" rows="5" class="form-control" id="comment"></textarea>
                          </div>
                          <div class="col-md-12 mt-2" >
                            <button style="width: 20%;" [disabled]="form.invalid || submitting" *ngIf="user != null else loginBtn" class="btn btn-success btn-md" type="submit">
                              <span>
                                <span>Add comment</span>
                              </span>
                            </button>
                            &nbsp;
                            <span *ngIf="submitting">Submitting ...</span>
                            <ng-template #loginBtn>
                              <a class="button btn-login-reply" [href]="'/#/signin?returnUrl=' + currentUrl ">Log In and Reply</a>
                            </ng-template>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </article>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
