<div class="container-fluid mb-4 recommended">
    <div class="row">
      <div class="col">
        <div class="bbb_main_container">
          <div class="bbb_viewed_title_container d-flex align-items-center justify-content-between">
            <h3 class="bbb_viewed_title">{{title}}</h3>
            <div class="d-none d-lg-block">
              <a [routerLink]="['/products', 'taxonomy', taxonomyId]"  class="text-white view-more">
                {{ "common.view_all" | translate }} <i class="fa fa-angle-right"></i>
              </a>
            </div>
            <div class="d-sm-block d-md-block d-lg-none d-none">
                <a [routerLink]="['/products', 'taxonomy', taxonomyId]" class="btn btn-default text-white btn-sm" >
                    {{ "common.view_all" | translate }} <i class="fa fa-angle-right"></i>
                </a>
            </div>
            <div class="d-lg-none d-sm-none d-block">
                <button class="btn btn-default p-2 text-white btn-sm">
                    <i class="fa fa-angle-right"></i>
                </button>
            </div>
          </div>
          <div class="loader-box" style="background-color: #fff;" *ngIf="loadingState; else loadingDiv">
            <div class="lds-dual-ring"></div>
          </div>
  
          <ng-template #loadingDiv>
            <div class="bbb_viewed_slider_container" >
              <div class="row product_row">
                <div *ngFor="let offer of offers.slice(0,15)" class="col-lg-2 col-6 px-0 product_col">
                  <div
                    [routerLink]="['products',offer.product.name.replace(' ', '-'),offer.id]"
                    [attr.title]="offer.product.name"
                    class="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="stock_badge">
                      <div class="badge green"  *ngIf="offer.qty > 0; else outOfStock">
                        {{ "product.in_stock" | translate }}
                      </div>
                      <ng-template #outOfStock>
                        <div class="badge red">
                          {{ "product.out_of_stock" | translate }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="bbb_viewed_image">
                      <img
                        [routerLink]="['products',offer.product.name.replace(' ', '-'),offer.id]"
                        [attr.title]="offer.product.name"
                        [lazyLoad]="getImageSrc(offer)"
                        [defaultImage]="offer.defaultPicture"
                        [alt]="offer.product.name"
                      />
                    </div>
                    <div class="bbb_viewed_content">
                      <div class="text-start">
                          <a  [routerLink]="['products',offer.product.name.replace(' ', '-'),offer.id]"
                              [attr.title]="offer.product.name"
                              class="bbb_viewed_name prdt_name fs-6 fw-medium">
                              {{
                              currentLanguage == "fr" &&
                              offer.product.productNameFr != null &&
                              offer.product.productNameFr != ""
                                  ? offer.product.productNameFr
                                  : offer.product.name
                              }}
                          </a>
                      </div>
    
                      <div class="d-flex align-items-end justify-content-between">
                        <div class="text-start">
                          <div class="bbb_viewed_price text-primary float-start">
                            {{ getOfferPrice(offer) }}
                            {{ offer.currency | uppercase }}
                          </div>
                          <div>
                            <small class="old_price">
                              / &nbsp;{{
                                currentLanguage == "fr" &&
                                offer.measurementunit.descriptionFr != null &&
                                offer.measurementunit.descriptionFr != ""
                                  ? offer.measurementunit.descriptionFr
                                  : offer.measurementunit.description
                              }}</small>
                          </div>
                        </div>
                        <div class="align-bottom" *ngIf="offer?.percentDiscount > 0">
                          <div class="text-primary discount">
                            -{{ offer.percentDiscount }}%
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <ul class="product-links">
                      <li>
                          <a href="#" data-tip="Add to Wishlist">
                              <i class="fas fa-heart"></i>
                          </a>
                      </li>
                      <li>
                          <a (click)="addProductToCart(offer)" data-tip="Add to Cart">
                              <i class="fa fa-shopping-cart"></i>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  