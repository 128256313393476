<div class="container-fluid">
  <div class="page-heading text-center mb-5 mt-5">
    <div class="page-title">
      <h1 *ngIf="!loading">{{ currentLanguage == "en" ?  page.title : page.titleFr }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 product-list">
      <p style="text-align: center;" *ngIf="loading else pageBlock">
        <span class="loader fe-loader"></span>
      </p>
      <ng-template #pageBlock>
        <div class="row">
          <div class="col-md-3">
            <app-page-sidebar [language]="currentLanguage"></app-page-sidebar>
          </div>
          <div class="col-md-9 page-content" [innerHTML]="currentLanguage == 'en' ? page.content : page.contentFr"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
