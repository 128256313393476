<div class="col-lg-12">
  <div class="card panel-default">
    <div class="card-header">
      {{ title | translate}}
    </div>
    <div class="card-body">
      <p>
        <button (click)="compose()" class="button button-submit button-md">
          {{ 'crm.compose' | translate }}
        </button>
      </p>
      <br/>
      <table class="table table-striped table-users outbox-table" id="my-orders-table">
        <thead>
          <tr class="first last">
            <th class="number">No</th>
            <th class="subject">Subject</th>
            <th class="send-mode">Send mode</th>
            <th class="total">Total</th>
            <th class="status">Status</th>
            <th class="action">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of data; let i = index">
            <td class="number">{{ i +1 }}</td>
            <td class="subject">{{ entry.template.subject }}</td>
            <td class="send-mode">{{ entry.template.sendMode }}</td>
            <td class="total">{{ entry.userIds.length }}</td>
            <td class="status">{{ entry.status }}</td>
            <td class="action">
              <button class="button button-md" (click)="send(entry.id)" *ngIf="entry.status != 'Running' ">
                {{ sending ? 'Waiting ..' : 'Send' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
