<div class="container-fluid">
  <div class="row">
      <div class="col-md-12">
          <div class="page-title">
              <h5>{{ placeCommandKey | translate }}</h5>
          </div>
      </div>
      <!--col-xs-12-->
  </div>
  <section class="main-container col2-right-layout">
    <div class="main container">
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        <h2></h2>
                    </div>
                    <div class="card-body">
                      <form [formGroup]="commandForm" id="contactForm" (submit)="sendRequest()">
                        <div class="content">
                          <fieldset class="group-select">
                            <input type="hidden" formControlName="userId">
                            <input type="hidden" formControlName="productId" class="form-control" />
                            <div class="row mt-3">
                              <div class="col-md-12" id="search-for-product">
                                <label>{{'place_command.search' | translate}}</label>
                                <input type="text" placeholder="Banana, Orange, Pineapple .." id="productSearchTerm"
                                       class="form-control" formControlName="productName" (keyup)="clearSearchProduct()"/>
                                <em *ngIf="(f.productName.touched && f.productId.invalid)" class="text-danger">
                                  {{'place_command.product_required' | translate}}
                                </em>
                                <ul id="productsList">
                                  <li *ngFor="let prod of products" (click)="selectProduct(prod)">{{ prod.name }}</li>
                                </ul>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label>{{'common.quantity' | translate}}</label>
                                    <input type="number" formControlName="qty" class="form-control" min="0"
                                           [ngClass]="{ 'is-invalid': submitted && f.qty.errors }">
                              </div>
                              <div class="col-md-4">
                                <label>{{'product.price' | translate}}</label>
                                    <input type="number" formControlName="price" class="form-control" min="0"
                                           [ngClass]="{ 'is-invalid': submitted && f.price.errors }">
                              </div>
                              <div  class="col-md-4">
                                <label>{{'common.currency' | translate}}</label>
                                    <select formControlName="currency" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                                      <option *ngFor="let c of currencies" [value]="c">{{ c }}</option>
                                    </select>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <label>{{'product.image' | translate}}</label>
                                    <input type="file" formControlName="picture" class="form-control" (change)="fileSelect($event)"
                                           style="border: medium none; padding-left: 0; padding-top: 5px;">
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <label>{{'common.description' | translate}}</label>
                                <textarea formControlName="description"
                                          class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
                                </textarea>
                              </div>
                            </div>
                            <div class="col-md-12 mt-3">
                              <button type="submit" [disabled]="loading || submitted || commandForm.invalid"
                                      class="button login" [title]="placeCommandKey | translate"
                                      name="send" id="send2">
                                <span>{{'common.submit' |translate}}</span>
                              </button>
                              <span class="loader" *ngIf="submitted">{{'common.waiting' | translate}} ...</span>
                            </div>
                          </fieldset>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <app-sidebar [currentUser]="user"></app-sidebar>
            </div>
        </div>
        <!--row-->
    </div>
    <!--main container-->
</section>

</div>


