import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../_models/user';
import {OfferService} from '../../_services/offer.service';
import {ProductsConditionsService} from '../../_services/products-conditions.service';
import {MeasurementService} from '../../_services/measurement.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../_services/user.service';
import {PricingService} from '../../_services/pricing.service';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.css']
})
export class EditOfferComponent implements OnInit {

  offer: any;
  form: UntypedFormGroup;
  pricing: any;
  productConditions: any;
  measurementUnits: any;
  userId: string;
  offerId: string;
  loading = true;
  selectedPricing: any = '';
  currentDate = '';
  submitted = false;
  user: User;
  date  = new Date();

  constructor(
      private fb: UntypedFormBuilder,
              private offerService: OfferService,
              private productConditionService: ProductsConditionsService,
              private measurementService: MeasurementService,
              private state: ActivatedRoute,
              public userService: UserService,
              private router: Router,
              private pricingService: PricingService
  ) {

      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.offerId = this.state.snapshot.paramMap.get('offerId');
      this.userId = this.state.snapshot.paramMap.get('userId');
  }

  ngOnInit() {
    combineLatest(
        this.offerService.loadOffer(this.offerId),
        this.pricingService.get_pricings(),
        this.productConditionService.get_product_conditions(),
        this.measurementService.getAll()
    ).subscribe(
        (values: any) => {
          this.offer = values[0];
          this.userId = this.offer.user.id;
          this.pricing = values[1].data;
          this.productConditions = values[2];
          this.measurementUnits = values[3];
          this.selectedPricing = this.offer.pricing;
        },
        null,
        () => {
          this.loading = false;
          this.buildForm();
        }
    );
  }

  private buildForm() {
    this.form = this.fb.group({
      currency: [this.offer.currency, Validators.required],
      description: new UntypedFormControl(this.offer.description, Validators.required),
      measurementunit: new UntypedFormControl(this.offer.measurementunit, Validators.required),
      price: new UntypedFormControl(this.offer.price, Validators.required),
      pricing: new UntypedFormControl(this.offer.pricing, Validators.required),
      productCondition: new UntypedFormControl(this.offer.productCondition, Validators.required),
      qty: new UntypedFormControl(this.offer.qty, Validators.required),
      expirationDate: new UntypedFormControl(this.offer.expirationDate),
      percentDiscount: new UntypedFormControl(this.offer.percentDiscount),
      returns: new UntypedFormControl(this.offer.returns),
    });
  }

  get f() {
    return this.form.controls;
  }

  onChange() {
    this.selectedPricing = this.form.value.pricing;
  }

  compareValues(currentVal, optionVal) {
    return currentVal.id === optionVal.id;
  }

  onSubmit() {
    if (this.form.untouched) {
      this.backToOfferViewPage();
    }
    this.submitted = true;
    this.offerService.update(this.offerId, this.form.value).subscribe(
        () => {
          this.backToOfferViewPage();
        },
        () => {
          this.submitted = false;
        }
    );
  }

  private backToOfferViewPage() {
    this.router.navigateByUrl('admin/offers/' + this.offerId );
  }

}
