<section class="h-100" style="background-color: #eee;">
    <div class="container h-100 py-5">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-10">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3 class="fw-normal mb-0 text-black">
                    {{ pageTitleKey | translate }}
                </h3>
            </div>
            <div id="cart-items" @fadeIn>
                <div class="card rounded-3 mb-4" *ngFor="let product of products | async" @slide>
                    <div class="card-body p-4">
                        <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-md-2 col-lg-2 col-xl-2">
                                <a class="product-image" [title]="product.name"
                                    [routerLink]="['/products', (product.name.replace(' ', '') | lowercase), product.id]">
                                    <img [alt]="product.name" [src]="getImageSrc(product.imagePath)" width="75">
                                </a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-xl-3">
                                <p class="lead fw-normal mb-2">{{product.name}}</p>
                                <p>
                                    <span class="text-muted">Items: </span> {{ product.qty}}
                                </p>
                                <div class="mb-2">
                                    <form class="form-inline">
                                        <div class="form-group">
                                            <select name="shippingOffer" class="form-control" (change)="selectShippingOffer($event, product)">
                                                <option [value]="0">{{'product.local_pickup_zero_fee' | translate}}</option>
                                                <option *ngFor="let so of product.shippingOffers" [value]="so.id" [selected]="so.id == product.shippingOfferId" >{{getShippingOfferName(so) | async}}</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-5 col-xl-5">
                                <h5 class="mb-0">
                                    <span class="nobr">{{'common.unit_price' | translate}} </span> : {{ (product.price  | tocurrency :product.currency | async).toLocaleString() }} {{ displayCurrency | uppercase }} / {{ product.measurementUnit }}
                                </h5>
                            </div>
                            <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                                <a class="text-danger" [title]="'common.remove_item' | translate"
                                                (click)="removeProductIntoCat(product)">
                                                <i class="fas fa-trash fa-lg"></i>
                                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <h5>
                            <span>
                                Sub total :  
                                {{ (calculateThePrice(product) | tocurrency :product.currency | async).toLocaleString() }} {{ displayCurrency | uppercase }}
                            </span>
                        </h5>
                    </div>
                </div>
            </div>

            <div class="card" *ngIf="!isCartEmpty(products | async)">
                <div class="card-body">
                    <a id="btn-checkout" style="float: left;" [routerLink]="['/products', 'all']" class="btn btn-success btn-block btn-lg">
                        Back to shop
                    </a>
                    <a id="btn-checkout" style="float: right;" [title]="'cart.checkout' | translate" class="btn btn-success btn-block btn-lg"
                                               [routerLink]="['/checkout']">
                                                <span>{{'cart.checkout' | translate}}</span>
                                            </a>
                </div>
            </div>
            <div class="card" *ngIf="isCartEmpty(products | async)">
                <div class="card-body">
                    <div class="alert alert-warning">
                        {{'cart.is_empty' | translate}}
                    </div>
                </div>
            </div>
  
        </div>
      </div>
    </div>
  </section>
<!-- 
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="cart wow bounceInUp animated animated" style="visibility: visible;">

                <div class="table-responsive shopping-cart-tbl  container">
                    <form novalidate>
                        <input name="form_key" type="hidden" value="EPYwQxF6xoWcjLUr">
                        <fieldset>
                            <table class="table table-borderless" id="shopping-cart-table">
                                <thead>
                                <tr class="first last">
                                    <th rowspan="1">&nbsp;</th>
                                    <th rowspan="1">
                                        <span class="nobr">{{'product.name' | translate}}</span>
                                    </th>
                                    <th></th>
                                    <th class="a-center" colspan="1">
                                        <span class="nobr">{{'common.unit_price' | translate}}</span>
                                    </th>
                                    <th class="a-center" rowspan="1">Qty</th>
                                    <th>{{'orders_list.shipping_offer' | translate}}</th>
                                    <th class="a-center" colspan="1">{{'common.subtotal' | translate}}</th>
                                    <th class="a-center" rowspan="1">&nbsp;</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <ng-template #tfoot>
                                    <tr class="first last">
                                        <td class="a-right last" colspan="50">
                                            <button class="button btn-continue" id="empty_cart_button" (click)="clearCart()"
                                                    [title]="'cart.clear' | translate" type="submit" value="empty_cart">
                                                    <span>
                                                        <span>{{'cart.clear' | translate}}</span>
                                                    </span>
                                            </button>
                                            <a [title]="'cart.checkout' | translate" class="button btn-proceed-checkout"
                                               [routerLink]="['/checkout']">
                                                <span>{{'cart.checkout' | translate}}</span>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                                <tr *ngIf="isCartEmpty(products | async) else tfoot"></tr>
                                </tfoot>
                                <tbody @fadeIn>
                                <tr *ngFor="let product of products | async" @slide>
                                    <td class="image hidden-table">
                                        <a class="product-image" [title]="product.name"
                                           [routerLink]="['/products', (product.name.replace(' ', '') | lowercase), product.id]">
                                            <img [alt]="product.name" [src]="getImageSrc(product.imagePath)" width="75">
                                        </a>
                                    </td>
                                    <td>
                                        <h2 class="product-name">
                                            <a [routerLink]="['/products', (product.name.replace(' ', '') | lowercase), product.id]">{{ product.name}}</a>
                                        </h2>
                                    </td>
                                    <td class="a-center hidden-table">
                                        <a [title]="'cart.edit_item' | translate" class="edit-bnt" [routerLink]="['/products', (product.name.replace(' ', '') | lowercase), product.id]"></a>
                                    </td>
                                   
                                    <td class="a-right hidden-table">
                                            <span class="cart-price">
                                                <span class="price">{{ (product.price  | tocurrency :product.currency | async).toLocaleString() }} {{ displayCurrency | uppercase }} / {{ product.measurementUnit }}</span>
                                            </span>
                                    </td>
                                    <td class="a-center movewishlist">
                                        <input class="input-text qty" maxlength="12" [disabled]="true" name="qty" size="4"
                                               title="Qty" [value]="product.qty">
                                    </td>
    
                                    <td class="a-center hidden-table">
                                        &nbsp;
                                        <form class="form-inline">
                                            <div class="form-group">
                                                <select name="shippingOffer" class="form-control" (change)="selectShippingOffer($event, product)">
                                                    <option [value]="0">{{'product.local_pickup_zero_fee' | translate}}</option>
                                                    <option *ngFor="let so of product.shippingOffers" [value]="so.id" [selected]="so.id == product.shippingOfferId" >{{getShippingOfferName(so) | async}}</option>
                                                </select>
                                            </div>
                                        </form>
                                    </td>
    
                                    <td class="a-right movewishlist">
                                            <span class="cart-price">
                                                <span class="price">{{ (calculateThePrice(product) | tocurrency :product.currency | async).toLocaleString() }} {{ displayCurrency | uppercase }}</span>
                                            </span>
                                    </td>
    
                                    <td class="a-center last">
                                        <a class="button remove-item" [title]="'common.remove_item' | translate"
                                           (click)="removeProductIntoCat(product)">
                                                <span>
                                                    <span>{{'common.remove_item' | translate}}</span>
                                                </span>
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="isCartEmpty(products | async)" class="first last">
                                    <td></td>
                                    <td colspan="6" class="a-center hidden-table">
                                        <span class="nobr">{{'cart.is_empty' | translate}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
    
                        </fieldset>
                    </form>
                </div>
            </div>  
        </div>
    </div>
</div> -->