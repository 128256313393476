import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SECURE_API_URLS } from '../config/api.urls';
import { CRMTemplate } from '../_models/crm-template';
import { IPagination } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class CrmTemplateService {
  constructor(
    private _http: HttpClient
  ) { }

  /**
   * get all saved CRMTemplates on efarm.cm
   * @returns IPagination of CRMTemplate
   */
   public getTemplates(page = 1): Observable<IPagination> {
    return this._http.get<IPagination>(SECURE_API_URLS.TEMPLATES_BASE_URL, { params: { page: String(page) } });
  }

  addTemplate(template: CRMTemplate): Observable<any> {
    return this._http.post(SECURE_API_URLS.TEMPLATES_BASE_URL, template);
  }

  remove(id: number): Observable<any> {
    return this._http.delete(SECURE_API_URLS.TEMPLATES_BASE_URL + '/' + id, {});
  }

  edit(id: number, value: CRMTemplate): Observable<any> {
    return this._http.put(SECURE_API_URLS.TEMPLATES_BASE_URL + '/ ' + id, value);
  }
}
