<div class="recent-orders">
    <div class="title-buttons">
        <strong>Users </strong>
    </div>
    <br/>
    <br/>
    <div class="table-responsive">
        <form  [formGroup]="searchForm" (ngSubmit)="searchUsers()">
            <div class="row">
                <div class="col-md-6">
                    <input type="text" placeholder="Search for users" style="padding: 5px 8px;" 
                    formControlName="searchTerm" class="form-control"/>
                </div>
                <div class="col-md-4">
                    <select class="form-control" formControlName="role">
                        <option selected="selected">-Select Account type-</option>
                        <option value="user">eFarm User</option>
                        <option value="admin">Admin</option>
                        <option value="business">Business</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <button class="btn bt-sm btn-primary" id="btn-search">Search</button>
                </div>
            </div>
        </form>
        <p class="mt-3 ms-3" style="color: #0c812c;">
            Total of users found :  {{ config.totalItems }}
        </p>
        <table class="table table-striped table-users" id="my-orders-table">
            <thead>
            <tr class="first last">
                <th style="width: 35%">Name</th>
                <th style="width: 10%">Email</th>
                <th style="width: 10%">Phone</th>
                <th style="width: 16%">Created at</th>
                <th style="width: 28%;">
                    <button (click)="addUser()"
                            class="button button-submit button-md"> Add </button>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="loading">
                <tr>
                    <td colspan="5" style="text-align: center">Loading ...</td>
                </tr>
            </tbody>
            <tbody *ngIf="!loading">
                <tr *ngFor="let user of users | paginate: config">
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{ user.createdAt | date: 'dd/MM/yyyy' }}</td>
                    <td>
                        <button type="button" (click)="editUser(user)">Edit</button>
                        <span class="sep-2">|</span>
                        <a (click)="activeOrDeactivateUser('user' + user.id)"> {{ user.activated ? 'Suspend' : 'Activate' }}</a>
                        <span class="sep-2">|</span>
                        <a (click)="deleteUser('user' + user.id)">Delete</a>
                        <p [attr.class]="'hidden'" id="{{ 'user' + user.id }}">
                            <span *ngIf="suspendOrActivationAction">
                                <b>Confirm to {{ user.activated ? 'suspend' : 'activate' }} this user ? </b>
                                <a (click)="confirmSuspendOrActivation(user)">Yes</a> | <a (click)="cancelAction('user' + user.id)">No</a>
                            </span>
                            <span *ngIf="deletedAction">
                                <b>Do you want to delete this user ? </b>
                                <a (click)="confirmDeleteAction(user)">Yes</a> | <a (click)="cancelAction('user' + user.id)">No</a>
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="toolbar mt-4" *ngIf="!loading">
        <div class="pager">
            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
                                 id="listing" (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </div>
</div>
