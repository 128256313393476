<div style="text-align: center;" *ngIf="loading; else paymentReturn">
    <span class="loader fe-loader"></span>
</div>
<ng-template #paymentReturn>
    <div class="page-heading">
        <div class="page-title">
            <h2>Payment status</h2>
        </div>
    </div>
    <section class="content-wrapper">
        <div class="container">
            <div class="std">
                <div class="page-return" style="text-align: center;">
                  <h3>{{ message }}</h3>
                  <br/>
                  <br/>
                  <a *ngIf="transaction_status == 'SUCCESS'" routerLink="/user/{{ user.id}}/orders/{{paymentRef}}" class="btn-next-page">View Details of the order</a>
                  <a *ngIf="transaction_status == 'FAILED'" routerLink="/shopping-cart" class="btn-cart-page">View your cart</a>
                </div>
            </div>
        </div>
    </section>
</ng-template>
