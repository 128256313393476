import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../_models/user';
import {UserService} from '../../_services/user.service';
import {Title} from '@angular/platform-browser';
import {PageTitleService} from '../../_services/page-title.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductCategoryService} from '../../_services/product-category.service';
import {Product} from '../../_models/product';
import {ProductService} from '../../_services/product.service';
import {debounceTime} from 'rxjs/operators';
import {IPagination} from '../../_models/pagination';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-place-command',
    templateUrl: './place-command.component.html',
    styleUrls: ['./place-command.component.css']
})
export class PlaceCommandComponent implements OnInit, OnDestroy {

    title = 'Place a command';

    placeCommandKey = 'menu.place_command'

    submitted = false;
    loading = true;
    user: User;
    commandForm: UntypedFormGroup;
    currencies = ['XAF', 'USD'];
    products: Product[];
    currentProductName = '';

    subscriptions: Subscription [] = [];
    constructor(private userService: UserService, private titleService: Title, private alertService: ToastrService,
                private productCategoryService: ProductCategoryService, private productService: ProductService,
                private pageTitleService: PageTitleService, private formBuilder: UntypedFormBuilder, 
                private translateService: TranslateService) {
      

        const subscription = this.translateService.get(this.placeCommandKey)
        .subscribe(value => {
            this.title  = value;
            this.titleService.setTitle(this.title);
        });

        this.subscriptions.push(subscription);

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.commandForm = this.formBuilder.group({
            'productId': ['', Validators.required],
            'productName': ['', Validators.required],
            'currency': ['XAF', Validators.required],
            'description': ['', Validators.required],
            'qty': [0, Validators.required],
            'price': [0, Validators.required],
            'userId': [this.user ? this.user.id : 0],
            'picture': ['', Validators.required]
        });
    }

    get f() {
        return this.commandForm.controls;
    }

    ngOnInit() {
        this.loading = false;
        this.productNameEventListener();
    }

    selectProduct(prod) {
        this.currentProductName = prod.name;
        this.commandForm.get('productId').setValue(prod.id);
        this.commandForm.get('productName').setValue(this.currentProductName);
        document.getElementById('productsList').style.display = 'none';
    }

    clearSearchProduct() {
        if (this.commandForm.get('productName').value === '') {
            this.products = [];
            this.commandForm.get('productId').setValue('');
            this.commandForm.get('productName').setValue('');
        }
    }
    fileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.commandForm.get('picture').setValue(file);
        }
    }

    productNameEventListener() {
      this.commandForm.get('productName').valueChanges
          .pipe(
              debounceTime(300)
          )
          .subscribe((value: string) => {
                // the search term must be contains at least 2 characters
                if (value.length > 2 ) {
                  if (this.commandForm.get('productName').value.name !== value.trim() &&
                      value.trim() !== this.currentProductName) {
                    this.productService.search(value, '1').subscribe((result: IPagination) => {
                      this.products = result.data;
                      document.getElementById('productsList').style.display = 'block';
                    });
                  }
                }
              }
          );
    }

    sendRequest() {
        if (!this.commandForm.valid) {
            return;
        }
        this.submitted = true;
        this.productService.sendRequest(this.commandForm.value).subscribe(
            (res: any) => {
                this.alertService.success(res.message);
            },
            () => this.submitted = false,
            () => {
                this.submitted = false;
                this.commandForm.reset();
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subs => subs.unsubscribe())
    }
}
