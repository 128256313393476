import { Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductsConditionsService } from 'src/app/_services/products-conditions.service';
import { ProductCondition } from 'src/app/_models/product-condition';
import { AppConstants } from 'src/app/_constants/constants';

@Component({
    selector: 'app-product-conditions',
    templateUrl: './product-conditions.component.html',
    styleUrls: ['./product-conditions.component.css']
})
export class ProductConditionsComponent implements OnInit, OnDestroy {

    conditions: ProductCondition[];
    loading: boolean;

    constructor(private pcService: ProductsConditionsService,
                private router: Router, private route: ActivatedRoute, private titleService: Title) {
    }

    ngOnInit() {
        this.load_categories();
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('');
    }

    load_categories() {
        this.loading = true;
        this.pcService.get_product_conditions().subscribe(
            (result) => {
                this.conditions = result;
                this.loading = false;
            }
        );
    }

    add_product_condition() {
        window.localStorage.removeItem(AppConstants.PRODUCT_CONDITION_KEY);
        this.router.navigate(['admin/product-conditions/add']);
    }

    edit_product_condition(productCondition: ProductCondition) {
        window.localStorage.removeItem(AppConstants.PRODUCT_CONDITION_KEY);
        window.localStorage.setItem(AppConstants.PRODUCT_CONDITION_KEY, JSON.stringify(productCondition));
        this.router.navigate(['admin/product-conditions', productCondition.id, 'edit']);
    }

    delete_product_condition(productCondition: ProductCondition) {
        this.pcService.delete_product_condition(productCondition).subscribe(
            () => {
                this.conditions = this.conditions.filter(pc => pc !== productCondition);
                this.router.navigate(['/admin/product-conditions']);
                this.load_categories();
            }
        );
    }
}
