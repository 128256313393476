import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';


@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private httpClient: HttpClient) {}

    sendMail(contactDto: any) {
        return this.httpClient.post(API_URLS.CONTACT_US_MAIL_API, contactDto);
    }

}
