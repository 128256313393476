import {BasicDataService} from './basic-data.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Sector } from '../_models/sector';
import { Observable } from 'rxjs';
import { API_URLS } from '../config/api.urls';

@Injectable()
export class SectorService {

    constructor(private http: HttpClient) {
    }


    getAll(): Observable<Sector[]> {
        return this.http.get<Sector[]>(API_URLS.LIST_OF_SECTORS);
    }

    create(sector: Sector) {
        return this.http.post(API_URLS.SINGLE_SECTOR_API, sector);
    }

    update(sector: Sector) {
        return this.http.put(API_URLS.SINGLE_SECTOR_API + '/' + sector.id, sector );
    }

    delete(sector: Sector) {
        return this.http.delete(API_URLS.SINGLE_SECTOR_API + '/' + sector.id);
    }
}
