<!-- Breadcrumb Start -->
<div class="breadcrumb-wrap d-none d-md-block">
  <div class="container-fluid">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']" [title]="'common.home' | translate">{{
          "common.home" | translate
        }}</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/products', 'all']">{{
          "common.products" | translate
        }}</a>
      </li>
      <li class="breadcrumb-item active">
        {{ "product.details" | translate }}
      </li>
    </ul>
  </div>
</div>
<!-- Breadcrumb End -->

<!-- Product Detail Start -->
<div class="product-detail" itemscope itemtype="https://schema.org/Product">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="product-detail-top">
          <div class="row p-4">
            <div class="col-md-5" style="z-index: 0">
              <div class="slick-list draggable">
                <div class="slick-track" style="opacity: 1; width: 445px">
                  <div
                    class="slick-slide slick-current slick-active"
                    style="
                      width: 445px;
                      position: relative;
                      left: 0px;
                      top: 0px;
                      z-index: 999;
                      opacity: 1;
                      display: block;
                    "
                    *ngIf="mediaList.length == 0 && offer != null"
                  >
                    <img itemprop="image" [alt]="usingFrench ? offer.product.productNameFr : offer.product.name"
                      id="product-zoom"
                      [src]="printImage(offer.product)"
                      [attr.data-zoom-image]="printImage(offer.product)"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-12" *ngIf="mediaList.length > 0">
                <ngx-slick-carousel
                  class="carousel product-slider-single normal-slider"
                  #slickModal="slick-carousel"
                  [config]="slideConfig"
                  (init)="slickInit($event)"
                  (breakpoint)="breakpoint($event)"
                  (afterChange)="afterChange($event)"
                  (beforeChange)="beforeChange($event)"
                >
                  <div ngxSlickItem *ngFor="let media of mediaList; let i = index">
                    <img
                      #productImage
                      *ngIf="checkIfLazy(productImage); else skeleton"
                      (click)="changeProductImage('product-zoom' + i)"
                      [alt]="'product.image' | translate"
                      [defaultImage]="offer.defaultPicture"
                      [lazyLoad]="getImageSrc(media.path)"
                    />
                    <ng-template #skeleton>
                      <ngx-skeleton-loader
                        [theme]="{
                          height: '411px',
                          'object-fit': 'cover',
                          width: '100%',
                          'margin-bottom': '0'
                        }"
                      >
                      </ngx-skeleton-loader>
                    </ng-template>
                  </div>
                </ngx-slick-carousel>

                <ngx-slick-carousel
                  class="carousel product-slider-single-nav normal-slider d-none d-lg-block"
                  #slickModal="slick-carousel"
                  [config]="navSlideConfig"
                  (init)="slickInit($event)"
                  (breakpoint)="breakpoint($event)"
                  (afterChange)="afterChange($event)"
                  (beforeChange)="beforeChange($event)"
                >
                  <div
                    class="slider-nav-img"
                    ngxSlickItem
                    *ngFor="let media of mediaList; let i = index"
                  >
                    <img itemprop="image"
                      [src]="getImageSrc(media.path)"
                      (click)="changeProductImage('product-zoom' + i)"
                      [alt]="'product.image' | translate"
                    />
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>

            <div class="col-md-7" *ngIf="this.offer != null">
              <div class="product-content">
                <div class="mb-0">
                  <div
                    class="instock mb-1"
                    *ngIf="offer.qty > 0; else outOfStock"
                  >
                    <i class="fa fa-check"></i>
                    {{ "product.in_stock" | translate }}
                  </div>
                  <ng-template #outOfStock>
                    <div class="outofstock mb-1">
                      <i class="fa fa-xmark"></i>
                      {{ "product.out_of_stock" | translate }}
                    </div>
                  </ng-template>
                </div>
                <div class="title" style="display: inline-grid; height: 40px;">
                  <h1>
                    {{
                      usingFrench
                        ? offer.product.productNameFr
                        : offer.product.name
                    }}
                  </h1>
                  <span  itemprop="name" style="visibility: hidden;">{{ finalTitle }}</span>
                </div>
                <div class="d-lg-flex align-items-center mt-2 mb-3 basic-info d-none">
                  <div class="d-flex align-items-center">
                    <ngb-rating
                      *ngIf="(reviews | json) != ({} | json)"
                      [max]="5"
                      [(rate)]="reviews.avgStars"
                      [readonly]="true"
                    ></ngb-rating>
                    <span class="rating-value ms-2">
                      ({{ reviews.avgStars }})
                    </span>
                  </div>

                  <div class="sep mx-2">
                    <i class="fa fa-circle"></i>
                  </div>

                  <div class="d-flex align-items-center">
                    <div><i class="far fa-comments"></i></div>
                    <div class="review ms-2 text-capitalize">
                      {{ reviews.nbReviews }} {{ "common.reviews" | translate }}
                    </div>
                  </div>
                  <span *ngIf="reviews.nbReviews > 0 && reviews.avgStars > 0"  style="visibility: hidden;" itemprop="aggregateRating" itemscope itemtype="https://schema.org/AggregateRating">
                    <span style="visibility: hidden;" itemprop="reviewCount">{{reviews.nbReviews}}</span>
                    <span style="visibility: hidden;" itemprop="ratingValue" >{{reviews.avgStars}}</span>
                  </span>
                  <span style="visibility: hidden;"  itemprop="offers" itemscope itemtype="https://schema.org/Offer">
                    <link itemprop="url" [href]="'https://www.example.com/products/' + offer.product.slug + '/' + offer.id" />
                    <span itemprop="priceCurrency" content="XAF">F CFA</span>
                    <span itemprop="price" [content]="getOfferPrice(offer)">{{getOfferPrice(offer)}}</span>
                    <span *ngIf="offer.qty > 0">
                      <link itemprop="availability" href="https://schema.org/InStock" />In stock
                    </span>
                  </span>
                </div>
                <div>
                  <p>
                    <span class="price" itemprop="price">{{ getOfferPrice(offer) }}</span>
                    {{ offer.currency | uppercase }} / {{ usingFrench ? offer.measurementunit.descriptionFr : offer.measurementunit.description }}
                    <br>
                    <span style="font-size: 14px; color: #e44934;" *ngIf="offer.pricing.nicename === 'promotion'">
                      <s>
                        {{ getDiscountPrice(offer) }}
                        {{ offer.currency | uppercase }}
                      </s>
                      </span>
                  </p>
                </div>
                <div class="quantity" *ngIf="getCurrentQty(offer, products | async) > 0">
                  <h5>Qty:</h5>
                  <div class="qty">
                    <button
                      type="button"
                      class="btn-minus"
                      (click)="decrementQty(offer)"
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                    <input
                      type="text"
                      title="Qty"
                      [value]="getCurrentQty(offer, products | async)"
                      maxlength="12"
                      id="qty"
                      name="qty"
                    />
                    <button
                      class="btn-plus"
                      type="button"
                      (click)="incrementQty(offer)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="border-top border-bottom px-0 py-2">
                  <div class="ps-1">
                    <div class="d-flex product_attr align-items-center">
                      <p class="attr_name">
                        {{ "common.category" | translate }}:
                      </p>
                      <p class="attr_text">
                        {{
                          usingFrench
                            ? offer.product.category.nameFr
                            : offer.product.category.name
                        }}
                      </p>
                    </div>
                    <div class="d-flex product_attr align-items-center">
                      <p class="attr_name">
                        {{'product.condition' | translate}}
                      </p>
                      <p class="attr_text">
                        {{ usingFrench ? offer.productCondition.descriptionFr : offer.productCondition.description }}
                      </p>
                    </div>
                    <div class="d-flex product_attr align-items-center">
                      <p class="attr_name">
                        {{'product.offer_returns.label' | translate}}
                      </p>
                      <p class="attr_text" *ngIf="offer.returns == 1; else not_accepted">
                        {{'product.offer_returns.accepted' | translate }}
                      </p>
                      <ng-template #not_accepted>
                        <p class="attr_text">
                          {{'product.offer_returns.not_accepted' | translate }}
                        </p>
                      </ng-template>
                    </div>
                  </div>
                </div>                
                <div class="border-bottom px-0 py-2">
                  <div class="ps-1">
                    <div
                      class="d-flex product_attr shop_info align-items-center"
                    >
                      <p class="attr_name">
                        {{ "common.seller" | translate }}:
                      </p>
                      <p class="attr_text">
                        {{ offer.user.name | titlecase }}
                      </p>
                    </div>
                    <div
                      class="d-flex product_attr shop_info align-items-center"
                    >
                      <p class="attr_name">
                        {{ "common.address" | translate }}:
                      </p>
                      <p class="attr_text mb-3" *ngIf="offer.user.country != null">
                        {{ offer.user.town | titlecase }},
                        {{ offer.user.country.nicename | titlecase }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="action mt-4 mb-5">
                  <button
                    class="btn btn-primary me-2 text-white fw-semibold rounded-1 shadow-sm btn-md px-4 py-2"
                    *ngIf="!productModel"
                    (click)="addProductToCart(offer)"
                    title="Add to Cart"
                    type="button"
                  >
                    <i class="fa fa-shopping-cart me-2"></i
                    >{{ "common.add_to_cart" | translate }}
                  </button>
                  <button
                    class="btn btn-outline-primary me-2 fw-semibold rounded-1 shadow-sm btn-md px-3 py-2"
                  >
                    <i class="far fa-heart me-2"></i
                    >{{ "common.add_to_wishlist" | translate }}
                  </button>
                </div>

                <div class="mt-2">
                  <div class="d-flex align-items-center">
                    <p class="me-2 mb-0">Share:</p>

                    <div class="share-icons">
                      <a
                        target="_blank"
                        [href]="facebookShareUrl"
                        class="btn btn-sm me-2 facebook"
                      >
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        [href]="twitterShareUrl"
                        class="btn btn-sm me-2 twitter"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                      <!-- <a
                        target="_blank"
                        [href]="instagramShareUrl"
                        class="btn btn-sm me-2 instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a> -->
                      <a
                        target="_blank"
                        [href]="linkedinShareUrl"
                        class="btn btn-sm me-2 linkedin"
                      >
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row product-detail-bottom">
          <div class="col-lg-12" *ngIf="this.offer != null">
            <ul
              class="nav nav-tabs py-0 flex-column flex-sm-row"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  data-bs-toggle="pill"
                  href="#description"
                  role="tab"
                >
                  {{ "product.description" | translate }}
                </a>
              </li>
          
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  data-bs-toggle="pill"
                  href="#shipping_options"
                  role="tab"
                >
                  {{ 'product.shipping_options' | translate }}
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  data-bs-toggle="pill"
                  href="#reviews"
                  role="tab"
                >
                  Reviews ({{ reviews.nbReviews }})
                </a>
              </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
              <div id="description" class="container tab-pane fade show active" role="tabpanel">
                <h4>Product description</h4>
                <p  itemprop="description" [innerHTML]="offer.description"></p>
              </div>
              <div id="shipping_options" class="container tab-pane fade" role="tabpanel">
                <ul>
                  <li *ngIf="offer.localPickup == 1">
                    {{'product.local_pickup' | translate}}
                  </li>
                  <li *ngIf="offer.localPickup == 0">
                    {{'product.local_pickup_zero_fee' | translate}}
                  </li>
                  <li *ngFor="let shippingOffer of offer.shippingOffers">
                    {{shippingOffer.shippingMean.shippingType.description}} :  
                    {{shippingOffer.shippingMean.description}} ({{shippingOffer.shippingMean.country.name}}) <br> 
                    <p style="padding-left: 22px;">
                      {{'product.shipping_offer.price' | translate}}: {{shippingOffer.shippingCost}} {{shippingOffer.scur | uppercase}}/{{usingFrench ? offer.measurementunit.descriptionFr : offer.measurementunit.description}} <br>
                      {{'product.shipping_offer.packaging_details' | translate}}: {{ shippingOffer.packagingDetails }} <br>
                      {{'product.shipping_offer.eta' | translate}}: {{ shippingOffer.eta }} day(s)
                    </p>
                  </li>
                </ul>
              </div>
              <div id="reviews" class="container tab-pane fade" role="tabpanel">
                <div
                  class="reviews-submitted"
                  *ngIf="displayReviews?.length > 0"
                >
                  <div *ngFor="let review of displayReviews">
                    <div class="reviewer">
                      {{ review?.fullName }} -
                      <span>{{
                        review?.createdAt | date : "dd MMM yyyy"
                      }}</span>
                    </div>
                    <!-- <ngb-rating
                      *ngIf="(reviews | json) != ({} | json)"
                      [max]="5"
                      [(rate)]="reviews.avgStars"
                      [readonly]="false"
                      formControlName="rating"
                    ></ngb-rating> -->
                    <p>
                      {{ review?.remark }}
                    </p>
                  </div>

                  <div *ngIf="showMoreButton">
                    <button (click)="showMore()">Show More</button>
                  </div>
                </div>
                <div class="reviews-submit">
                  <h4>Give your Review:</h4>
                  <form
                    [formGroup]="reviewForm"
                    (ngSubmit)="submitReviewForm()"
                  >
                    <ngb-rating
                      [max]="5"
                      [(rate)]="reviewStars"
                      [readonly]="false"
                      class="review"
                      formControlName="rating"
                    ></ngb-rating>
                    <div class="row form">
                      <div class="col-sm-12">
                        <textarea
                          row="10"
                          formControlName="review"
                          placeholder="Drop a review"
                        ></textarea>
                      </div>
                      <div class="col-sm-12">
                        <button>Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Detail End -->

<!-- related products start -->
<app-related-products
  [relatedProducts]="relatedProducts"
  [loadingState]="relatedOffersLoading"
></app-related-products>
<!-- related products end -->
