<div class="bottom-bar shadow-sm">
  <div class="container-fluid">
    <div
      class="row align-items-center justify-content-between py-2 py-sm-3 sticky-top"
    >
      <div class="col-md-2 col-sm-4 col-6">
        <div class="logo float-start">
          <a [routerLink]="['/']">
            <img src="assets/images/logo.png" alt="Logo" />
          </a>
        </div>
      </div>
      <div class="col-sm-4 col-md-6 col-lg-4 d-sm-none d-md-block d-lg-none d-none">
        <nav class="navbar navbar-expand-md">
          <div class="justify-content-between" id="navbarCollapse">
            <div class="navbar-nav me-2 flex-row">
              <a
                [routerLink]="['/']"
                [title]="'common.home' | translate"
                class="nav-item nav-link active"
                >{{ "common.home" | translate }}</a
              >
              <a [routerLink]="['/products', 'all']" class="nav-item nav-link">{{
                "common.all_products" | translate
              }}</a>
              <!-- <a [routerLink]="['blog']" class="nav-item nav-link">Blog</a> -->
              <!-- <a href="#" class="nav-item nav-link">Shop</a> -->
              <a
                [routerLink]="['/user','place-your-command']"
                class="nav-item nav-link"
                >{{ "menu.place_command" | translate }}</a
              >
              <a
                [routerLink]="['/blog']"
                class="nav-item nav-link"
                >Blog</a
              >
              <a [routerLink]="['/contact-us']" class="nav-item nav-link">{{
                "menu.contact" | translate
              }}</a>
            </div>
          </div>
        </nav>
      </div>
      <div class="col-md-5 col-sm-5 col-6">
        <!-- Mobile buttons -->
        <div
          class="offcanvas-header d-lg-none d-flex align-items-center justify-content-end"
        >
          <!-- search -->
          <div>
            <a
              (click)="showMobileSearch()"
              id="searchBtn"
              class="btn border-0 text-primary"
            >
              <i class="fa fa-search fa-md"></i>
            </a>
          </div>
          <!-- shopping cart -->
          <div>
            <a
              class="btn border-0 text-primary position-relative"
              [routerLink]="['/shopping-cart']"
            >
              <i class="fa fa-shopping-cart fa-md"></i>
              <span class="badge badge-warning" id="lblCartCount">
                {{ nbOfProducts(products | async) }}
              </span>
            </a>
          </div>
          <!-- Hamburger Menu -->
          <div class="float-end">
            <a class="btn border-0" id="menu-btn" data-trigger="navbar_main">
              <i class="fa fa-bars fa-md"></i>
            </a>
          </div>
        </div>

        <nav
          id="navbar_main"
          class="mobile-offcanvas navbar navbar-expand-md navbar-dark bg-lg-none float-lg-none float-end"
        >
          <div class="container-fluid d-lg-none">
            <div class="offcanvas-header">
              <button class="btn-close float-end"></button>
            </div>
            <div class="py-3 border-bottom border-primary">
              <h4 class="text-center">Profile</h4>
            </div>

            <ul class="navbar-nav flex-column mt-3">
              <li class="nav-item active">
                <a
                  *ngIf="!userService.isLoggedIn(); else logout"
                  [routerLink]="['/signin']"
                  class="nav-link"
                >
                  {{ "rightnav.log_in" | translate }}
                </a>
                <ng-template #logout>
                  <a (click)="userService.logout()" class="nav-link">
                    {{ "rightnav.sign_out" | translate }}
                  </a>
                </ng-template>
              </li>
              <!-- <li class="nav-item" *ngIf="userService.isLoggedIn()">
                <a class="nav-link" [routerLink]="['/user', user.id ,'products', 'add']">
                  {{ "common.sell" | translate }}
                </a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/user','dashboard']">
                  {{ "rightnav.my_account" | translate }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/']">{{
                  "common.home" | translate
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/products', 'all']">
                  {{ "common.all_products" | translate }}
                </a>
              </li>
              <a [routerLink]="['/user','place-your-command']"
                class="nav-item nav-link"
                >{{ "menu.place_command" | translate }}</a
              >
              <a [routerLink]="['/blog']"
                class="nav-item nav-link"
                >Blog</a
              >
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/contact-us']">
                  {{ "menu.contact" | translate }}
                </a>
              </li>
            </ul>
          </div>

          <div class="justify-content-between d-lg-block d-none" id="navbarCollapse">
            <div class="navbar-nav me-2">
              <a
                [routerLink]="['/']"
                [title]="'common.home' | translate"
                class="nav-item nav-link active"
                >{{ "common.home" | translate }}</a
              >
              <a [routerLink]="['/products', 'all']" class="nav-item nav-link">{{
                "common.all_products" | translate
              }}</a>
              <a
                [routerLink]="['/user','place-your-command']"
                class="nav-item nav-link"
                >{{ "menu.place_command" | translate }}</a
              >
              <a
                [routerLink]="['/blog']"
                class="nav-item nav-link"
                >Blog</a
              >
              <a [routerLink]="['/contact-us']" class="nav-item nav-link">{{
                "menu.contact" | translate
              }}</a>
            </div>
          </div>
        </nav>
      </div>

      <div class="col-md-3 d-none d-lg-block">
        <form
          class="search"
          [formGroup]="searchForm"
          (keydown.enter)="$event.preventDefault()"
        >
          <input
            formControlName="q"
            type="text"
            [placeholder]="'common.search' | translate"
            (keyup)="getInput($event)"
          />
          <button><i class="fa fa-search"></i></button>
        </form>
      </div>
      <div class="col-md-2 d-none d-lg-block">
        <div class="user d-flex align-items-center">
          <a href="#" class="btn wishlist d-flex">
            <i class="far fa-heart"></i>
            <span>(0)</span>
          </a>
          <a
            href="#"
            class="btn cart d-flex position-relative"
            [routerLink]="['/shopping-cart']"
          >
            <i class="fa fa-shopping-cart"></i>
            <span class="badge badge-warning" id="lblCartCount">{{
              nbOfProducts(products | async)
            }}</span>
          </a>
        </div>
      </div>
    </div>
    <div id="mobileSearch" class="row mt-1 mb-0" style="display: none">
      <div class="col-12 pb-3">
        <div class="mobile-search search">
          <form
            [formGroup]="searchForm"
            (keydown.enter)="$event.preventDefault()"
          >
            <input
              type="text"
              [placeholder]="'common.search' | translate"
              (keyup)="getInput($event)"
              formControlName="q"
            />
            <button type="submit" [disabled]="searchForm.invalid" (click)="search()" ><i class="fa fa-search"></i></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
