<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="page-title">
                <h5>{{'common.sell_a_product' | translate}}</h5>
            </div>
        </div>
        <!--col-xs-12-->
    </div>
    <section class="main-container col2-right-layout">
        <div class="main container">
            <div class="row">
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header">
                            <h2></h2>
                        </div>
                        <div class="card-body">
                            <form class="form-offer" novalidate [formGroup]="sellProductForm" (ngSubmit)="onSubmit()">
                                <div class="content">
                                    <fieldset class="group-select">
                                        <input type="hidden" formControlName="product">
                                        <div class="col-md-12" id="search-for-product">
                                            <label>{{'place_command.search' | translate}}</label>
                                            <input type="text" placeholder="Banana, Orange, Pineapple .." id="productSearchTerm"
                                                       class="form-control" formControlName="searchProduct" (keyup)="clearSearchProduct()"/>
                                            <em *ngIf="(f.searchProduct.touched && f.searchProduct.invalid)" class="text-danger">
                                                   {{'place_command.product_required' | translate}}
                                            </em>
                                            <ul id="productsList">
                                                <li *ngFor="let prod of products" (click)="selectProduct(prod)">{{ prod.name }}</li>
                                            </ul>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <label>{{'common.quantity' | translate}}</label>
                                                <input type="number" min="1" formControlName="qty" class="form-control"/>
                                                <em *ngIf="f.qty.touched && f.qty.invalid" class="text-danger">{{'common.quantity_required' | translate}}</em>
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{'product.measurement_unit' | translate}}</label>
                                                <select class="form-control" formControlName="measurementunit">
                                                    <option value></option>
                                                    <option *ngFor="let measurement of measurementUnits"
                                                            [ngValue]="measurement">{{ currentLanguage === 'fr'? measurement.descriptionFr : measurement.description }}</option>
                                                </select>
                                                <em *ngIf="f.measurementunit.touched && f.measurementunit.invalid"
                                                    class="text-danger">
                                                    {{'offers.select_measurement_unit' | translate}}
                                                </em>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <label>{{'product.price' | translate}}</label>
                                                <input type="number" min="00.00" formControlName="price"
                                                    class="form-control"
                                                    ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01"/>
                                                <em *ngIf="f.price.touched && f.price.invalid" class="text-danger">{{'offers.price_required' | translate}}</em>
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{'common.currency' | translate}}</label>
                                                <select class="form-control" formControlName="currency">
                                                    <option [value]="'xaf'" >XAF</option>
                                                    <option [value]="'usd'">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <label>{{'product.condition' | translate}}</label>
                                                <select class="form-control" formControlName="productCondition">
                                                    <option value=""></option>
                                                    <option *ngFor="let productCondition of productConditions"
                                                            [ngValue]="productCondition">{{ currentLanguage === 'fr'? productCondition.descriptionFr : productCondition.description }}</option>
                                                </select>
                                                <em *ngIf="f.productCondition.touched && f.productCondition.invalid" class="text-danger">
                                                    {{'offers.select_product_condition' | translate}}
                                                </em>
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{'product.pricing' | translate}}</label>
                                                <select class="form-control" formControlName="pricing" (change)="onChange()">
                                                    <option value=""></option>
                                                    <option *ngFor="let p of pricing" [ngValue]="p">{{ currentLanguage === 'fr' ? p.nameFr : p.name }}</option>
                                                </select>
                                                <em *ngIf="f.pricing.touched && f.pricing.invalid" class="text-danger">
                                                    {{'offers.select_pricing' | translate}}
                                                </em>
                                            </div>
                                        </div>
                                        <div class="row mt-3"  *ngIf="(selectedPricing.nicename == 'promotion' || selectedPricing.nicename == 'auction' )">
                                            <div class="col-md-6" *ngIf="selectedPricing != '' && selectedPricing.nicename == 'promotion'">
                                                <label>{{'offers.percent_discount' | translate}}</label>
                                                <input type="number" min="0" max="99" formControlName="percentDiscount" class="form-control" />
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{'offers.expiration_date' | translate}}</label>
                                                <input type="date" [min]="currentDate" formControlName="expirationDate" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <label>{{'product.description' | translate}}</label>
                                            <!-- <textarea class="input-text" formControlName="description"></textarea> -->
                                            <angular-editor  formControlName="description"  [config]="editorConfig"></angular-editor>
                                            <em *ngIf="f.description.touched && f.description.invalid" class="text-danger">
                                                {{'offers.product_description_required' | translate}}
                                            </em>
                                        </div>
                                        <div class="col-md-12 mt-3 checkbox">
                                            <label>
                                                <input type="checkbox" formControlName="returns"/>
                                                {{ 'common.returns_accepted' | translate }} ?
                                            </label>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button type="submit" id="btn-submit"  [disabled]="sellProductForm.invalid" class="btn btn-success btn-md">
                                                {{ (save ? 'common.saving' : 'common.save') | translate }} <span class="ms-3" *ngIf="save"> ...</span>
                                            </button>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <app-sidebar [currentUser]="user"></app-sidebar>
                </div>
            </div>
            <!--row-->
        </div>
        <!--main container-->
    </section>
</div>




