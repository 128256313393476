import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NewsletterService {
    constructor(private httpClient: HttpClient) { }

    newsLetterSubscribe(email: string): Observable<any> {
        return this.httpClient.post(API_URLS.NEWSLETTER_URL, { "emailAddress": email });
    }
}
