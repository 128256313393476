import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../_constants/constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add the language header and client app
        request = request.clone({
            headers: request.headers
                .append('Accept-Language', localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY))
                .append('Client-App', AppConstants.CLIENT_APP)
        });

        // add authorization header with jwt token if available
        const tokenDetails = JSON.parse(localStorage.getItem('tokenDetails'));

        if ((request.url.includes('shipping_offers') || request.url.includes('/oauth/logout')
            || request.url.includes('/user') || request.url.includes('secure') || request.url.includes('/payments') || (request.url.includes("offers") && request.url.includes("reviews")))
            && tokenDetails != null && tokenDetails.access_token) {
            return next.handle(request.clone({
                headers: request.headers.append('Authorization', 'Bearer ' + tokenDetails.access_token)
            }));
        } else if (request.url.includes('/oauth/token') || request.url.includes('/oauth/verify_token/')) {
            return next.handle(request.clone({
                headers: request.headers.append('Authorization', 'Basic ZWZhcm0td2ViLWFwcDplZmFybS13ZWItYXBw')
            }));
        } else {
            return next.handle(request);
        }
    }
}
