import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppConstants } from 'src/app/_constants/constants';
import { MeasurementUnit } from 'src/app/_models/measurement-unit';
import { MeasurementService } from 'src/app/_services/measurement.service';

@Component({
    selector: 'app-measurement-nits',
    templateUrl: './measurement-units.component.html',
    styleUrls: ['./measurement-units.component.css']
})
export class MeasurementUnitsComponent implements OnInit, OnDestroy {

    measurementUnits: MeasurementUnit[];
    loading: boolean;

    constructor(private measurementUnitService: MeasurementService,
        private router: Router, private route: ActivatedRoute, private titleService: Title) {
    }

    ngOnInit() {
        this.load_measurementUnits();
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('');
    }

    load_measurementUnits() {
        this.loading = true;
        this.measurementUnitService.getAll()
            .subscribe(
                (result) => {
                    this.measurementUnits = result;
                    this.loading = false;
                }
            );
    }

    add_measurementUnit() {
        window.localStorage.removeItem(AppConstants.MEASUREMENT_UNITS_KEY);
        this.router.navigate(['admin/measurement-units/add']);
    }

    edit_measurementUnit(measurementUnit: MeasurementUnit) {
        window.localStorage.removeItem(AppConstants.MEASUREMENT_UNITS_KEY);
        window.localStorage.setItem(AppConstants.MEASUREMENT_UNITS_KEY, JSON.stringify(measurementUnit));
        this.router.navigate(['admin/measurement-units', measurementUnit.id, 'edit']);
    }

    delete_measurementUnit(measurementUnit: MeasurementUnit) {
        this.measurementUnitService.delete(measurementUnit)
            .subscribe(() => {
                this.measurementUnits = this.measurementUnits.filter(sc => sc.id !== measurementUnit.id);
                this.router.navigateByUrl('admin/measurement-units');
                this.load_measurementUnits();
            });
    }
}
