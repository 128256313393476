<div class="container-fluid">
    <div class="page-title text-center mt-5 mb-5">
      <h2>
        {{ 'common.jobs_offers' | translate }}
      </h2>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <app-page-sidebar [language]="currentLanguage"></app-page-sidebar>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-9 product-list">
        <p style="text-align: center;" *ngIf="loading else pageBlock">Loading ...</p>
        <ng-template #pageBlock>
          <div ngbAccordion [closeOthers]="true">
            <div ngbAccordionItem *ngFor="let job of jobs; let i = index" [collapsed]="i != 0">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  {{ job.title }}
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody [innerHTML]="job.description">
                  
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    
  </div>