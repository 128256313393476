import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CurrencyRate } from '../_currency/currency-model';
import { CurrencyActions } from '../_currency/currency.actions';
import { CurrencyRatesModel } from '../_currency/currency.state';

const AddRate = CurrencyActions.AddRate;
const Clear = CurrencyActions.Clear;
const GetRate = CurrencyActions.GetRate;

@Injectable({
  providedIn: 'root'
})
export class CurrencyStoreService {

  private rates: CurrencyRate[] = [];

  constructor(
    private store: Store
  ) {
    this.getAllRates().subscribe(r => {
      this.rates = r;
    })
  }

  private getAllRates() {
    return this.store.select(r => r.currencyRates.rates);
  }

  public add(rate: CurrencyRate) {
    this.store.dispatch(new AddRate(rate))
  }

  public clear() {
    this.store.dispatch(new Clear())
  }

  public retrieve(from: string, to: string) {
    return this.rates.find(rate => rate.from === from && rate.to === to);
  }
}
