<form class="shipping-offer-form" [formGroup]="shippingOfferForm" novalidate>
  <input type="hidden" formControlName="id">
  <div class="col-md-3">
    <div class="form-group">
      <label>{{'offers.shipping_means' | translate}} </label>
      <select class="form-control" formControlName="shippingMean" [compareWith]="compareValues">
        <option>- {{'common.select' | translate}} -</option>
        <optgroup *ngFor="let s of shippingTypes" label="{{ s.description }}">
          <option *ngFor="let m of s.shippingMeanList" [ngValue]="m">{{ m.description }}</option>
        </optgroup>
      </select>
      <em *ngIf="(f.shippingMean.touched && f.shippingMean.invalid)" class="text-danger">
        {{'offers.select_means' | translate}}
      </em>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>{{'product.price' | translate}}</label>
      <input class="form-control" formControlName="shippingCost" [placeholder]="'offers.shipping_cost' | translate" type="number" min="1">
      <em *ngIf="(f.shippingCost.touched && f.shippingCost.invalid)" class="text-danger">
        {{'offers.price_required' | translate}}
      </em>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>{{'common.currency' | translate}}</label>
      <select class="form-control" formControlName="scur">
        <option>- {{'common.select' | translate}} -</option>
        <option [value]="'xaf'" >XAF</option>
        <option [value]="'usd'">USD</option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>{{'offers.estimated_time_of_arrival' | translate}}</label>
      <select class="form-control" formControlName="eta">
        <option>- {{'common.select' | translate}} -</option>
        <option *ngFor="let n of days" [value]="n"> {{ ( n > 1 ? (n + 'offers.days') : 'offers.one_day') | translate  }}</option>
      </select>
      <em *ngIf="(f.eta.touched && f.eta.invalid)" class="text-danger">
        {{'offers.select_estimated_time' | translate}}
      </em>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
      <label>{{'offers.packaging_details' | translate}}</label>
      <input class="form-control" formControlName="packagingDetails" />
      <em *ngIf="(f.packagingDetails.touched && f.packagingDetails.invalid)" class="text-danger">
        {{'offers.enter_details' | translate}}
      </em>
    </div>
  </div>
  <div class="col-md-12">
    <button class="btn btn-success" type="submit" [disabled]="submitted || shippingOfferForm.invalid" (click)="onSubmit()">
      {{ (submitted ? 'common.saving' : 'common.save') | translate}} <span *ngIf="submitted"> ...</span>
    </button> &nbsp;
    <button class="btn btn-danger" type="reset" [disabled]="submitted" (click)="closeShippingOfferForm()">{{'common.cancel' | translate}}</button>
  </div>
  <div class="clearfix"></div>
</form>
