import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogPost } from '../_models/blog-post';
import { API_URLS, SECURE_API_URLS } from '../config/api.urls';
import { HttpClient } from '@angular/common/http';
import { IPagination } from '../_models/pagination';
import { Comment } from '../_models/comment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private httpClient: HttpClient) { }

  public loadBlogs() {
    return this.httpClient.get(API_URLS.BLOG_BASE_URL);
  }

  public loadLatest(): Observable<BlogPost[]> {
    return this.httpClient.get<BlogPost[]>(API_URLS.BLOG_BASE_URL + '/latest');
  }

  public loadBlog(page: any): Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.BLOG_BASE_URL, {
      params: {
        page: page
      }
    });
  }

  public loadBlogPost(id): Observable<BlogPost> {
    return this.httpClient.get<BlogPost>(API_URLS.BLOG_BASE_URL + '/' + id);
  }

  public addBlogPost(blogPost: BlogPost, picture: Blob) {
    const formData = new FormData();
    formData.append('userId', '' + blogPost.userId);
    formData.append('categoryId', '' + blogPost.categoryId);
    formData.append('title', '' + blogPost.title);
    formData.append('name', '' + blogPost.name);
    formData.append('body', '' + blogPost.body);
    formData.append('picture', picture);
    return this.httpClient.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/blog', formData);
  }

  public updateBlogPost(blogPost: any, picture: Blob) {
    let formData = new FormData();
    formData.append('categoryId', '' + blogPost.category_id);
    formData.append('title', '' + blogPost.title);
    formData.append('body', '' + blogPost.body);
    formData.append('picture', picture);
    return this.httpClient.put(API_URLS.LOAD_CURRENT_USER_DETAILS + '/blog/' + blogPost.id, formData);
  }

  public adminUpdateBlogPost(blogPost: any, picture: Blob) {
    let formData = new FormData();
    formData.append('categoryId', '' + blogPost.category_id);
    formData.append('title', '' + blogPost.title);
    formData.append('body', '' + blogPost.body);
    formData.append('picture', picture);
    formData.append('weight', '' + blogPost.weight);
    formData.append('slug', '' + blogPost.slug);
    return this.httpClient.put(SECURE_API_URLS.BLOGS_BASE_URL + '/' + blogPost.id, formData);
  }

  public deleteBlogPost(blogPost: BlogPost) {
    return this.httpClient.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/blog' + blogPost.id);
  }

  public loadComments(id): Observable<IPagination> {
    return this.httpClient.get<IPagination>(API_URLS.BLOG_BASE_URL + '/' + id + '/comments');
  }

  public saveComment(id, comment: Comment) {
    return this.httpClient.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/blog/' + id + '/comments', comment);
  }

}
