import { Component, OnDestroy, OnInit } from '@angular/core';
import { PricingService } from '../_services/pricing.service';
import { MeasurementService } from '../_services/measurement.service';
import { ProductsConditionsService } from '../_services/products-conditions.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, first } from 'rxjs/operators';
import { OfferService } from '../_services/offer.service';
import { User } from '../_models/user';
import 'rxjs/operator/combineLatest';
import { combineLatest, Subscription } from 'rxjs';
import { ProductService } from '../_services/product.service';
import { IPagination } from '../_models/pagination';
import { UserService } from '../_services/user.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-offer-form',
    templateUrl: './offer-form.component.html',
    styleUrls: ['./offer-form.component.css'],
    providers: [DatePipe]
})
export class OfferFormComponent implements OnInit, OnDestroy {
    pricing: any[];
    productConditions: any[];
    measurementUnits: any[];
    sellProductForm: UntypedFormGroup;
    searchProduct = '';
    submitted = false;
    save = false;
    user: User;
    products: any[];
    selectedPricing: any = '';
    currentDate = '';

    currentLanguage = 'fr';
    editorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        // uploadUrl: 'v1/image',
        // upload: (file: File) => { ... }
        // uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };

    private subscriptions: Subscription[] = [];

    constructor(private pricingService: PricingService,
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe,
        private router: Router,
        private offerService: OfferService,
        private productService: ProductService,
        public userService: UserService,
        private toastr: ToastrService,
        private productConditionService: ProductsConditionsService,
        private measurementService: MeasurementService,
        private translateService: TranslateService) {
        // fetch the current from localStorage
        const date = new Date();
        this.currentDate = this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd');
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        this.currentLanguage = this.translateService.currentLang;
    }

    ngOnInit() {
        combineLatest(
            [
                this.pricingService.get_pricings(),
                this.productConditionService.get_product_conditions(),
                this.measurementService.getAll()
            ]
        ).subscribe(
            ([response, condtions, measurementUnits]) => {
                this.pricing = response.data;
                this.productConditions = condtions;
                this.measurementUnits = measurementUnits;
            }
        );

        // build the sell product form
        this.sellProductForm = this.formBuilder.group({
            currency: new UntypedFormControl('', Validators.required),
            searchProduct: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl('', Validators.required),
            measurementunit: new UntypedFormControl('', Validators.required),
            price: new UntypedFormControl('', Validators.required),
            pricing: new UntypedFormControl('', Validators.required),
            productCondition: new UntypedFormControl('', Validators.required),
            qty: new UntypedFormControl('', Validators.required),
            product: new UntypedFormControl('', Validators.required),
            expirationDate: new UntypedFormControl(this.currentDate),
            percentDiscount: new UntypedFormControl(''),
            returns: new UntypedFormControl(0)
        });

        // set search auto complete on the searchProduct
        const subs = this.sellProductForm.get('searchProduct').valueChanges
            .pipe(
                debounceTime(300)
            )
            .subscribe((value: string) => {
                // the search term must be contains at least 2 characters
                if (value.length > 2) {
                    if (this.sellProductForm.get('product').value.name !== value.trim()) {
                        // call the search end point to get a list of products having this search term
                        this.productService.search(value, '1').subscribe((result: IPagination) => {
                            this.products = result.data;

                            // show products list block
                            document.getElementById('productsList').style.display = 'block';
                        });
                    }
                }
            }
            );
        this.subscriptions.push(subs);
    }

    onChange() {
        this.selectedPricing = this.sellProductForm.value.pricing;
    }


    // convenience getter for easy access to form fields
    get f() {
        return this.sellProductForm.controls;
    }

    clearSearchProduct() {
        if (this.sellProductForm.get('searchProduct').value === '') {
            this.products = [];
            this.sellProductForm.get('product').setValue('');
            this.sellProductForm.get('searchProduct').setValue('');
        }
    }

    selectProduct(prod) {
        this.sellProductForm.get('product').setValue(prod);
        this.sellProductForm.get('searchProduct').setValue(prod.name);
        document.getElementById('productsList').style.display = 'none';
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (!this.sellProductForm.valid) {
            return;
        }
        this.save = true;
        const subs = this.offerService.addOffer(this.user.id, this.sellProductForm.value)
            .pipe(first())
            .subscribe(
                () => {
                    this.translateService.get('offers.message_product_saved')
                        .subscribe(value => {
                            this.toastr.success(value);
                        });
                    // back to products list view
                    this.router.navigate(['user', this.user.id, 'products']);
                },
                () => {
                    this.save = false;
                    this.submitted = false;
                });
        this.subscriptions.push(subs)
    }


    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
