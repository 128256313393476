import {Component, OnInit} from '@angular/core';
import {Page} from 'src/app/_models/page';
import {PageService} from 'src/app/_services/page.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

    pages: Page[] = [];
    loading = true;
    config: any;
    page: string;

    constructor(private pageService: PageService,
                private router: Router, private titleService: Title,
                private activatedRoute: ActivatedRoute) {
        this.page = this.activatedRoute.snapshot.queryParamMap.get('page') || '1';
    }

    ngOnInit() {
        this.loadPages();
    }

    loadPages() {
        this.pageService.loadPages(this.page).subscribe(
            (res) => this.setConfig(res),
            null,
            () => this.loading = false
        );
    }

    addPage() {
        this.router.navigateByUrl('/admin/pages/add');
    }

    deletePage(id: number) {
        if (confirm('Are you want to deleted this entry ?')) {
            this.pageService.deletePage(id).subscribe();
            this.loading = true;
            this.loadPages();
        }
    }

    pageChange(page) {
        this.page = page;
        this.router.navigate(['pages'], {queryParams: {page: page}});
        this.loadPages();
    }

    private setConfig(result: any) {
        this.pages = result.data;
        this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            totalOfPage: result.totalOfPage,
            id: 'listing'
        };
    }

}
