import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TeamMember } from 'src/app/_models/team-member';
import { TeamService } from 'src/app/_services/team.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {

  title = 'Add Member';
  memberId: any;
  loading = true;
  submitting = false;
  form: UntypedFormGroup;
  teamMember: TeamMember = null;
  picture: Blob = null;
  weights = ['1', '2', '3', '4', '5', '6' , '7', '8', '9', '10'];

  constructor(private titleService: Title, private router: Router, private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute, private teamService: TeamService) {
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.memberId == null) {
      this.titleService.setTitle(this.title);
    } else{
      this.title = 'Edit member'
    }
  }

  ngOnInit() {
    this.buildForm();
    if (this.memberId !== null) {
      this.loadTeamMember();
    } else {
      this.loading = false;
    }
  }

  loadTeamMember() {
    this.teamService.loadTeamMember(this.memberId).subscribe(
      (teamMember: TeamMember) => {
        this.titleService.setTitle('Edit Team Member');
        this.teamMember = teamMember;
      },
      (error) => {},
      () => {
        this.updateForm();
        this.loading = false;
      }
    );
  }
  onChangeFile(event) {
    this.picture = event.target.files[0];
  }

  addMember() {
    this.teamService.saveMember(this.form.value, this.picture).subscribe(
      () => this.router.navigateByUrl('/admin/team')
    );
  }

  private buildForm() {
    this.form = this.fb.group({
      id: new UntypedFormControl(0),
      fullName: new UntypedFormControl('', [Validators.required]),
      jobPosition: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      picture: new UntypedFormControl('', Validators.required),
      weight: new UntypedFormControl('', [Validators.required]),
    });
  }

  private updateForm() {
    this.form.get('picture').clearValidators();
    this.form.patchValue({
      id: this.teamMember.id,
      fullName: this.teamMember.fullName,
      jobPosition: this.teamMember.jobPosition,
      description: this.teamMember.description,
      weight: this.teamMember.weight,
    });
  }

}
