<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-6 mx-auto mt-4" >
      <p *ngIf="loading; else resetform">
        Please wait ...
      </p>
      <app-alert></app-alert>
      <ng-template #resetform>
        <form (ngSubmit)="updatePassword()" [formGroup]="passwordResetForm" *ngIf="tokenIsValid">
          <input type="hidden" formControlName="token" >
          <div class="col-md-12">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control"
                   placeholder="Enter your password "/>
            
            <div *ngIf="f.password.errors && (f.password.dirty || f.password.touched)" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <label>Confirm Password</label>
            <input type="password" formControlName="confirmPassword" class="form-control"
                         placeholder="Confirm your new password"/>
            <div *ngIf="f.confirmPassword.errors && (f.confirmPassword.dirty || f.confirmPassword.touched)" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Password don't match</div>
            </div>
          </div>
          <div class="buttons-set mt-3">
            <button [disabled]="disabled" type="submit" class="btn btn-success" title="Login" name="send" id="btn-submit">
              <span *ngIf="!submitted; else waiting">{{'common.submit' | translate}}</span>
              <ng-template #waiting>
                <span >{{'common.waiting' | translate}} ...</span>
              </ng-template>
            </button>
          </div>
        </form>
      </ng-template>
    </div>
  </div>

</div>
