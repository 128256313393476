<div class="container-fluid">
  <div class="row">
    <div class="page-title">
      <h3>{{ titleKey | translate }}</h3>
  </div>
  </div>
  <div class="row">
    <div class="col-md-9" *ngIf="loading" style="text-align: center;">{{'common.loading' | translate}}  ...</div>
    <div class="col-md-9" *ngIf="!loading">
      <div class="recent-orders">
        <br />
        <div class="table-responsive">
          <table class="table table-striped" id="my-offers">
            <thead>
              <tr>
                <th>#</th>
                <th>{{'orders_list.ref' | translate}}</th>
                <th>{{'common.status' | translate}}</th>
                <th>{{'common.paid' | translate}}</th>
                <th>{{'orders_list.done_at' | translate}}</th>
                <th>{{'common.details' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="loading else data_loaded">
                <td colspan="7" style="text-align: center; vertical-align: middle; padding: 10px; height: 200px;">
                  {{'common.loading' | translate}} ...
                </td>
              </tr>
              <ng-template #data_loaded>
                <tr *ngFor="let order of orders | paginate: config; let i = index;">
                  <td> {{ config.itemsPerPage * (config.currentPage - 1) + ( i + 1)}}</td>
                  <td style="color: #0c812c;">
                    {{ (order.orderNumber.length>15) ? (order.orderNumber | slice: 0 : 15) + '...' : order.orderNumber }}
                  </td>
                  <td>{{ order.status }}</td>
                  <td>{{ (order.payment != null ? "common.yes" : "common.yes" ) | translate}}</td>
                  <td>{{ order.updatedAt }}</td>
                  <td>
                    <a *ngIf="order.status != 'CANCELLED'; else viewAndDelete " [routerLink]="['/user', user.id, 'orders', order.orderNumber]" >{{'common.view' | translate}}</a>
                    <ng-template #viewAndDelete>
                      <a [routerLink]="['/user', user.id, 'orders', order.orderNumber]" >{{'common.view' | translate}}</a>
                      |
                      <button class="bnt btn-delete" (click)="deletedOrder(order.id)">{{'common.delete' | translate}}</button>
                    </ng-template>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div class="toolbar" *ngIf="!loading">
          <div class="pager">
            <pagination-controls class="pages" maxSize="5" previousLabel="Previous" nextLabel="Next" autoHide="true"
              id="listing" (pageChange)="pageChange($event)">

            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
        <app-sidebar [currentUser]="user"></app-sidebar>
    </div>
  </div>
</div>



