import { Component, OnInit } from '@angular/core';
import { WishList } from 'src/app/_models/wish-list';
import { WishlistService } from 'src/app/_services/wishlist.service';
import { User } from 'src/app/_models/user';
import { Title } from '@angular/platform-browser';
import { PageTitleService } from 'src/app/_services/page-title.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/_services/cart.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  wishlist: WishList[] = [];
  loading = true;
  user: User;
  title = 'WishList';

  constructor(private wishListService: WishlistService,
    private titleService: Title,
    private cartService: CartService,
    public userService: UserService,
    private toastr: ToastrService,
    private pageTitleService: PageTitleService) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.titleService.setTitle(this.title);
    this.pageTitleService.setPageTitle(this.title);
  }

  ngOnInit() {
    this.loadWishlist().subscribe(
      (wishlist: WishList[]) => this.wishlist = wishlist,
      null,
      () => this.loading = false
    );
  }

  loadWishlist() {
    return this.wishListService.myWishList(this.user.id);
  }

  removeFromWishlist(id: number) {
    this.wishListService.removeFromWishList(id, this.user.id)
    .mergeMap(() => this.loadWishlist())
    .subscribe(
      (wishlist: WishList[]) => {
        this.wishlist = wishlist;
        this.toastr.success('Item removed');
      },
    );
  }

  getImageSrc(imagePath) {
    return imagePath !== '' && imagePath !== null && imagePath.includes('media') ?
        environment.apiUrl + '/' + imagePath : '/assets/images/default.jpg';
  }

  addToCart(offer) {
    this.cartService.addProduct(offer).subscribe(
      () => this.toastr.success('product added to cart')
    );
  }


}
