import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SECURE_API_URLS } from '../config/api.urls';
import { IPagination } from '../_models/pagination';
import { SingleOutBoxMessage } from '../_models/crm-outbox-message';
import { NewOutBoxMessage } from '../_models/crm-outbox-message-compose';
import {CRMTemplate} from '../_models/crm-template';

@Injectable({
  providedIn: 'root'
})
export class CRMService {
  constructor(
    private _http: HttpClient
  ) {
  }

  public getOutbox(page?: any): Observable<IPagination> {
    return this._http.get<IPagination>(SECURE_API_URLS.OUTBOX_BASE_URL + '?page=' + page);
  }

  public loadMessage(messageId: number): Observable<SingleOutBoxMessage> {
    return this._http.get<SingleOutBoxMessage>(SECURE_API_URLS.OUTBOX_BASE_URL + messageId);
  }

  public send(messageId: number): Observable<any> {
    return this._http.post<void>(SECURE_API_URLS.OUTBOX_BASE_URL + '/' + messageId + '/send', {});
  }

  public composeMessage(message: NewOutBoxMessage): Observable<number> {
    return this._http.post<number>(SECURE_API_URLS.OUTBOX_BASE_URL + '/compose', message);
  }

  loadTemplateBySendMode(currentSendMode: string) {
    return this._http.post<CRMTemplate[]>(SECURE_API_URLS.TEMPLATES_BASE_URL + '/' + currentSendMode, {});
  }

}
