<div class="recent-orders">
  <div class="title-buttons">
    <strong>Our Team </strong>
  </div>
  <br />
  <br />
  <div class="table-responsive">
    <table class="data-table table-striped table-users" id="my-orders-table">
      <thead>
        <tr class="first last">
          <th style="width: 60%">FullName</th>
          <th style="width: 25%">Job Position</th>
          <th style="width: 15%;">
            <button (click)="addMember()" class="button button-submit button-md"> Add Member </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading else tbody">
          <td colspan="3" style="text-align: center;">Loading ...</td>
        </tr>
        <ng-template #tbody>
          <tr *ngFor="let member of teamMembers">
            <td>
              {{ member.fullName }}
            </td>
            <td>{{ member.jobPosition }}</td>
            <td>
              <a [routerLink]="['/admin', 'team-member', member.id, 'edit']">Edit</a>
              &nbsp;
              <button type="submit" class="btn-delete" (click)="deleteTeamMember(member.id)">Delete</button>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>