<div class="recent-orders">
  <div class="title-buttons">
    <strong>{{ title }}</strong>
  </div>
  <br/>
  <br/>
  <div class="table-responsive">
    <table class="table table-striped products-table" id="my-orders-table">
      <thead>
      <tr class="first last">
        <th>Title</th>
        <th>Type</th>
        <th>Country</th>
        <th>Status</th>
        <th>
          <button (click)="addShippingMean()"
                  class="button button-submit button-md"> Add
          </button>
        </th>
      </tr>
      </thead>
      <tbody *ngIf="loading == true; then dataLoading else dataLoaded ">
      </tbody>
      <ng-template #dataLoading>
        <tr>
          <td colspan="5" style="text-align: center">Loading ...</td>
        </tr>
      </ng-template>
      <ng-template #dataLoaded>
        <tr *ngFor="let shippingMean of shippingMeans">
          <td>{{ shippingMean.description.slice(0, 30) }}</td>
          <td>{{ shippingMean.shippingType.description }}</td>
          <td>{{ shippingMean.country.name }}</td>
          <td>{{ shippingMean.status ? 'Yes' : 'No' }}</td>
          <td>
                         <span class="nobr">
                             <a (click)="editShippingMean(shippingMean)">Edit</a>
                             <span class="separator"> | </span>
                             <a (click)="deleteShippingMean(shippingMean)">Delete</a>
                         </span>
          </td>
        </tr>

      </ng-template>
    </table>
  </div>
</div>
