import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import { Observable } from 'rxjs';
import { TeamMember } from '../_models/team-member';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private httpClient: HttpClient) { }

  loadTeam(): Observable<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(API_URLS.TEAM_BASE_PATH);
  }

  saveMember(teamMember: TeamMember, picture: Blob) {
    if (teamMember.id == null || teamMember.id == undefined || teamMember.id == 0) {
      return this.addteamMember(teamMember, picture);
    } else {
      return this.updateteamMember(teamMember, picture);
    }
  }

  addteamMember(teamMember: TeamMember, picture: Blob) {
    return this.httpClient.post(API_URLS.SECURE_TEAM_BASE_PATH, this.builFormData(teamMember, picture));
  }

  loadTeamMember(id): Observable<TeamMember> {
    return this.httpClient.get<TeamMember>(API_URLS.SECURE_TEAM_BASE_PATH + '/' + id);
  }

  updateteamMember(teamMember: TeamMember, picture: Blob) {
    return this.httpClient.put(API_URLS.SECURE_TEAM_BASE_PATH + '/' + teamMember.id, this.builFormData(teamMember, picture));
  }

  deleteteamMember(id: number) {
    return this.httpClient.delete(API_URLS.SECURE_TEAM_BASE_PATH + '/' + id);
  }

  private builFormData(teamMember: TeamMember, picture: any) {
    const formData = new FormData();
    formData.append('fullName', teamMember.fullName);
    formData.append('jobPosition', teamMember.jobPosition);
    formData.append('description', teamMember.description);
    formData.append('weight', teamMember.weight);
    formData.append('picture', picture);
    return formData;
  }

}
