<div class="page-heading">
  <div class="page-title">
    <h2>{{ title }}</h2>
  </div>
</div>
<section class="content-wrapper">
  <div class="container">
    <div class="std">
      <div class="page-not-found">
        <div>
          <h3>Oops, the page you requested was not found</h3>
        </div>
        <div>
          <a href="/#/" class="btn-home">Back to Home</a>
        </div>
      </div>
    </div>
  </div>
</section>