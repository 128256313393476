import { Component, OnInit } from '@angular/core';
import {ApiService} from '../_services/api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  constructor(public apiService: ApiService) {
    this.apiService.admin_layout = true;
  }
}
