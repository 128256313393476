<section class="main-container col2-right-layout">
    <div class="main container">
        <div class="row">
            <section class="col-main col-sm-9 col-xs-12 wow bounceInUp animated animated animated">
                <router-outlet></router-outlet>
            </section>
            <aside class="col-right sidebar col-sm-3 col-xs-12 wow bounceInUp animated animated animated">
                <div class="block block-account">
                    <div class="block-title">
                        Admin Account
                    </div>
                    <div class="block-content">
                        <ul>
                            <li>
                                <a [routerLink]="['/admin', 'dashboard']">
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin','users']">
                                    <span>Users</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin','jobs']">
                                    <span>Career</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin','blog-posts']">
                                    <span>Blog posts</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin','team']">
                                    <span>Our Team</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'pages']" class="nav-link">
                                    <span>Pages</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'product-categories']">
                                    <span>Product categories</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'product-conditions']">
                                    <span>Product Conditions</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'pricing']">
                                    <span>Pricing</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin','sectors']">
                                    <span>Sectors</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'measurement-units']">
                                    <span>Measurement Units</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'requested-products']">
                                    <span>Requested Products</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'products']" class="nav-link">Products</a>
                            </li>
                            <li>
                                <a [routerLink]="['shipping-means']" class="nav-link">
                                    <span>Shipping means</span>
                                </a>
                            </li>

                            <li>
                                <a [routerLink]="['/admin', 'taxonomies']">
                                    <span>Taxonomies</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['offers']" class="nav-link">
                                    <span>Offers</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'orders']" class="nav-link">
                                    <span>Orders List</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'crm-templates']">
                                    <span>{{'CRM Templates' | translate}}</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/admin', 'outbox']">
                                    <span>{{'Outbox' | translate}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</section>
