import { Component, OnInit } from '@angular/core';
import { ProductCategoryService } from '../_services/product-category.service';
import { AppConstants } from '../_constants/constants';

@Component({
  selector: 'app-new-slider',
  templateUrl: './new-slider.component.html',
  styleUrls: ['./new-slider.component.css']
})
export class NewSliderComponent implements OnInit {
  category_list: any[];
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);
  loadingCategories = true;
  slides = [
    { img: "assets/images/new/1.jpg", url: '/', show_btn: false  },
    // { img: "assets/images/new/2.jpg", url: '/products/Pork/80', show_btn: false },
    // { img: "assets/images/new/vivres-frais.jpeg", url: '/' , show_btn: false },
    { img: "assets/images/new/slider-2.jpg", url: '/', show_btn: false  },
    { img: "assets/images/new/slider-1.jpg", url: '/' , show_btn: false },
    { img: "assets/images/new/3.jpeg", url: '/', show_btn: false},
  ];

  slideConfig = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<button class="btn nextBtn"><i class="fa fa-lg fa-angle-right"></i></button>',
    prevArrow: '<button class="btn prevBtn"><i class="fa fa-lg fa-angle-left"></i></button>',
  };

  slickInit(e: any) {

  }
  breakpoint(e: any) {

  }
  afterChange(e: any) {

  }
  beforeChange(e: any) {

  }

  constructor(private productCatService: ProductCategoryService) { }

  ngOnInit() {
    this.productCatService.get_category_list()
    .subscribe((data) => {
      this.category_list = data;
      this.loadingCategories = false;
    });
  }
}
