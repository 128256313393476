<div class="row">
    <div class="col-md-12">
        <h5>Blog post edit</h5>
    </div>
    <div class="col-md-12 mt-3">
        <div class="card">
            <div class="card-header">
                Blog post details
            </div>
            <div class="card-body">
                <div class="text-center" *ngIf="loading; else editForm;">
                    Loading ...
                </div>
                <ng-template #editForm>
                    <form [formGroup]="blogPostForm">
                        <div class="row">
                            <div class="col-md-12">
                                <label>
                                    Title
                                </label>
                                <input type="hidden"  formControlName="id">
                                <input type="text" class="form-control" formControlName="title">
                            </div>
                            <div class="col-md-12 mt-2">
                                <label>
                                    Slug
                                </label>
                                <input type="text" class="form-control" formControlName="slug">
                                <em *ngIf="(f.slug.touched && f.slug.invalid)" class="text-danger">
                                    this field should contain only numbers, letters and / or the character -
                                </em>
                            </div>
                            <div class="col-md-12 mt-2">
                                <label>
                                    Category
                                </label>
                                <select type="text" class="form-control" formControlName="category_id">
                                    <option *ngFor="let category of categories" [value]="category.id">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-2">
                                <label>
                                    Body
                                </label>
                                <!-- <textarea rows="5" cols="5" class="form-control" formControlName="body">
                                    </textarea> -->
                                <angular-editor formControlName="body" [config]="editorConfig"></angular-editor>
                            </div>
                            <div class="col-md-12 mt-2">
                                <label>
                                    Image
                                </label> <br/>
                                <input type="file"  formControlName="picture" (change)="onSelectedPicture($event)">
                            </div>
                            <div class="col-md-12 mt-2">
                                <label>
                                    Weight
                                </label> <br/>
                                <input type="number" formControlName="weight" min="1" class="form-control">
                            </div>
                            <div class="col-md-12 mt-2 mt-5">
                                <a class="btn btn-default me-3" id="btn-cancel" [routerLink]="['/admin/blog-posts']">
                                    Cancel
                                </a>
                                <button class="btn btn-success" id="btn-submit" type="submit"
                                (click)="submit()"
                                [disabled]="blogPostForm.pristine ||  blogPostForm.invalid || submitting">
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-template>

            </div>
        </div>
    </div>
</div>
