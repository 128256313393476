<div class="col-lg-12">
  <div class="card">
    <div class="card-header">
      {{ title | translate}}
    </div>
    <div class="card-body">
      <form (ngSubmit)="save()" [formGroup]="newTemplate">
        <div class="input-field">
          <label for="subject-en">Send Mode</label>
          <select class="input" id="send-mode" type="text" formControlName="sendMode" (change)="changeSendMode($event)">
            <option [value]="'EMAIL'">EMAIL</option>
            <option [value]="'SMS'">SMS</option>
          </select>
        </div>
        <div class="input-field">
          <label for="subject-en">Subject (EN)</label>
          <input class="input" id="subject-en" type="text" formControlName="subject" />
        </div>

        <div class="input-field">
          <label for="subject-fr">Subject (FR)</label>
          <input id="subject-fr" class="input" type="text" formControlName="subjectFr" />
        </div>

        <div class="input-field">
          <label for="body-en">Body (EN)</label>
          <angular-editor [config]="editorConfig" class="content" id="body-en" formControlName="body" *ngIf="currentSendMode == 'EMAIL'"></angular-editor>
          <textarea formControlName="body" class="content"  *ngIf="currentSendMode == 'SMS'"></textarea>
        </div>

        <div class="input-field">
          <label for="body-fr">Body (FR)</label>
          <angular-editor [config]="editorConfig" class="content" id="body-fr" formControlName="bodyFr"  *ngIf="currentSendMode == 'EMAIL'"></angular-editor>
          <textarea formControlName="bodyFr" class="content"  *ngIf="currentSendMode == 'SMS'"></textarea>
        </div>

        <div>
          <button (click)="exit()"  class="button button-back" type="button">Cancel</button>
          &nbsp;
          <button [disabled]="newTemplate.invalid || submitting"  class="button button-submit" type="submit" >Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
