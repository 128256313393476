import { Component, OnInit } from '@angular/core';
import { ApiService } from './_services/api.service';
import { UserService } from './_services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from './_constants/constants';
import { SwUpdate } from '@angular/service-worker';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CurrencyStoreService } from './_services/currency-store.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    constructor(public apiService: ApiService,
        private authService: AuthenticationService,
        private router: Router,
        private translateService: TranslateService,
        public userService: UserService,
        private serviceWorker: SwUpdate,
        private toastr: ToastrService,
        private currencyRates: CurrencyStoreService) {

        let defaultLanguage = AppConstants.FRENCH.toString();
        const currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);
        if (currentLanguage) { // if a current language is set in storage, use it
            defaultLanguage = currentLanguage;
        } else {
            localStorage.setItem(AppConstants.CURRENT_LANG_STORE_KEY, AppConstants.FRENCH);
            // if not saved, then use fr and set it in storage
        }
        this.translateService.setDefaultLang(defaultLanguage);
        this.translateService.use(defaultLanguage);
    }

    ngOnInit(): void {
        // clear cached currencies and save new rate for default currency
        this.currencyRates.clear();
        const defaultCurrency = environment.currency;
        this.currencyRates.add({from: defaultCurrency, to: defaultCurrency, rate: 1})

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            // close nav sidebar if it is opened
            document.getElementById('mySidenav').style.width = '0';
            window.scrollTo(0, 0);
        });

        if (this.serviceWorker.isEnabled) {
            this.serviceWorker.available.pipe(
                switchMap(() => this.translateService.get('common.updates_available'))
            ).subscribe((message) => { // should user confirm to get changes now, changes should immediately reflect
                location.reload();
            });
        }
    }
}
