import { Component, OnInit } from '@angular/core';
import {RequestedProductService} from '../../_services/requested-product.service';
import {RequestedProduct} from '../../_models/requested-product';
import {IPagination} from '../../_models/pagination';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-requested-product',
  templateUrl: './requested-product.component.html',
  styleUrls: ['./requested-product.component.css']
})
export class RequestedProductComponent implements OnInit {

  requestedProducts: RequestedProduct[];
  loading = true;
  page = '1';
  config: any;
  title = 'Requested Products';

  constructor(private requestProductService: RequestedProductService, private router: Router,
              private pageTitle: Title) {
      this.pageTitle.setTitle(this.title);
  }

  ngOnInit() {
      this.loadRequestProducts(this.page);
  }

  loadRequestProducts(page: string) {
      this.loading = true;
      this.requestProductService.findAll(page).subscribe(
          (res: IPagination) => {
              this.requestedProducts = res.data;
              this.config = {
                  currentPage: res.page,
                  itemsPerPage: res.itemPerPage,
                  totalItems: res.totalOfItems,
                  id: 'listing'
              };
          },
          () => null,
          () => this.loading = false
      );
  }

    pageChange(page) {
        this.page = page;
        this.router.navigate(['/admin/requested-products'], {queryParams: {page: page}});
        this.loadRequestProducts(this.page);
    }

}
