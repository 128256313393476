import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../_helpers/MustMatch';
import {UserService} from '../../_services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    loading = true;
    passwordResetForm: UntypedFormGroup;
    disabled = false;
    submitted = false;
    token: string;
    tokenIsValid =  false;

    constructor(private apiService: ApiService, private userService: UserService,
                private activatedRoute: ActivatedRoute, private  router: Router,
                private toastService: ToastrService, private fb: UntypedFormBuilder) {
        this.apiService.show_header_footer = false;
        this.token = this.activatedRoute.snapshot.paramMap.get('token');
        this.passwordResetForm = this.fb.group(
            {
                token: new UntypedFormControl(this.token),
                password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
                confirmPassword: new UntypedFormControl('', Validators.required),
            },
            {
                validator: MustMatch('password', 'confirmPassword')
            }
        );
    }

    ngOnInit() {
        const context = this;
        setTimeout(function () {
            context.userService.verify_password_reset_token(context.token).subscribe(
                () => {
                    context.loading = false;
                    context.tokenIsValid = true;
                },
                () => {
                    context.loading = false;
                    setTimeout(function () {
                        context.router.navigate(['/signin']);
                    }, 1000);
                }
            );
        }, 2000);

    }

    get f() { return this.passwordResetForm.controls; }

    updatePassword() {
        this.submitted = true;
        this.disabled = true;
        this.userService.resetPassword(this.passwordResetForm.value).subscribe(
            () => {
                this.router.navigate(['/password-reset-complete']);
            },
            (response) => {
                this.toastService.error(response);
                this.loading = false;
                this.disabled = false;
                this.submitted = false;
            }
        );
    }

    ngOnDestroy(): void {
        this.apiService.show_header_footer = true;
    }

}
