import { Component, OnInit} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/_services/page.service';
import { Title } from '@angular/platform-browser';
import { Page } from 'src/app/_models/page';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.css']
})
export class AddPageComponent implements OnInit {
  form: UntypedFormGroup;
  loading = true;
  submitting = false;
  id: string;
  page: Page = null;
  Editor: ClassicEditor;

  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private pageService: PageService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
    this.Editor = ClassicEditor;
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.titleService.setTitle('Edit Page - Pages');
    }
  }

  ngOnInit() {
    if (this.id) {
      this.pageService.loadPageById(this.id).subscribe(
          (page: Page) => {
            this.page = page;
            this.form.get('title').setValue(this.page.title);
            this.form.get('titleFr').setValue(this.page.titleFr);
            this.form.get('content').setValue(this.page.content);
            this.form.get('contentFr').setValue(this.page.contentFr);
            this.loading = false;
          },
          () => this.router.navigateByUrl('/admin/pages'),
          null
      );
    } else {
      this.titleService.setTitle('New Page - Pages');
      this.buildForm();
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      title: new UntypedFormControl( '', [Validators.required]),
      titleFr: new UntypedFormControl( '', [Validators.required]),
      content: new UntypedFormControl('', [Validators.required]),
      contentFr: new UntypedFormControl('', [Validators.required])
    });
  }

  save() {
    this.pageService.savePage(this.form.value, this.id).subscribe(
      () => {},
      () => (this.submitting = false),
      () => this.router.navigateByUrl('/admin/pages')
    );
  }
}
