<div class="header mb-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 pe-0 d-none d-lg-block">
        <nav class="navbar bg-light">
          <div class="loader-box" style="background-color: #fff;" 
              *ngIf="loadingCategories; else categories_list">
            <div class="lds-dual-ring"></div>
          </div>
          <ng-template #categories_list>
            <ul class="navbar-nav" *ngIf="(category_list | json) != ({} | json)">
              <li *ngFor="let category of category_list.slice(0, 8)" class="nav-item">
                <a class="nav-link d-flex justify-content-between align-items-center"
                  [routerLink]="['products','category' ,category.slug]">
                  <span>
                    {{
                      currentLanguage == "fr" &&
                      category.nameFr != null &&
                      category.nameFr != ""
                        ? category.nameFr
                        : category.name
                    }}
                  </span>
                  <i class="fa fa-angle-right me-0"></i>
                </a>
              </li>
              <!-- <li class="nav-item">
                <a
                  [routerLink]="['']"
                  class="nav-link text-decoration-underline"
                  >{{ "home.moreCategories" | translate }}</a
                >
              </li> -->
            </ul>
          </ng-template>
        </nav>
      </div>

      <div class="col-lg-3 d-none d-lg-block px-2">
        <div class="d-flex flex-column">
          <div class="header-img" style="height: 195px; margin-bottom: 5px;">
            <div class="img-item">
              <a>
                <img src="assets/images/new/5.jpg" />
              </a>
            </div>
          </div>
          <div class="header-img"  style="height: 195px; margin-top: 5px;">
            <div class="img-item">
              <a>
                <img src="assets/images/new/ad-item-2.jpeg" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 slider-padding">
        <div class="header-slider normal-slider">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let slide of slides" class="header-slider-item" [routerLink]="['']">
              <a routerLink="{{ slide.url}}">
                <img  src="{{ slide.img }}" alt="" />
              </a>
              <div class="header-slider-button" *ngIf="slide.show_btn">
                <a routerLink="{{ slide.url}}" class="btn shadow-sm slide_btn">Shop Now</a>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
