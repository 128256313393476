import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPagination } from 'src/app/_models/pagination';
import { PaymentService } from '../../_services/payment.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  private hideOtherElements(eltId) {
    const nodes = document.getElementById('payments-table').querySelectorAll('p#confirm-action');
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].className = 'hidden';
    }
    document.getElementById(eltId).classList.remove('hidden');
  }

  payments: any[];
  loading = true;

  action = null;

  searchForm: UntypedFormGroup;
  page: string;
  paymentMethod: string;
  status: string;
  config: any;
  searchTerm: any;

  searched = false //indicates whether or not the payments are being searched

  constructor(
    private paymentService: PaymentService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute) {
    this.searchForm = this.formBuilder.group({
      paymentMethod: formBuilder.control('', []),
      status: formBuilder.control('', []),
      searchTerm: formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit() {
    this.activeRoute.queryParamMap
      .subscribe(p => {
        this.searchTerm = p.get('searchTerm');
        this.paymentMethod = p.get('paymentMethod');
        this.status = p.get('status');
        this.page = p.get('page') || '1';

        this.searched = this.searchTerm || this.paymentMethod || this.status;

        if (this.searched) {
          this.doSearch(this.searchTerm, this.paymentMethod, this.status, this.page);
        } else {
          this.loadPayments();
        }
      })
  }

  loadPayments() {
    this.loading = true;
    this.paymentService
      .loadPayments(this.page).subscribe(
        (res: any) => {
          this.setConfig(res);
        },
        () => {},
        () => this.loading = false
      );
  }

  setConfig(page: IPagination) {
    this.payments = page.data;
    this.config = {
      currentPage: page.page,
      itemsPerPage: page.itemPerPage,
      totalItems: page.totalOfItems,
      id: 'payments'
    };
  }

  convertStrToDate(dateInStr: string) {
    const date = new Date(dateInStr);
    return date;
  }

  convertArrayToDate(dateArray: number[]) {
    const date = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    return date;
  }

  undoAction(transaction) {
    this.action = null;
    document.getElementById('payment'+transaction).classList.add('hidden');
  }

  changeStatus(transaction: string, status: string) {
    const newStatus = status == 'approve' ? 'Validated' : 'Rejected';
    this.paymentService.changeStatus(transaction, newStatus)
      .subscribe(() => {
        location.reload();
      })
  }

  approve(ref: string): void {
    this.action = 'approve';
    this.hideOtherElements('payment' + ref);
  }

  reject(ref: string): void {
    this.action = 'reject';
    this.hideOtherElements('payment' + ref);
  }

  search(page: string = '1') {
    if (this.searchForm.valid) {
      this.router.navigate(['/admin/payments'], {
        queryParams: {
          paymentMethod: this.searchForm.value.paymentMethod,
          status: this.searchForm.value.status,
          searchTerm: this.searchForm.value.searchTerm,
          page: page
        }
      });
    }
  }

  pageChange(page: string) {
    this.page = page;
    if (this.router.url.includes('search')) {
      this.router.navigate(['/admin/payments'], {
        queryParams: {
          paymentMethod: this.paymentMethod,
          status: this.status,
          searchTerm: this.searchTerm,
          page: page
        }
      });
    } else {
      this.router.navigate(['/admin/payments'], { queryParams: { page: page } });
    }
  }

  doSearch(searchTerm: string, paymentMethod: string, status: string, page) {
    this.loading = true;
    const searchBtn = document.getElementById('btn-search');
    searchBtn.innerText = 'Searching ...';
    this.paymentService
      .searchPayment(searchTerm, paymentMethod, status, page)
      .subscribe(
        (result: any) => {
          searchBtn.innerText = 'Search';
          this.payments = result.data;
          this.setConfig(result)
          this.loading = false;
        }
      );
  }
}
