import { CurrencyRate } from "./currency-model";

export namespace CurrencyActions {
    export class Clear {
        static readonly type = '[CurrencyRate] clear all';
    }

    export class AddRate {
        static readonly type = '[CurrencyRate] add rate';
        constructor(public rate: CurrencyRate) {
        }
    }

    export class GetRate {
        static readonly type = '[CurrencyRate] retrieve rate';
        constructor(public from: string, public to: string) {
        }
    }
}
