import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from '../_models/group';
import { SECURE_API_URLS } from '../config/api.urls';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Group[]>{
    return this.http.get<Group[]>(SECURE_API_URLS.GROUPS_BASE_URL);
  }

  addGroup(title: string) {
    return this.http.post(SECURE_API_URLS.GROUPS_BASE_URL, {title: title});
  }

  updateGroup(title: string, id: number) {
    return this.http.put(SECURE_API_URLS.GROUPS_BASE_URL + '/' + id, {title: title});
  }

  deleteGroup(id: number) {
    return this.http.delete(SECURE_API_URLS.GROUPS_BASE_URL + '/' + id);
  }

  saveOffers(offerIds: any, id: number) {
    return this.http.post(SECURE_API_URLS.GROUPS_BASE_URL + '/' + id + '/offers', {ids: offerIds})
  }
}
