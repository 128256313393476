import { Component, OnInit } from '@angular/core';
import {ProductService} from '../../_services/product.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Product} from '../../_models/product';
import {IProductCategory} from '../../_models/iproduct-category';
import {ProductCategoryService} from '../../_services/product-category.service';
import {Location} from '@angular/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {AlertService} from '../../_services/alert.service';
import {PaymentMethod} from '../../_models/PaymentMethod';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {

  productForm: UntypedFormGroup;
  submitted: boolean;
  product: Product;
  title: string;
  productCategories: IProductCategory[] = [];
  selectedFile = null;
  imageChangedEvent: any = '';
  imageCroppedEvent: ImageCroppedEvent;
  croppedImage: any = '';
  croppedImageBase64: any = '';
  cropped = false;
  loading = true;

  constructor(private productService: ProductService, private router: Router,
              private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private pcService: ProductCategoryService, private location: Location) {
    const item = window.localStorage.getItem('product');
    this.product = item ? JSON.parse(item) : new PaymentMethod();
    this.title = item ? 'Edit Product' : 'Add Product';
  }

  ngOnInit() {
    console.log('hello');
    this.pcService.get_category_list().subscribe(
      (categories) => {
        this.productCategories = categories;
        this.formInit();
        this.loading = false;
      },
      (error) => console.error(error),
      () => {}
    );
  }

  formInit() {
    this.productForm = this.fb.group({
      id: new UntypedFormControl(this.product.id != null ? this.product.id : ''),
      name: new UntypedFormControl(this.product.id != null ? this.product.name : '', Validators.required),
      productNameFr: new UntypedFormControl(this.product.id != null ? this.product.productNameFr : '', Validators.required),
      categoryId: new UntypedFormControl(
          (this.product.id != null && this.product.category.id !== null ) ? this.product.category.id : 0, Validators.required),
      picture: new UntypedFormControl(''),
      status: new UntypedFormControl(this.product.id != null ? this.product.status : true )
    });
  }

  loadCategories() {
    
  }

  get f() {
    return this.productForm.controls;
  }

  onSelectedPicture( event ) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
  }

  imageLoaded( ) {
    this.alertService.hide();
  }

  loadImageFailed( ) {
    this.selectedFile = null;
    this.alertService.error('Only PNG, JPEG and JPG files are allowed');
  }

  onCancelImageChangedEvent() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.croppedImageBase64 = '';
    this.cropped = false;
    this.selectedFile = null;
  }

  onCrop() {
    this.cropped = true;
  }

  imageCropped(imageCroppedEvent: ImageCroppedEvent) {
    this.imageCroppedEvent = imageCroppedEvent;
    this.croppedImage = imageCroppedEvent.file;
    this.croppedImageBase64 = imageCroppedEvent.base64;
  }

  onSubmit(event) {
    if (this.productForm.invalid ) {
      return;
    }
    this.submitted = true;
    if ( this.croppedImage !== '' ) {
      this.croppedImage.name =  this.selectedFile.name;
      this.productForm.value.picture =  this.croppedImage;
    }
    this.product.id ? this.update() : this.create();
  }

  create() {
    this.productService.addProduct(this.productForm.value).subscribe(
        () => {
          this.router.navigate(['/admin/products']);
        },
        () => { this.submitted = false; }
    );
  }

  update() {
    this.productService.updateProduct(this.productForm.value).subscribe(
        () => {
          this.router.navigate(['/admin/products']);
        },
        () => { this.submitted = false; }
    );
  }

  backToPrevious() {
    this.location.back();
  }

}
