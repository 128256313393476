import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { mergeMap } from 'rxjs/operators';
import { Job } from 'src/app/_models/job';
import { JobService } from 'src/app/_services/job.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit{
  loading = true;
  jobs: Job[] = [];

  constructor(private jobService: JobService, private toastService: ToastrService,
    private router: Router){}

  ngOnInit(): void {
    this.jobService.loadJobsByadmin().subscribe(
      (jobs) => this.jobs = jobs,
      (error) => this.toastService.error(error),
      () => this.loading = false
    );
  }

  deleteJob(id){
    this.loading = true;
    this.jobService.deleteJob(id).pipe(
      mergeMap(() => {
        return this.jobService.loadJobsByadmin();
      })
    ).subscribe(
      (jobs) => this.jobs = jobs,
      (error) => this.toastService.error(error),
      () => this.loading = false
    )
  }

  addJob(){
    this.router.navigateByUrl('/admin/jobs/add');
  }

}
