<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="page-title text-center">
        <h3>{{ title | translate }}</h3>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-lg-6  col-md-6 mx-auto">
      <p>
        A message was sent to your email. Please, click on the link in the message to reset your password.
        If you do not see it in your inbox, check it in your spam messages. Thank you!
      </p>
    </div>
  </div>

</div>




