<div id="mobile-menu" style="left: -250px; width: 0px;">
  <ul class="mobile-menu">
      <li>
          <div class="mm-search">
              <form id="search1" name="search">
                  <div class="input-group">
                      <input type="text" class="form-control simple" [placeholder]="('common.search'| translate ) +' ...'" name="srch-term" id="srch-term">
                      <div class="input-group-btn">
                          <button class="btn btn-default" type="submit"><i class="fa fa-search"></i> </button>
                      </div>
                  </div>
              </form>
          </div>
      </li>
      <li>
          <div class="home"><a href="#">{{'common.home' | translate}}</a></div>
      </li>
      <li>
          <span class="expand icon-plus" style="padding-bottom: 12px; padding-top: 12px;"></span>
          <a style="padding-right: 55px;">{{'common.products' | translate}}</a>
          <ul style="display: none;">

            <li><a [routerLink]="['products', 'all']">{{'common.all_products' | translate}}</a></li>
            <li class="level1" *ngFor="let p of pricing">
              <a [routerLink]="['products', p.nicename ]">
                  <span>{{ usingEnglish ? p.name : p.nameFr }}</span>
              </a>
          </li>
              <!-- <li><a href="/#/products/all">{{'common.all_products' | translate}}</a></li>
              <li><a href="/#/products/fixed">{{'menu.fixed' | translate}}</a></li>
              <li><a href="/#/products/bestoffer">{{'menu.bet_offer' | translate}}</a></li>
              <li><a href="/#/products/auction">{{"menu.auction" | translate}}</a></li>
              <li><a href="/#/products/promotion">{{'menu.promotion' | translate}}</a></li> -->
          </ul>
      </li>
      <li>
          <div><a href="/#/user/place-your-command">{{'menu.place_command' | translate}}</a></div>
      </li>
      <li>
          <div><a href="/#/blog">Blog</a></div>
      </li>
      <li>
          <div><a href="/#/contact-us">{{'footer.contact_us' | translate}}</a></div>
      </li>
  </ul>
</div>