<div class="container-fluid">
  <div class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="page-title mt-5 mb-5 text-center">
            <h2>{{ titleTranslateKey | translate }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 col-md-7 col-sm-12">
      <form [formGroup]="form" id="contactForm" (ngSubmit)="mailUs()">
        <div class="static-contain form">
          <fieldset class="group-select">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <label>{{'common.name' | translate}} <span class="required">*</span></label>
                <input formControlName="name" class="form-control" />
              </div>
              <div class="col-md-6 col-sm-12">
                <label>{{'common.email' | translate}} </label><br />
                <input formControlName="email" type="email" class="form-control" />
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <label>{{'common.phone' | translate}} <span class="required">*</span></label>
              <input formControlName="phone" class="form-control" />
            </div>
            <div class="col-md-12 col-sm-12">
              <label>{{'contact.subject' | translate}} <span class="required">*</span></label>
              <input formControlName="subject" class="form-control" />
            </div>
            <div class="col-md-12 col-sm-12">
              <label>{{'contact.message' | translate}} <span class="required">*</span></label>
              <textarea formControlName="body" class="form-control" cols="50" rows="5"></textarea>
            </div>
            <p class="required mt-3">
              * {{'common.required_fields' | translate}}
            </p>
            <div class="col-md-12">
              <button type="submit" class="btn btn-success btn-md" [disabled]="form.invalid || submitting">
                <span>
                  <span>{{'common.submit' | translate}}</span>
                </span>
              </button>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
    <aside class="sidebar col-lg-4 col-md-5 col-sm-12 mb-5">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">{{'contact.contact_info' | translate}}</div>
            <div class="card-body">
              <div class="card-text" itemscope itemtype="https://schema.org/Organization">
                <h5 itemprop="name">eFarm.CM</h5>
                <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
                  <span style="font-size: 14px;" itemprop="addressLocality">En face Gare Routière</span> <br />
                  <span style="font-size: 14px;" itemprop="addressRegion">Littoral Region, Cameroon</span> <br />
                </div>
                <span style="font-size: 14px;" itemprop="telephone">Whatapp / Tel: +237 681 763 523 | +237 652 730 173</span> <br />
                <span style="font-size: 14px;" itemprop="email">Email : info@efarmcm.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{'contact.location' | translate}}
              </div>
            </div>
            <ul class="list-group list-group-flush">
              <li *ngFor="let location of locations" class="list-group-item">
                <div class="card-text">
                  <span style="font-size: 16px; color: #1b8a45; ">{{location.name}}</span><br>
                  <span style="font-size: 14px;">
                    <i class="fa fa-phone"></i> : {{location.tel}}
                  </span> <br>
                  <span style="font-size: 14px;">
                    <i class="fa fa-envelope"></i> : <a [href]="'mailto://'+location.email">{{location.email}}</a>
                  </span> <br>
                  <span style="font-size: 14px;">
                    WhatsApp : {{location.whatsapp}}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  </div>
  <!-- <div>
    <hr>
    <div id="testimonials" class="testimonials-header">
      <div>
        {{'common.testimonials' | translate | uppercase}}
      </div>
      <h1 class="cta">
        {{'common.what_clients_say' | translate | titlecase}}
      </h1>
      <div class="testimonials-message">
        {{'common.testimonials_message_large' |translate }}
        {{'common.testimonials_message_short' |translate }}
      </div>
    </div>
    <div class="testimonials-parent">
      <div class="row">
        <div *ngFor="let e of testimonials" class=" col-lg-4 col-md-6 col-sm-12 ms-auto me-auto mb-4">
          <div class="card">
            <div class="card-body">
              <a target="_blank" [href]="e.externalUrl" class="overlay">{{e.author}}</a>
              <div>
                <iframe width="100%" height="250" [src]="e.url" title={{e.author}} [class.thumbnail]="thumbnail"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"></iframe>
              </div>
              <h4 class="author">
                {{e.author}}
              </h4>
              <span class="role">{{e.role}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>