<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <div class="page-title text-center">
        <h2>{{ title }}</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-left sidebar col-md-3 blog-side">
      &nbsp;
    </div>
    <div class="col-md-9 main-blog">
      <div id="main" class="blog-wrapper">
        <div id="primary" class="site-content">
          <div id="content" role="main">
            <p *ngIf="loading else blog" style="text-align: center; padding: 10px 0;">
              <label>{{'common.loading' | translate}} ...</label>
            </p>
            <ng-template #blog>
              <p *ngIf="user != null " class="blog_entry" style="text-align: right;">
                <a [routerLink]="['/blog/add']" class="btn btn-success" id="btn-submit">{{'blog.add' | translate}}</a>
              </p>
              <article *ngFor="let blog of blogPosts | paginate: config" class="blog-post-item">
                <a [routerLink]="[blog.slug != null ? blog.slug : blog.title , blog.id]" class="blog-post-thumbnail">
                  <img [src]="getImageSrc(blog.imagePath) + '?func=cropfit&width=364&dpr=1.75&bg_colour=auto&trim=0&quality=100'" width="364" height205="blog.title" />
                </a>
                <div class="blog-post-summary">
                  <h2 class="blog-post-title">
                    <a class="blog-link" style="text-decoration: none;" [routerLink]="[blog.slug != null ? blog.slug : blog.title , blog.id]">
                      {{ blog.title }}
                    </a>
                  </h2>
                  <div class="blog-post-excerpt" [innerHTML]="trimHTMLTags(blog.body) | slice:0:150"></div>
                  <div class="blog-post-meta">
                    <div class="blog-post-meta-date">
                      {{ blog.postedAt }}
                    </div>
                    <div class="blog-post-meta-comments-count">
                      <span>
                        <i class="fa fa-comments"></i> {{ blog.commentsCount }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div> -->
                  <!-- <div class="featured-thumb" *ngIf="hasImagePath(blog)">

                  </div> -->

                  <!-- <header class="blog_entry-header clearfix">
                    <div class="blog_entry-header-inner">
                      <h4 class="blog_entry-title" >
                        <a class="blog-link" style="text-decoration: none;" [routerLink]="[blog.slug != null ? blog.slug : blog.title , blog.id]">{{ blog.title }}</a>
                      </h4>
                    </div>
                  </header> -->
                  <!-- <div class="entry-content">

                  </div> -->
                  <!-- <p>
                    <a [routerLink]="[blog.slug != null ? blog.slug : blog.title , blog.id]" class="btn">{{'blog.read_more' | translate}}</a>
                  </p> -->
                <!-- </div> -->
              </article>
            </ng-template>

          </div>
        </div>
        <div class="pager" *ngIf="config.totalOfPage > 1">
            <pagination-controls class="pages" maxSize="5" [previousLabel]="'common.previous' | translate" [nextLabel]="'common.next' | translate"
                                 autoHide="true" id="listing" (pageChange)="pageChange($event)">
            </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>