import { Component, OnInit } from '@angular/core';
import { Job } from '../_models/job';
import { JobService } from '../_services/job.service';

import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../_constants/constants';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit{

  loading = true;
  jobs: Job[] = [];
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(private jobService: JobService, private toastService: ToastrService){
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.jobService.loadJobs().subscribe(
      (jobs) => {
        this.jobs = jobs;
        this.loading = false;
      },
      (error) => this.toastService.error(error)
    )
  }
}
