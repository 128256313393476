import { Component, OnInit } from '@angular/core';
import { TeamService } from 'src/app/_services/team.service';
import { Title } from '@angular/platform-browser';
import { TeamMember } from 'src/app/_models/team-member';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit {

  title = 'Team';
  loading = true;
  teamMembers: TeamMember[] = [];

  constructor(private teamService: TeamService, private titleService: Title, private router: Router) { }

  ngOnInit() {
    this.loadTeamMembers();
  }

  loadTeamMembers() {
    this.teamService.loadTeam().subscribe(
      (teamMembers: TeamMember[]) => this.teamMembers = teamMembers,
      null,
      () => this.loading = false
    );
  }

  addMember() {
    this.router.navigateByUrl('/admin/team-member/add');
  }

  deleteTeamMember(id: number) {
    this.teamService.deleteteamMember(id)
    .mergeMap(() => {
      this.loading = true;
      return this.teamService.loadTeam();
    })
    .subscribe(
      (teamMembers: TeamMember[]) => this.teamMembers = teamMembers,
      null,
      () => this.loading = false
    );
  }

}
