import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/_services/blog.service';
import { User } from 'src/app/_models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogPost } from 'src/app/_models/blog-post';
import { environment } from 'src/environments/environment';
import { IPagination } from 'src/app/_models/pagination';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css']
})
export class BlogPostComponent implements OnInit {

  user: User = null;
  title = 'blog';
  loading = true;
  loadingComments = true;
  blogPostId: string;
  blog: BlogPost;
  config: any;
  comments: Comment[] = [];
  form: UntypedFormGroup;
  currentUrl = '';
  submitting = false;

  constructor(private titleService: Title, private blogService: BlogService, private fb: UntypedFormBuilder, private activateRoute: ActivatedRoute, private router: Router) {
    this.titleService.setTitle(this.title);
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.blogPostId = this.activateRoute.snapshot.paramMap.get('id');
    this.currentUrl = this.router.url;
  }

  ngOnInit() {
    this.loadBlogPost();
    this.loadComments();
    this.buildFormComment();
  }

  loadBlogPost() {
    this.blogService.loadBlogPost(this.blogPostId).subscribe(
      (blogPost: BlogPost) => {
        this.blog = blogPost;
        this.titleService.setTitle(this.blog.title.concat(' - ' + this.title) );
      },
      () => this.router.navigateByUrl('blog'),
      () => this.loading = false
    );
  }

  loadComments() {
    this.blogService.loadComments(this.blogPostId).subscribe(
      (res: IPagination) => this.setConfig(res),
      null,
      () => this.loadingComments = false
    );
  }

  hasImagePath(blog) {
    return blog.imagePath !== '' && blog.imagePath !=  null && blog.imagePath.includes('media');
  }

  getImageSrc(imagePath) {
    return imagePath !== '' && imagePath !== null ?
    environment.image_cdn_url + '/' + imagePath + '?func=bound&width=342&height=256' : '/assets/images/blog-img1_1.jpg';
  }

  saveComment() {
    this.submitting = true;
    this.blogService.saveComment(this.blogPostId , this.form.value).subscribe(
      (res: any) => {},
      null,
      () => {
        this.submitting = false;
        this.form.removeControl('body');
        this.form.addControl('body',
        new UntypedFormControl('', [Validators.required]));
        this.loadComments();
      }
    );
  }

  convertPostedDateToISODate(strPostedDate: string) {
    return new Date(strPostedDate).toISOString();
  }

  private setConfig(result: any) {
    this.comments = result.data;
    this.config = {
        currentPage: result.page,
        itemsPerPage: result.itemPerPage,
        totalItems: result.totalOfItems,
        totalOfPage: result.totalOfPage,
        id: 'listing'
    };
  }

  private buildFormComment() {
    this.form = this.fb.group({
      id: new UntypedFormControl(0),
      userId: new UntypedFormControl(this.user != null ? this.user.id : null, [Validators.required]),
      name: new UntypedFormControl(this.user != null ? this.user.name : null, [Validators.required]),
      email: new UntypedFormControl(this.user != null ? this.user.email : '', [Validators.required]),
      city: new UntypedFormControl(this.user != null ? this.user.town : ''),
      body: new UntypedFormControl('', [Validators.required]),
    });
  }

}
