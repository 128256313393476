import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/_constants/constants';
import { CRMTemplate } from 'src/app/_models/crm-template';
import { ApiService } from 'src/app/_services/api.service';
import { CrmTemplateService } from 'src/app/_services/crm-template.service';
import { CRMService } from 'src/app/_services/crm.service';

@Component({
  selector: 'app-crm-templates',
  templateUrl: './crm-templates.component.html',
  styleUrls: ['./crm-templates.component.css']
})
export class CRMTemplatesComponent implements OnInit, OnDestroy {

  templates: CRMTemplate[] = [];

  useFrench = true;
  useEnglish = false;

  page = '1';
  loading = true;
  itemPerPage = 20;
  config = {
    currentPage: 1,
    itemsPerPage: 0,
    totalItems: 0,
    id: null
  };
  deletedAction = false;
  suspendOrActivationAction = false;

  constructor(
    public titleService: Title,
    public apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private crmTemplateService: CrmTemplateService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.useFrench = this.translateService.currentLang === AppConstants.FRENCH;
    this.useEnglish = !this.useFrench;

    this.route.queryParamMap.subscribe(
      (paramAsMap: any) => {
        if (paramAsMap.params.page) {
          this.page = paramAsMap.params.page;
        }
      }
    );
    this.loadTemplates(this.page);
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('');
  }

  loadTemplates(page) {
    this.loading = true;
    this.crmTemplateService
      .getTemplates(page).subscribe(
        (result: any) => {
          this.templates = result.data;
          this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            id: 'listing'
          };
          this.loading = false;
        }
      );
  }

  pageChange(page: string) {
    this.router.navigate(['/admin/crm-templates'], { queryParams: { page: page } });
  }

  newTemplate(template = 0) {
    if (template === 0) {
      this.router.navigate(['/admin/crm-templates/add']);
    } else {
      this.router.navigate(['/admin/crm-templates/add'], {queryParams: {id: template}});
    }
  }

  edit(template: CRMTemplate) {
    localStorage.setItem(AppConstants.CURRENT_OUTBOX_TEMPLATE_KEY, JSON.stringify(template));
    this.newTemplate(template.id);
  }

  remove(template: CRMTemplate) {
    this.crmTemplateService
      .remove(template.id)
      .subscribe(response => {
        const index = this.templates.findIndex(v => v.id === template.id);
        this.templates.splice(index, 1);
      });
  }
}
