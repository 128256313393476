import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../_constants/constants';
import { UserService } from '../_services/user.service';
import { User } from '../_models/user';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  appCurrentLang: string;
  user: User;
  availableLangs: string[] = [AppConstants.FRENCH, AppConstants.ENGLISH];
  currentLanguage = localStorage.getItem(AppConstants.CURRENT_LANG_STORE_KEY);

  constructor(private translationService: TranslateService, public userService: UserService) {
    this.appCurrentLang = this.translationService.currentLang;
    this.user = JSON.parse(localStorage.getItem(AppConstants.CURRENT_USER_KEY));
  }

  changeLanguage(newLanguage: string) {
    // use the new languages
    if (this.availableLangs.includes(newLanguage)) {
      this.translationService.use(newLanguage)
        .subscribe(response => {
          this.appCurrentLang = newLanguage;

          // persist the new language
          localStorage.setItem(AppConstants.CURRENT_LANG_STORE_KEY, this.appCurrentLang);
          // refresh the page for complete translation
          location.reload();
        });
    } else { throw new Error('Unknown Language'); }
  }

  ngOnInit() {
    throw new Error('Method not implemented.');
  }

}
