import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BlogService } from 'src/app/_services/blog.service';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { BlogPost } from 'src/app/_models/blog-post';
import { IPagination } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  title = 'Blog';
  page = '1';
  blogPosts: BlogPost[] = [];
  loading = true;
  config: any;
  user: User = null;

  constructor(private titleService: Title, private blogService: BlogService, private router: Router, private activeRoute: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.titleService.setTitle(this.title);
    const p = this.activeRoute.snapshot.queryParamMap.get('page');
    if ( p !== null ) {
      this.page = p;
    }
  }

  ngOnInit() {
    this.loadBlog();
    window.scrollTo(0, 0);
  }

  loadBlog() {
    this.blogService.loadBlog(this.page).subscribe(
      (iPagenition: IPagination) => this.setConfig(iPagenition),
      null,
      () => this.loading = false
    );
  }

  hasImagePath(blog) {
    return blog.imagePath !== '' && blog.imagePath !=  null && blog.imagePath.includes('media');
  }

  getImageSrc(imagePath) {
    return imagePath !== '' && imagePath !== null && imagePath.includes('media') ?
    environment.image_cdn_url + '/' + imagePath : '/assets/images/blog-img1_1.jpg';
  }

  pageChange(page) {
    this.page = page;
    this.router.navigate(['blog'], {queryParams: {page: page}});
    this.loadBlog();
    window.scrollTo(0, 0);
 }

  private setConfig(result: any) {
    this.blogPosts = result.data;
    this.config = {
        currentPage: result.page,
        itemsPerPage: result.itemPerPage,
        totalItems: result.totalOfItems,
        totalOfPage: result.totalOfPage,
        id: 'listing'
    };
  }

  trimHTMLTags(content: string) {
    return content.replace(/(<([^>]+)>)/ig, '')
  }

}
