import {Component, OnInit} from '@angular/core';
import {Product} from '../../_models/product';
import {ProductService} from '../../_services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MediaService} from '../../_services/media.service';
import {AlertService} from '../../_services/alert.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

    products: Product[];
    loading: boolean;
    config: any;
    page = '1';
    searchForm: UntypedFormGroup;
    searchTerm = '';

    constructor(private productService: ProductService,
                private router: Router,
                private mediaService: MediaService,
                private alertService: AlertService,
                private toastr: ToastrService,
                private fb: UntypedFormBuilder,
                private route: ActivatedRoute) {
        this.searchForm = this.fb.group({
            searchTerm: new UntypedFormControl('')
        });
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(
            (paramAsMap: any) => {
                if (paramAsMap.params.page) {
                    this.page = paramAsMap.params.page;
                }
                if (paramAsMap.params.searchTerm) {
                    this.searchTerm = paramAsMap.params.searchTerm;
                }
            }
        );
        this.router.url.includes('search') ? this.searchProducts(this.searchTerm, this.page) : this.loadProducts(this.page);
    }
    //
    // loadAllPictures() {
    //     this.products.forEach((product) => {
    //         this.mediaService.loadMedia('product', product.id).subscribe(
    //             (media: any[]) => {
    //                 const img =  media.length > 0 ? media[0].data : '';
    //                 if ( img !== '') {
    //                     document.getElementById('img-' + product.id).setAttribute('src', img);
    //                 }
    //             }
    //         );
    //     });
    // }

    loadProducts(page: string) {
        this.loading = true;
        this.productService.loadProducts(page).subscribe(
            (result) => {
                this.products = result.data;
                this.config = {
                    currentPage: result.page,
                    itemsPerPage: result.itemPerPage,
                    totalItems: result.totalOfItems,
                    id: 'listing'
                };
                this.loading = false;
            }
        );
    }

    addProduct() {
        window.localStorage.removeItem('product');
        this.router.navigate(['admin/products/add']);
    }

    editProduct(product: Product) {
        window.localStorage.removeItem('product');
        window.localStorage.setItem('product', JSON.stringify(product));
        this.router.navigate(['admin/products', product.id, 'edit']);
    }

    deleteProduct(product: Product) {
    }

    pageChange(page) {
        this.page = page;
        this.router.navigate(['/admin/products'], {queryParams: {page: page}});
        this.loadProducts(this.page);
    }

    searchProducts(searchTerm: string, page: string = '1') {
        this.loading = true;
        const searchBtn = document.getElementById('btn-search');
        searchBtn.innerText = 'Searching ...';
        this.productService.search(this.searchTerm, page).subscribe(
            (result: any) => {
                searchBtn.innerText = 'Search';
                this.products = result.data;
                this.config = {
                    currentPage: result.page,
                    itemsPerPage: result.itemPerPage,
                    totalItems: result.totalOfItems,
                    id: 'listing'
                };
                this.loading = false;
            },
            () => this.loading = false,
            () => this.loading = false
        );
    }

    search() {
        if (this.searchForm.value.searchTerm !== '') {
            this.router.navigate(['/admin/products/search'], {
                queryParams: {
                    searchTerm: this.searchForm.value.searchTerm,
                    page: this.page
                }
            });
        } else {
            this.router.navigate(['/admin/products']);
        }
    }


    printImage(product) {
        return product.picture !== '' && product.picture !== null  ?
            environment.image_cdn_url + '/' + product.picture + '?func=crop&width=45&height=45' : '/assets/images/default.jpg';
    }

}
