import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WishList } from '../_models/wish-list';
import { API_URLS } from '../config/api.urls';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  constructor(private httpClient: HttpClient) { }

  public myWishList(userId: number): Observable<WishList[]> {
      return this.httpClient.get<WishList[]>(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/wish_list');
  }

  public addToWishList(userId: number, offerId: number) {
    return this.httpClient.post(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/wish_list/add', {
      userId: userId,
      offerId: offerId
    });
  }

  public removeFromWishList(id: number, userId: number) {
    return this.httpClient.delete(API_URLS.LOAD_CURRENT_USER_DETAILS + '/' + userId + '/wish_list/' + id);
  }

}
