import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { API_URLS } from '../config/api.urls';
import { Observable } from 'rxjs';
import { IPagination } from '../_models/pagination';
import { Pricing } from '../_models/pricing';

@Injectable()
export class PricingService {

    constructor(private http: HttpClient) {}


    get_pricings(): Observable<IPagination> {
        return this.http.get<IPagination>(API_URLS.LIST_OF_PRICING, { params: {itemPerPage: '-1', page: '1' } });
    }

    local_pricings(itemPerPage, page): Observable<IPagination> {
        return this.http.get<IPagination>(API_URLS.LIST_OF_PRICING, {
            params:  {
                itemPerPage: itemPerPage,
                page: page
            }
        });
    }

    add_pricing(pricing: Pricing) {
        return this.http.post(API_URLS.SINGLE_PRICING, pricing);
    }

    update_pricing(pricing: Pricing) {
        return this.http.put(API_URLS.SINGLE_PRICING  + '/' + pricing.id, pricing );
    }

    delete_pricing(pricing: Pricing) {
        return this.http.delete(API_URLS.SINGLE_PRICING + '/' + pricing.id);
    }
}
