import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../_models/user';
import {UserService} from '../../_services/user.service';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  users: User[];
  page = '1';
  searchTerm;
  role;
  loading =  true;
  itemPerPage = 20;
  config = {
      currentPage: 1,
      itemsPerPage: 0,
      totalItems: 0,
      id: null
  };
  searchForm: UntypedFormGroup;
  deletedAction = false;
  suspendOrActivationAction = false;

  static hideOtherElement(eltId) {
        const nodes =  document.getElementById('my-orders-table').getElementsByTagName('p');
        for ( let i = 0; i < nodes.length; i++ ) {
            nodes[i].className = 'hidden';
        }
        document.getElementById(eltId).classList.remove('hidden');
  }

  constructor(public titleService: Title, public apiService: ApiService, private userService: UserService,
              private activatedRoute: ActivatedRoute, private  router: Router, private fb: UntypedFormBuilder,
              private alertService: AlertService) {
    this.apiService.admin_layout = true;
    this.searchTerm = this.activatedRoute.snapshot.queryParamMap.get('searchTerm');
    this.role = this.activatedRoute.snapshot.queryParamMap.get('role');
    this.page = this.activatedRoute.snapshot.queryParamMap.get('page');
    this.searchForm = this.fb.group({
        'searchTerm': [this.searchTerm],
        'role': [this.role],
    });
  }

  ngOnInit() {
    this.router.url.includes('search') ? this.doSearch(
        { searchTerm: this.searchTerm, role: this.role}, this.page == null ? 1 :  this.page) :
        this.loadUsers(this.page == null ? 1 : this.page);
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('');
  }

  loadUsers(page) {
    this.loading = true;
    this.userService.loadUsers(page).subscribe(
        (result: any) => {
          this.users = result.data;
          this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            id: 'listing'
          };
          this.loading = false;
        }
    );
  }

  doSearch(values: any, page) {
    this.loading = true;
    const searchBtn = document.getElementById('btn-search');
    searchBtn.innerText = 'Searching ...';
    this.userService.searchUsers(values, page).subscribe(
        (result: any) => {
          searchBtn.innerText = 'Search';
          this.users = result.data;
          this.config = {
            currentPage: result.page,
            itemsPerPage: result.itemPerPage,
            totalItems: result.totalOfItems,
            id: 'listing'
          };
          this.loading = false;
        }
    );
  }

  searchUsers(page: string = '1') {
      console.log('I am doing search');
      this.doSearch(this.searchForm.value, page);

    this.router.navigate(['/admin/users/search'], { queryParams: {
        searchTerm: this.searchForm.value.searchTerm,
        role: this.searchForm.value.role,
        page: page
      }
    });
  }

    pageChange(page: number) {
    this.page = `${page}`;
      console.log('I am in pageChange');
        console.log();
    if (this.activatedRoute.snapshot.url.length > 1) {
      this.router.navigate(['/admin/users/search'], {
        queryParams: {
          searchTerm: this.searchForm.value.searchTerm,
          role: this.searchForm.value.role,
          page: page
        }
      });
      this.doSearch(this.searchForm.value, this.page);
    } else {
      this.router.navigate(['/admin/users'], { queryParams: {page: page}});
      this.loadUsers(this.page);
    }
  }

  addUser() {
    this.router.navigate(['/admin/users/add']);
  }

  editUser(user: User) {
    localStorage.setItem('user-to-edit', JSON.stringify(user));
    this.router.navigateByUrl('admin/users/' + user.id + '/edit');
  }

  activeOrDeactivateUser (eltId) {
    // hide confirmation message if open
    this.suspendOrActivationAction = true;
    this.deletedAction = false;
    UserComponent.hideOtherElement(eltId);
  }

  confirmSuspendOrActivation (user: User) {
    document.getElementById('user' + user.id).innerHTML = 'updating ...';
    this.userService.changeStatus(user, user.activated ? 0 : 1).subscribe(
        (result: any) => {
          this.alertService.success(result.message);
        },
        null,
        () => this.loadUsers(this.page == null ? 1 : this.page)
    );
    this.suspendOrActivationAction = false;
  }

  cancelAction (eltId) {
    document.getElementById(eltId).classList.add('hidden');
    this.suspendOrActivationAction = false;
    this.deletedAction = false;
  }

  deleteUser (eltId) {
    this.suspendOrActivationAction = false;
    this.deletedAction = true;
    UserComponent.hideOtherElement(eltId);
  }

  confirmDeleteAction (user: User) {
    document.getElementById('user' + user.id).innerHTML = 'deleting ...';
    this.userService.deleteUser(user).subscribe(
        (result: any) => {
          this.loadUsers(this.page == null ? 1 : this.page);
          this.alertService.success(result.message);
        }
    );
    this.deletedAction = false;
  }
}
