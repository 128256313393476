<!-- Category Listing Start -->
<div class="container-fluid">
  <div class="categories mb-3">
    <div class="row align-items-center">
      <div class="category-slider mx-auto px-3">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)"
        >
          <div
            ngxSlickItem
            *ngFor="let category of categories"
            class="item text-center categ_card"
          >
            <a [routerLink]="['/products/category', category.slug]">
              <div class="pad15 d-flex justify-content-center">
                <img [src]="printImage(category)" alt="" />
              </div>
              <div class="category_name">
                <p class="text-center mb-0 mt-2">
                  {{ lang == 'en' ? category.name : category.nameFr }}
                </p>
              </div>
            </a>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>
<!-- Category Listing End -->
