import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {MustMatch} from '../../_helpers/MustMatch';
import {IProductCategory} from '../../_models/iproduct-category';
import {ProductCategoryService} from '../../_services/product-category.service';
import {CountryService} from '../../country/country.service';
import {Country} from '../../country/Country';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../_models/user';
import {UserService} from '../../_services/user.service';
import {CommonService} from '../../_services/common.service';
import {combineLatest} from 'rxjs';
import { SectorService } from 'src/app/_services/sector.service';
import { Sector } from 'src/app/_models/sector';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit, OnDestroy {

  loading = true;
  userForm: UntypedFormGroup;
  user: User;
  countries: Country[];
  productCategories: IProductCategory[];
  submitting = false;
  userTypes = ['user', 'business', 'admin'];
  userToEdit: any;
  businessTypes: any[];
  sectors: Sector[] =[];
  userId: any;

  constructor (
    private fb: UntypedFormBuilder,
    public location: Location,
    private toastService: ToastrService,
    private sectorService: SectorService,
    private commonService: CommonService,
    private pcs: ProductCategoryService,
    private cs: CountryService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
      this.userId = this.activatedRoute.snapshot.paramMap.get('id') || 0;

    this.userForm = this.fb.group({
      id: new UntypedFormControl(0),
      name: new UntypedFormControl('', Validators.required),
      userType: new UntypedFormControl('user', Validators.required),
      businessType: new UntypedFormControl(''),
      sectorId: new UntypedFormControl(null, Validators.required),
      phone: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.email),
      overview: new UntypedFormControl(''),
      website: new UntypedFormControl(''),
      contactName: new UntypedFormControl(''),
      contactPhoneNumber: new UntypedFormControl(''),
      state: [''],
      productCategoryId: new UntypedFormControl(0),
      town: new UntypedFormControl('', Validators.required),
      code: new UntypedFormControl(37, Validators.required),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('')
    }, {
      validator: MustMatch('password', 'confirmPassword')});
  }

  ngOnInit() {
    combineLatest([
      this.cs.loadAll(),
      this.commonService.loadAll(),
      this.pcs.get_product_categories(),
      this.sectorService.getAll(),
      this.userService.loadByAdmin(this.userId)
    ]).subscribe(
        ([countries, businessTypes, productCategories, sectors, userToEdit]) => {
          this.countries = countries;
          this.businessTypes = businessTypes;
          this.productCategories = productCategories;
          this.sectors = sectors;
          this.userToEdit = userToEdit;


          this.setFormValues();
        },
        (error) => {
          this.toastService.error(error);
          this.router.navigateByUrl('/admin/users');
        },
        () => {
          this.loading = false;
        }
    );
  }

  setFormValues(){
    if (this.userToEdit !== null ) {
      this.userForm.patchValue({
        id: this.userToEdit.id,
        name: this.userToEdit.name,
        email: this.userToEdit.email,
        userType: this.userToEdit.role,
        businessType: this.userToEdit.businessType,
        sectorId: this.userToEdit.sector ? this.userToEdit.sector.id : 0,
        phone: this.userToEdit.phone,
        overview: this.userToEdit.overview,
        website: this.userToEdit.website,
        contactName: this.userToEdit.contactName,
        contactPhoneNumber: this.userToEdit.contactPhone,
        code: this.userToEdit.country ? this.userToEdit.country : 37,
        state: this.userToEdit.state,
        town: this.userToEdit.town,
        productCategoryId: 0,
        password: '',
        confirmPassword: ''
      });
      this.userForm.get('password').clearValidators();
      this.userForm.get('confirmPassword').clearValidators();
    }
  }

  get f() { return this.userForm.controls; }

  isBusinessAccount(u: User): boolean {
    return u != null && u.role === 'business';
  }

  back_to_previous() {
    this.location.back();
  }

  submitUserForm() {
    if (!this.userForm.valid) {
      return;
    }
    this.submitting = true;

    if ( !this.userForm.value.id ) {
      this.userService.addUser(this.userForm.value).subscribe(
          () => {
            this.router.navigate(['/admin/users']);
          },
          () => {
            this.submitting = false;
          }
      );
    } else {
      this.userService.updateUser(this.userForm.value).subscribe(
          () => {
            this.router.navigate(['/admin/users']);
          },
          () => {
            this.submitting = false;
          }
      );
    }
  }

  ngOnDestroy(): void {
    window.localStorage.removeItem('user-to-edit');
  }

}
