import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URLS} from '../config/api.urls';
import {Observable} from 'rxjs';
import {IPagination} from '../_models/pagination';
import {ShippingType} from '../_models/shipping-type';
import {ShippingMean} from '../_models/shipping-mean';

@Injectable({
    providedIn: 'root'
})
export class ShippingMeanService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(page): Observable<IPagination> {
        return this.httpClient.get<IPagination>(API_URLS.SHIPPING_MEANS_BASE_URL, {params: {page: page}});
    }

    addShippingMean(resource: any) {
        return this.httpClient.post(API_URLS.SHIPPING_MEANS_BASE_URL, resource);
    }

    updateShippingMean(shippingMeanId, resource) {
        return this.httpClient.put(API_URLS.SHIPPING_MEANS_BASE_URL + '/' + shippingMeanId, resource);
    }

    deleteShippingMean(shippingMeanId) {
        return this.httpClient.delete(API_URLS.SHIPPING_MEANS_BASE_URL + '/' + shippingMeanId);
    }

    getShippingTypes(): Observable<ShippingType[]>  {
        return this.httpClient.get<ShippingType[]>(API_URLS.SHIPPING_TYPES_BASE_URL);
    }

    getAllShippingMeans(): Observable<ShippingMean> {
        return this.httpClient.get<ShippingMean>(API_URLS.SHIPPING_MEANS_UNAUTHENTICATED_BASE_URL);
    }

}
