import { environment } from '../../environments/environment';

export const API_URLS = {
    LIST_OF_COUNTRIES: environment.apiUrl + '/countries',
    LIST_OF_BUSINESS_TYPES: environment.apiUrl + '/businessTypes',
    LIST_OF_PRODUCT_CATEGORIES: environment.apiUrl + '/product_categories',
    REGISTER: environment.apiUrl + '/oauth/register',
    CHECK_TOKEN: environment.apiUrl + '/oauth/check_token',
    LOGIN: environment.apiUrl + '/oauth/token',
    LOGOUT: environment.apiUrl + '/oauth/logout',
    LOAD_CURRENT_USER_DETAILS: environment.apiUrl + '/user',
    USERS_BASE_PATH: environment.apiUrl + '/users/',
    FORGET_PASSWORD: environment.apiUrl + '/oauth/password-reset/request-token',
    PASSWORD_VERIFY_TOKEN: environment.apiUrl + '/oauth/password-reset/verify-token',
    VERIFY_SIGN_UP_TOKEN: environment.apiUrl + '/oauth/verify_token',
    UPDATE_PASSWORD: environment.apiUrl + '/oauth/password-reset/update-password',
    PRODUCT_CATEGORIES_API: environment.apiUrl + '/secure/product_categories',
    LIST_OF_USERS: environment.apiUrl + '/secure/users/',
    LIST_OF_PRODUCTS: environment.apiUrl + '/secure/products',
    LIST_OF_SECTORS: environment.apiUrl + '/sectors',
    LIST_OF_PRICING: environment.apiUrl + '/pricing',
    LIST_OF_PRODUCTS_CONDITIONS: environment.apiUrl + '/product_conditions',
    LIST_OF_MEASUREMENT: environment.apiUrl + '/measurement_units',
    MEDIA_BASE_URL: environment.apiUrl + '/media',
    OFFERS_BASE_URL: environment.apiUrl + '/offers',
    RELATED_OFFERS_BASE_URL: environment.apiUrl + '/relatedOffers',
    SEARCH_URL: environment.apiUrl + '/search',
    LIST_OF_PAYMENT_METHODS: environment.apiUrl + '/payment_methods',
    PAYMENT_METHODS_BASE_URL: environment.apiUrl + '/secure/payment_methods',
    SHIPPING_MEANS_BASE_URL: environment.apiUrl + '/secure/shipping_means',
    SHIPPING_MEANS_UNAUTHENTICATED_BASE_URL: environment.apiUrl + '/shipping_means',
    SHIPPING_TYPES_BASE_URL: environment.apiUrl + '/shipping_types',
    PAYMENTS_BASE_URL: environment.apiUrl + '/payments',
    BLOG_BASE_URL: environment.apiUrl + '/blog',
    ADMIN_PAGES_BASE_URL: environment.apiUrl + '/secure/pages',
    SINGLE_PAGE_BASE_PATH: environment.apiUrl + '/pages',
    CONTACT_US_MAIL_API: environment.apiUrl + '/contact-us/send-mail',
    TEAM_BASE_PATH: environment.apiUrl + '/team_member',
    TAXONOMY_URL: environment.apiUrl + '/taxonomies',
    SECURE_TEAM_BASE_PATH: environment.apiUrl + '/secure/team_member',
    SECURE_REQUESTED_PRODUCTS_BASE_PATH: environment.apiUrl + '/secure/request_products',
    SINGLE_PRICING: environment.apiUrl + '/secure/pricing',
    SINGLE_SECTOR_API: environment.apiUrl + '/secure/sectors',
    NEWSLETTER_URL: environment.apiUrl + '/subscriptions',
    JOBS_URL: environment.apiUrl + '/jobs',
};

export const SECURE_API_URLS = {
    MEASUREMENT: environment.apiUrl + '/secure/measurement_units',
    LIST_OF_PRODUCTS_CONDITIONS: environment.apiUrl + '/secure/product_conditions',
    OUTBOX_BASE_URL: environment.apiUrl + '/secure/outbox',
    TEMPLATES_BASE_URL: environment.apiUrl + '/secure/templates',
    OFFERS_BASE_URL: environment.apiUrl + '/secure/offers',
    GROUPS_BASE_URL: environment.apiUrl + '/secure/groups',
    BLOGS_BASE_URL: environment.apiUrl + '/secure/blogs',
    ORDERS_BASE_URL: environment.apiUrl + '/secure/orders',
    JOBS_BASE_URL: environment.apiUrl + '/secure/jobs',
    USERS_SEARCH_BASE_URL: environment.apiUrl + '/secure/users/search-eFarms',
};

export const ADMIN_API_URLS = {
    PAYMENTS_PATH: environment.apiUrl + '/secure/payments'
};

export const EXT_URLS = {
    FIXER_CURRENCY_EXCHANGE: `http://data.fixer.io/api/latest?access_key=${environment.fixerKey}`,
    EXCHANGE_RATE_API_URL: `https://v6.exchangerate-api.com/v6/${environment.exchangeRateApiKey}/pair`
};

