import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS} from '../config/api.urls';
import { CartService } from './cart.service';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) {}

    login(formData: FormData) {
        formData.append('client_id', 'efarm-web-app');
        formData.append('grant_type', 'password');
        return this.http.post(API_URLS.LOGIN, formData, { withCredentials: true });
    }

    logout() {
        // remove user from local storage to log user out
        this.http.delete(API_URLS.LOGOUT).subscribe();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tokenDetails');
    }

    checkToken() {
        const tokenDetails = JSON.parse(localStorage.getItem('tokenDetails'));
        if (tokenDetails !== null) {
            return this.http.get(API_URLS.CHECK_TOKEN, { params: { token: (tokenDetails && tokenDetails.access_token) ?
                tokenDetails.access_token : '' }});
        }
    }

    isTokenValid() {
        const tokenExpiredAt = localStorage.getItem('tokenExpiredAt');
        const today = new Date();
        return tokenExpiredAt && (parseInt(tokenExpiredAt, 0) <= today.getTime());
    }
}
