<div class="recent-orders">
  <div class="title-buttons">
    <strong>New Team Member </strong>
  </div>
  <br />
  <br />
 <p *ngIf="loading; else memberForm">Loading ...</p>
  <ng-template #memberForm>
    <div class="table-responsive">
      <div class="form-page-block">
        <div class="card">
          <div class="card-header">
            <span>&nbsp;</span>
          </div>
          <div class="card-body">
            <form class="form-page" [formGroup]="form" (ngSubmit)="addMember()">
              <input type="hidden" formControlName="id" />
              <div class="form-group">
                <label>Full Name <span class="required">*</span> </label>
                <input type="text" class="form-control" formControlName="fullName" />
              </div>
              <div class="form-group mt-3">
                <label>Job Position <span class="required">*</span> </label>
                <input type="text" class="form-control" formControlName="jobPosition" />
              </div>
              <div class="form-group mt-3">
                <label>Profile Picture <span *ngIf="teamMember == null"  class="required">*</span> </label><br>
                <input type="file" formControlName="picture" (change)="onChangeFile($event)" />
              </div>
              <div class="form-group mt-3">
                <label>Description <span class="required">*</span> </label>
                <textarea type="text" class="form-control" formControlName="description" cols="50" rows="8"></textarea>
              </div>
              <div class="form-group mt-3">
                <label>Weight <span class="required">*</span> </label>
                <select formControlName="weight" class="form-control">
                  <option *ngFor="let w of weights" [value]="w">{{ w }}</option>
                </select>
              </div>
              <div class="button-set mt-3">
                <button [disabled]="form.invalid || submitting" class="btn btn-success" id="btn-submit" type="submit">
                  Save
                </button>
                &nbsp;
                <span *ngIf="submitting">saving ...</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
